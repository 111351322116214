import React from "react";
import CheckedIcon from "../icons/CheckedIcon";

interface AlertInfoProps {
  to?: string;
  text: string;
  linkText?: string;
}

const AlertSuccess = (props: AlertInfoProps) => (
  <div className="rounded-md bg-green-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <CheckedIcon />
      </div>
      <div className="ml-3">
        <div className="text-lg text-green-700">
          <div>{props.text}</div>
          {props.to && (
            <div className="mt-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={props.to}
                className="font-medium underline text-green-700 hover:text-yellow-600"
              >
                {props.linkText}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default AlertSuccess;
