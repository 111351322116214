import React from "react";
import {
  ChevronDownIcon,
  SearchIcon,
  SortAscendingIcon,
} from "@heroicons/react/solid";
import OngoingPill from "./OngoingPill";

const people = [
  {
    name: "Eric Rea",
    title: "11/12/2021",
    event: "Cancellation",
    status: "ongoing",
    email: "e.rea@podium.com",
    subscription: "Premium",
    frequency: "Monthly",
    priority: "2",
    image:
      "https://images.unsplash.com/photo-1504257432389-52343af06ae3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  {
    name: "Jon Dahl",
    title: "11/12/2021",
    event: "Cancellation",
    status: "recovered",
    email: "jon@mux.com",
    subscription: "Premium",
    frequency: "Annual",
    priority: "1",
    image:
      "https://images.unsplash.com/photo-1552374196-c4e7ffc6e126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  {
    name: "Amir Nathoo",
    title: "11/12/2021",
    event: "Cancellation",
    status: "recovered",
    email: "nathoo@outschool.com",
    subscription: "Premium",
    frequency: "Annual",
    priority: "1",
    image:
      "https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
];

const StatusPill = (props) => {
  switch (props.status) {
    case "ongoing":
      return <OngoingPill />;
    case "recovered":
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          Recovered
        </span>
      );
    default:
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blueish text-blue-900">
          Saved
        </span>
      );
  }
};

const ChatButton = () => {
  return (
    <button className="hover:bg-blue-600 bg-gray-400 text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center shadow-md hover:shadow-sm">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
        />
      </svg>
    </button>
  );
};
const StatsButton = () => {
  return (
    <button className="hover:bg-blue-600 bg-gray-400 text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center shadow-md hover:shadow-sm">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
    </button>
  );
};

export default function SmartIncentivesHeroAnimation() {
  return (
    <div className="mt-4 mx-auto md:px-3 lg:px-14">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-2">
            <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
              <div className="rounded-t-lg px-4 pt-2 border-bottom border-gray-200">
                <div className="pb-3 sm:flex sm:items-center sm:justify-between">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Customer Activity Feed
                  </h3>
                  <div className="mt-3 sm:mt-0 sm:ml-4">
                    <label
                      htmlFor="mobile-search-candidate"
                      className="sr-only"
                    >
                      Search
                    </label>
                    <label
                      htmlFor="desktop-search-candidate"
                      className="sr-only"
                    >
                      Search
                    </label>
                    <div className="flex rounded-md shadow-sm">
                      <div className="relative flex-grow focus-within:z-10">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <SearchIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="text"
                          name="mobile-search-customer"
                          id="mobile-search-customer"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:hidden border-gray-300"
                          placeholder="Search"
                        />
                        <input
                          type="text"
                          name="desktop-search-customer"
                          id="desktop-search-customer"
                          className="hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300"
                          placeholder="Search customers"
                        />
                      </div>
                      <button
                        type="button"
                        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                      >
                        <SortAscendingIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="ml-2">Sort</span>
                        <ChevronDownIcon
                          className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      NAME
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:inline-flex"
                    >
                      EVENT
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:overflow-hidden"
                    >
                      SUBSCRIPTIONS
                    </th>
                    <th
                      scope="col"
                      className="relative px-6 py-3 hidden lg:inline-flex"
                    >
                      <span className="sr-only">ACTIONS</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {people.map((person) => (
                    <tr key={person.email}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0  h-10">
                            <img
                              className="h-10 rounded-full border-4 border-gray-300"
                              src={person.image}
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {person.name}
                            </div>
                            <div className="text-sm text-gray-500">
                              {person.email}
                            </div>

                            <div className="flex items-start mt-2 md:hidden">
                              <div className="text-sm font-medium text-gray-900 mr-2">
                                {person.event}
                              </div>
                              <StatusPill status={person.status} />
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap hidden md:inline-block">
                        <div className="text-sm font-medium text-gray-900">
                          {person.event}
                        </div>
                        <StatusPill status={person.status} />
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap hidden lg:overflow-hidden">
                        <div className="text-sm font-medium text-gray-900">
                          {person.subscription}
                        </div>
                        <div className="text-sm font-light text-gray-900">
                          {" "}
                          ({person.frequency})
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap hidden lg:inline-block">
                        <ChatButton /> <StatsButton />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
