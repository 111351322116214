import React, { useEffect, useState } from "react";
import SingleMetric, { SingleMetricProps } from "./SingleMetric";
import { MetricsResponse, StatsResponse } from "../../../domain/metricsModel";
import { Rates } from "../../../domain/DomainModel";

export interface KpiBlocksProps {
  statsData?: StatsResponse;
  churnData?: MetricsResponse;
  currency: string;
  numMonths: number;
  limitToNMetrics?: number;
  rates: Rates;
  convertOthers: boolean;
  loading: boolean;
  mapper: (
    statsData: StatsResponse | undefined,
    churnData: MetricsResponse | undefined,
    currency: string,
    numMonths: number,
    rates: Rates,
    convertOthers: boolean
  ) => SingleMetricProps[];
}

const KpiBlocks = (props: KpiBlocksProps): React.ReactElement | null => {
  const {
    statsData,
    churnData,
    currency,
    numMonths,
    limitToNMetrics,
    rates,
    convertOthers,
    mapper,
  } = props;

  const [sourceKpiBlock, setSourceKpiBlock] = useState<SingleMetricProps[]>(
    mapper(statsData, churnData, currency, numMonths, rates, convertOthers)
  );

  useEffect(() => {
    const metrics = mapper(
      statsData,
      churnData,
      currency,
      numMonths,
      rates,
      convertOthers
    );

    if (limitToNMetrics) {
      setSourceKpiBlock(metrics.slice(0, limitToNMetrics));
    } else {
      setSourceKpiBlock(metrics);
    }
  }, [
    statsData,
    churnData,
    currency,
    numMonths,
    limitToNMetrics,
    convertOthers,
    rates,
    mapper,
  ]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
      {sourceKpiBlock.map((chart) => (
        <SingleMetric
          key={chart.description}
          {...chart}
          loading={props.loading}
        />
      ))}
    </div>
  );
};

export default KpiBlocks;
