import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { SharedContext } from "./SharedProvider";
import SnackBarMessage from "./SnackBarMessage";

const Footer = () => {
  const utils: { SnackConfig: any; setSnackConfig: Function } = useContext<any>(
    SharedContext
  );

  return (
    <div className="footer fixed-bottom">
      <SnackBarMessage
        {...utils.SnackConfig}
        setSnackConfig={utils.setSnackConfig}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h3 className="footer-usp">
              Retention & Growth
              <br /> Apps for SaaS
            </h3>
          </div>
          <div className="col-9">
            <div className="row pm-5">
              <div className="col-md-3">
                <strong>Product</strong>
                <br />
                <ul className="my-3">
                  <li>
                    <Link to="/features/reporting-dashboard">
                      Reporting Dashboard
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <strong>Company</strong>
                <br />
                <ul className="my-3">
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-service">Terms of service</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy policy</Link>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-3">
                <strong>Office</strong>
                <p className="mb-3">
                  WeWork, <br />
                  Victoria, London
                  <br /> SW1W 9SH
                </p>
              </div>
              <div className="col-md-3">
                <strong>Happy client?</strong>
                <p>please, leave us a review:</p>
                <a href="https://www.trustpilot.com/review/tenstars.co">
                  <img
                    className="my-3 m-md-1"
                    src={require("../../img/logo/trustpilot.svg")}
                    alt="Trustpilot"
                    width="120px"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="footer-detail">
            <div className="col">
              <p className="text-right">
                We are a distributed company, by design. | ©2020-2021 TenStars
                Ltd.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
