import ExpandableContainer from "../../basic/ExpandableContainer";
import React from "react";
import { MetricsResponse } from "../../../domain/metricsModel";
import LtvChart from "./LtvChart";
import { Rates } from "../../../domain/DomainModel";

interface DashboardLtvChartProps {
  numMonths: number;
  currency: string;
  data?: MetricsResponse;
  rates: Rates;
  convertOthers: boolean;
  menuClosed: boolean;
  loading: boolean;
  minHeight?: number;
  defaultExpanded?: boolean;
}

const DashboardChurnChart = (props: DashboardLtvChartProps) => {
  const [isExpanded, setExpanded] = React.useState(false);

  return (
    <ExpandableContainer
      onUpdate={setExpanded}
      minHeight={props.minHeight}
      defaultExpanded={props.defaultExpanded}
    >
      <LtvChart
        key={`ltv-${isExpanded}/${props.numMonths}/${props.currency}/${props.data?.testMode}/${props.data?.byMonth.length}/${props.convertOthers}/${props.menuClosed}`}
        loading={props.loading}
        currency={props.currency}
        data={props.data}
        numMonths={props.numMonths}
        rates={props.rates}
        convertOthers={props.convertOthers}
      />
    </ExpandableContainer>
  );
};

export default DashboardChurnChart;
