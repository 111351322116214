import React from "react";
import Header from "../../components/Header/Header";
import SignUpComponent from "../../components/onboarding/SignUpComponent";
import { AuthUserContext, withAuthorization } from "../../components/session";

import { REDIRECT_STORAGE_KEY } from "../../components/common/StripeConnectButton";
import { HOME_URL } from "../protected/MainPage/resources";
import { RouteComponentProps } from "react-router-dom";
import { AuthUser } from "../../domain/DomainModel";
import amplitudeService, {
  USER_REGISTERED_EMAIL,
} from "../../service/amplitudeService";

const INITIAL_STATE = {
  step_register: false,
  register_loading: false,
};

interface RegisterPageState {
  register_loading: boolean;
}

class RegisterPage extends React.Component<
  RouteComponentProps,
  RegisterPageState
> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static contextType = AuthUserContext;

  changeLoadingState = () => {
    this.setState((prevState) => ({
      register_loading: !prevState.register_loading,
    }));
  };

  sendEvent = (username: string, email: string): void => {
    amplitudeService
      .getInstance()
      .logEvent(USER_REGISTERED_EMAIL, { username, email });

    if (window.dataLayer) {
      window.dataLayer.push({ event: "onboarding_register_email" });
    } else {
      console.log("datalayer not defined, event will not be saved");
      return;
    }
  };

  onSuccessfulRegistration = (username: string, email: string): void => {
    this.sendEvent(username, email);
    const url = sessionStorage.getItem(REDIRECT_STORAGE_KEY);

    if (url) {
      window.location.href = url;
    } else {
      this.props.history.push(HOME_URL);
    }
  };

  goHomeIfRegistered = (authUser: AuthUser | null) => {
    if (
      // user must exist and not be isAnonymous
      (authUser && !authUser.isAnonymous) ||
      // provider providerData[0]?.providerId must exists and not be password
      (authUser?.providerData[0]?.providerId &&
        authUser.providerData[0].providerId !== "password")
    ) {
      this.props.history.push(HOME_URL);
      return;
    }
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => {
          this.goHomeIfRegistered(authUser);

          return (
            <div className="container-main">
              <Header />
              <div className="container bg-img-fancy">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="px-3 px-md-5 py-5 my-3 shadow-lg bg-white rounded mt-5">
                      <div className="text-center mb-3">
                        <h2 className="mb-3 text-lg-plus">
                          <strong>Create your account</strong>
                        </h2>
                        <p>
                          You have now connected your Stripe account. Tenstars
                          is no longer a simulation.
                        </p>
                      </div>
                      <SignUpComponent
                        onSubmitOk={this.onSuccessfulRegistration}
                        changeToLoading={this.changeLoadingState}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser: AuthUser | null) => !!authUser;
export default withAuthorization(condition)(RegisterPage);
