import React from "react";
import styled from "styled-components";
import Header from "../../components/Header/Header";

const H2 = styled.h2`
  margin: 1.5em 0 1em 0;
`;

const P = styled.p`
  margin-bottom: 1em;
`;

const Quote = styled.div`
  padding: 1.5em;
  font-size: 2em;
  line-height: 1.4em;
  color: #ff644d;
  font-family: "Playfair Display", serif;
  font-style: italic;
`;

const AboutUsPage = () => {
  return (
    <div className="container-main">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-2"/>
          <div className="col-12 col-md-8 col-lg-8 section">
            <h1 className="section-header mb-5">About us</h1>

            <H2>Our mission</H2>
            <P>
              We provide tools to SaaS business to increase their retention and brand perception of their most valuable users.
              We are 100% remote and bootstrapped.
            </P>
            <P>
              We are strong believers on the need to focus more on customer retention.
              <b className="ml-1">
                When we focus on reducing customer churning, we make the business healthier
              </b>
              , support the acquisition efforts and increase user satisfaction. It is a rewarding bet.
            </P>
            <P>
              We come from a consultancy background and while working with multiple
              companies, faced a constant problem: There was a lot of
              knowledge and an endless list of tools to facilitate the customer
              acquisition process. Nothing like that was available to help with
              customer retention.
            </P>
            <P>
              <b>
                Nevertheless, the most business struggled with was precisely
                retention.
              </b>
             </P>
            <P>
              They had staggering churn rate values and little knowledge about
              how to stop the bleeding. We wanted to have a tool to tackle that
              in an efficient and automated way. Customers first.
            </P>
            <P>
              We have built Tenstars to let customers easily implement solutions
              tested and validated with our experts.
            </P>

            <H2>The vision</H2>
            <P>
              We have started helping companies retain their most important
              users in the critical cancellation moments. Our vision does not
              stop there.
              <b className="ml-1">
                We want to be an essential tool for nurturing and retaining
                their customers
              </b>
              .
            </P>
            <p>
              We aspire to bring that magic to SaaS all over the world. There is
              a whole category waiting to be discovered and we want to be in the
              front line.
            </p>

            <H2>The name</H2>
            <P>
              The “Ten Stars” concept comes from{" "}
              <a href="https://twitter.com/bchesky">Brian Chesky</a>. He introduced it
              during the Technology-enabled Blitzscaling Stanford&apos;s
              University course. He described its goal as follows:
            </P>
            <Quote>
              &quot;At Airbnb, we strive to have our customers contact the
              company and demand a 6th star be added to our 5-star review
              because the experience was so good&quot;
            </Quote>
            <P>
              The principle was described as follows:
            </P>
            <P>
              To achieve your goal,
              imagine what would be the best possible experience for your
              product and <strong>go beyond that</strong>. By going through
              different scenarios, you iterate and shape an experience that goes
              beyond your users&apos; expectations. There is where the magic
              happens.
            </P>
          </div>
          <div className="col-2"/>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
