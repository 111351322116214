import React, { useContext, useEffect } from "react";
import TestModeToggle from "../../components/common/TestModeToggle";
import ConfigPageContainer from "../../components/config/ConfigPageContainer";
import ConfigSectionContainer from "../../components/config/ConfigSectionContainer";
import ActivityFeed from "../../components/ActivityFeed/ActivityFeed";
import Firebase, { withFirebase } from "../../firebase";
import _ from "lodash";
import { hasCredentials, hasCredentialsFor } from "../../utils/siteUtils";
import {
  TestModeContext,
  TestModeStoreState,
} from "../../reducer/TestModeStore";
import {
  recoveredEventKey,
  savedEventKey,
  cancellationEventKey,
  Site,
  TenstarsAction,
} from "../../domain/DomainModel";
import firebase from "firebase";
import { FAKE_EVENTS } from "../../components/ActivityFeed/fakeData";
import SimulationTeaser from "../../components/common/SimulationTeaser";
import AlertInfo from "../../components/basic/AlertInfo";

interface AccountHistoryPageProps {
  firebase: Firebase;
  site: Site;
}

const AccountHistoryPage = (
  props: AccountHistoryPageProps
): React.ReactElement => {
  const [allConfirmedEvents, setAllConfirmedEvents] = React.useState<
    TenstarsAction[]
  >([]);
  const [loadTime] = React.useState<Date>(new Date());
  const { testMode } = useContext<TestModeStoreState>(TestModeContext);

  useEffect(() => {
    if (props.site?.key) {
      fetchAllEvents();
    }
    // eslint-disable-next-line
  }, [props.site?.key]);

  const onDataLoaded = (snapshot: any, type: string) => {
    const newEvent = { ...snapshot.val(), type };

    if (newEvent.fake) {
      return;
    }

    const events = allConfirmedEvents;
    events.push(newEvent);
    const allEventsSorted = _.orderBy(events, ["createdAt"], ["desc"]);
    setAllConfirmedEvents(allEventsSorted);
  };

  const fetchAllEvents = async (): Promise<void> => {
    await fetchEvent(cancellationEventKey, "cancelled");
    await fetchEvent(savedEventKey, "saved");
    await fetchEvent(recoveredEventKey, "recovered");
  };

  const fetchEvent = async (eventName: string, type: string): Promise<void> => {
    const siteId = props.site.key;
    await props.firebase
      .getDb()
      .ref(eventName)
      .orderByChild("siteId")
      .equalTo(siteId)
      .on("child_added", (snap: firebase.database.DataSnapshot) => {
        onDataLoaded(snap, type);
      });
  };

  const renderContent = (): React.ReactElement => {
    const hasEventsForType = allConfirmedEvents?.some(
      (e) => e.testMode === testMode
    );

    return (
      <div>
        <SimulationTeaser
          text="Connect your Stripe account and enable Tenstars to start recovering customers."
          testMode={testMode}
          disabled={hasCredentialsFor(testMode, props.site)}
        />
        <>
          {!hasEventsForType && hasCredentialsFor(testMode, props.site) && (
            <AlertInfo text="The data in this view is a sample. Your customers' data will appear here once the first event arrives." />
          )}
        </>
        <ActivityFeed
          pageLoadTime={loadTime}
          events={hasEventsForType ? allConfirmedEvents : FAKE_EVENTS()}
          testMode={testMode}
          isSample={!hasEventsForType}
        />
      </div>
    );
  };

  return (
    <ConfigPageContainer>
      <ConfigSectionContainer title="Customer Activity Feed">
        <div className="container-fluid position-relative">
          <div className="mb-2">
            <TestModeToggle
              disabled={false}
              visible={hasCredentials(props.site)}
            />
          </div>
          {renderContent()}
        </div>
      </ConfigSectionContainer>
    </ConfigPageContainer>
  );
};

export default withFirebase(AccountHistoryPage);
