import { TenstarsAction } from "../../domain/DomainModel";

export const FAKE_EVENTS: () => TenstarsAction[] = () => {
  const now = new Date();

  return [
    {
      createdAt: new Date(1627925407688).setFullYear(now.getFullYear()),
      currency: "usd",
      discount: 25,
      fake: true,
      inTrialPeriod: false,
      language: "en",
      offSite: false,
      priceInCents: 9900,
      siteId: "-MRL5JAqHx5J_5-ityHA",
      subscriptionId: "sub_tenstarstest",
      testMode: true,
      type: "cancelled",
    },
    {
      createdAt: new Date(1627925321536).setFullYear(now.getFullYear()),
      currency: "usd",
      discount: 25,
      fake: true,
      inTrialPeriod: false,
      language: "en",
      offSite: false,
      priceInCents: 9900,
      siteId: "-MRL5JAqHx5J_5-ityHA",
      subscriptionId: "sub_tenstarstest",
      testMode: true,
      type: "cancelled",
    },
    {
      createdAt: new Date(1627925248286).setFullYear(now.getFullYear()),
      currency: "usd",
      discount: 25,
      fake: true,
      inTrialPeriod: false,
      language: "en",
      offSite: false,
      priceInCents: 9900,
      siteId: "-MRL5JAqHx5J_5-ityHA",
      testMode: true,
      type: "saved",
    },
    {
      createdAt: new Date(1626423762958).setFullYear(now.getFullYear()),
      currency: "eur",
      customer: "cus_ItyvcGRfJv06TM",
      discount: 25,
      email: "barbaranorena.user@yahoo.com",
      language: "es",
      name: "Barbara Norena",
      offSite: false,
      priceInCents: 9000,
      productId: "prod_IIb0tXBa0fsbpy",
      productName: "Premium Plan",
      siteId: "-MRL5JAqHx5J_5-ityHA",
      subscriptionId: "sub_JQH6UbmQCrwRdt",
      testMode: true,
      type: "cancelled",
    },
    {
      createdAt: new Date(1626355893403).setFullYear(now.getFullYear()),
      currency: "eur",
      customer: "cus_ItyuK13zV09nrp",
      discount: 25,
      email: "alfredburns.user@gmail.com",
      inTrialPeriod: true,
      language: "en",
      name: "Alfred Burns",
      offSite: false,
      priceInCents: 9000,
      productId: "prod_IIb0tXBa0fsbpy",
      productName: "Premium Plan",
      siteId: "-MRL5JAqHx5J_5-ityHA",
      subscriptionId: "sub_JQHwC7gIZ2REHy",
      testMode: true,
      type: "cancelled",
    },
    {
      createdAt: new Date(1610988511678).setFullYear(now.getFullYear()),
      currency: "eur",
      customer: "cus_IkglsthPJ1ehXS",
      discount: 20,
      email: "jaumamc0@gmail.com",
      name: "Lucas Weidner",
      newSubscriptionId: "sub_Ima4RvXpyOm73n",
      offSite: true,
      oldSubscriptionEventId: "-MRLOM1yl42_kl3cSfq3",
      oldSubscriptionId: "sub_Ima3S9Z1E1nTOH",
      priceInCents: 7200,
      productId: "prod_IIb0tXBa0fsbpy",
      productName: "Premium Plan",
      siteId: "-MRL5JAqHx5J_5-ityHA",
      subscriptionId: "sub_Ima4RvXpyOm73n",
      testMode: true,
      type: "recovered",
    },
    {
      createdAt: new Date(1610986945578).setFullYear(now.getFullYear()),
      currency: "eur",
      customer: "cus_Ikgn25RdNoi6mC",
      discount: 20,
      email: "jitewaboh@lagify.com",
      language: "en",
      name: "Jite Waboh",
      offSite: false,
      priceInCents: 9000,
      productId: "prod_IIb0tXBa0fsbpy",
      productName: "Premium Plan",
      siteId: "-MRL5JAqHx5J_5-ityHA",
      subscriptionId: "sub_ImZYfJIIshdcA6",
      testMode: true,
      vote: "I found a better solution",
      type: "saved",
    },
    {
      createdAt: new Date(1610986374773).setFullYear(now.getFullYear()),
      currency: "eur",
      customer: "cus_IIazhZmyZaopv2",
      discount: 20,
      email: "fmontserrat.carvajal@gmail.com",
      inTrialPeriod: true,
      language: "en",
      name: "Francesc Montserrat",
      offSite: false,
      priceInCents: 9000,
      productId: "prod_IIb0tXBa0fsbpy",
      productName: "Premium Plan",
      siteId: "-MRL5JAqHx5J_5-ityHA",
      subscriptionId: "sub_IloyIlVovFMcQB",
      testMode: true,
      vote: "It's too expensive",
      type: "cancelled",
    },
  ];
};
