import React from "react";
import styled, { keyframes } from "styled-components";
import FollowUpConnectionsAnimation from "./FollowUpConnectionsAnimation";

export default function FollowUpAnimation() {
  return (
    <Wrapper className="bg-dotted text-center">
      <First className="shadow-xl">
        <div>
          <img src={require("../../img/HP/gmail_browser.svg")} alt="" />
        </div>
      </First>
      <ConnectorContainerOne>
        <FollowUpConnectionsAnimation />
      </ConnectorContainerOne>
      <Second className="shadow-xl">
        <div>
          <img src={require("../../img/HP/gmail_email.png")} alt="" />
        </div>
      </Second>
    </Wrapper>
  );
}

const animation = keyframes`
  0% { opacity: 0.8; }
  35% { opacity: 1; transform: scale(1.1,1.1); }
  75% { opacity: 1; transform: scale(1.1,1.1); }
  100% { opacity: 1; }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: "a" "connectorone" "b" "connectortwo" "c";
  grid-template-columns: 1fr;
  width: 100%;
  min-height: 450px;
  margin: auto;
  padding: 32px 16px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    // large screens
    grid-template-areas: "a" "connectorone" "b" "connectortwo" "c";
    grid-template-columns: 1fr;
  }
`;
const First = styled.div`
  border: 1px solid #e0e0e0;
  padding: 12px;
  background-color: white;
  grid-area: a;
  margin: auto;
  animation-name: ${animation};
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
  animation-timing-function: ease-in;
`;
const Second = styled.div`
  border: 1px solid #e0e0e0;
  padding: 12px;
  background-color: white;
  grid-area: b;
  margin: auto;
  animation-name: ${animation};
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
  animation-timing-function: ease-in;
  animation-delay: 2s;
`;

const ConnectorContainerOne = styled.div`
  grid-area: connectorone;
  z-index: 9999;
  max-height: 100px;
  min-height: 100px;
  min-width: 275px;
  margin: auto;
`;
