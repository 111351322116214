import React, { Component } from "react";
import Firebase, { withFirebase } from "../../firebase";
import AuthUserContext from "../session/context";
import { withRouter } from "react-router-dom";
import amplitudeService from "../../service/amplitudeService";

const MIN_PASSWORD_LENGTH = 8;

export interface SignUpPageComponentProps {
  onSubmitOk: (username: string, email: string) => void;
  changeToLoading: () => void;
  firebase?: Firebase;
}

export interface SignUpPageComponentState {
  username: string;
  email: string;
  passwordOne: string;
  passwordTwo: string;
  userAnon: boolean | null;
  error: Error | null;
}

const SignUpPageComponent = (props: SignUpPageComponentProps) => {
  return (
    <div className="col-12">
      <SignUpForm
        onSubmitOk={props.onSubmitOk}
        changeParent={(e: React.ChangeEvent) => {
          e.preventDefault();
          props.changeToLoading();
        }}
      />
    </div>
  );
};

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  userAnon: null,
  error: null,
};

class SignUpFormBase extends Component<
  SignUpPageComponentProps,
  SignUpPageComponentState
> {
  static contextType = AuthUserContext;

  componentDidMount() {
    let userType = this.props.firebase?.isUserAnonymous();
    this.setState({ userAnon: userType || null });
  }

  constructor(props: SignUpPageComponentProps) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event: any) => {
    event.preventDefault();
    const { username, email, passwordOne, userAnon } = this.state;

    if (passwordOne.length < MIN_PASSWORD_LENGTH) {
      this.setState({
        error: {
          name: "error",
          message: `The password has to be at least ${MIN_PASSWORD_LENGTH} characters long`,
        },
      });
      return;
    }

    const { firebase } = this.props;

    if (!firebase) {
      return;
    }

    if (!userAnon) {
      this.props.onSubmitOk(this.state.username, this.state.email);
    } else {
      firebase
        .upgradeAccount(email, passwordOne)
        .then((upgradedUser) => {
          upgradedUser?.user &&
            firebase.getDb().ref(`users/${upgradedUser.user.uid}`).update({
              username,
              email,
            });
        })
        .then(() => {
          this.setState({ userAnon: false }, () => {
            this.props.onSubmitOk(this.state.username, this.state.email);
          });
        })
        .catch((error) => {
          this.setState({ error });
          amplitudeService.reportRegistrationError(error);
        });
    }
  };

  onChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value,
      error: null,
    } as any);
  };

  render() {
    const { username, email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "" ||
      !!error;

    const isPasswordValid = passwordOne !== passwordTwo;

    return (
      <div>
        {!this.state.userAnon ? (
          <div className="alert alert-success my-auto text-center" role="alert">
            <h3>
              <span role="img" aria-label="Check Mark Button">
                ✅{" "}
              </span>
              Email registered!
            </h3>
          </div>
        ) : (
          <form onSubmit={this.onSubmit.bind(this)} className="ui form">
            <div className="form-group">
              <input
                name="username"
                value={username}
                onChange={this.onChange}
                type="text"
                placeholder="Enter your full name"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <input
                name="email"
                value={email}
                onChange={this.onChange}
                type="email"
                placeholder="Enter your email address"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <input
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                type="password"
                placeholder="Create your Password"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <input
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                type="password"
                placeholder="Repeat your Password"
                className="form-control"
              />
            </div>
            <div className="d-flex">
              <div className="p-2">
                {error && <p className="alert alert-danger">{error.message}</p>}
                {isPasswordValid && (
                  <p className="alert alert-warning">Passwords are not equal</p>
                )}
              </div>
              <div className="ml-auto p-2">
                <button
                  disabled={isInvalid}
                  type="submit"
                  className="btn btn-primary pull-right"
                  id="btn-signup"
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPageComponent;

export { SignUpForm };
