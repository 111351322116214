import React, { Fragment } from "react";
import styled from "styled-components";
import { Parallax } from "react-scroll-parallax";
import {
  TagIcon,
  UserCircleIcon,
  AnnotationIcon,
} from "@heroicons/react/solid";
import DetailStarAnimated from "./DetailStarAnimated";

const activity = [
  {
    id: 1,
    type: "attempt",
    person: { name: "Mathew Wong", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1522556189639-b150ed9c4330?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
    comment: "Cancellation in course...",
    date: "12m ago",
  },
  {
    id: 2,
    type: "survey",
    person: { name: "Eduardo Benz", href: "#" },
    tags: [{ name: "Bug", href: "#", color: "bg-rose-500" }],
    date: "11m ago",
  },
  {
    id: 3,
    type: "comment",
    person: { name: "Eduardo Benz", href: "#" },
    comment:
      "Lately there has been lots of latency issues with the CRM and API sync",
    date: "11m ago",
  },
  {
    id: 4,
    type: "win",
    incentive: "10%",
    conditions: "3 months permanency",
    date: "5m ago",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AppPreviewCustomer() {
  return (
    <Parallax className="app-preview-parallax" y={[-20, 220]} tagOuter="figure">
      <div className="p-5">
        <ExampleCustomerInfo className="border shadow-lg">
          <div className="flow-root">
            <ul className="-mb-8">
              {activity.map((activityItem, activityItemIdx) => (
                <li key={activityItem.id}>
                  <div className="relative pb-8">
                    {activityItemIdx !== activity.length - 1 ? (
                      <span
                        className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex items-start space-x-3">
                      {activityItem.type === "attempt" ? (
                        <>
                          <div className="relative">
                            <img
                              className="h-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                              src={activityItem.imageUrl}
                              alt=""
                            />

                            <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px"></span>
                          </div>
                          <div className="min-w-0 flex-1">
                            <div>
                              <div className="text-sm">
                                <span className="font-medium text-gray-900">
                                  {activityItem.person.name}
                                </span>
                              </div>
                              <p className="mt-0.5 text-sm text-gray-500">
                                Started cancellation {activityItem.date}
                              </p>
                            </div>
                            <div className="mt-2 text-sm text-gray-700">
                              <p>{activityItem.comment}</p>
                            </div>
                          </div>
                        </>
                      ) : activityItem.type === "assignment" ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                <UserCircleIcon
                                  className="h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 py-1.5">
                            <div className="text-sm text-gray-500">
                              <span className="font-medium text-gray-900">
                                {activityItem.person.name}
                              </span>{" "}
                              assigned{" "}
                              <span className="font-medium text-gray-900">
                                {activityItem.assigned.name}
                              </span>{" "}
                              <span className="whitespace-nowrap">
                                {activityItem.date}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : activityItem.type === "tags" ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                <TagIcon
                                  className="h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 py-0">
                            <div className="text-sm leading-8 text-gray-500">
                              <span className="mr-0.5">
                                <span className="font-medium text-gray-900">
                                  {activityItem.person.name}
                                </span>{" "}
                                added tags
                              </span>{" "}
                              <span className="mr-0.5">
                                {activityItem.tags.map((tag) => (
                                  <Fragment key={tag.name}>
                                    <span className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                      <span className="absolute flex-shrink-0 flex items-center justify-center">
                                        <span
                                          className={classNames(
                                            tag.color,
                                            "h-1.5 w-1.5 rounded-full"
                                          )}
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="ml-3.5 font-medium text-gray-900">
                                        {tag.name}
                                      </span>
                                    </span>{" "}
                                  </Fragment>
                                ))}
                              </span>
                              <span className="whitespace-nowrap">
                                {activityItem.date}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : activityItem.type === "survey" ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                <TagIcon
                                  className="h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 py-0">
                            <div className="text-sm leading-8 text-gray-500">
                              <span className="mr-0.5">
                                <span className="font-medium text-gray-900">
                                  {" "}
                                </span>{" "}
                                Surveyed. Answer type: <br />
                              </span>{" "}
                              <span className="mr-0.5">
                                {activityItem.tags.map((tag) => (
                                  <Fragment key={tag.name}>
                                    <span className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                      <span className="absolute flex-shrink-0 flex items-center justify-center">
                                        <span
                                          className={classNames(
                                            tag.color,
                                            "h-1.5 w-1.5 rounded-full"
                                          )}
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="ml-3.5 font-medium text-gray-900">
                                        {tag.name}
                                      </span>
                                    </span>{" "}
                                  </Fragment>
                                ))}
                              </span>
                              <span className="whitespace-nowrap">
                                {activityItem.date}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : activityItem.type === "comment" ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                <AnnotationIcon
                                  className="h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1">
                            <div>
                              <p className="mt-0.5 text-sm text-gray-500">
                                Elaborated on reason {activityItem.date}
                              </p>
                            </div>
                            <div className="mt-2 text-sm text-gray-700">
                              <blockquote className="relative p-2 text-sm italic text-neutral-600 border-neutral-500 quote bg-orange-50 rounded-sm">
                                <p className="mb-0">
                                  &ldquo;{activityItem.comment}&rdquo;
                                </p>
                              </blockquote>
                            </div>
                          </div>
                        </>
                      ) : activityItem.type === "win" ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                <DetailStarAnimated />
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className="mt-0.5 text-sm text-gray-500">
                              Account rescued! {activityItem.date}
                            </p>
                            <p>
                              Accepted to remain with a{" "}
                              <span className="font-bold color-orange-brand">
                                {activityItem.incentive}
                              </span>{" "}
                              incentive, with{" "}
                              <span className="color-orange-brand">
                                {activityItem.conditions}
                              </span>
                            </p>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </ExampleCustomerInfo>
      </div>
    </Parallax>
  );
}

const ExampleCustomerInfo = styled.div`
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  box-shadow: 8px 16px 16px hsl(0deg 0% 0% / 0.15);

  position: absolute;
  right: -150px;
  top: 80px;
  text-align: left;
  max-width: 350px;
  padding: 32px 24px;
`;
