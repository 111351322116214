import { ChartMode, Rates } from "../../../domain/DomainModel";
import { MetricsResponse } from "../../../domain/metricsModel";
import { lastN } from "../../../utils/ArrayUtils";
import statsCalculator from "../../../service/statsCalculator";
import { formatChurn } from "../../../utils/formatters";
import { LineChartProps } from "../../charts/LineChart";

export const transformChurnChartData = (
  numMonths: number,
  currency: string,
  chartMode: ChartMode,
  convertOthers: boolean,
  rates: Rates,
  churn?: MetricsResponse
): LineChartProps => {
  if (!churn?.byMonth) {
    return { data: { labels: [], series: [] }, options: { fullWidth: true } };
  }

  const filteredData = lastN(numMonths, churn.byMonth) || [];

  const data: number[] = filteredData.map((c) =>
    statsCalculator.calculateChurn(c, currency, chartMode, convertOthers, rates)
  );

  return {
    data: {
      labels: filteredData.map((c, i) => c.month || `Month ${i + 1}`),
      series: [
        {
          name: "Churn Rate",
          data,
        },
      ],
    },
    options: {
      stackBars: true,
      fullWidth: true,
      chartPadding: {
        right: 40,
      },
      axisY: {
        labelInterpolationFnc: (value: number) => {
          return `${formatChurn(value)}%`;
        },
      },
    },
  };
};
