import React from "react";
import PricingPageBuilderEdit from "../../components/pricePreview/PricingPageBuilderEdit";
import PricingPageBuilderStyle from "../../components/pricePreview/PricingPageBuilderStyle";
import PricingPageTypePick, { defaultType } from "./PricingPageTypePick";
import PricePreview from "./SimplePricePreview";
import PricingPageBuilderPublish from "./PricingPageBuilderPublish";
import classNames from "classnames";
import Firebase, { withFirebase } from "../../firebase";
import { updatePricing } from "../../api/api";
import {
  dataStorageKey,
  defaultDescription,
  defaultSampleProducts,
  defaultTitle,
  themes,
} from "./data";
import {
  AuthUser,
  Pricing,
  PricingPageType,
  PricingRoot,
  PricingTheme,
} from "../../domain/DomainModel";

export const loadDataFromStore = () =>
  JSON.parse(localStorage.getItem(dataStorageKey) || "{}");

export const deleteFromStore = () => localStorage.removeItem(dataStorageKey);

interface PricingPageBuilderProps {
  authUser?: AuthUser;
  firebase: Firebase;
}

type Tab = "type" | "edit" | "style" | "publish";

type PricingPageBuilderState = Partial<Pricing> & {
  activeTab: Tab;
  themes: PricingTheme[];
};

class PricingPageBuilder extends React.Component<
  PricingPageBuilderProps,
  PricingPageBuilderState
> {
  constructor(props: PricingPageBuilderProps) {
    super(props);
    this.state = {
      activeTab: "type",
      themes: [],
      type: defaultType,
      products: [],
    };

    this.postPublish.bind(this);
  }

  componentDidMount() {
    this.loadBasedOnPriority();
  }

  componentDidUpdate(prevProps: PricingPageBuilderProps) {
    if (this.props.authUser?.site && !prevProps.authUser?.site) {
      this.loadBasedOnPriority();
    }
  }

  loadBasedOnPriority() {
    const dataFromDb = this.props.authUser?.site?.pricing?.a;

    if (dataFromDb) {
      this.onUpdate({ ...dataFromDb, themes: themes[dataFromDb.type] });
    } else {
      const dataFromStore: Pricing = loadDataFromStore();
      if (dataFromStore?.products?.length > 0) {
        this.setState({
          ...dataFromStore,
          themes: themes[dataFromStore.type],
          activeTab: this.state.activeTab,
        });
      }
    }
  }

  setDefault(type: PricingPageType) {
    this.onUpdate({
      products: defaultSampleProducts[type],
      theme: themes[type][0].name.toLowerCase(),
      title: defaultTitle[type],
      description: defaultDescription[type],
      type: type,
      themes: themes[type],
    });
  }

  onTypeContinue(type: PricingPageType) {
    if (!this.state.products?.length) {
      this.setDefault(type);
    } else {
      this.onUpdate({ type });
    }

    this.setState({ activeTab: "edit", themes: themes[type] });
  }

  onEditContinue() {
    this.setState({ activeTab: "style" });
  }

  onStyleContinue() {
    this.setState({ activeTab: "publish" });
  }

  onUpdate = (
    data: Partial<PricingRoot> & Partial<PricingPageBuilderState>
  ) => {
    this.setState({ ...this.state, ...data }, () => {
      const iframe = document.getElementById(
        "price-preview-iframe"
      ) as HTMLIFrameElement;
      const event = new CustomEvent("pricing_data_updated", { detail: data });
      if (iframe.contentWindow) {
        iframe.contentWindow.dispatchEvent(event);
      }
      localStorage.setItem(dataStorageKey, JSON.stringify(this.state));
    });
  };

  async onPublish(): Promise<void> {
    if (this.props.authUser?.site?.key) {
      const dataFromStorage = loadDataFromStore();
      const token = await this.props.firebase.getToken();

      if (!token) {
        return;
      }

      await updatePricing(token, this.props.authUser.site.key, {
        ...this.props.authUser.site.pricing,
        a: { ...dataFromStorage, themes: null, activeTab: null },
      }).then((response) => this.postPublish(response.data));
    }
  }

  postPublish = (updatedPricing: PricingRoot): void => {
    deleteFromStore();
    this.onUpdate({ ...updatedPricing.a, activeTab: "publish" });
  };

  onDiscard() {
    this.onUpdate({
      ...this.props.authUser?.site.pricing.a,
      activeTab: "publish",
    });
  }

  changeToTab(tab: Tab) {
    if (
      tab === "type" ||
      this.state.activeTab !== "edit" ||
      !(this.state.products || []).some((p) => !p.cancelUrl || !p.successUrl)
    ) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    const { activeTab } = this.state;

    const isType = activeTab === "type";
    const isEdit = activeTab === "edit";
    const isStyle = activeTab === "style";
    const isPublish = activeTab === "publish";

    return (
      <div className="position-relative">
        <nav aria-label="Progress" className="mb-12 bg-white">
          <ol className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
            <li
              className="relative md:flex-1 md:flex"
              onClick={() => this.changeToTab("type")}
            >
              <Section
                active={isType}
                checked={!isType}
                title="PAGE TYPE"
                number="01"
              />
              <Separator />
            </li>

            <li
              className="relative md:flex-1 md:flex"
              onClick={() => this.changeToTab("edit")}
            >
              <Section
                active={isEdit}
                checked={!isType}
                title="EDIT PAGE"
                number="02"
              />
              <Separator />
            </li>

            <li
              className="relative md:flex-1 md:flex"
              onClick={() => this.changeToTab("style")}
            >
              <Section
                active={isStyle}
                checked={isPublish}
                title="CUSTOMIZE PAGE"
                number="03"
              />
              <Separator />
            </li>
            <li
              className="relative md:flex-1 md:flex"
              onClick={() => this.changeToTab("publish")}
            >
              <Section
                active={isPublish}
                checked={isPublish}
                title="PUBLISH"
                number="04"
              />
            </li>
          </ol>
        </nav>
        {isType && (
          <PricingPageTypePick onClick={this.onTypeContinue.bind(this)} />
        )}
        {isEdit && (
          <PricingPageBuilderEdit
            {...this.state}
            freePlanUrl={this.state.freePlanUrl || null}
            emptyPlanUrl={this.state.emptyPlanUrl || null}
            quoteUrl={this.state.quoteUrl || null}
            products={
              (this.state.products?.length || 0) > 0
                ? this.state.products || []
                : defaultSampleProducts[this.state.type || defaultType]
            }
            onUpdate={this.onUpdate.bind(this)}
            onContinue={this.onEditContinue.bind(this)}
            siteUrl={this.props.authUser?.site?.urls?.siteUrl[0]}
          />
        )}
        {isStyle && (
          <PricingPageBuilderStyle
            {...this.state}
            themes={this.state.themes}
            onUpdate={this.onUpdate.bind(this)}
            onContinue={this.onStyleContinue.bind(this)}
          />
        )}
        {isPublish && (
          <PricingPageBuilderPublish
            authUser={this.props.authUser}
            onPublish={this.onPublish.bind(this)}
            onDiscard={this.onDiscard.bind(this)}
          />
        )}
        <div
          style={{
            visibility: isType || isPublish ? "hidden" : "unset",
            height: isType || isPublish ? "auto" : "0",
          }}
          className="d-flex justify-content-center position-relative h-screen mt-8"
        >
          <PricePreview />
        </div>
      </div>
    );
  }
}

const Section = (props: {
  checked: boolean;
  active: boolean;
  title: string;
  number: string;
}) => (
  <div className="px-6 py-4 flex items-center text-sm font-medium clickable">
    {props.checked && (
      <span className="rounded-full h-8 w-8 inline-flex items-center justify-center mr-3 bg-indigo-600 text-base group-hover:bg-indigo-800 bg-orange-brand">
        <svg
          className="w-6 h-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    )}
    {!props.checked && !props.active && (
      <span className="rounded-full h-8 w-8 inline-flex items-center justify-center border-2 group-hover:border-gray-400">
        <span className="text-gray-500 group-hover:text-gray-900">
          {props.number}
        </span>
      </span>
    )}
    {!props.checked && props.active && (
      <span className="rounded-full h-8 w-8 inline-flex items-center justify-center bg-orange-brand border-orange-brand group-hover:border-gray-400">
        <span className="text-white group-hover:text-gray-900">
          {props.number}
        </span>
      </span>
    )}
    <span
      className={classNames("ml-4 text-sm font-medium text-indigo-600", {
        "color-orange-brand": props.active,
        "font-bold": props.active,
      })}
    >
      {props.title}
    </span>
  </div>
);

const Separator = () => (
  <div
    className="hidden md:block absolute top-0 right-0 h-full w-5"
    aria-hidden="true"
  >
    <svg
      className="h-full w-full text-gray-300"
      viewBox="0 0 22 80"
      fill="none"
      preserveAspectRatio="none"
    >
      <path
        d="M0 -2L20 40L0 82"
        vectorEffect="non-scaling-stroke"
        stroke="currentcolor"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default withFirebase(PricingPageBuilder);
