import React from "react";
import ListQuestionItem from "./ListQuestionItem";
import CreateQuestionItem from "./CreateQuestionItem";
import { Question } from "../../domain/DomainModel";
import _ from "lodash";
import { DEFAULT_LANGUAGE } from "../../domain/InternationalDomain";

interface ListQuestionsProps {
  language: string;
  questions: Question[];
  onSubmit: (value: string, id: number) => void;
  onDelete: (order: number) => void;
}

class ListQuestions extends React.Component<ListQuestionsProps, {}> {
  render() {
    const { language, questions, onSubmit, onDelete } = this.props;
    let mappedQuestions;

    mappedQuestions = questions.map((question: Question, index: number) => {
      const value = _.get(
        question,
        language,
        _.get(question, DEFAULT_LANGUAGE)
      );
      return (
        <div key={value + index + "listQ"}>
          <ListQuestionItem
            name={"name" + index}
            title={value}
            id={question.id}
            onSubmit={onSubmit}
            onDelete={onDelete}
            order={index}
            disabled={index === questions.length - 1}
          />
        </div>
      );
    });

    return (
      <div>
        <div className="question">{mappedQuestions}</div>
        <div>
          <CreateQuestionItem
            onSubmit={this.props.onSubmit}
            key="last"
            title="Add one more"
            id="last"
            order={questions.length}
          />
        </div>
      </div>
    );
  }
}

export default ListQuestions;
