import React from "react";
import Trumbowyg from "./Trumbowyg";
import {
  AvailableLanguage,
  InternationalizedContent,
} from "../../domain/InternationalDomain";

interface EmailEditorProps {
  emailBody: InternationalizedContent;
  language: AvailableLanguage;
  onChange: (event: React.ChangeEvent) => void;
}
class EmailEditor extends React.Component<EmailEditorProps> {
  render() {
    const { emailBody, language, onChange } = this.props;

    return (
      <Trumbowyg
        id="react-trumbowyg"
        buttons={[
          ["viewHTML"],
          ["formatting"],
          "btnGrp-semantic",
          ["link"],
          "btnGrp-justify",
          "btnGrp-lists",
          ["table"], // I ADDED THIS FOR THE TABLE PLUGIN BUTTON
          ["fullscreen"],
        ]}
        data={emailBody[language] || "There is no template for this language"}
        placeholder="Type your text!"
        onChange={(e: React.ChangeEvent) => onChange(e)}
        ref="trumbowyg"
      />
    );
  }
}

export const EmailFrame = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[] | null;
}) => (
  <div>
    <img
      className="border absolute-center w-100"
      src={require("../../img/email.png")}
      alt="email"
    />
    <span
      style={{ maxHeight: "60%", height: "55%" }}
      className="absolute-center overflow-y-scroll my-4 p-4 w-100"
    >
      {children}
    </span>
  </div>
);

export default EmailEditor;
