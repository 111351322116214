import MoreInfoHelper from "../common/MoreInfoHelper";
import Toggle from "react-toggle";
import React from "react";

interface ToggleWithTipProps {
  title: string;
  infoTitle: string;
  infoDescription: string;
  value?: boolean;
  disabled: boolean;
  visible: boolean;
  onChange: () => void;
}

const ToggleWithTip = (props: ToggleWithTipProps) => {
  if (!props.visible) {
    return null;
  }

  return (
    <div>
      <span className="config-item">{props.title} </span>
      <MoreInfoHelper
        info={{
          title: props.infoTitle,
          description: props.infoDescription,
        }}
      />
      <Toggle
        checked={props.value || false}
        disabled={props.disabled}
        value="yes"
        onChange={props.onChange}
        className="align-middle ml-2"
      />
    </div>
  );
};

export default ToggleWithTip;
