import {
  AuditMetric,
  DiscountMetric,
  PlanMetric,
  RevenueAndSubscriptions,
} from "../../../domain/metricsModel";
import { RowValue } from "../../basic/DataTable";
import statsCalculator from "../../../service/statsCalculator";
import { Dictionary, Rates } from "../../../domain/DomainModel";
import { camel2title, formatFigure } from "../../../utils/formatters";
import React from "react";
import { Link } from "react-router-dom";
import { SUBSCRIBERS_URL } from "../../../pages/protected/MainPage/resources";
import _ from "lodash";

export const metricsToSegregatedSubscriptions = (
  data: Dictionary<AuditMetric>,
  currency: string,
  convertOthers: boolean,
  rates: Rates,
  testMode: boolean
): RowValue[][] => {
  return Object.keys(data).map((k) => {
    const row = data[k];
    const values = [];

    if (row) {
      const ras: RevenueAndSubscriptions = {
        monthlyRevenue: row.mrr || {},
        monthlySubscriptions: row.count,
      };
      values.push(formatStatus(k.slice()));
      const subscriptions = statsCalculator.getValue(
        ras,
        currency,
        "subscriptions",
        convertOthers,
        rates
      );
      values.push(formatSubscriptions(subscriptions, k.slice()));
      const mrr = statsCalculator.getValue(
        ras,
        currency,
        "revenue",
        convertOthers,
        rates
      );
      values.push(formatFigure(mrr / 100, currency));
      const plans: (React.ReactElement | null)[] = Object.keys(
        row.plans
      ).map((p) =>
        formatPlan(testMode, p, row.plans[p], currency, convertOthers)
      );
      values.push(<div>{plans}</div>);
      const discounts: (React.ReactElement | null)[] = Object.keys(
        row.discounts
      ).map((d) =>
        formatDiscount(testMode, d, row.discounts[d], currency, convertOthers)
      );
      values.push(<div>{discounts}</div>);
    }

    return values;
  });
};

const formatPlan = (
  testMode: boolean,
  id: string,
  plan: PlanMetric,
  currency: string,
  convertOthers: boolean
): React.ReactElement | null => {
  const count = !convertOthers
    ? plan.count[currency] || 0
    : Object.values(plan.count).reduce((a, b) => a + b, 0);

  if (count === 0) {
    return null;
  }

  return (
    <div key={id}>
      ({count})
      <a
        className="ml-2"
        href={
          testMode
            ? `https://dashboard.stripe.com/test/prices/${id}`
            : `https://dashboard.stripe.com/prices/${id}`
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {`Price for ${plan.name}`}
        {` (${id})`}
      </a>
    </div>
  );
};

const formatDiscount = (
  testMode: boolean,
  id: string,
  discount: DiscountMetric,
  currency: string,
  convertOthers: boolean
): React.ReactElement | null => {
  const count = !convertOthers
    ? discount.count[currency] || 0
    : Object.values(discount.count).reduce((a, b) => a + b, 0);

  if (count === 0) {
    return null;
  }

  return (
    <div key={id}>
      ({count})
      <a
        className="mx-2"
        href={
          testMode
            ? `https://dashboard.stripe.com/test/coupons/${id}`
            : `https://dashboard.stripe.com/coupons/${id}`
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {discount.percentOff
          ? `${discount.percentOff}%`
          : formatFigure((discount.amountOff || 0) / 100, currency)}
        {" " + discount.duration}
        {` (${id})`}
      </a>
    </div>
  );
};

const formatStatus = (status: string): React.ReactElement => {
  return <div>{camel2title(status)}</div>;
};

const formatSubscriptions = (
  count: number,
  status: string
): React.ReactElement => {
  return (
    <Link to={`${SUBSCRIBERS_URL}?status=${_.snakeCase(status)}`}>{count}</Link>
  );
};
