import React, { useContext } from "react";
import ConfigPageContainer from "../../components/config/ConfigPageContainer";
import ConfigSectionContainer from "../../components/config/ConfigSectionContainer";
import { AuthUser } from "../../domain/DomainModel";
import LanguageDropdown from "../../components/common/LanguageDropdown";
import Firebase, { withFirebase } from "../../firebase";
import { SharedContext } from "../../components/common/SharedProvider";
import RetentionWidgetTryButton from "../../components/common/RetentionWidgetTryButton";
import TenstarsPricingPage from "../public/TenstarsPricingPage";
import {
  AvailableLanguage,
  DEFAULT_LANGUAGE,
} from "../../domain/InternationalDomain";
import CurrencyDropdown from "../../components/common/CurrencyDropdown";

interface AccountPageProps {
  authUser?: AuthUser;
  firebase: Firebase;
}

const TENSTARS_API_KEY =
  "2bc88e00593157b4723597914fcc7908feef82d6f3e7ccd1b707443bc9f89752f7678964ff63f5d1c8d7efc3875f4888";

export const DEFAULT_CURRENCY = "usd";

const AccountPage = (props: AccountPageProps): React.ReactElement | null => {
  const [
    selectedLanguage,
    setSelectedLanguage,
  ] = React.useState<AvailableLanguage>(
    props.authUser?.user.defaultLanguage ||
      (DEFAULT_LANGUAGE as AvailableLanguage)
  );

  const [preferredCurrency, setPreferredCurrency] = React.useState<string>(
    props.authUser?.user.preferredCurrency || DEFAULT_CURRENCY
  );

  const [pricingHidden, setPricingHidden] = React.useState(true);

  const utils: any = useContext(SharedContext);

  const onSaveLanguage = async (): Promise<void> => {
    if (!props.authUser?.uid) {
      return;
    }

    props.firebase
      .updateUser(props.authUser.uid, {
        defaultLanguage: selectedLanguage,
        preferredCurrency,
      })
      .then(() => {
        utils.setSnackConfig({
          isOpen: true,
          message: `Your settings have been saved!`,
          severity: "success",
        });
      })
      .catch(() => {
        utils.setSnackConfig({
          isOpen: true,
          message:
            "There was an error saving your settings. Please try again later.",
          severity: "error",
        });
      });
  };

  if (!props.authUser) {
    return null;
  }

  return (
    <ConfigPageContainer>
      <ConfigSectionContainer title="Account Settings" onSave={onSaveLanguage}>
        <p className="mb-2">Default language:</p>
        <LanguageDropdown
          selected={selectedLanguage}
          action={setSelectedLanguage}
          modifier="col-4"
        />

        <p className="mb-2 mt-8">Preferred currency:</p>
        <CurrencyDropdown
          convertOthers={false}
          selected={preferredCurrency}
          action={setPreferredCurrency}
          modifier="col-4"
        />
      </ConfigSectionContainer>

      <ConfigSectionContainer title="Subscription to Tenstars">
        {props.authUser?.user.subscriptionId && (
          <div className="mt-3">
            <div id="tenstars-widget" />
            <RetentionWidgetTryButton
              apiKey={TENSTARS_API_KEY}
              title="Cancel my subscription"
              language={selectedLanguage}
              simulation={false}
              subscriptionId={props.authUser?.user.subscriptionId}
            />
          </div>
        )}
        {!props.authUser?.user.subscriptionId && (
          <div className="mt-3">
            <p>You do not have a subscription.</p>
            <div
              className="mt-8 underline cursor-pointer text-lg"
              onClick={() => setPricingHidden(!pricingHidden)}
            >
              {pricingHidden ? "Show me the plans" : "Hide the plans"}
            </div>
            {!pricingHidden && (
              <div>
                <div className="mt-12">
                  <TenstarsPricingPage apiKey={TENSTARS_API_KEY} />
                </div>
                <div className="text-center mb-4">
                  * Prices don&apos;t include VAT, which may be added depending
                  on the country where the service recipient has its billing
                  location.
                </div>
              </div>
            )}
          </div>
        )}
        <p />
      </ConfigSectionContainer>
    </ConfigPageContainer>
  );
};

export default withFirebase(AccountPage);
