import React, { useState } from "react";
import { DocumentTextIcon } from "@heroicons/react/solid";
import { MetricsResponse } from "../../../domain/metricsModel";
import ExpandableContainer from "../../basic/ExpandableContainer";
import { Rates } from "../../../domain/DomainModel";
import DataTable, { RowValue } from "../../basic/DataTable";
import { metricsToSegregatedSubscriptions } from "./mapper";
import Loader from "../../icons/Loader";

interface DashboardSegregationTablesProps {
  data?: MetricsResponse;
  loading: boolean;
  currency: string;
  defaultExpanded: boolean;
  rates: Rates;
  convertOthers: boolean;
  testMode: boolean;
}

const DashboardSegregationTables = (props: DashboardSegregationTablesProps) => {
  const [mappedData, setMappedData] = useState<RowValue[][]>([[]]);

  React.useEffect(() => {
    if (!props.data?.segmentedSubscriptions) {
      setMappedData([]);
      return;
    }

    setMappedData(
      metricsToSegregatedSubscriptions(
        props.data.segmentedSubscriptions,
        props.currency,
        props.convertOthers,
        props.rates,
        props.testMode
      )
    );
    // eslint-disable-next-line
  }, [
    props.data,
    props.convertOthers,
    props.testMode,
    props.rates,
    props.currency,
  ]);

  if (!props.loading && !props.data) {
    return null;
  }

  return (
    <ExpandableContainer defaultExpanded={props.defaultExpanded}>
      <h5 className="text-xl font-weight-bold text-gray-500 text-left mb-1">
        All subscriptions today
      </h5>
      {props.loading && (
        <div className="m-8">
          <Loader />
        </div>
      )}
      {!props.loading && (
        <>
          <div
            key={`seg-${props.currency}/${props.testMode}/${props.data?.byMonth.length}/${props.convertOthers}/${props.rates}`}
            className="mx-4 my-6"
          >
            <DataTable
              headers={["Status", "Total", "MRR", "Plans/Prices", "Discounts"]}
              data={mappedData}
            />
          </div>
          <div className="pb-1">
            <div className="ml-3 cursor-pointer">
              <DocumentTextIcon
                className="ml-1 mr-0.5 inline-flex self-center h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
              <a href="https://stripe.com/docs/billing/subscriptions/overview#subscription-statuses">
                Stripe&apos;s documentation about the different statuses
              </a>
            </div>
          </div>
        </>
      )}
    </ExpandableContainer>
  );
};

export default DashboardSegregationTables;
