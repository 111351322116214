import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { withFirebase } from "../../firebase";
import CheckedIcon from "../icons/CheckedIcon";

interface ConditionWaiterProps {
  condition: boolean;
  waitingText: string;
  completeText: string;
  waitingLink?: string;
  waitingHref?: string;
}

const ConditionWaiter = (props: ConditionWaiterProps) => {
  useEffect(() => {}, [props.condition]);

  return (
    <div className="mb-8">
      {!props.condition && (
        <div>
          <div className="items-center inline-flex">
            <span className="inline-block">
              <svg
                width="44"
                height="44"
                viewBox="0 0 44 44"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#C4C4C4"
              >
                <g fill="none" fillRule="evenodd" strokeWidth="2">
                  <circle cx="22" cy="22" r="1">
                    <animate
                      attributeName="r"
                      begin="0s"
                      dur="1.8s"
                      values="1; 20"
                      calcMode="spline"
                      keyTimes="0; 1"
                      keySplines="0.165, 0.84, 0.44, 1"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="stroke-opacity"
                      begin="0s"
                      dur="1.8s"
                      values="1; 0"
                      calcMode="spline"
                      keyTimes="0; 1"
                      keySplines="0.3, 0.61, 0.355, 1"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="22" cy="22" r="1">
                    <animate
                      attributeName="r"
                      begin="-0.9s"
                      dur="1.8s"
                      values="1; 20"
                      calcMode="spline"
                      keyTimes="0; 1"
                      keySplines="0.165, 0.84, 0.44, 1"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="stroke-opacity"
                      begin="-0.9s"
                      dur="1.8s"
                      values="1; 0"
                      calcMode="spline"
                      keyTimes="0; 1"
                      keySplines="0.3, 0.61, 0.355, 1"
                      repeatCount="indefinite"
                    />
                  </circle>
                </g>
              </svg>
            </span>
            <strong className="mr-4 ml-1">Waiting for event:</strong>
          </div>
          <div className="ml-12">
            <span>{props.waitingText}</span>
            {props.waitingHref && props.waitingLink && (
              <Link className="ml-2" to={props.waitingHref}>
                {props.waitingLink}
              </Link>
            )}
          </div>
        </div>
      )}
      {props.condition && (
        <div>
          <div className="items-center inline-flex">
            <span className="inline-block mr-2">
              <CheckedIcon />
            </span>
            <strong className="mx-2">Event complete: </strong>
          </div>
          <div className="ml-12">{props.completeText}</div>
        </div>
      )}
    </div>
  );
};

export default withFirebase(ConditionWaiter);
