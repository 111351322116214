import React from "react";

interface MonthsDropdownProps {
  selected: string;
  action: (value: string) => void;
}

const MonthsDropdown = (props: MonthsDropdownProps): React.ReactElement => (
  <select
    value={props.selected}
    onChange={(e) => {
      props.action(e.currentTarget.value);
    }}
    className="browser-default custom-select m-auto w-auto"
  >
    {[3, 6, 12, 18, 24].map((s) => (
      <option key={s} value={s} label={s + " Months"} />
    ))}
  </select>
);

export default MonthsDropdown;
