import React, { useState } from "react";

import {
  STRIPE_LIVE_MODE_REDIRECT_PATH,
  STRIPE_TEST_MODE_REDIRECT_PATH,
} from "../../App";

const STRIPE_AUTHORIZE_URL = "https://connect.stripe.com/oauth/authorize";
const STRIPE_LANDING = "login";
const NGROK_URI =
  process.env.REACT_APP_NGROK_DOMAIN || "http://3bb9f7197992.ngrok.io";

export const REDIRECT_STORAGE_KEY = "last_visited_uri_before_stripe";
export const REDIRECT_FAILURE_STORAGE_KEY = "on_cancel_stripe_page";
export const SKIP_REGISTRATION_STEP = "skip_registration_step";

const StripeConnectButton = ({
  testMode,
  redirectAfterSuccess,
  redirectAfterFailure,
  text,
  scope = "read_write",
  disabled,
  preconnectionStep,
}: {
  testMode: boolean;
  redirectAfterSuccess: string;
  redirectAfterFailure?: string;
  text?: string;
  scope?: "read_write";
  disabled?: boolean;
  preconnectionStep?: () => Promise<void>;
}) => {
  const [testCallbackUrl] = useState<string>(
    process.env.NODE_ENV === "production"
      ? `https://tenstars.co${STRIPE_TEST_MODE_REDIRECT_PATH}`
      : `${NGROK_URI}${STRIPE_TEST_MODE_REDIRECT_PATH}`
  );
  const [prodCallbackUrl] = useState<string>(
    process.env.NODE_ENV === "production"
      ? `https://tenstars.co${STRIPE_LIVE_MODE_REDIRECT_PATH}`
      : `${NGROK_URI}${STRIPE_LIVE_MODE_REDIRECT_PATH}`
  );

  const connectToStripe = async () => {
    let CLIENT_ID = testMode
      ? process.env.REACT_APP_STRIPE_CLIENT_ID_DEVELOPMENT
      : process.env.REACT_APP_STRIPE_CLIENT_ID_PRODUCTION;

    let redirectUrl = testMode ? testCallbackUrl : prodCallbackUrl;

    sessionStorage.setItem(REDIRECT_STORAGE_KEY, redirectAfterSuccess);
    sessionStorage.setItem(
      REDIRECT_FAILURE_STORAGE_KEY,
      redirectAfterFailure || redirectAfterSuccess
    );

    preconnectionStep && (await preconnectionStep());

    window.location.href = `${STRIPE_AUTHORIZE_URL}?response_type=code&client_id=${CLIENT_ID}&scope=${scope}&redirect_uri=${encodeURI(
      redirectUrl
    )}&stripe_landing=${STRIPE_LANDING}${testMode ? `` : `&production`}`;
  };

  return (
    <button
      className="btn btn-secondary"
      disabled={disabled}
      onClick={connectToStripe}
    >
      {text || "Connect"}
    </button>
  );
};

export default StripeConnectButton;
