import React from "react";
import styled, { keyframes } from "styled-components";
import InsightsHeroSurveyAnimation from "./InsightsHeroSurveyAnimation";

export default function InsightsHeroAnimation() {
  return (
    <Wrapper>
      <CardSurveyExample className="max-w-md pt-4 px-8 shadow-lg rounded-lg my-20 mx-auto background-blue-301 text-center">
        <div className="flex justify-center md:justify-center -mt-16">
          <img
            className="h-20 object-cover rounded-full border-4 border-blue-301"
            alt="The CEO will receive your answers today"
            src={require("../../img/survey_owner.png")}
          />
        </div>
        <Intro className="text-center">Can we ask you a few questions?</Intro>
        <CardBody>
          <p className="text-center">Your insights help us improve</p>
          <p className="mb-3 font-weight-bold mt-2 font-italic">
            <img
              src={require("../../img/SurveySteps.svg")}
              alt=""
              className="inline-block mr-2"
            />
            Why are you cancelling?
          </p>
          <InsightsHeroSurveyAnimation />

          <SurveyFooter>
            <div>
              <span className="underline">I&apos;ll continue later</span>
            </div>
            <div>
              <button className="rounded px-4 py-2 bg-blueish self-end right">
                Continue
              </button>
            </div>
          </SurveyFooter>
        </CardBody>
      </CardSurveyExample>
    </Wrapper>
  );
}

const AnimationCard = keyframes`
  0%    { opacity: 0; transform: scale(1.1); }
  5%    { opacity: 1;transform: scale(1); }
  95% { opacity: 1; transform: scale(1); }
  100%  { opacity: 0; transform: scale(1.1);}
 `;

const Wrapper = styled.div`
  display: block;
  animation-name: ${AnimationCard}  
   animation-duration: 15s;
   animation-direction: Normal;
   animation-iteration-count: infinite;
`;
const CardSurveyExample = styled.div``;
const Intro = styled.span`
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", "Icons16",
    sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
`;
const CardBody = styled.div`
  display: grid;
  background-color: #ffffff;
  margin-top: 16px;
  margin-left: -32px;
  margin-right: -32px;
  padding-top: 15px;
  padding-bottom: 24px;
  min-height: 100%;
`;

const SurveyFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
