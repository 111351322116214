import ConfigSectionsResurrectionPage from "../../components/config/ConfigSectionsResurrectionPage";
import ConfigPageContainer from "../../components/config/ConfigPageContainer";
import React from "react";
import { AuthUser, Site } from "../../domain/DomainModel";

const ResurrectionPage = (props: {
  authUser: AuthUser;
  site?: Site;
}): React.ReactElement => (
  <ConfigPageContainer>
    <ConfigSectionsResurrectionPage
      site={props.site}
      authUser={props.authUser}
    />
  </ConfigPageContainer>
);

export default ResurrectionPage;
