import React from "react";
import ReactCohort from "react-cohort-graph";
import { ChartMode, Dictionary } from "../../../domain/DomainModel";
import ExpandableContainer from "../../basic/ExpandableContainer";
import Loader from "../../icons/Loader";
import SubscriptionsOrRevenue from "../SubscriptionsOrRevenue";
import FigureHeader from "../FigureHeader";

export type CohortData = {
  days?: Dictionary<number[]>;
  weeks?: Dictionary<number[]>;
  months?: Dictionary<number[]>;
};

interface CohortChartProps {
  data?: CohortData;
  type?: "percent" | "value";
  defaultExpanded?: boolean;
  loading: boolean;
  hideSwitch?: boolean;
  chartMode: ChartMode;
  updateChartMode: (mode: ChartMode) => void;
  currency: string;
  convertOthers: boolean;
  numMonths: number;
}

const CohortChart = (props: CohortChartProps) => {
  const [isExpanded, setExpanded] = React.useState(false);

  return (
    <ExpandableContainer
      onUpdate={setExpanded}
      defaultExpanded={props.defaultExpanded}
    >
      {props.loading && (
        <div className="m-8">
          <Loader />
        </div>
      )}

      {!props.loading && props.data && (
        <div>
          <div className="w-100 ml-2">
            <FigureHeader
              name="Customer Retention cohorts"
              hideFigure={true}
              descriptor={`During the last ${props.numMonths} months`}
            />
          </div>
          {!props.hideSwitch && (
            <div className="py-4 mb-4">
              <SubscriptionsOrRevenue
                updateChartMode={props.updateChartMode}
                chartMode={props.chartMode}
                currency={props.currency}
                convertOthers={props.convertOthers}
              />
            </div>
          )}
          <div className="cohort-container px-8 pb-8">
            <ReactCohort
              key={`${isExpanded}/${JSON.stringify(props.data)}/${props.type}`}
              data={props.data}
              defaultValueType={props.type || "percent"}
              headerCellColor="white"
              bodyCellColor="white"
              headerCellStyles={{
                color: "black",
                border: "0px",
                backgroundColor: "white !important",
                fontWeight: "500",
              }}
              tableHeadingStyles={{
                border: "0px",
              }}
              tableStyles={{ border: "0px" }}
              tableCellStyles={{ border: "2px solid white", padding: "16px" }}
              headerLabelStyles={{
                backgroundColor: "white",
                fontWeight: "500",
              }}
              showHeaderValues={true}
              headerValueStyles={{
                backgroundColor: "white",
                fontWeight: "500",
              }}
              shadeColor="#2D9CDB"
              fixedTablePartStyles={{ backgroundColor: "white !important" }}
              bodyCellStyles={{
                fontWeight: 700,
                color: "#4F4F4F",
                fontSize: "16px",
              }}
            />
          </div>
        </div>
      )}
    </ExpandableContainer>
  );
};

export default CohortChart;
