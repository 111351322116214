import React from "react";
import styled, { keyframes } from "styled-components";

export default function PriceHeroConnector() {
  return (
    <Wrapper className="align-middle">
      <NodeContainer>
        <NodeLeft className="shadow-sm" />
        <Connector />
        <NodeRight className="shadow-sm" />
      </NodeContainer>
    </Wrapper>
  );
}

const animationConnector = keyframes`
  0%    { opacity: 0; }
  10%   { 
    opacity: 0;
    transform: scale(-0.5);
  }
  15%   { 
    opacity: 1;
    transform: scale(1);
  }
  85%   { opacity: 1; }
  90%   { opacity: 0; }
  100%  { 
    opacity: 0;
  }
`;

const animationNodeRight = keyframes`
  0%    { opacity: 0; transform: scale(2); }
  5%    { opacity: 0; transform: scale(2); }
  15%   { opacity: 1; transform: scale(1); }
  85%   { opacity: 1; transform: scale(1); }
  100%  { opacity: 0; transform: scale(2); }
`;
const animationNodeLeft = keyframes`
  0%    { opacity: 0; }
  100% {opacity: 1; }
`;

const Wrapper = styled.span`
  display: inline-block;
  position: absolute;
`;

const NodeContainer = styled.div`
  position: absolute;
  top: 90px;
  left: -150px;
  width: 300px;
  transform: rotate(90deg);
  @media only screen and (min-width: 768px) {
    top: -270px;
    left: 30px;
    transform: rotate(0deg);
  }
`;
const NodeLeft = styled.div`
  display: inline-block;
  background-color: #bdbdbd;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  border: 1px solid #f3f3f3;

  animation-name: ${animationNodeLeft};
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
`;
const NodeRight = styled.div`
  display: inline-block;
  background-color: #bdbdbd;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  border: 1px solid #f3f3f3;

  animation-name: ${animationNodeRight};
  animation-duration: 25s;
  animation-direction: normal;
  animation-iteration-count: infinite;
`;
const Connector = styled.div`
  display: inline-block;
  position: relative;
  top: -4px;
  background-color: #bdbdbd;
  width: 75px;
  height: 1px;

  animation-name: ${animationConnector};
  animation-duration: 25s;
  animation-direction: normal;
  animation-iteration-count: infinite;
`;
