import React from "react";

interface PostSampleProps {
  link: string;
  title?: { rendered: string };
  excerpt?: { rendered: string };
  date?: Date;
  imageSrc?: string;
}

const PostSample = ({
  link,
  title,
  excerpt,
  date,
  imageSrc,
}: PostSampleProps) => (
  <div className="mt-4 min-w-full max-w-lg mx-auto grid gap-5 lg:max-w-none">
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="flex-shrink-0 h-48 w-full">
        {imageSrc && (
          <a href={link}>
            <img
              className="object-cover"
              src={imageSrc}
              alt=""
              onError={(event) =>
                ((event.target as any).style.display = "none")
              }
            />
          </a>
        )}
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <a href={link} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">
              {title?.rendered}
            </p>
            <div className="flex space-x-1 text-sm text-gray-500">
              <time>{date?.toLocaleDateString()}</time>
            </div>
            {excerpt && (
              <p
                className="mt-3 text-base text-gray-500"
                dangerouslySetInnerHTML={{ __html: excerpt?.rendered }}
              />
            )}
            {/*Placeholder for size. We could add some content too, maybe */}
            {!excerpt && <p className="h-64" />}
          </a>
        </div>
        <div className="mt-6 flex items-center">
          <a className="text-lg brand-text font-bold" href={link}>
            {title && "Read full post"}
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default PostSample;
