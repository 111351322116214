import React, { useEffect, useState } from "react";
import LineChart from "../../charts/LineChart";
import { transformLtvChartData } from "./mapper";
import { MetricsResponse } from "../../../domain/metricsModel";
import { ChartMode, Rates } from "../../../domain/DomainModel";
import Loader from "../../icons/Loader";
import FigureHeader from "../FigureHeader";
import { lastN, secondToLastN } from "../../../utils/ArrayUtils";

const chartMode: ChartMode = "revenue";

interface LtvChartProps {
  title?: string;
  data?: MetricsResponse;
  currency: string;
  numMonths: number;
  rates: Rates;
  convertOthers: boolean;
  loading: boolean;
}

const LtvChart = (props: LtvChartProps) => {
  const {
    title,
    data,
    loading,
    currency,
    numMonths,
    rates,
    convertOthers,
  } = props;

  const [ltvData, setLtvData] = useState(
    transformLtvChartData(
      data,
      currency,
      chartMode,
      numMonths,
      convertOthers,
      rates
    )
  );

  useEffect(() => {
    updatedData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updatedData();
    // eslint-disable-next-line
  }, [data, currency, chartMode, numMonths, rates, convertOthers]);

  const updatedData = () => {
    const newData = transformLtvChartData(
      data,
      currency,
      chartMode,
      data?.byMonth.length || 24,
      convertOthers,
      rates
    );

    setLtvData(newData);
  };

  if (!loading && !data) {
    return null;
  }

  let currentData = ltvData;
  let prevData = ltvData;
  let currentAverage: number | undefined = 0;
  let prevAverage: number | undefined = 0;

  if (ltvData?.data.series && ltvData?.data.series[0]) {
    currentData = {
      options: ltvData.options,
      data: {
        series: [
          {
            data: lastN(numMonths, ltvData.data.series[0].data) || [],
            name: ltvData.data.series[0].name,
          },
        ],
        labels: lastN(numMonths, ltvData.data.labels) || [],
      },
    };

    prevData = {
      options: ltvData.options,
      data: {
        series: [
          {
            data: secondToLastN(numMonths, ltvData.data.series[0].data) || [],
            name: ltvData.data.series[0].name,
          },
        ],
        labels: secondToLastN(numMonths, ltvData.data.labels) || [],
      },
    };

    const filteredCurrentData: number[] = currentData.data.series[0].data.filter(
      (v) => v !== undefined
    ) as number[];
    const filteredPrevData: number[] = prevData.data.series[0].data.filter(
      (v) => v !== undefined
    ) as number[];
    currentAverage =
      filteredCurrentData.reduce((a, b) => a + b, 0) /
        filteredCurrentData.length || undefined;
    prevAverage =
      filteredPrevData.reduce((a, b) => a + b, 0) / filteredPrevData.length ||
      undefined;
  }

  return (
    <div key={`ltv-chart-${chartMode}`}>
      {title && <h5 className="mb-4">{title}</h5>}
      {loading && (
        <div className="m-8">
          <Loader />
        </div>
      )}
      {!loading && data && (
        <div className="w-100 ml-2">
          <FigureHeader
            name="Lifetime Value"
            descriptor={`Average of the last ${numMonths} months`}
            unit={currency}
            figure={currentAverage}
            prevFigure={prevAverage}
          />
          <LineChart data={currentData.data} options={currentData.options} />
        </div>
      )}
    </div>
  );
};

export default LtvChart;
