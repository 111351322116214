import React, { useEffect, useState } from "react";
import LineChart from "../../charts/LineChart";
import Loader from "../../icons/Loader";
import { MetricsResponse } from "../../../domain/metricsModel";
import { ChartMode, Rates } from "../../../domain/DomainModel";
import SubscriptionsOrRevenue from "../SubscriptionsOrRevenue";
import FigureHeader from "../FigureHeader";
import { formatChurn } from "../../../utils/formatters";
import { transformChurnChartData } from "./mapper";

interface ChurnChartProps {
  title?: string;
  data?: MetricsResponse;
  loading: boolean;
  currency: string;
  numMonths: number;
  rates: Rates;
  convertOthers: boolean;
  hideSwitch?: boolean;
  chartMode: ChartMode;
  updateChartMode: (mode: ChartMode) => void;
}

const ChurnChart = ({
  title,
  data,
  loading,
  currency,
  numMonths,
  convertOthers,
  rates,
  hideSwitch,
  chartMode,
  updateChartMode,
}: ChurnChartProps) => {
  const [sourceChurnChart, setSourceChurnChart] = useState(
    transformChurnChartData(
      numMonths,
      currency,
      chartMode,
      convertOthers,
      rates,
      data
    )
  );

  useEffect(() => {
    setSourceChurnChart(
      transformChurnChartData(
        numMonths,
        currency,
        chartMode,
        convertOthers,
        rates,
        data
      )
    );
  }, [data, currency, numMonths, chartMode, convertOthers, rates]);

  if (!loading && !data) {
    return null;
  }

  let firstValue;
  let lastValue;

  if (sourceChurnChart.data.series && sourceChurnChart.data.series[0]) {
    const data = sourceChurnChart.data.series[0].data;
    firstValue = formatChurn(data[0]);
    lastValue = formatChurn(data[data.length - 1]);
  }

  return (
    <div key={`churn-${chartMode}`}>
      {title && <h5 className="mb-4">{title}</h5>}
      {loading && (
        <div className="m-8">
          <Loader />
        </div>
      )}
      {!loading && data && (
        <div className="w-100 ml-2">
          <FigureHeader
            name="Churn Rate Evolution"
            descriptor={`In the last ${numMonths} months`}
            unit="%"
            figure={lastValue}
            prevFigure={firstValue}
            reverseDelta={true}
          />
          {!hideSwitch && (
            <div className="py-4">
              <SubscriptionsOrRevenue
                updateChartMode={updateChartMode}
                chartMode={chartMode}
                currency={currency}
                convertOthers={convertOthers}
              />
            </div>
          )}
          <LineChart
            data={sourceChurnChart.data}
            options={sourceChurnChart.options}
          />
        </div>
      )}
    </div>
  );
};

export default ChurnChart;
