import React from 'react'
import StripeConnectButton, {SKIP_REGISTRATION_STEP} from "../../../components/common/StripeConnectButton";
import {AUDIT_SUBMITTED_PATH} from "../../../App";
import Header from "../../../components/Header/Header";
import OnboardingForm from '../../../components/onboarding/OnboardingForm';
import {EMAIL_REGEXP, URL_REGEXP} from "../../../utils/regex";
import {registerWithEmail} from "../../../service/authService";
import Firebase, { withFirebase } from '../../../firebase';
import {HOME_URL} from "../../protected/MainPage/resources";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Check from '../../../components/basic/Check';

const AuditPage = ({firebase, history} : {firebase: Firebase} & RouteComponentProps) => {

    const [loading, setLoading] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [siteUrl, setSiteUrl] = React.useState('')
    const [isEmailValid, setIsEmailValid] = React.useState(true)
    const [isUrlValid, setIsUrlValid] = React.useState(true)

    const onSubmit = async () => {
        setLoading(true)
        await registerWithEmail(firebase, email, siteUrl, null)
        sessionStorage.setItem(SKIP_REGISTRATION_STEP, 'true')
    }

    const onChangeUrl = (siteUrl: string) => {
        setSiteUrl(siteUrl)
        setIsUrlValid(!!siteUrl.match(URL_REGEXP))
    }

    const onChangeEmail = (email: string) => {
        setEmail(email)
        setIsEmailValid(!!email.match(EMAIL_REGEXP))
    }

    if (firebase.getAuth().currentUser && !firebase.getAuth().currentUser?.isAnonymous) {
        history.push(HOME_URL);
    }

    return (
        <div className="container-main blue-background-bottom">
            <Header />
            <div className="max-w-7xl mx-auto py-auto md:px-4 sm:px-6 lg:px-8 pb-3 md:pb-0">
                <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:max-w-none">
                    <div className="col">
                        <div className="px-1 px-md-0 px-lg-5 py-auto my-3 mt-5">
                            <div className="text-center mb-3">
                                <h2 className="italic">
                                    Get an overview of your numbers
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 inline-block ml-2"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </h2>
                                <img
                                    src={require("../../../img/increase_revenue_illustration.svg")}
                                    alt=""
                                />
                            </div>
                            <div className="text-center mb-3">
                                <p>
                                    ,
                                    <br />
                                    Guaranteed ROI. <br className="md:hidden"/>You pay <u>based on performance</u>.
                                </p>
                            </div>
                            <div className="px-3 px-md-5 px-lg-5 mb-3">
                                <ul className="tickList pb-2 md:pb-5 md:pl-14">
                                    <li>
                                        <Check />
                                        <span className="ml-2">You only pay for your retention increment</span>
                                    </li>
                                    <li>
                                        <Check />
                                        <span className="ml-2">We guarantee 100% of your investment</span>
                                    </li>
                                    <li>
                                        <Check />
                                        <span className="ml-2">Win back customers automatically</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="px-5">
                                <img
                                    src={require("../../../img/brands_small.png")}
                                    alt=""
                                    className="grayscale"
                                />
                            </div>
                        </div>
                    </div>
                    <OnboardingForm
                        title="Should I invest in improving my retention?"
                        subtitle="Find out your ROI, how much your customer retention numbers could improve with our solution."
                        email={email}
                        isEmailValid={isEmailValid}
                        onChangeEmail={onChangeEmail}
                        siteUrl={siteUrl}
                        isUrlValid={isUrlValid}
                        onChangeUrl={onChangeUrl}
                    >
                        <StripeConnectButton
                            scope="read_write"
                            testMode={false}
                            disabled={!isEmailValid || !isUrlValid || !email || !siteUrl || loading}
                            redirectAfterSuccess={`https://tenstars.co${AUDIT_SUBMITTED_PATH}`}
                            redirectAfterFailure={window.location.href}
                            preconnectionStep={onSubmit}
                            text={loading ? "Loading..." : "Get an ROI analysis"}
                        />
                    </OnboardingForm>
                </div>
            </div>
        </div>)
}

export default withRouter(withFirebase(AuditPage))
