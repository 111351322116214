import React from "react";
import { Collapse } from "@chakra-ui/core";

function CollapsibleSection({
  title,
  children,
}: {
  title: string;
  children: React.ReactElement | React.ReactElement[];
}) {
  const [show, setShow] = React.useState(true);

  const handleToggle = () => setShow(!show);

  return (
    <div className="mb-8">
      <div className="relative cursor-pointer" onClick={handleToggle}>
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-start">
          <h2 className="pr-3 bg-gray-100 text-lg font-medium text-gray-900">
            {title}
          </h2>
        </div>
      </div>
      <Collapse mt={4} isOpen={show}>
        {children}
      </Collapse>
    </div>
  );
}

export default CollapsibleSection;
