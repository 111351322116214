import React from "react";
import Header from "../../components/Header/Header";
import {AuthUserContext} from "../../components/session";
import {withRouter} from "react-router-dom";
import {withFirebase} from "../../firebase";
import classNames from 'classnames';
import Calendar from 'react-calendar';
import CurrencyDropdown from "../../components/common/CurrencyDropdown";
import TryNow from "../../components/common/TryNow";
import Toggle from "react-toggle";
import MoreInfoHelper from "../../components/common/MoreInfoHelper";
import RevenueChart from "../../components/charts/RevenueChart";
import 'react-calendar/dist/Calendar.css';
import {currencySymbols} from "../../domain/InternationalDomain";

const defaultMonths = 12;
const defaultUsersNumber = 500;
const defaultSubscriptionPrice = 20;
const defaultCurrency = 'usd';
const defaultSymbol = '$';

const average = (list: number[]) => list.reduce((prev, curr) => Number(prev) + Number(curr), 0) / list.length;

const tenstarsPromise = 0.2;

export const MONTH_NAMES = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

interface ChurnRateCalculatorPageState {
	selectedCurrency: string
	currencySymbol: string
	firstMonth: Date
	subscriptionPrice: number
	shouldDefineMonthly: boolean
	months: number
	lostOn: number[]
	before: number[]
	createdOn: number[]
	data: any
	churnRate: number[]
	errors: (number | string | undefined)[]
	calendarVisible?: boolean
	currency?: number
	recoveredRevenue?: number
	recoveredRevenuePercentage?: number
	accumLoses?: number
	atTheEnd?: number
}

interface ChurnRateCalculatorPageProps {

}

class ChurnRateCalculatorPage extends React.Component<ChurnRateCalculatorPageProps, ChurnRateCalculatorPageState> {

	constructor(props: ChurnRateCalculatorPageProps) {
		super(props);

		this.state = {
			subscriptionPrice: defaultSubscriptionPrice,
			firstMonth: new Date(),
			selectedCurrency: defaultCurrency,
			currencySymbol: defaultSymbol,
			shouldDefineMonthly: false,
			months: defaultMonths,
			...this.stateForNMonths(defaultMonths, false),
		};

		this.state = {
			...this.state,
			...this.computeData()
		}
	}

	clone = (obj: any) => Object.assign({}, ...obj);

	monthName = (i: number) => {
		let month = new Date(this.state?.firstMonth || new Date());
		month.setMonth(month.getMonth() + i);
		return `${MONTH_NAMES[month.getMonth()]} ${month.getFullYear()}`;
	}

	stateForNMonths = (num: number, isAverage: boolean) => {

		return {
			atTheEnd: defaultUsersNumber,
			before: Array.from({length: num}).map((x, i) => (isAverage ? this.state?.before[0] : this.state?.before[i]) || defaultUsersNumber),
			createdOn: Array.from({length: num}).map((x, i) => (isAverage ? this.state?.createdOn[0] : this.state?.createdOn[i] || 0)),
			lostOn: Array.from({length: num}).map((x, i) => (isAverage ? this.state?.lostOn[0] : this.state?.lostOn[i] || 0)),
			churnRate: Array.from({length: num}).map(() => 0),
			errors: Array.from({length: num}).map(() => 0),
			accumLoses: 0,
			recoveredRevenue: 0,
			recoveredRevenuePercentage: 0,
			data: {
				byMonth: Array.from({length: num}).map(() => {
					return {}
				})
			}
		}
	}

	addMonth = () => {

		const {months, createdOn, lostOn, data, churnRate, errors, before} = this.state;

		createdOn.push(0);
		lostOn.push(0);
		churnRate.push(0);
		before.push(before[months - 1]);
		errors.push(undefined);
		data.byMonth.push({churnRate: 0, month: this.monthName(createdOn.length - 1)});
		this.setState({months: months + 1, createdOn, lostOn, churnRate, data}, this.computeDataAndSetState);
	}

	computeData = (): ChurnRateCalculatorPageState => {

		const {
			before,
			createdOn,
			lostOn,
			churnRate,
			data,
			errors,
			shouldDefineMonthly,
			subscriptionPrice,
		} = this.state;

		const lostOnWithTenstars = lostOn.map(l => l * (1 - tenstarsPromise));

		let numberOfUsersNormal = Number(before[0]);
		let numberOfLostUsersNormal = 0;

		let numberOfUsersTenstars = Number(before[0]);

		let accumLoses = 0;

		createdOn.forEach((c, i) => {
			const monthName = this.monthName(i);

			before[i] = i === 0 ? before[0] : Math.max((Number(before[i - 1]) + Number(createdOn[i - 1]) - Number(lostOn[i - 1])), 0);
			let churn = (Number(lostOn[i]) || 0) / Number(before[i]);

			if (Number(lostOn[i]) > Number(before[i])) {

				if (!shouldDefineMonthly) {
					errors[i] = `Churning ${lostOn[i]} users per month, you will lose ALL your users by month ${i + 1}.`;
				} else {
					errors[i] = `The churn rate on ${monthName} is greater than 100%. You are cancelling ${lostOn[i]} users but you only had ${before[i]}.`;

				}

				churn = churn || 1;
			} else {
				errors[i] = undefined;
			}

			churnRate[i] = churn || 0;

			// Revenue
			numberOfUsersNormal = numberOfUsersNormal + (Number(createdOn[i]) - Number(lostOn[i]));
			const revenue = numberOfUsersNormal * Number(subscriptionPrice);

			// Revenue with tenstars
			numberOfUsersTenstars = numberOfUsersTenstars + (Number(createdOn[i]) - Number(lostOnWithTenstars[i]));
			const hackedRevenue = numberOfUsersTenstars * Number(subscriptionPrice);

			// Accumulated loses without Tenstars
			numberOfLostUsersNormal = numberOfLostUsersNormal + Number(lostOn[i]);
			accumLoses += numberOfLostUsersNormal * Number(subscriptionPrice);

			data.byMonth[i] = {month: monthName, churnRate: churn, revenue, hackedRevenue};
		});

		const totalRevenue = data.byMonth.map((a: any) => a.revenue).reduce((a: any, b: any) => a + b, 0) || 0;
		const totalRevenueHacked = data.byMonth.map((a: any) => a.hackedRevenue).reduce((a: any, b: any) => a + b, 0) || 0;

		return {
			...this.state,
			data,
			currency: Math.random(),
			errors,
			before,
			recoveredRevenue: (totalRevenueHacked - totalRevenue),
			recoveredRevenuePercentage: ((totalRevenueHacked / totalRevenue) - 1) || 0,
			accumLoses,
			atTheEnd: Math.max((Number(before[before.length - 1]) + Number(createdOn[createdOn.length - 1]) - Number(lostOn[lostOn.length - 1])), 0)
		};
	}

	computeDataAndSetState = () => {
		this.setState({...this.computeData()});
	}

	renderInteractiveSection() {

		const {before, subscriptionPrice, shouldDefineMonthly, selectedCurrency, churnRate, accumLoses, currencySymbol} = this.state;

		return (<div>
			<div className="w-100 m-auto p-3 p-md-4">

				<label className="p-3 font-weight-bold w-100">1. How many users do you have?</label>

				<Input className="mb-4"
				       label="Users"
				       value={before[0]}
				       max={5000}
				       onChange={(e: React.ChangeEvent) => {
					       before[0] = (e.target as any).value;
					       this.setState({before}, this.computeDataAndSetState)
				       }}
				/>

				<Input className="mb-4"
				       label="Average Subscription Price"
				       value={subscriptionPrice}
				       allowFractions={true}
				       max={5000}
				       onChange={(e) => {
					       this.setState({subscriptionPrice: (e.target as any).value}, this.computeDataAndSetState)
				       }}
				>
					<div className="ml-2">
						<CurrencyDropdown
							selected={selectedCurrency}
							convertOthers={false}
							action={(c) => this.setState({selectedCurrency: c, currencySymbol: currencySymbols[c]} )}
						/>
					</div>
				</Input>

				<label className="p-3 font-weight-bold w-100">2.
					How many users you acquired? how many did you lose?</label>

				<div className="m-3">
					<span className="config-item">Define month by month </span>
					<MoreInfoHelper
						info={{
							title: "Define month by month",
							description: "Switch between defining the average monthly new users and lost/churned users or listing them for every single month.",
						}}
					/>
					<Toggle
						checked={shouldDefineMonthly}
						disabled={false}
						value="yes"
						onChange={() => this.setState({shouldDefineMonthly: !shouldDefineMonthly})}
						className="align-middle ml-2"
					/>
				</div>

				{shouldDefineMonthly && this.renderMonthByMonth()}
				{!shouldDefineMonthly && this.renderAverageMonth()}

				<div className="row no-gutters align-center mx-3 mt-md-3">
					<div className="col-6 col-md-4 mb-3">
						<div className="text-xs">Avg. Churn Rate</div>
						<div className="text-sm mt-2 font-weight-bold">{(average(churnRate) * 100).toFixed(2)}%</div>
					</div>
					<div className="col-6 col-md-4 mb-3">
						<div className="text-xs">Total Loses</div>
						<div
							className="text-sm mt-2 font-weight-bold">{((accumLoses || 0).toFixed(2)) || 0} {currencySymbol}</div>
					</div>
					<div className="col-12 col-md-4 mb-3">
						<div className="text-xs">Avg. Monthly Loss</div>
						<div className="text-sm mt-2 font-weight-bold">
							{(((accumLoses || 0) / 12).toFixed(2)) || 0} {currencySymbol}
						</div>
					</div>
				</div>
			</div>
		</div>);
	}

	renderResultsSection() {

		const {
			data,
			currency,
			subscriptionPrice,
			churnRate,
			lostOn,
			months,
			recoveredRevenue,
			recoveredRevenuePercentage,
			currencySymbol
		} = this.state;

		return <div>

			<div className="row justify-content-center">
				<img
					src={require("../../img/illustrations/calculator.svg")}
					className="mb-4 d-none d-sm-block"
					alt="Calculate your churn rate"
				/>
			</div>

			<div className="row align-center my-5">
				<div className="col-12 col-md-6 mb-3">
					<div className="text-lg py-3">We can lower your churn to</div>
					<div
						className="text-3xl mt-2 font-weight-bold">{(average(churnRate) * (100 - 100 * tenstarsPromise) || 0).toFixed(2)}%
					</div>
				</div>
				<div className="col-12 col-md-6 mb-md-3">
					<div className="text-lg py-3">Saving you</div>
					<div className="text-3xl mt-2 font-weight-bold">
						{((average(lostOn) * subscriptionPrice * 0.2).toFixed(2)) || 0} {currencySymbol} MRR
					</div>
				</div>
			</div>

			<div className="row align-center">
				<div className="col-12 col-md-6 mb-3">
					<div className="text-lg py-3">In {months} months</div>
					<div
						className="text-3xl mt-2 font-weight-bold">{(recoveredRevenue || 0).toFixed(2)} {currencySymbol}</div>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<div className="text-lg py-3">That means ending the exercise with</div>
					<div className="text-3xl mt-2 font-weight-bold">+{(recoveredRevenuePercentage || 0).toFixed(2)}%
						Revenue
					</div>
				</div>
			</div>

			<div className="my-5">
				<RevenueChart data={data}
				              title={`${data.byMonth.length} months Revenue Progression`}
				              loaded={true}
				              currency={(currency || '').toString()}
				              currencyValue={currencySymbol}
				              center
				/>
			</div>

			<div className="text-center mt-5 mx-2">
				<h5 className="mb-3 text-center">Get your real stats for free. Connect your Stripe account</h5>
				<div className="flex justify-content-center">
					<TryNow/>
				</div>
			</div>


		</div>
	}

	renderAverageMonth() {

		const {before, createdOn, lostOn, months, errors} = this.state;

		return <>

			{
				[errors.find(e => !!e)].map(e => {
					return !e ? null : <div className="m-3 w-auto">
						<div key={e} className="alert alert-danger">{e}</div>
					</div>
				})
			}

			<Input className="w-auto h-100"
			       label="How many months will the exercise last?"
			       value={months}
			       min={2}
			       max={12}
			       onChange={(e) => {
				       const updatedMonths = Math.min((e.target as any).value, 24);
				       this.setState({
						       months: updatedMonths,
						       ...this.stateForNMonths(updatedMonths, true)
					       },
					       this.computeDataAndSetState
				       )
			       }}
			/>

			<Input className="w-auto h-100"
			       label="Average New Users (per month)"
			       value={createdOn[0]}
			       max={Number(before[0]) * 3}
			       allowFractions={true}
			       onChange={(e) => {
				       const newCreatedOn = createdOn.map(() => (e.target as any).value || 0);
				       this.setState({createdOn: newCreatedOn}, this.computeDataAndSetState);
					   (e.target as any).focus();
			       }}
			/>
			<Input className="w-auto h-100"
			       label="Average Lost/Churned Users (per month)"
			       value={lostOn[0]}
			       max={Number(before[0] / 12)}
			       allowFractions={true}
			       onChange={(e) => {
				       const newLostOn = lostOn.map(() => (e.target as any).value || 0);
				       this.setState({lostOn: newLostOn}, this.computeDataAndSetState);
					   (e.target as any).focus();
			       }}
			/>
		</>
	}

	renderMonthByMonth() {

		const {before, createdOn, lostOn, churnRate, firstMonth, calendarVisible, errors} = this.state;

		return <>
			{
				errors.map(e => {
					return !e ? null : <div key={e} className="m-3 w-auto">
						<div key={e} className="alert alert-danger">{e}</div>
					</div>
				})
			}

			{createdOn.map((e, i) => {

				const monthName = this.monthName(i);
				const isFirst = i === 0;

				// leave without key, let it be inefficient
				// it flickers or misses renders, with a bad key
				// eslint-disable-next-line react/jsx-key
				return (<div className="w-100">

					{!isFirst && <h5 className="font-weight-bold ml-3 mb-3">{monthName}</h5>}
					{isFirst && !calendarVisible &&
					<button
						className="btn btn-primary negative m-3"
						onClick={() => this.setState({calendarVisible: true})}
					>
						{this.monthName(0)}
					</button>}

					{isFirst && calendarVisible &&
					<Calendar className="calendar mx-auto mb-3" defaultView="year" view="year"
					          value={firstMonth}
					          onClickMonth={(value) => this.setState({
						          firstMonth: value,
						          calendarVisible: false
					          }, this.computeDataAndSetState)}
					/>}

					<Input className="w-auto h-100"
					       label="New users"
					       value={createdOn[i]}
					       max={before[i]}
					       onChange={(e) => {
						       createdOn.splice(i, 1, (e.target as any).value);
						       this.setState({createdOn}, this.computeDataAndSetState);
							   (e.target as any).focus();
					       }}
					/>
					<Input className="w-auto h-100"
					       label="Lost/churned users"
					       value={lostOn[i]}
					       max={before[i]}
					       onChange={(e) => {
						       lostOn.splice(i, 1, (e.target as any).value);
						       this.setState({lostOn}, this.computeDataAndSetState);
							   (e.target as any).focus();
					       }}
					/>
					<div>
						<div
							className="mb-3 p-3 w-100 h-100 flex-column align-center">
							<strong>Churn Rate {(churnRate[i] * 100).toFixed(2)} %</strong>
						</div>
					</div>
				</div>);
			})}

			<div
				className="mt-3 w-100 align-center">
				<button
					onClick={this.addMonth}
					className="btn btn-primary mb-3"
				>
					<>+ Add Month</>
				</button>
			</div>
		</>
	}

	render() {

		return (<AuthUserContext.Consumer>
			{() => (
				<div className="container-main mb-md-4 mb-lg-4">
					<Header/>
					<div className="container">
						<div className="row">
							<div className="col section mb-3">
								<h1 className="align-center">Churn Rate Calculator</h1>
								<h2 className="align-center mt-3">Find out what it is and how to improve
									it <MoreInfoHelper
										info={{
											title: "Find out what it is and how to improve it",
											description: "What are your values? How it impacts your business? How can a Churn Rate reduction impact your business?",
										}}
									/></h2>
							</div>
						</div>
						<div className="section-rounded-shadow mt-3 mb-5">
							<div className="row ">
								<div className="col-12 col-md-4">
									{this.renderInteractiveSection()}
								</div>
								<div className="col-12 col-md-8 bg-blueish pt-0">
									<div className="px-2 pt-0 pl-md-5 px-md-5 px-lg-5 pb-5">
										{this.renderResultsSection()}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</AuthUserContext.Consumer>);
	}
}

interface InputProps {
	className: string
	label: string
	value: number
	onChange: (e: React.ChangeEvent) => void
	children?: React.ReactChild
	max: number
	min?: number
	allowFractions?: boolean
}
const Input = (props: InputProps) => {

	const {label, value, onChange, className, children, max, min, allowFractions} = props;

	return <div className={classNames(className, 'p-3', 'd-flex', 'align-center')}>
		<div className="d-flex flex-column w-100">
			{label && <label className="text-left">{label}</label>}
			<div className="d-flex font-weight-bold">
				<input className="form-control-range mr-1 align-center w-50"
				       min={min || 0}
				       max={max}
				       type="range"
				       value={value}
				       onChange={onChange}/>
				<div className="w-50 d-flex">
					<input className={classNames("form-control align-center w-100", {'w-75': !!children})}
					       type="number"
					       min={0}
					       step={allowFractions ? "0.01" : 1}
					       value={value}
					       onChange={onChange}
					/>
					<div>{children}</div>
				</div>
			</div>
		</div>
	</div>;
}

const Composed = withRouter(withFirebase(ChurnRateCalculatorPage));

export default Composed;
