const subscriptionLink = (id, testMode) => {
  if (testMode) {
    return `https://dashboard.stripe.com/test/subscriptions/${id}`;
  } else {
    return `https://dashboard.stripe.com/subscriptions/${id}`;
  }
};

const subscriptionEditLink = (id, testMode) => {
  if (testMode) {
    return `https://dashboard.stripe.com/test/subscriptions/${id}/edit`;
  } else {
    return `https://dashboard.stripe.com/subscriptions/${id}/edit`;
  }
};

const productLink = (id, testMode) => {
  if (testMode) {
    return `https://dashboard.stripe.com/test/products/${id}`;
  } else {
    return `https://dashboard.stripe.com/products/${id}`;
  }
};

export { subscriptionLink, subscriptionEditLink, productLink };
