import React from "react";
import styled, { keyframes } from "styled-components";

export default function PriceHeroPriceAB() {
  return (
    <Wrapper>
      <PriceA>$50</PriceA>
      <PriceB>$99</PriceB>
    </Wrapper>
  );
}

//

const animationA = keyframes`
  0% { opacity: 0; transform: scale(0.5); transition-duration: .5s; animation-delay: 5s;}
  30% { opacity: 1; transform: scale(1); }
  35% { opacity: 0; transform: scale(1); transition-duration: .5s; transform: translate3d(-50px, 0px, 0px); }
  75% { opacity: 0; transform: scale(1);}
  100% { opacity: 0; transform: scale(0.5);}
`;
const animationB = keyframes`
  0% { opacity: 0; width: 0; left: 0; animation-delay: 5s;}
  30% { opacity: 0; width: 0;transform: scale(1); left: 50%; }
  35% { opacity: 1; width: 0;transform: scale(1); transition-duration: .5s; transform: translate3d(-85px, 0px, 0px); }
  75% { opacity: 1; width: 0;transform: scale(1); transform: translate3d(-85px, 0px, 0px);}
  100% { opacity: 0; width: 0;transform: scale(2);transform: translate3d(-85px, 0px, 0px);}
`;

const Wrapper = styled.span`
  display: inline-block;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;

  & span {
    display: inline-block;
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 66px;
    color: #3c3c3c;
    letter-spacing: 0.33px;
    font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", "Icons16",
      sans-serif;
  }
`;
const PriceA = styled.span`
  animation-name: ${animationA};
  animation-duration: 9s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  //animation-delay: 5s;
  //animateMotion: ease-in;
`;
const PriceB = styled.span`
  z-index: 1000;
  animation-name: ${animationB};
  animation-duration: 9s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
`;
