import ExpandableContainer from "../../basic/ExpandableContainer";
import React, { useState } from "react";
import { StatsResponse } from "../../../domain/metricsModel";
import RetentionChart from "./RetentionChart";
import { ChartMode, Rates } from "../../../domain/DomainModel";

interface DashboardRetentionChartProps {
  numMonths: number;
  currency: string;
  data?: StatsResponse;
  rates: Rates;
  convertOthers: boolean;
  menuClosed: boolean;
  loading: boolean;
  minHeight?: number;
}

const DashboardRetentionChart = (props: DashboardRetentionChartProps) => {
  const [isExpanded, setExpanded] = React.useState(false);
  const [chartMode, setChartMode] = useState<ChartMode>("revenue");

  return (
    <ExpandableContainer onUpdate={setExpanded} minHeight={props.minHeight}>
      <RetentionChart
        key={`retention-saved-${isExpanded}/${props.numMonths}/${props.currency}/${props.data?.testMode}/${props.data?.retentionByMonth?.length}/${props.convertOthers}/${chartMode}/${props.menuClosed}`}
        loading={props.loading}
        currency={props.currency}
        data={props.data}
        numMonths={props.numMonths}
        rates={props.rates}
        convertOthers={props.convertOthers}
        chartMode={chartMode}
        updateChartMode={setChartMode}
      />
    </ExpandableContainer>
  );
};

export default DashboardRetentionChart;
