import classNames from "classnames";
import React from "react";
import { formatDecimals, formatFigure } from "../../../utils/formatters";
import statsCalculator from "../../../service/statsCalculator";

const Delta = (props: {
  current?: number;
  previous?: number;
  reverse?: boolean;
  unit?: string;
}): React.ReactElement | null => {
  if (props.current === undefined || props.previous === undefined) {
    return <div style={{ minHeight: "32px" }} />;
  }

  const wasPercentage = props.unit === "%";

  const diff = !wasPercentage ? props.current - props.previous : undefined;

  const delta = statsCalculator.doDelta(
    props.current,
    props.previous,
    wasPercentage
  );
  const isGreen =
    (delta >= 0 && !props.reverse) || (delta <= 0 && props.reverse);
  const isRed = !isGreen;

  return (
    <div
      style={{ minHeight: "32px" }}
      className={classNames(
        "items-baseline delta whitespace-nowrap",
        { "text-gray-100": isGreen },
        { "text-red-700": isRed }
      )}
    >
      <span
        className={classNames(
          " px-2.5 py-0.5 rounded-full text-xl font-bold",
          { "bg-green-500": isGreen },
          { "bg-red-100": isRed }
        )}
      >
        <span>
          {delta >= 0 && "+"}
          {formatDecimals(delta, 1)}%
        </span>
      </span>
      {diff !== undefined && (
        <span
          className={classNames(
            "ml-2 text-lg",
            { "text-green-700": isGreen },
            { "text-red-700": isRed }
          )}
        >
          {diff >= 0 && "+"}
          {formatFigure(diff, props.unit)}
        </span>
      )}
    </div>
  );
};

export default Delta;
