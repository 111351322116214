import { Dictionary, Rates } from "../domain/DomainModel";

export const getOrConvertCurrency = (
  data: Dictionary<number> | undefined,
  rates: Rates,
  baseCurrency: string,
  convertOthers: boolean
): number => {
  if (!data) {
    return 0;
  }

  if (!convertOthers) {
    return data[baseCurrency] || 0;
  } else {
    return Object.keys(data)
      .map((k) => data[k] * (rates[k] ? rates[k][baseCurrency] : 1))
      .reduce((a, b) => a + b, 0);
  }
};
