import CancellationsByReasonChart from "./CancellationsByReasonChart";
import React, { useState } from "react";
import { StatsResponse } from "../../../domain/metricsModel";
import ExpandableContainer from "../../basic/ExpandableContainer";
import { ChartMode, Rates } from "../../../domain/DomainModel";

interface DashboardCancellationsChartProps {
  data?: StatsResponse;
  loading: boolean;
  numMonths: number;
  selectedLanguage: string;
  updateSelectedLanguage: (value: string) => void;
  setNumMonths: (value: number) => void;
  setCurrency: (value: string) => void;
  currency: string;
  defaultExpanded: boolean;
  rates: Rates;
  convertOthers: boolean;
  updateConvertOthers?: (value: boolean) => void;
}

const DashboardCancellationsChart = (
  props: DashboardCancellationsChartProps
) => {
  const [isExpanded, setExpanded] = React.useState(props.defaultExpanded);
  const [chartMode, setChartMode] = useState<ChartMode>("subscriptions");

  return (
    <ExpandableContainer
      onUpdate={setExpanded}
      defaultExpanded={props.defaultExpanded}
    >
      <CancellationsByReasonChart
        loading={props.loading}
        key={`byreason-${isExpanded}/${props.numMonths}/${props.currency}/${props.data?.testMode}/${props.data?.cancellationAttemptsByMonth.length}/${props.convertOthers}/${chartMode}`}
        selectedLanguage={props.selectedLanguage}
        updateSelectedLanguage={props.updateSelectedLanguage}
        data={props.data}
        setNumMonths={props.setNumMonths}
        numMonths={props.numMonths}
        setCurrency={props.setCurrency}
        currency={props.currency}
        rates={props.rates}
        convertOthers={props.convertOthers}
        updateConvertOthers={props.updateConvertOthers}
        chartMode={chartMode}
        updateChartMode={setChartMode}
        isExpanded={isExpanded}
      />
    </ExpandableContainer>
  );
};

export default DashboardCancellationsChart;
