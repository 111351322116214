import React, { Component } from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import { PasswordForgetLink } from "./ForgotPasswordPage";
import Firebase, { withFirebase } from "../../firebase";
import Header from "../../components/Header/Header";
import {HOME_URL} from "../protected/MainPage/resources";
import GoogleLogin from "../../components/basic/GoogleLogin";
import {registerWithGoogle} from "../../service/authService";

const SignInPage = () => (
  <div className="container-main blue-background-bottom">
    <Header />
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8 ">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:max-w-none">
              <div className="col"/>
              <div className="col">
                  <div className="px-3 px-md-5 py-auto my-3 mt-5">
                      <div className="mb-3 px-3 text-md-left text-sm-center">
                          <h2 className="mb-3 px-2 text-lg-plus">
                              <strong>Sign In to your account</strong>
                          </h2>
                          <h3 className="px-2">Grow your business faster</h3>
                      </div>
                      <div className="d-block">
                          <SignInForm />
                      </div>
                      <div className="d-block">
                          <PasswordForgetLink />
                      </div>
                      <div className="d-block">
                          <p className="mt-2">
                              You don&apos;t have an account yet? <Link to="/onboarding">Get one here</Link>
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

interface SignInFormBaseProps {
    firebase: Firebase;
    onGoogleSubmit: () => void;
}

interface SignInFormBaseState {
    email: string;
    password: string;
    error: string | null;
}

class SignInFormBase extends Component<SignInFormBaseProps & RouteComponentProps, SignInFormBaseState> {
  constructor(props: SignInFormBaseProps & RouteComponentProps) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }


    onGoogleSubmit = () => {
        registerWithGoogle(this.props.firebase, null)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(HOME_URL);
            })
            .catch((error) => {
                this.setState({ error: error.message });
            });
    };

  onSubmitEmailAndPassword = (event: React.FormEvent) => {
    event.preventDefault();
    const { email, password } = this.state;

    this.props.firebase
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(HOME_URL);
      })
      .catch((error) => {
        this.setState({ error: error.message });
      });
  };

      onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ email: event.target.value });
      };

    onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ password: event.target.value });
    };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <React.Fragment>
          {/*<div className="flex justify-center my-8">*/}

          {/*</div>*/}

          {/*<div className="flex justify-center my-8 text-lg font-weight-bold">*/}
          {/*    - OR -*/}
          {/*</div>*/}
          <form onSubmit={this.onSubmitEmailAndPassword} className="ui form">
            <div className="form-group">
              <label htmlFor="username">Email</label>
              <input
                name="email"
                value={email}
                onChange={this.onChangeEmail}
                type="text"
                placeholder="Email Address"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="username">Password</label>
              <input
                name="password"
                value={password}
                onChange={this.onChangePassword}
                type="password"
                placeholder="Password"
                className="form-control"
              />
            </div>
              <div className="text-right">
                  <button
                      disabled={isInvalid}
                      type="submit"
                      className="btn btn-primary mb-2"
                      id="btn-signin"
                  >
                      Sign In
                  </button>
              </div>
            {error && <div style={{display: 'block'}} className="invalid-feedback py-4">{error}</div>}
          </form>
          <div className="flex justify-content-start items-center md:pl-4">
              <GoogleLogin onClick={this.onGoogleSubmit} />
          </div>
      </React.Fragment>
    );
  }
}

const SignInForm = withRouter(withFirebase(SignInFormBase));

export default SignInPage;

export { SignInForm };
