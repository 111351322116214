import React, { useEffect, useState } from "react";
import LineChart from "../../charts/LineChart";
import { transformRetentionChartData } from "./mapper";
import { StatsResponse } from "../../../domain/metricsModel";
import { ChartMode, Rates } from "../../../domain/DomainModel";
import Loader from "../../icons/Loader";
import SubscriptionsOrRevenue from "../SubscriptionsOrRevenue";
import FigureHeader from "../FigureHeader";

interface RetentionChartProps {
  title?: string;
  data?: StatsResponse;
  currency: string;
  numMonths: number;
  rates: Rates;
  convertOthers: boolean;
  hideSwitch?: boolean;
  chartMode: ChartMode;
  updateChartMode: (mode: ChartMode) => void;
  loading: boolean;
}

const RetentionChart = (props: RetentionChartProps) => {
  const {
    title,
    data,
    loading,
    currency,
    numMonths,
    rates,
    convertOthers,
    chartMode,
    updateChartMode,
    hideSwitch,
  } = props;

  const [retentionData, setRetentionData] = useState(
    transformRetentionChartData(
      data,
      currency,
      chartMode,
      numMonths,
      convertOthers,
      rates
    )
  );

  useEffect(() => {
    updatedData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updatedData();
    // eslint-disable-next-line
  }, [data, currency, chartMode, numMonths, rates, convertOthers]);

  const updatedData = () => {
    setRetentionData(
      transformRetentionChartData(
        data,
        currency,
        chartMode,
        numMonths,
        convertOthers,
        rates
      )
    );
  };

  if (!loading && !data) {
    return null;
  }

  const unit = chartMode === "revenue" ? currency : "";
  let firstValue;
  let lastValue;

  if (retentionData.data.series) {
    const data = retentionData.data.series[2].data;
    firstValue = data[0];
    lastValue = data[data.length - 1];
  }

  return (
    <div key={`retention-chart-${chartMode}`}>
      {title && <h5 className="mb-4">{title}</h5>}
      {loading && (
        <div className="m-8">
          <Loader />
        </div>
      )}
      {!loading && data && (
        <div>
          <div className="w-100 ml-2">
            <FigureHeader
              name="Customer Retention Evolution"
              descriptor={`In the last ${numMonths} months`}
              unit={unit}
              figure={lastValue}
              prevFigure={firstValue}
            />
          </div>
          {!hideSwitch && (
            <div className="py-4">
              <SubscriptionsOrRevenue
                updateChartMode={updateChartMode}
                chartMode={chartMode}
                currency={currency}
                convertOthers={convertOthers}
              />
            </div>
          )}
          <LineChart
            data={retentionData.data}
            options={retentionData.options}
          />
        </div>
      )}
    </div>
  );
};

export default RetentionChart;
