import tenstars from "../../img/logo/tenstars.svg";
import dollar from "../../img/logo/dollar.svg";
import logoDropbox from "../../img/logo/dropbox.svg";
import logoGitlab from "../../img/logo/gitlab.svg";
import lime from "../../img/logo/lime.svg";
import red_cross from "../../img/logo/red_cross.svg";
import { PricingTheme, Product } from "../../domain/DomainModel";

export const dataStorageKey = "tenstars_pricing_simulation_data";

export const defaultType = "classic";

export const defaultTitle: {
  classic: string;
  single: string;
  donation: string;
} = {
  classic: "Choose a Product",
  single: "10x times better than pizza",
  donation: "My Donation",
};
export const defaultDescription: {
  classic: string;
  single: string;
  donation: string;
} = {
  classic:
    "Choose the plan that's right for yours business. Whether you're just getting started or well down the path, you've got you covered.",
  single:
    "Eat our product at work, bus station or in the post office. With friends, family or your favorite kitten.",
  donation:
    "You can protect threatened species and their habitats today with a monthly donation. Your support will make a world of difference.",
};

export const defaultSampleProducts: {
  classic: Product[];
  single: Product[];
  donation: Product[];
} = {
  classic: [
    {
      name: "Freelancer",
      description: "The basic features for your business",
      features: [
        "Daily updates",
        "Complete report",
        "Coffee shots",
        "Casual Fridays",
        "-Weekly consultations",
      ],
      price: { currencySymbol: "$", currency: "usd", price: "24" },
      interval: "2",
    },
    {
      name: "Startup",
      description: "Additional features for teams",
      features: [
        "Daily updates",
        "Complete report",
        "Coffee shots",
        "Weekly consultations",
        "-Ping Pong table",
      ],
      price: { currencySymbol: "$", currency: "usd", price: "50" },
      interval: "2",
    },
    {
      name: "Enterprise",
      description: "Support multiple teams across your organization",
      features: [
        "Daily updates",
        "Complete report",
        "Coffee shots",
        "Weekly consultations",
        "Ping Pong table",
      ],
      price: { currencySymbol: "$", currency: "usd", price: "120" },
      interval: "2",
    },
  ],
  single: [
    {
      name: "The perfect snack",
      description: "The right size for every user.",
      features: [
        "Daily updates",
        "Complete report",
        "Coffee shots",
        "Casual Fridays",
      ],
      price: { currencySymbol: "$", currency: "usd", price: "99" },
      interval: "2",
    },
    {
      name: "Startup",
      description: "Additional features for teams",
      features: [
        "Daily updates",
        "Complete report",
        "Coffee shots",
        "Weekly consultations",
        "-Ping Pong table",
      ],
      price: { currencySymbol: "$", currency: "usd", price: "50" },
      interval: "2",
    },
    {
      name: "Enterprise",
      description: "Support multiple teams across your organization",
      features: [
        "Daily updates",
        "Complete report",
        "Coffee shots",
        "Weekly consultations",
        "Ping Pong table",
      ],
      price: { currencySymbol: "$", currency: "usd", price: "120" },
      interval: "2",
    },
  ],
  donation: [
    {
      description: "Buys a koala breakfast",
      features: [
        "Daily updates",
        "Complete report",
        "Coffee shots",
        "Casual Fridays",
        "-Weekly consultations",
      ],
      interval: "2",
      name: "10",
      price: {
        currency: "usd",
        currencySymbol: "$",
        price: "10",
      },
    },
    {
      description: "Gives a panda a massage",
      features: [
        "Daily updates",
        "Complete report",
        "Coffee shots",
        "Casual Fridays",
        "-Weekly consultations",
      ],
      interval: "2",
      name: "25",
      price: {
        currency: "usd",
        currencySymbol: "$",
        price: "25",
      },
    },
    {
      description: "Sends a giraffe to school",
      features: [
        "Daily updates",
        "Complete report",
        "Coffee shots",
        "Casual Fridays",
        "-Weekly consultations",
      ],
      interval: "2",
      name: "50",
      price: {
        currency: "usd",
        currencySymbol: "$",
        price: "50",
      },
    },
  ],
};

export const themes: {
  classic: PricingTheme[];
  single: PricingTheme[];
  donation: PricingTheme[];
} = {
  classic: [
    {
      name: "Tenstars",
      description: "Our favorite design",
      logo: tenstars,
    },
    {
      name: "Minimal",
      description: "Default minimal theme",
      logo: dollar,
    },
    {
      name: "Dropbox",
      description: "A price page like the files guys",
      logo: logoDropbox,
    },
    {
      name: "Gitlab",
      description: "What does the fox say?",
      logo: logoGitlab,
    },
  ],
  single: [
    {
      name: "Minimal",
      description: "Default minimal theme",
      logo: dollar,
    },
    {
      name: "Lime",
      description: "A citric touch and a quote button",
      logo: lime,
    },
  ],
  donation: [
    {
      name: "Red Cross",
      description: "A simple approach is a big plus",
      logo: red_cross,
    },
  ],
};
