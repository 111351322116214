import React from "react";
import { fetchRates } from "../api/api";
import { Dictionary } from "../domain/DomainModel";

export const RatesContext = React.createContext({});

export const RatesWrapper = (props: {
  token?: string;
  children: React.ReactNode;
}) => {
  const [rates, setRates] = React.useState<Dictionary<Dictionary<string>>>({});

  React.useEffect(() => {
    if (props.token) {
      updateRates(props.token);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (props.token && !Object.keys(rates).length) {
      updateRates(props.token);
    }
    // eslint-disable-next-line
  }, [props.token]);

  const updateRates = async (token: string): Promise<void> => {
    const newRates = await fetchRates(token);
    setRates(newRates.data);
  };
  return (
    <RatesContext.Provider value={rates}>
      {props.children}
    </RatesContext.Provider>
  );
};
