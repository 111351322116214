import React from "react";
import Firebase from ".";
const FirebaseContext = React.createContext<Firebase | undefined>(undefined);
// Higher order component https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial/
export const withFirebase = (Component: any) => (props: any) => (
  <FirebaseContext.Consumer>
    {(firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;
