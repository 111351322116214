import React, { useEffect, useState } from "react";
import Drawer from "../basic/Drawer";
import DrawerSection from "./DrawerSection";
import { URL_REGEXP } from "../../utils/regex";
import { Pricing, Product } from "../../domain/DomainModel";

interface PricingPageBuilderEditProps {
  freePlanUrl: string | null;
  emptyPlanUrl: string | null;
  quoteUrl: string | null;
  products: Product[];
  onUpdate: (values: Partial<Pricing>) => void;
  onContinue: () => void;
  siteUrl?: string;
}

const PricingPageBuilderEdit = (props: PricingPageBuilderEditProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [
    successCancelLinksSectionInvalid,
    setSuccessCancelLinksSectionInvalid,
  ] = useState(true);
  const [zeroPriceSectionInvalid, setZeroPriceSectionInvalid] = useState(true);
  const [emptyPriceSectionInvalid, setEmptyPriceSectionInvalid] = useState(
    true
  );
  const [quoteSectionInvalid, setQuoteSectionInvalid] = useState(true);
  const [redirectFree, setRedirectFree] = useState(!!props.freePlanUrl);
  const [redirectEmpty, setRedirectEmpty] = useState(!!props.emptyPlanUrl);
  const [freePlanUrl, setFreePlanUrl] = useState<string | undefined | null>(
    props.freePlanUrl
  );
  const [emptyPlanUrl, setEmptyPlanUrl] = useState<string | undefined | null>(
    props.emptyPlanUrl
  );
  const [quoteUrl, setQuoteUrl] = useState<string | undefined | null>(
    props.quoteUrl
  );

  const [hasTrialPeriod, setHasTrialPeriod] = useState<boolean>(
    props.products.some((p) => !!p.trialPeriodDays)
  );
  const [trialPeriodDays, setTrialPeriodDays] = useState<number | undefined>(
    props.products[0].trialPeriodDays
  );
  const [
    trialPeriodSectionInvalid,
    setTrialPeriodSectionInvalid,
  ] = useState<boolean>(true);

  const onUpdateSuccessCancelInput = (
    update: { successUrl: string } | { cancelUrl: string }
  ): void => {
    validate();

    const products = props.products.map((p: Product) => {
      return { ...p, ...update };
    });

    if (!successCancelLinksSectionInvalid) {
      props.onUpdate({ products });
    }
  };

  const onUpdateFreePlanInput = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFreePlanUrl(value);

    validate();

    if (!zeroPriceSectionInvalid) {
      props.onUpdate({ freePlanUrl: value });
    }
  };

  const onUpdateEmptyPlanInput = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setEmptyPlanUrl(value);

    validate();

    if (!emptyPriceSectionInvalid) {
      props.onUpdate({ emptyPlanUrl: value });
    }
  };

  const onFreePlanChecked = (checked: boolean) => {
    setRedirectFree(checked);

    if (checked) {
      setFreePlanUrl(props.freePlanUrl || "");
    } else {
      setFreePlanUrl("");
    }

    validate();

    if (!zeroPriceSectionInvalid) {
      props.onUpdate({ freePlanUrl: null });
    }
  };

  const onEmptyPlanChecked = (checked: boolean) => {
    setRedirectEmpty(checked);

    if (checked) {
      setEmptyPlanUrl(props.emptyPlanUrl || "");
    } else {
      setEmptyPlanUrl("");
    }

    validate();

    if (!emptyPriceSectionInvalid) {
      props.onUpdate({ emptyPlanUrl: null });
    }
  };

  const onUpdateQuoteUrlInput = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuoteUrl(value);

    validate();

    if (!quoteSectionInvalid) {
      props.onUpdate({ quoteUrl: value });
    }
  };

  const onTrialPeriodUpdate = (
    checked: boolean,
    newTrialPeriodDays?: number
  ) => {
    setHasTrialPeriod(checked);

    if (checked) {
      setTrialPeriodDays(newTrialPeriodDays);
    } else {
      setTrialPeriodDays(undefined);
    }

    validate();

    const products = props.products.map((p: Product) => {
      return { ...p, trialPeriodDays: newTrialPeriodDays };
    });

    if (!trialPeriodSectionInvalid) {
      props.onUpdate({ products });
    }
  };

  const validate = () => {
    const invalidSuccessCancelLinksSection = props.products.some(
      (p: Product) =>
        (!!p.cancelUrl && !p.cancelUrl.match(URL_REGEXP)) ||
        (p.successUrl && !p.successUrl?.match(URL_REGEXP))
    );
    const invalidZeroPriceSection =
      redirectFree && !!freePlanUrl && !freePlanUrl.match(URL_REGEXP);

    const invalidEmptyPriceSection =
      redirectEmpty && !!emptyPlanUrl && !emptyPlanUrl.match(URL_REGEXP);

    const invalidQuoteSection = !!quoteUrl && !quoteUrl?.match(URL_REGEXP);

    const invalidTrialPeriod =
      !!trialPeriodDays && (trialPeriodDays <= 0 || trialPeriodDays > 90);

    setSuccessCancelLinksSectionInvalid(invalidSuccessCancelLinksSection);
    setZeroPriceSectionInvalid(invalidZeroPriceSection);
    setEmptyPriceSectionInvalid(invalidEmptyPriceSection);
    setQuoteSectionInvalid(invalidQuoteSection);
    setTrialPeriodSectionInvalid(invalidTrialPeriod);

    const incompleteSuccessCancelLinksSection =
      invalidSuccessCancelLinksSection ||
      props.products.some((p: Product) => !p.cancelUrl || !p.successUrl);

    const incompleteZeroPriceSection =
      invalidZeroPriceSection || (redirectFree && !freePlanUrl);

    const incompleteEmptyPriceSection =
      invalidEmptyPriceSection || (redirectEmpty && !emptyPlanUrl);

    const incompleteQuoteSection = invalidQuoteSection || !quoteUrl;

    setDisabled(
      incompleteSuccessCancelLinksSection ||
        incompleteZeroPriceSection ||
        incompleteEmptyPriceSection ||
        incompleteQuoteSection ||
        invalidTrialPeriod
    );
  };

  useEffect(() => {
    validate();
  });

  return (
    <div>
      <div className="flex justify-content-end">
        <button
          onClick={() => setDrawerOpen(true)}
          className="btn btn-primary pull-right"
        >
          Configure Content
        </button>
        {drawerOpen && (
          <Drawer
            disabled={disabled}
            onClose={() => setDrawerOpen(false)}
            onContinue={props.onContinue}
            title="Configure Page"
            description="Before proceeding, please configure the behaviour of your new pricing page."
          >
            <DrawerSection
              required={true}
              sectionTitle="Success and cancel pages"
              infoHelperTitle="When Stripe redirects back to you"
              infoHelperDescription="Stripe will redirect to your own success page upon a successful subscription. Alternatively Stripe will redirect to your own cancel page if the users cancels the payment process."
              errorText="Invalid URL (must start with https://)"
              hasError={successCancelLinksSectionInvalid}
            >
              <div className="mt-2">
                <input
                  type="text"
                  defaultValue={props.products[0]?.successUrl || ""}
                  onBlur={(event) =>
                    onUpdateSuccessCancelInput({
                      successUrl: event.target.value,
                    })
                  }
                  placeholder={`${
                    props.siteUrl || "https://www.yourdomain.com"
                  }/success.html`}
                  className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
              <div className="mt-2">
                <input
                  type="text"
                  defaultValue={props.products[0]?.cancelUrl || ""}
                  onBlur={(event) =>
                    onUpdateSuccessCancelInput({
                      cancelUrl: event.target.value,
                    })
                  }
                  placeholder={`${
                    props.siteUrl || "https://www.yourdomain.com"
                  }/cancel.html`}
                  className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </DrawerSection>

            <DrawerSection
              sectionTitle="Trial Period"
              infoHelperTitle="Trial Period"
              infoHelperDescription="Define the length of your trial period or disable it."
              errorText="Number of days must be a value between 1 and 90"
              hasError={trialPeriodSectionInvalid}
            >
              <div className="mt-2">
                <input
                  type="checkbox"
                  checked={redirectEmpty}
                  className="mr-2 shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                  onChange={(e): void =>
                    onTrialPeriodUpdate(e.target.checked, trialPeriodDays)
                  }
                />
                <label
                  onClick={(e): void =>
                    onTrialPeriodUpdate(!hasTrialPeriod, trialPeriodDays)
                  }
                >
                  Trial period length in days
                </label>
                <input
                  type="number"
                  min="0"
                  disabled={!hasTrialPeriod}
                  defaultValue={trialPeriodDays ? String(trialPeriodDays) : ""}
                  onBlur={(): void =>
                    onTrialPeriodUpdate(true, trialPeriodDays)
                  }
                  onChange={(e) => setTrialPeriodDays(Number(e.target.value))}
                  placeholder="15"
                  className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </DrawerSection>

            <DrawerSection
              sectionTitle="Redirect free plans"
              infoHelperTitle="Plans where price is zero"
              infoHelperDescription="If you chose 0 as a price for any plan, you can now configure its button to forward your customers to a different page. Leave this unchecked to send your customer to Stripe (if, for example, you want them to enter their credit card details)."
              errorText="Invalid URL (must start with https://)"
              hasError={zeroPriceSectionInvalid}
            >
              <div className="mt-2">
                <input
                  type="checkbox"
                  checked={redirectFree}
                  className="mr-2 shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                  onChange={(e) => onFreePlanChecked(e.target.checked)}
                />
                <label onClick={() => onFreePlanChecked(!redirectFree)}>
                  Forward free plan to this URL
                </label>
                <input
                  type="text"
                  disabled={!redirectFree}
                  defaultValue={freePlanUrl || ""}
                  onBlur={onUpdateFreePlanInput}
                  onChange={(e) => setFreePlanUrl(e.target.value)}
                  placeholder={`${
                    props.siteUrl || "https://www.yourdomain.com"
                  }/register`}
                  className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </DrawerSection>

            <DrawerSection
              sectionTitle="Redirect plans with empty price"
              infoHelperTitle="Plans where price is empty"
              infoHelperDescription="If you clear the price for any plan, you can now configure its button to forward your customers to a different page."
              errorText="Invalid URL (must start with https://)"
              hasError={emptyPriceSectionInvalid}
            >
              <div className="mt-2">
                <input
                  type="checkbox"
                  checked={redirectEmpty}
                  className="mr-2 shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                  onChange={(e) => onEmptyPlanChecked(e.target.checked)}
                />
                <label onClick={() => onEmptyPlanChecked(!redirectEmpty)}>
                  Forward plan with empty price to this URL
                </label>
                <input
                  type="text"
                  disabled={!redirectEmpty}
                  defaultValue={emptyPlanUrl || ""}
                  onBlur={onUpdateEmptyPlanInput}
                  onChange={(e) => setEmptyPlanUrl(e.target.value)}
                  placeholder={`${
                    props.siteUrl || "https://www.yourdomain.com"
                  }/get-quote`}
                  className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </DrawerSection>

            <DrawerSection
              required={true}
              sectionTitle="Request a quote"
              infoHelperTitle="Let users request a quote"
              infoHelperDescription="Some price page themes include a button to ask for a custom quote, please specify a target URL for that button."
              errorText="Invalid URL (must start with https://)"
              hasError={quoteSectionInvalid}
            >
              <div className="mt-2">
                <input
                  type="text"
                  defaultValue={props?.quoteUrl || ""}
                  onBlur={onUpdateQuoteUrlInput}
                  placeholder={`${
                    props.siteUrl || "https://www.yourdomain.com"
                  }/get-quote`}
                  className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </DrawerSection>
          </Drawer>
        )}
      </div>
    </div>
  );
};

export default PricingPageBuilderEdit;
