import classNames from "classnames";
import React from "react";

export default function ButtonGroup<T>(props: {
  data: { text: string; value: T }[];
  onClick: (value: T) => void;
  active: T;
}) {
  return (
    <div className="relative z-0 inline-flex shadow-sm rounded-md">
      {props.data.map((b, i) => (
        <button
          key={String(b.value)}
          type="button"
          onClick={(): void => props.onClick(b.value)}
          className={classNames(
            "inline-flex items-center px-4 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500",
            { relative: i !== props.data.length - 1 },
            { "-ml-px py-2 py-2": i !== 0 },
            { "rounded-l-md": i === 0 },
            { "rounded-r-md": i === props.data.length - 1 }
          )}
        >
          <span
            className={classNames({
              "color-orange-brand font-extrabold": props.active === b.value,
            })}
          >
            {b.text}
          </span>
        </button>
      ))}
    </div>
  );
}
