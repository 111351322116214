import classNames from "classnames";
import * as React from "react";
import { ReactNode } from "react";

const useScroll = () => {
  const elRef = React.useRef(null);
  const executeScroll = (): any[] => (elRef?.current as any)?.scrollIntoView();

  return [executeScroll, elRef];
};

const ExpandButton = (props: { onClick: () => void }) => (
  <svg
    onClick={props.onClick}
    className="w-6 h-6 cursor-pointer hidden lg:block"
    style={{ position: "absolute", right: "12px", top: "12px" }}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
    />
  </svg>
);

const ExpandableContainer = (props: {
  children: ReactNode;
  defaultExpanded?: boolean;
  onUpdate?: (expanded: boolean) => void;
  minHeight?: number;
}): React.ReactElement => {
  const [expanded, setExpanded] = React.useState<boolean>(
    props.defaultExpanded || false
  );
  const [scrollTop, refTop] = useScroll();

  const wait = async (time: number, run: () => void) => {
    await new Promise((resolve) => setTimeout(resolve, time));
    run();
  };

  // Await transition to rerender, to be save with charts
  React.useEffect(() => {
    wait(200, () => props.onUpdate && props.onUpdate(expanded));
    // eslint-disable-next-line
  }, [expanded]);

  const onClick = () => {
    (scrollTop as () => void)();
    setExpanded(!expanded);
  };

  return (
    <div
      ref={refTop}
      className={classNames(
        "transition-all",
        { "w-full h-full": expanded },
        { "w-full lg:w-1/2 h-full lg:h-1/2": !expanded }
      )}
    >
      <div
        className={classNames(
          "overflow-auto px-4 py-auto m-2.5 p-6 shadow-md border rounded-xxl bg-white relative"
        )}
        style={{ minHeight: props.minHeight || 0, height: "auto" }}
      >
        <ExpandButton onClick={onClick} />
        <div className="min-w-0 min-h-0 object-scale-down">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ExpandableContainer;
