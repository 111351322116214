import React from "react";
import { DataEntry } from "react-minimal-pie-chart/types/commonTypes";
import classNames from "classnames";
import { toFullCurrency } from "../../../utils/formatters";

const percentage = (element: DataEntry, all: DataEntry[]): number =>
  element.value / all.map((a) => a.value).reduce((a, b) => a + b, 0);

interface CancellationsBuyReasonTableProps {
  data: DataEntry[];
  selected?: number;
  setSelected: (value?: number) => void;
  hovered?: number;
  setHovered: (value?: number) => void;
}

// TODO: Eventually move to StatsTable.tsx (custom fields might be trickier)
export default function CancellationsByReasonTable(
  props: CancellationsBuyReasonTableProps
) {
  return (
    <div className="flex flex-col m-auto lg:pl-2 pr-8">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Reason
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider d-none d-lg-block"
                  >
                    Frequency
                  </th>
                  {props.data && props.data[0].unit === "users" && (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Users
                    </th>
                  )}
                  {props.data && props.data[0].unit !== "users" && (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Revenue
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {props.data.map((row: DataEntry, index: number) => {
                  const iSelected = props.selected === index;
                  const isHovered = props.hovered === index;
                  const isEven = index % 2 === 0;
                  return (
                    <tr
                      key={row.title}
                      className={classNames(
                        "cursor-pointer",
                        { "bg-white": !iSelected && !isHovered && isEven },
                        { "bg-gray-50": !iSelected && !isHovered && !isEven },
                        { "bg-blue-200 font-bold": iSelected },
                        { "bg-blue-50": isHovered }
                      )}
                      onClick={() =>
                        props.setSelected(
                          props.selected === index ? undefined : index
                        )
                      }
                      onMouseOver={() => {
                        props.setHovered(index);
                      }}
                      onMouseOut={() => {
                        props.setHovered(undefined);
                      }}
                    >
                      <td className="px-6 py-4 lg:whitespace-nowrap text-sm font-medium text-gray-900">
                        {row.title}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 d-none d-lg-block">
                        <div className="mb-2">
                          {(percentage(row, props.data) * 100).toFixed(2)}%
                        </div>
                      </td>
                      {row.unit === "users" && (
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <div>{`${row.value} ${
                            row.value === 1 ? "user" : "users"
                          }`}</div>
                        </td>
                      )}
                      {row.unit !== "users" && (
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <span>{`${toFullCurrency(row.value)} `}</span>
                          <span>{row.unit}</span>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
