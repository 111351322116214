import {
  MonthlyMetricRow,
  MetricsResponse,
  StatsResponse,
} from "../../../domain/metricsModel";
import { SingleMetricProps } from "./SingleMetric";
import { ChartMode, Rates } from "../../../domain/DomainModel";
import statsCalculator from "../../../service/statsCalculator";
import { formatChurn } from "../../../utils/formatters";

export const transformAcquisitionKpiBlocksData = (
  statsData: StatsResponse | undefined,
  churnData: MetricsResponse | undefined,
  currency: string,
  numMonths: number,
  rates: Rates,
  convertOthers: boolean
): SingleMetricProps[] => {
  const calculateMetrics = (
    chartMode: ChartMode,
    metrics?: MonthlyMetricRow,
    prevChurn?: number
  ): {
    active?: number;
    trialling?: number;
    activeAnnually?: number;
    ltv?: number;
    churnRate?: number;
  } => {
    const activeRows = metrics?.revenueActiveAtTheEndOfTheMonth;
    const active =
      activeRows &&
      statsCalculator.getValue(
        activeRows,
        currency,
        chartMode,
        convertOthers,
        rates
      );

    const triallingRows = metrics?.revenueTriallingAtTheEndOfTheMonth;
    const trialling =
      triallingRows &&
      statsCalculator.getValue(
        triallingRows,
        currency,
        chartMode,
        convertOthers,
        rates
      );

    const activeAnnually =
      activeRows &&
      statsCalculator.getValue(
        activeRows,
        currency,
        chartMode,
        convertOthers,
        rates,
        true
      );

    let churnRate;
    let ltv;

    if (metrics && chartMode === "revenue") {
      churnRate = statsCalculator.calculateChurn(
        metrics,
        currency,
        chartMode,
        convertOthers,
        rates
      );
      ltv = statsCalculator.calculateLtv(
        metrics,
        currency,
        convertOthers,
        rates,
        prevChurn ? Math.max(prevChurn, churnRate) : churnRate
      );
    }

    return { active, trialling, activeAnnually, ltv, churnRate };
  };

  const prevMetricsSubscriptions = calculateMetrics(
    "subscriptions",
    churnData?.byMonth && churnData?.byMonth[churnData?.byMonth.length - 2]
  );
  const prevMetricsRevenue = calculateMetrics(
    "revenue",
    churnData?.byMonth && churnData?.byMonth[churnData?.byMonth.length - 2]
  );

  const currentMetricsSubscriptions = calculateMetrics(
    "subscriptions",
    churnData?.byMonth && churnData?.byMonth[churnData?.byMonth.length - 1]
  );
  const currentMetricsRevenue = calculateMetrics(
    "revenue",
    churnData?.byMonth && churnData?.byMonth[churnData?.byMonth.length - 1],
    prevMetricsRevenue.churnRate
  );

  return [
    {
      subscriptionMetric: {
        name: "Active Subscriptions",
        unit: "",
        descriptor: `At the end of the month`,
        figure: currentMetricsSubscriptions.active,
        prevFigure: prevMetricsSubscriptions.active,
      },
      revenueMetric: {
        name: "Active MRR",
        unit: currency,
        descriptor: `At the end of the month`,
        figure:
          currentMetricsRevenue.active === undefined
            ? undefined
            : currentMetricsRevenue.active / 100,
        prevFigure:
          prevMetricsRevenue.active === undefined
            ? 0
            : prevMetricsRevenue.active / 100,
      },
      description: `Number of active subscriptions and MRR. Projected to the end of the ongoing month.`,
      convertOthers,
      currency,
      defaultMode: "subscriptions",
    },
    {
      subscriptionMetric: {
        name: "Active Yearly Subscriptions",
        unit: "",
        descriptor: `At the end of the month`,
        figure: currentMetricsSubscriptions.activeAnnually,
        prevFigure: prevMetricsSubscriptions.activeAnnually,
      },
      revenueMetric: {
        name: "Active ARR",
        unit: currency,
        descriptor: `At the end of the month`,
        figure:
          currentMetricsRevenue.activeAnnually === undefined
            ? undefined
            : currentMetricsRevenue.activeAnnually / 100,
        prevFigure:
          prevMetricsRevenue.activeAnnually === undefined
            ? 0
            : prevMetricsRevenue.activeAnnually / 100,
      },
      description: `Number of active annual subscriptions and their ARR. Projected to the end of the ongoing month.`,
      convertOthers,
      currency,
      defaultMode: "subscriptions",
    },
    {
      subscriptionMetric: {
        name: "Subscriptions On Trial",
        unit: "",
        descriptor: `At the end of the month`,
        figure: currentMetricsSubscriptions.trialling,
        prevFigure: prevMetricsSubscriptions.trialling,
      },
      revenueMetric: {
        name: "Trial Potential MRR",
        unit: currency,
        descriptor: `At the end of the month`,
        figure:
          currentMetricsRevenue.trialling === undefined
            ? undefined
            : currentMetricsRevenue.trialling / 100,
        prevFigure:
          prevMetricsRevenue.trialling === undefined
            ? 0
            : prevMetricsRevenue.trialling / 100,
      },

      description: `Number of subscriptions of users on trial and potential MRR. Projected to the end of the ongoing month.`,
      convertOthers,
      currency,
      defaultMode: "subscriptions",
    },
    {
      revenueMetric: {
        name: "Lifetime Value",
        unit: currency,
        descriptor: `At the end of the month assuming a churn rate of 
        ${formatChurn(
          Math.max(
            currentMetricsRevenue.churnRate || 0,
            prevMetricsRevenue.churnRate || 0
          )
        )}%`,
        figure:
          currentMetricsRevenue.ltv === undefined ||
          !isFinite(currentMetricsRevenue.ltv)
            ? undefined
            : currentMetricsRevenue.ltv / 100,
        prevFigure:
          prevMetricsRevenue.ltv === undefined ||
          !isFinite(prevMetricsRevenue.ltv)
            ? 0
            : prevMetricsRevenue.ltv / 100,
      },

      description: `Dollar amount received per user on average. Projected to the end of the ongoing month.`,
      convertOthers,
      currency,
      defaultMode: "revenue",
      hideSwitch: true,
    },
  ];
};
