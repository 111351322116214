import React from "react";
import styled, { keyframes } from "styled-components";
import { StarIcon } from "@heroicons/react/solid";

export default function DetailStarAnimated() {
  return (
    <Wrapper>
      <StarIcon className="h-5 w-5 color-orange-brand" aria-hidden="true" />
      <AnimatedRingOuter>
        <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center"></div>
      </AnimatedRingOuter>
      <AnimatedRing>
        <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center"></div>
      </AnimatedRing>
    </Wrapper>
  );
}

const animation = keyframes`
  0%    { opacity: 0; transform: scale(1); }
  100%  { opacity: 0.3; transform: scale(1.5); }
`;

const animationOuter = keyframes`
  0%    { opacity: 0; transform: scale(1.2); }
  100%  { opacity: 0.2; transform: scale(2.5); }
`;

const Wrapper = styled.span`
  display: inline-block;
`;
const AnimatedRing = styled.span`
  position: absolute;
  top: 0;
  right: 4px;
  animation-name: ${animation};
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
`;
const AnimatedRingOuter = styled.span`
  position: absolute;
  top: 0;
  right: 4px;
  animation-name: ${animationOuter};
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
`;
