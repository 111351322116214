import MoreInfoHelper from "../common/MoreInfoHelper";
import React from "react";

interface DrawerSectionProps {
  children: React.ReactNode | React.ReactNode[];
  sectionTitle: string;
  infoHelperTitle: string;
  infoHelperDescription: string;
  hasError: boolean;
  errorText: string;
  required?: boolean;
}

const DrawerSection = ({
  hasError,
  errorText,
  children,
  sectionTitle,
  infoHelperTitle,
  infoHelperDescription,
  required,
}: DrawerSectionProps) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-900 font-bold font-xl mb-2">
      {`${sectionTitle}${required ? " * read" : ""}`}
      <MoreInfoHelper
        placement="bottom"
        info={{
          title: infoHelperTitle,
          description: infoHelperDescription,
        }}
      />
    </label>
    {children}
    {hasError && <p className="alert alert-warning mt-2">{errorText}</p>}
  </div>
);

export default DrawerSection;
