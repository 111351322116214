import React from "react";
import AuthUserContext from "./context";
import { withFirebase } from "../../firebase";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    fetchUser(authUser, token) {
      this.props.firebase.db
        .ref(`users/${authUser.uid}`)
        .on("value", (snapshot) => {
          const user = snapshot.val();

          if (user) {
            this.fetchSite(authUser, user, token);
          }
        });
    }

    fetchSite(authUser, user, token) {
      this.props.firebase.db
        .ref(`sites/${user.siteId}`)
        .on("value", (snapshot) => {
          this.setState({
            authUser: {
              ...authUser,
              site: { ...snapshot.val(), key: user.siteId },
              user,
              token,
            },
          });
        });
    }

    componentDidMount() {
      if (this.props.firebase) {
        this.listener = this.props.firebase.auth.onAuthStateChanged(
          async (authUser) => {
            const token = await this.props.firebase.auth.currentUser?.getIdToken();

            if (!authUser) {
              this.setState({ authUser: undefined });
            } else {
              this.fetchUser(authUser, token);
            }
          }
        );
      }
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
