import React from "react";
import styled from "styled-components";
import PriceHeroResults from "./PriceHeroResults";
import PriceHeroPriceAB from "./PriceHeroPriceAB";
import PriceHeroConnector from "./PriceHeroConnector";

export default function PriceHeroAnimation() {
  return (
    <>
      <div className="animationContainer">
        <AbAnimationCard>
          <h3>
            <img
              src={require("../../img/rephonic_logo.png")}
              alt="Company_logo"
              className="mr-2"
            />
            Pro Plan
          </h3>
          <div id="">
            <PriceHeroPriceAB />
            <span className="frequency">/ person / month</span>
          </div>
          <p>
            Best for managers with too much on their plate. Everything in Basic,
            plus:
          </p>
          <div className="mt-3" id="featuresList">
            <ListItem>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 inline  mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <b>5</b> Users
            </ListItem>
            <ListItem>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 inline mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <b>500</b> Searches/Month
            </ListItem>
            <ListItem>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 inline  mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <b>50</b> Lists
            </ListItem>
            <ListItem>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 inline  mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <b>50</b> Concierge credits
            </ListItem>
          </div>
        </AbAnimationCard>
        <PriceHeroResults />
        <PriceHeroConnector />
      </div>
    </>
  );
}

const AbAnimationCard = styled.div`
  background-color: var(--background-white);
  border: 1px solid lightgrey;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  width: 295px;
  padding: 32px 24px;
  h3 {
    font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", "Icons16",
      sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: #000000;
  }
  .frequency {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    vertical-align: middle;
    margin-left: 5px;
  }
`;
const ListItem = styled.span`
  display: block;
  vertical-align: middle;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top: 1px solid rgba(0, 0, 0, 0.075);
`;
