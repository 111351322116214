import React from "react";
import styled from "styled-components";

export default function AppPreviewAvatar() {
  return (
    <div className="ml-3 flex place-items-center">
      <span className="inline-block relative">
        <AvatarFrame
          className="h-10 rounded-full"
          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
        />
      </span>
    </div>
  );
}

const AvatarFrame = styled.img`
  border: 2px solid #f4f4f4;
`;
