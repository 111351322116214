import React, { useContext, useState } from "react";
import TryNow from "../common/TryNow";
import ConfirmationModal from "../basic/ConfirmationModal";
import { SharedContext } from "../common/SharedProvider";
import { AuthUser, SharedContextUtils } from "../../domain/DomainModel";
import { PRICING_PAGE_URL } from "../../pages/protected/MainPage/resources";
import QuickstartPricingSection from "../quickstart/QuickstartPricingSection";

interface PricingPageBuilderPublishProps {
  authUser?: AuthUser;
  onPublish: () => Promise<void>;
  onDiscard: () => void;
}

const PricingPageBuilderPublish = (props: PricingPageBuilderPublishProps) => {
  const [discardVisible, setDiscardVisible] = useState(false);
  const [publishVisible, setPublishVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [testScreenVisible, setTestScreenVisible] = React.useState(false);

  const utils: SharedContextUtils = useContext<SharedContextUtils>(
    SharedContext
  );

  const { authUser } = props;

  const onPublish = async () => {
    setIsLoading(true);
    await props
      .onPublish()
      .then(() => {
        utils.setSnackConfig({
          isOpen: true,
          message: "Changes published successfully!",
          severity: "success",
        });
        setPublishVisible(false);
      })
      .catch((_e) => {
        utils.setSnackConfig({
          isOpen: true,
          message: "Error while publishing changes, please try again",
          severity: "error",
        });
      });
    setIsLoading(false);
  };

  const onDiscard = () => {
    props.onDiscard();
    setDiscardVisible(false);

    utils.setSnackConfig({
      isOpen: true,
      message: "Changes discarded",
      severity: "info",
    });
  };

  if (authUser) {
    return (
      <div>
        {publishVisible && (
          <ConfirmationModal
            title="Publish Changes"
            description="Are you sure you want to publish the changes? The changes will be immediately visible if you are already using this pricing page in your site. This action cannot be undone."
            cancelText="Cancel"
            confirmText="Publish"
            onConfirm={onPublish}
            isLoading={isLoading}
            onCancel={() => setPublishVisible(false)}
          />
        )}
        {discardVisible && (
          <ConfirmationModal
            title="Discard Changes"
            description="Are you sure you want to discard the changes? All unpublished changes will be lost."
            cancelText="Cancel"
            confirmText="Discard"
            onConfirm={onDiscard}
            isLoading={isLoading}
            onCancel={() => setDiscardVisible(false)}
          />
        )}
        <div className="flex justify-content-center mt-4 mb-8">
          <button
            onClick={() => setDiscardVisible(true)}
            className="btn btn-secondary"
          >
            Discard Changes
          </button>
          <button
            onClick={() => setPublishVisible(true)}
            className="btn btn-primary ml-2"
          >
            Publish Changes
          </button>
        </div>
        <QuickstartPricingSection
          testScreenVisible={testScreenVisible}
          setTestScreenVisible={setTestScreenVisible}
          authUser={authUser}
          testMode={false}
          title="Include Pricing Page in your site"
        />
        <QuickstartPricingSection
          testScreenVisible={testScreenVisible}
          setTestScreenVisible={setTestScreenVisible}
          authUser={authUser}
          testMode={true}
          title="Try the Pricing Page with test subscriptions"
        />
      </div>
    );
  } else {
    return (
      <div className="flex justify-content-center">
        <div className="flex flex-column">
          <h5 className="pb-4 text-center">One last step!</h5>
          <div className="flex justify-content-center">
            <TryNow
              buttonText="Register"
              hideFeatures={true}
              redirect={PRICING_PAGE_URL}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default PricingPageBuilderPublish;
