import React, { useEffect } from "react";
import classNames from "classnames";
import TenstarsLogoWithTitle from "../../../components/common/TenstarsLogoWithTitle";
import { Link } from "react-router-dom";
import {
  HOME_URL,
  MenuItem,
  QUICK_START_PAGE_URL,
  tenstarsCollapsedMenuKey,
} from "./resources";
import CompletionDonut from "../../../components/quickstart/CompletionDonut";
import { countCompleteSteps } from "../../../components/quickstart/QuickstartSection";
import { Site } from "../../../domain/DomainModel";

interface FixSideBarProps {
  menu: MenuItem[];
  currentUrl: string;
  tools: MenuItem[];
  onSignOut: () => void;
  site?: Site;
}

const FixSideBar = (props: FixSideBarProps) => {
  const [collapsed, setCollapsed] = React.useState(
    localStorage.getItem(tenstarsCollapsedMenuKey) === "true"
  );

  const [completedSteps, setCompletedSteps] = React.useState<number>(1);

  const updateCollapsed = (value: boolean) => {
    setCollapsed(value);
    localStorage.setItem(tenstarsCollapsedMenuKey, String(value));
  };

  useEffect(() => {
    if (props.site) {
      const newCompletedStepsCount = countCompleteSteps(props.site, false);
      setCompletedSteps(newCompletedStepsCount);
    }
  }, [props.site]);

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div
        className={classNames(
          "flex flex-col",
          { "w-64": !collapsed },
          { "w-auto": collapsed }
        )}
      >
        <div className="flex flex-col h-0 flex-1">
          <div
            className={classNames(
              "flex items-center h-16 flex-shrink-0 px-4 bg-gray-900",
              { "bg-orange-brand": collapsed }
            )}
          >
            {!collapsed && (
              <TenstarsLogoWithTitle white={true} linkTo={HOME_URL} />
            )}
            {collapsed && (
              <img
                className="h-8 inline-block"
                src={require("../../../img/logo/tenstars_negative.svg")}
                alt="Tenstars"
              />
            )}
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 px-2 py-4 bg-gray-800 space-y-1">
              {props.menu.map((t: MenuItem) => {
                if (t.disabled) {
                  return null;
                }

                return (
                  <Link
                    key={`${t.name}_fixed_1`}
                    to={t.to}
                    className={classNames(
                      "fill-current text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md sidebar-link",
                      {
                        "fill-current  bg-gray-900 text-white":
                          props.currentUrl === t.to,
                      }
                    )}
                  >
                    <div
                      className={classNames({
                        "mx-auto": collapsed,
                        "mr-4": !collapsed,
                      })}
                    >
                      {t.icon}
                    </div>
                    {!collapsed && t.name}
                    {t.beta && !collapsed && (
                      <span className="badge badge-primary ml-2">Beta</span>
                    )}
                  </Link>
                );
              })}

              <div className="space-y-1 mt-5">
                {!collapsed && (
                  <h3
                    className="px-3 py-2 text-white-50 px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                    id="projects-headline"
                  >
                    config
                  </h3>
                )}
                <div
                  className="space-y-1"
                  role="group"
                  aria-labelledby="projects-headline"
                >
                  {props.tools.map((t) => {
                    if (t.disabled) {
                      return null;
                    }

                    return (
                      <div key={`tool_${t.to}_fixed_2`} className={t.classname}>
                        <Link
                          to={t.to}
                          className={classNames(
                            "px-3 py-2 text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md sidebar-link",
                            {
                              "bg-gray-900 text-white":
                                props.currentUrl === t.to,
                            }
                          )}
                        >
                          <div
                            className={classNames({
                              "mx-auto": collapsed,
                              "mr-4": !collapsed,
                            })}
                          >
                            {(t.to !== QUICK_START_PAGE_URL || !collapsed) &&
                              t.icon}
                          </div>
                          {!collapsed && t.name}
                          {t.to === QUICK_START_PAGE_URL && props.site && (
                            <div className={classNames({ "ml-2": !collapsed })}>
                              <CompletionDonut
                                maxSteps={3}
                                completeSteps={completedSteps}
                              />
                            </div>
                          )}
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </nav>
          </div>

          {!collapsed && (
            <div className="flex-shrink-0 flex bg-gray-800 px-2 py-4">
              <nav
                className="flex-1 px-2 bg-gray-800 space-y-12"
                onClick={props.onSignOut}
              >
                <a
                  href="/"
                  className="fill-current text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md sidebar-link"
                >
                  <svg
                    className={classNames(
                      "h-6 w-6",
                      { "mr-2": !collapsed },
                      { "mx-auto": collapsed }
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Sign Out
                </a>
              </nav>
            </div>
          )}

          {!collapsed && (
            <div
              className="clickable pb-4 fill-current text-gray-300 flex justify-content-end bg-gray-800 pr-4"
              onClick={() => updateCollapsed(true)}
            >
              <svg
                width="32px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
          {collapsed && (
            <div
              className="clickable pb-4 fill-current text-gray-300 flex justify-content-center bg-gray-800"
              onClick={() => updateCollapsed(false)}
            >
              <svg
                width="32px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FixSideBar;
