import React from "react";
import styled, { keyframes } from "styled-components";
import FollowUpConnection from "./FollowUpConnection";

// todo: iterate X times, number of stars
// todo: for each iteration, pass a random number of coordinates

const NumberOfStars = 7;

const Star = () => {
  return [...Array(NumberOfStars)].map((item, index) => (
    <StarWrapper key={index}>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8 bg-white shadow-lg border rounded-pill p-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#DAD9D9"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
          />
        </svg>
      </span>
    </StarWrapper>
  ));
};

export default function () {
  return (
    <Wrapper>
      <Star />
      <FollowUpConnection />
    </Wrapper>
  );
}

const animation = keyframes`
  0% { opacity: 0; transform: translateY(-100px) skewY(10dg) skewX(10dg) rotateZ(30dg); filter: blur(10px); }
  35% { opacity: 1; transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px); }
  75% { opacity: 0; transform: translateY(100px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px); }
  100% { opacity: 0; transform: translateY(150px) skewY(10dg) skewX(10dg) rotateZ(30dg); filter: blur(10px); }
`;

const Wrapper = styled.div`
  display: inline-block;

  span {
    display: inline-block;
    animation-name: ${animation};
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  span:nth-child(1) {
    animation-delay: 0.3s;
  }
  span:nth-child(2) {
    animation-delay: 0.5s;
    transform: translateY(-50px);
  }
  span:nth-child(3) {
    animation-delay: 0.2s;
    transform: translateY(10px);
  }
  span:nth-child(4) {
    animation-delay: 0.4s;
    transform: translateY(-20px);
    animation-timing-function: ease-in-out;
  }
  span:nth-child(5) {
    animation-delay: 0.5s;
  }
  span:nth-child(6) {
    animation-delay: 0.5s;
    transform: translateY(80px);
    animation-timing-function: ease-in-out;
  }
  span:nth-child(7) {
    animation-delay: 0.7s;
    animation-timing-function: ease-in-out;
  }
`;
const StarWrapper = styled.span`
  display: inline-block;
`;
