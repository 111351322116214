import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Firebase, { withFirebase } from "../../firebase";
import { HOME_URL } from "../../pages/protected/MainPage/resources";
import amplitudeService, {
  REGISTRATION_START_HP,
} from "../../service/amplitudeService";
import Check from "../basic/Check";

interface TryNowProps {
  firebase: Firebase;
  buttonText?: string;
  redirect?: string;
  hideFeatures: boolean;
  bulletPoints: boolean;
}

const TryNow = (props: TryNowProps & RouteComponentProps) => {
  const onClickRegister = async () => {
    // identify if the user is already registered
    if (props.firebase.getAuth().currentUser) {
      props.history.push(HOME_URL);
    } else {
      if (!props.redirect) {
        amplitudeService
          .getInstance()
          .logEvent(REGISTRATION_START_HP, {}, () => {
            props.history.push("/onboarding");
          });
      } else {
        props.history.push(
          `/onboarding?redirect=${encodeURIComponent(props.redirect)}`
        );
      }
    }
  };

  return (
    <>
      <button
        onClick={onClickRegister}
        id="btn-start-registration"
        className="btn btn-primary px-4 mb-sm-4"
      >
        <>
          {props.buttonText || "Start Today for FREE"}
          <span className="blink">
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              className="ml-2 d-inline"
            >
              <path
                d="M1 5.16772H9.5M9.5 5.16772L6.5 1.66772M9.5 5.16772L6.5 8.66772"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </>
      </button>
      {!props.hideFeatures && <div className="mt-2" />}
      {props.bulletPoints && (
        <div>
          <Check />
          <span className="mx-2">Free features</span>
          <Check />
          <span className="mx-2">Takes less than 5 minutes</span>
          <Check />
          <span className="mx-2">No Credit Card</span>
        </div>
      )}
    </>
  );
};

const ComposedTryNow = withRouter(withFirebase(TryNow));

export default ComposedTryNow;
