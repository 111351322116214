import CollapsibleContainer from "./CollapsibleContainer";
import React from "react";

interface FaqItemProps {
  title: string;
  children: React.ReactElement | React.ReactElement[];
}
const FaqItem = (props: FaqItemProps) => (
  <CollapsibleContainer title={props.title}>
    <div className="text-monospace bg-light rounded-sm p-4 mx-auto">
      {props.children}
    </div>
  </CollapsibleContainer>
);

export default FaqItem;
