// @ts-ignore
import * as SVGLoaders from "svg-loaders-react";
import React from "react";

const Loader = () => (
  <div className="container align-middle d-flex justify-content-center">
    <SVGLoaders.Puff stroke="#FF644D" />
    <span className="m-2">It might take some seconds...</span>
  </div>
);

export const SimplerLoader = () => <SVGLoaders.Puff stroke="#FF644D" />;

export default Loader;
