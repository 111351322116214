import React from "react";
import { Collapse } from "@chakra-ui/core";

function CollapsibleContainer({
  title,
  children,
}: {
  title: string;
  children: React.ReactElement | React.ReactElement[];
}) {
  const [show, setShow] = React.useState(false);

  const handleToggle = () => setShow(!show);

  return (
    <div className="mt-3">
      <strong>{title}</strong>{" "}
      <span className="clickable" onClick={handleToggle}>
        {" "}
        {show ? "[-]" : "[+]"}
      </span>
      <Collapse mt={4} isOpen={show}>
        {children}
      </Collapse>
    </div>
  );
}

export default CollapsibleContainer;
