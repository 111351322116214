import { Dictionary } from "./DomainModel";

export const DEFAULT_LANGUAGE: string = "en";

export type AvailableLanguage = "en" | "es";

export const LanguageNames: Dictionary<string> = {
  en: "English",
  es: "Spanish",
};

export interface InternationalizedContent {
  en?: string;
  es?: string;
}

export const currencySymbols: Dictionary<string> = {
  usd: "$", // Dollar
  eur: "€", // Euro
  gbp: "£", // British Pound Sterling
  aud: "$", // Australian dollar
  cad: "$", // CAD Dollar
  inr: "₹", // Indian Rupee
  jpy: "¥", // Japanese Yen
};
