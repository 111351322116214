import { Site } from "../domain/DomainModel";

export const hasCredentials = (site?: Site): boolean => {
  if (!site) {
    return false;
  }
  return !!site?.credentials?.dev || !!site?.credentials?.prod;
};

export const hasCredentialsFor = (testMode: boolean, site?: Site): boolean => {
  if (!site) {
    return false;
  }

  return (
    (testMode && !!site?.credentials?.dev) ||
    (!testMode && !!site?.credentials?.prod)
  );
};
