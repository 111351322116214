import { Color } from "@material-ui/lab/Alert";
import {
  AvailableLanguage,
  InternationalizedContent,
} from "./InternationalDomain";
import firebaseLib from "firebase";

export interface Site {
  questions?: Question[];
  key: string;
  basicRecovery?: BasicRecovery;
  liveApiKey?: string;
  testApiKey?: string;
  credentials?: { dev?: string; prod?: string };
  pricing: PricingRoot;
  retentionWidgetActive?: { dev?: boolean; prod?: boolean };
  pricingWidgetActive?: { dev?: boolean; prod?: boolean };
  hasReceivedEvents?: { dev?: boolean; prod?: boolean };
  isRetentionActive: boolean;
  urls: { siteTestUrl: string[]; siteUrl: string[] };
}

export interface Question {
  id: number;
  en?: string;
  es?: string;
}

export interface BasicRecovery {
  productName: any;
  senderName: any;
  discount: number;
  replyAddress: any;
  emailSubject: any;
  customerName: string;
  makeOfferDuringTrialPeriod?: boolean;
  isOpenCommentActive?: boolean;
  retentionDiscount: number;
  showPriceDifference?: boolean;
  emailBodyTemplate?: InternationalizedContent;
}

export interface Product {
  cancelUrl?: string;
  successUrl?: string;
  name: string;
  description: string;
  features: string[];
  price: { currencySymbol: string; currency: string; price: string };
  interval: string;
  trialPeriodDays?: number;
}

export interface Pricing {
  products: Product[];
  freePlanUrl: string | null;
  emptyPlanUrl: string | null;
  quoteUrl: string | null;
  theme: string;
  type: PricingPageType;
  title: string;
  description: string;
}

export type PricingPageType = "classic" | "single" | "donation";

export interface PricingRoot {
  a: Pricing;
}

export interface AuthUser extends firebaseLib.User {
  uid: string;
  site: Site;
  user: User;
  token: string;
  email: string;
}

export interface SharedContextUtils {
  setSnackConfig: (context: SnackContext) => void;
}

export interface SnackContext {
  isOpen: boolean;
  message: string;
  severity: Color;
}

export interface User {
  email: string;
  defaultLanguage?: AvailableLanguage;
  subscriptionId?: string;
  username: string;
  preferredCurrency: string;
}

export interface PricingTheme {
  name: string;
  logo: string;
  description: string;
}

export const cancellationEventKey = "report-subscription-cancelled";
export const savedEventKey = "report-cancellation-saved";
export const recoveredEventKey = "report-cancellation-recovered";

export interface TenstarsAction {
  subscriptionId?: string;
  newSubscriptionId?: string;
  oldSubscriptionEventId?: string;
  oldSubscriptionId?: string;
  name?: string;
  productId?: string;
  productName?: string;
  type: "recovered" | "saved" | "cancelled";
  discount: number;
  priceInCents: number;
  currency: string;
  vote?: string;
  testMode: boolean;
  email?: string;
  createdAt: number;
  fake?: boolean;
  inTrialPeriod?: boolean;
  language?: string;
  offSite: boolean;
  siteId: string;
  customerId?: string;
  confirmed?: boolean;
  emailSent?: boolean;
  appliedPercentageDiscount?: number | null;
  appliedAmountDiscount?: number | null;
  appliedDuration?: string | null;
}

export type RecursivePartial<T> = {
  [K in keyof T]?: T[K] extends Array<infer R>
    ? Array<RecursivePartial<R>>
    : RecursivePartial<T[K]>;
};

export type Dictionary<T> = { [key: string]: T };

export type Rates = Dictionary<Dictionary<number>>;

export type ChartMode = "subscriptions" | "revenue";
