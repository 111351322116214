import React, { useEffect, useState } from "react";
import FullOptionPieChart from "./FullOptionPieChart";
import LanguageDropdown from "../../common/LanguageDropdown";
import CancellationsByReasonTable from "./CancellationsByReasonTable";
import MonthsDropdown from "../../common/MonthsDropdown";
import CurrencyDropdown from "../../common/CurrencyDropdown";
import { transformDataHorizontalBarChart } from "./mapper";
import { StatsResponse } from "../../../domain/metricsModel";
import { ChartMode, Rates } from "../../../domain/DomainModel";
import { DataEntry } from "react-minimal-pie-chart/types/commonTypes";
import SubscriptionsOrRevenue from "../SubscriptionsOrRevenue";
import Loader from "../../icons/Loader";
import FigureHeader from "../FigureHeader";

interface CancellationsByReasonChartProps {
  title?: string;
  data?: StatsResponse;
  numMonths: number;
  selectedLanguage: string;
  updateSelectedLanguage: (value: string) => void;
  setNumMonths: (value: number) => void;
  setCurrency: (value: string) => void;
  currency: string;
  rates: Rates;
  convertOthers: boolean;
  updateConvertOthers?: (value: boolean) => void;
  chartMode: ChartMode;
  updateChartMode: (mode: ChartMode) => void;
  hideSwitch?: boolean;
  loading: boolean;
  isExpanded: boolean;
}

const CancellationsByReasonChart = (props: CancellationsByReasonChartProps) => {
  const {
    chartMode,
    updateChartMode,
    title,
    data,
    numMonths,
    selectedLanguage,
    currency,
    rates,
    convertOthers,
    hideSwitch,
    updateConvertOthers,
    setCurrency,
    updateSelectedLanguage,
    setNumMonths,
    loading,
    isExpanded,
  } = props;
  const [selected, setSelected] = useState<number | undefined>(0);
  const [hovered, setHovered] = useState<number | undefined>(undefined);

  const [attemptsByReason, setAttemptsByReason] = useState<DataEntry[]>([]);

  useEffect(() => {
    updateChartData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateChartData();
    // eslint-disable-next-line
  }, [
    data,
    numMonths,
    selectedLanguage,
    currency,
    chartMode,
    convertOthers,
    rates,
  ]);

  const updateChartData = () => {
    const updatedData = transformDataHorizontalBarChart(
      data,
      numMonths,
      selectedLanguage,
      currency,
      chartMode,
      rates,
      convertOthers
    );

    setAttemptsByReason(updatedData);
  };

  if (!loading && !data) {
    return null;
  }

  return (
    <div>
      {title && <h5 className="mb-4">{title}</h5>}
      {loading && (
        <div className="m-8">
          <Loader />
        </div>
      )}
      {!loading && (
        <div className="relative">
          <div className="w-100 ml-2">
            <FigureHeader
              name="Results"
              hideFigure={true}
              descriptor="during cancellations"
            />
          </div>
          <div className="flex flex-row justify-between flex-wrap my-4">
            {!hideSwitch && (
              <div className="mx-1">
                <SubscriptionsOrRevenue
                  updateChartMode={updateChartMode}
                  chartMode={chartMode}
                  currency={currency}
                  convertOthers={convertOthers}
                />
              </div>
            )}
            <div className="flex flex-row">
              <div className="mx-1 my-2">
                <LanguageDropdown
                  showAllOption
                  selected={selectedLanguage}
                  action={(value) => updateSelectedLanguage(value)}
                  modifier="m-auto w-auto"
                />
              </div>
              <div className="mx-1 my-2">
                <MonthsDropdown
                  action={(c) => setNumMonths(parseInt(c))}
                  selected={String(numMonths)}
                />
              </div>
            </div>
            <div className="mx-1 my-2">
              <CurrencyDropdown
                action={(c) => setCurrency(c)}
                selected={currency}
                modifier="w-auto"
                convertOthers={convertOthers}
                canCovert={!!Object.keys(rates).length}
                updateConvertOthers={updateConvertOthers}
              />
            </div>
          </div>
          {!!attemptsByReason.length && (
            <div className="xl:flex w-100 lg:w75">
              {isExpanded && (
                <FullOptionPieChart
                  data={attemptsByReason}
                  selected={selected}
                  setSelected={setSelected}
                  hovered={hovered}
                  setHovered={setHovered}
                />
              )}
              <CancellationsByReasonTable
                data={attemptsByReason}
                selected={selected}
                setSelected={setSelected}
                hovered={hovered}
                setHovered={setHovered}
              />
            </div>
          )}
          {!attemptsByReason.length && (
            <div className="container align-middle d-flex justify-content-center text-gray-600 mb-8 py-60 text-lg font-bold">
              There are 0 results matching the selected criteria
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CancellationsByReasonChart;
