import React from "react";
import styled, { keyframes } from "styled-components";

export default function InsightsHeroSurveyAnimation() {
  return (
    <Wrapper className="text-left">
      <div>
        <input type="radio" id="huey" name="drone" value="huey" />
        <label htmlFor="huey" className="ml-2">
          The price is too high
        </label>
      </div>
      <div>
        <input type="radio" id="huey" name="drone" value="huey" />
        <label htmlFor="huey" className="ml-2">
          It is really hard to use
        </label>
      </div>
      <div>
        <input
          type="radio"
          id="huey"
          name="drone"
          value="huey"
          checked={true}
          readOnly={true}
        />
        <label htmlFor="huey" className="ml-2">
          We are not using it much
        </label>
      </div>
      <div>
        <input type="radio" id="huey" name="drone" value="huey" />
        <label htmlFor="huey" className="ml-2">
          I found a better solution
        </label>
      </div>
    </Wrapper>
  );
}

const animation = keyframes`
  0% { opacity: 0; transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); filter: blur(10px); }
  25% { opacity: 1; transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px); }
  75% { opacity: 1; transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px); }
  100% { opacity: 0; transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); filter: blur(10px); }
`;
const Wrapper = styled.div`
  display: grid;
  margin: auto;
  margin-bottom: 24px;

  div {
    display: inline-block;
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 5s;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  div:nth-child(1) {
    animation-delay: 0.5s;
  }
  div:nth-child(2) {
    animation-delay: 1s;
  }
  div:nth-child(3) {
    animation-delay: 1.5s;
  }
  div:nth-child(4) {
    animation-delay: 2s;
  }
`;
