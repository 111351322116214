import React from "react";
import ConfigSectionContainer from "../config/ConfigSectionContainer";
import QuickStartStep from "./QuickStartStep";
import ConditionWaiter from "./ConditionWaiter";
import StripeConnectButton from "../common/StripeConnectButton";
import SimpleSnippetSection from "../common/SimpleSnippetSection";
import TipBox from "../basic/TipBox";
import Tip from "./Tip";
import { Site } from "../../domain/DomainModel";
import Toggle from "react-toggle";
import { Link } from "react-router-dom";
import Firebase, { withFirebase } from "../../firebase";
import { savedEventKey, cancellationEventKey } from "../../domain/DomainModel";
import firebase from "firebase";
import FaqItem from "../basic/FaqItem";
import RetentionWidgetTryButton from "../common/RetentionWidgetTryButton";
import {
  ACCOUNT_HISTORY_URL,
  RESULTS_PAGE_URL,
  RESURRECTION_PAGE_URL,
  RETENTION_PAGE_URL,
} from "../../pages/protected/MainPage/resources";
import SuccessBubble from "./SuccessBubble";

interface QuickstartSectionProps {
  site: Site;
  testMode: boolean;
  title: string;
  children: React.ReactElement;
  testScreenVisible?: boolean;
  setTestScreenVisible: (value: boolean) => void;
  firebase: Firebase;
}

const widgetSnippet = (apiKey: string) => [
  '<div id="tenstars-widget"></div>',
  '<button class="btn btn-danger mx-3" id="tenstars_cancel_btn" value="{customerSubscriptionIdGoesHere}">Cancel my subscription</button>',
  `<script id="tenstars" src="https://tenstars.web.app/bundled-survey-script.js?apiKey=${apiKey}"></script>`,
];

export const countCompleteSteps = (site: Site, testMode: boolean) => {
  let steps = 1;

  if (step2Complete(site, testMode)) {
    steps += 1;
  }

  if (step3Complete(site, testMode)) {
    steps += 1;
  }

  return steps;
};

const step2Complete = (site: Site, testMode: boolean): boolean =>
  testMode ? !!site?.credentials?.dev : !!site?.credentials?.prod;

const step3Complete = (site: Site, testMode: boolean): boolean =>
  testMode
    ? !!site.retentionWidgetActive?.dev && !!site.hasReceivedEvents?.dev
    : !!site.retentionWidgetActive?.prod && !!site.hasReceivedEvents?.prod;

const QuickstartSection = (
  props: QuickstartSectionProps
): React.ReactElement => {
  const [cancellationReceived, setCancellationReceived] = React.useState(false);
  const [saveReceived, setSaveReceived] = React.useState(false);
  const [fetched, setFetched] = React.useState(false);

  React.useEffect(() => {
    if (!fetched && props.site?.key) {
      fetchAllEvents();
      setFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.site?.key]);

  React.useEffect(() => {
    if (
      saveReceived &&
      cancellationReceived &&
      ((props.testMode && !props.site.hasReceivedEvents?.dev) ||
        (!props.testMode && !props.site.hasReceivedEvents?.prod))
    ) {
      props.firebase.updateSite(props.site.key, {
        hasReceivedEvents: {
          dev: props.testMode
            ? true
            : props.site.hasReceivedEvents?.dev || false,
          prod: !props.testMode
            ? true
            : props.site.hasReceivedEvents?.prod || false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveReceived, cancellationReceived]);

  const fetchAllEvents = async () => {
    await fetchEvent(cancellationEventKey, "cancelled");
    await fetchEvent(savedEventKey, "saved");
  };

  const fetchEvent = async (
    eventName: string,
    type: "cancelled" | "saved"
  ): Promise<void> => {
    if (!props.site.key) {
      return;
    }

    await props.firebase
      .getDb()
      .ref(eventName)
      .orderByChild("siteId")
      .equalTo(props.site.key)
      .on("child_added", (snapshot: firebase.database.DataSnapshot) => {
        const newEvent = { ...snapshot.val(), type };

        if (newEvent.testMode === props.testMode) {
          if (type === "cancelled") {
            setCancellationReceived(true);
          } else if (type === "saved") {
            setSaveReceived(true);
          }
        }
      });
  };

  return (
    <div id={props.testMode ? "test-mode" : "live-mode"} className="relative ">
      <ConfigSectionContainer title={props.title}>
        <div className="config-item-box flex justify-content-between justify-content-between md:px-4">
          <div>
            {props.children}

            {props.testMode && (
              <div className="my-4">
                <Toggle
                  checked={props.testScreenVisible}
                  title="Display the development settings"
                  disabled={false}
                  value="yes"
                  onChange={() =>
                    props.setTestScreenVisible &&
                    props.setTestScreenVisible(!props.testScreenVisible)
                  }
                  className="align-middle"
                />
                <span className="ml-4 text-lg">
                  Display the development settings
                </span>
              </div>
            )}
            {(!props.testMode || props.testScreenVisible) && (
              <React.Fragment>
                <QuickStartStep
                  key={1}
                  stepNumber={1}
                  title="Create a new account for your site"
                  isComplete={true}
                >
                  <p className="mt-4 mb-8">
                    With your email and domain. Currently{" "}
                    <strong>
                      {props.testMode
                        ? props.site.urls.siteTestUrl[0]
                        : props.site.urls.siteUrl[0]}
                    </strong>
                    .
                    <br />
                    You can{" "}
                    <Link to="/home/config">
                      always change the domain in your settings page.
                    </Link>
                  </p>

                  <ConditionWaiter
                    waitingText=""
                    completeText="Register with your email and domain."
                    condition={true}
                  />
                </QuickStartStep>

                <QuickStartStep
                  key={2}
                  stepNumber={2}
                  title={
                    props.testMode
                      ? "Connect your Stripe Account in Test Mode"
                      : "Connect your Stripe Account"
                  }
                  isComplete={step2Complete(props.site, props.testMode)}
                >
                  <p className="mt-4 mb-2">
                    You will be taken to your stripe account and will be asked
                    to grant us permission to connect it.
                  </p>
                  {!props.testMode && (
                    <p className="my-2">
                      If you have a development environment and want to test
                      your integration first?{" "}
                      <a
                        href="#test-mode"
                        onClick={() => props.setTestScreenVisible(true)}
                      >
                        you can do it here.
                      </a>
                    </p>
                  )}
                  {!step2Complete(props.site, props.testMode) && (
                    <div className="my-8">
                      <StripeConnectButton
                        scope="read_write"
                        testMode={props.testMode}
                        text="Connect to Stripe"
                        redirectAfterSuccess={window.location.href}
                      />
                    </div>
                  )}
                  <ConditionWaiter
                    waitingText="Connect and grant permissions"
                    completeText="Stripe account connected"
                    condition={step2Complete(props.site, props.testMode)}
                  />
                </QuickStartStep>

                <QuickStartStep
                  key={3}
                  stepNumber={3}
                  title={
                    props.testMode
                      ? "Install the Tenstars Widget in your test site"
                      : "Install the Tenstars Widget"
                  }
                  isLast={
                    props.testMode || !step3Complete(props.site, props.testMode)
                  }
                  isComplete={step3Complete(props.site, props.testMode)}
                >
                  <p className="mt-4 mb-2">
                    Copy the code below and paste it in the page you use to
                    handle your cancellations. It usually goes in the users
                    private dashboard.
                  </p>
                  <SimpleSnippetSection
                    site={props.site}
                    isTest={props.testMode}
                    snippetTextFunction={widgetSnippet}
                  />

                  <ConditionWaiter
                    waitingText="Paste the code, load the page and click the button"
                    completeText="Widget correctly embedded to your site"
                    condition={
                      props.testMode
                        ? !!props.site.retentionWidgetActive?.dev
                        : !!props.site.retentionWidgetActive?.prod
                    }
                  />

                  <TipBox>
                    <Tip>
                      <ul>
                        <li className="list-disc mb-2">
                          Make sure the Subscription Id is passed to the widget.
                        </li>
                        <li className="list-disc mb-2">
                          Make sure you do not use a real customer for this
                          test.
                        </li>
                        <li className="list-disc">
                          If you choose not to use a real Stripe subscription,
                          you can use <i>"sub_tenstarstest"</i> as id for a
                          simulation.
                        </li>
                      </ul>
                    </Tip>
                  </TipBox>

                  <ConditionWaiter
                    waitingText="Use the button to attempt to cancel, submit a survey and DECLINE the offer"
                    waitingLink="Check your activity stream"
                    waitingHref={`/home/history?test-mode=${props.testMode}`}
                    completeText="your cancellation request was received!"
                    condition={cancellationReceived}
                  />
                  <ConditionWaiter
                    waitingText="Use the button to attempt to cancel, submit a survey and ACCEPT the offer"
                    waitingLink="Check your activity stream"
                    waitingHref={`/home/history?test-mode=${props.testMode}`}
                    completeText="your discount request was received!"
                    condition={saveReceived}
                  />

                  <div className="my-8">
                    <FaqItem title="What is the customer Subscription Id and where do I find it?">
                      <p>
                        <i>&#123;customerSubscriptionIdGoesHere&#125;</i> needs
                        to be replaced with the subscription id of the
                        cancelling customer.
                      </p>
                      <p className="mt-4">
                        The Subscription ID is provided by your Stripe library.
                        Each paid user you have does have one assigned.
                      </p>
                      <p className="mt-4">
                        Want to learn more?{" "}
                        <a
                          href="https://stripe.com/docs/billing/subscriptions/overview"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Stripe&apos;s documentation about the subscription
                          object
                        </a>
                      </p>
                    </FaqItem>

                    <FaqItem title="That looks complicated, can you help?">
                      <p>
                        <strong>Check our examples for inspiration:</strong>
                      </p>
                      <p className="mt-2">
                        <a
                          className="mt-2"
                          href="https://github.com/tenstarsapp/retention-examples"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="inline-block align-middle mr-2">
                            <img
                              src={require("../../img/icons/github.svg")}
                              width="16px"
                              alt="github"
                            />
                          </span>
                          <span className="inline-block ml-2">
                            Github examples
                          </span>
                        </a>
                      </p>
                      <p className="mt-4">
                        Or{" "}
                        <a href="mailto:francesc@tenstars.co">
                          ask a Tenstars team member
                        </a>
                      </p>
                    </FaqItem>
                    <FaqItem title="How will it work and  look in my site?">
                      <p className="mb-2">Try the button:</p>
                      <RetentionWidgetTryButton
                        title="Cancel my subscription"
                        simulation={true}
                        apiKey={
                          props.testMode
                            ? props.site.testApiKey!
                            : props.site.liveApiKey!
                        }
                      />
                    </FaqItem>
                  </div>

                  {step3Complete(props.site, props.testMode) && (
                    <div className="flex flex-col justify-center items-center my-8 mb-16 text-center">
                      <div className="my-16">
                        <SuccessBubble />
                      </div>
                      <strong className="text-xl mb-4">
                        Congrats! you are done!
                      </strong>
                      {props.testMode && (
                        <a href="#live-mode">Set up the production app now</a>
                      )}
                    </div>
                  )}
                </QuickStartStep>

                {!props.testMode && step3Complete(props.site, props.testMode) && (
                  <QuickStartStep
                    key={4}
                    stepNumber={4}
                    title="Do you want to personalize your product?"
                    isComplete={true}
                    isLast={true}
                  >
                    <p className="mt-8 mb-4">
                      You have successfully installed a key part of your
                      customer retention strategy. What is next?
                    </p>
                    <ul>
                      <li className="list-disc mb-2 ml-8">
                        Do you want to survey your customers when they leave?
                        Use your tone,{" "}
                        <Link to={RETENTION_PAGE_URL}>
                          customize your exit surveys
                        </Link>
                      </li>
                      <li className="list-disc mb-2 ml-8">
                        Do you want automatic follow up on cancellations?{" "}
                        <Link to={RESURRECTION_PAGE_URL}>
                          Personalize your follow up email template
                        </Link>
                      </li>
                      <li className="list-disc ml-8">
                        Curious about how your retention is doing?{" "}
                        <Link to={RESULTS_PAGE_URL}>
                          Check your metrics dashboard
                        </Link>{" "}
                        and <Link to={ACCOUNT_HISTORY_URL}>activity feed</Link>
                      </li>
                    </ul>
                  </QuickStartStep>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </ConfigSectionContainer>
    </div>
  );
};

export default withFirebase(QuickstartSection);
