import React from "react";
import ChartistGraph from "react-chartist";
import Chartist from "chartist";
// @ts-ignore
import Legend from "chartist-plugin-legend";

export interface ChartSerie {
  name: string;
  data: number[];
}

export interface ChartData {
  series: ChartSerie[];
  labels: string[];
}

export interface ChartOptions {
  fullWidth: boolean;
  stackBars?: boolean;
  chartPadding?: {
    right: number;
  };
  axisY?: {
    labelInterpolationFnc: (value: number) => string | number;
  };
}

export interface LineChartProps {
  data: ChartData;
  options: ChartOptions;
}

const LineChart = ({ data, options }: LineChartProps): React.ReactElement => {
  const plugins = [
    Legend({ className: "crazy-churn", position: "bottom", clickable: false }),
  ];

  return (
    <div className="container-chart vertical-chart">
      <ChartistGraph
        data={data}
        options={{ ...options, plugins }}
        type="Line"
        listener={{
          draw: (d: any) => {
            if (d.type === "bar") {
              d.element.animate({
                y2: {
                  begin: 0,
                  dur: 1500,
                  from: d.y1,
                  to: d.y2,
                  easing: Chartist.Svg.Easing.easeOutSine,
                },
              });
            }
          },
        }}
      />
    </div>
  );
};

export default LineChart;
