import { TestModeStoreState } from "./TestModeStore";

export const storageKey = "tenstars_test_mode";

export const updateTestModeAction = "UPDATE_TEST_MODE";

const TestModeStorageKey = (
  state: TestModeStoreState,
  action: { type: string; payload: { testMode: boolean } }
): TestModeStoreState => {
  switch (action.type) {
    case updateTestModeAction:
      localStorage.setItem(storageKey, String(action.payload.testMode));
      return { testMode: action.payload.testMode };
    default:
      return state;
  }
};

export default TestModeStorageKey;
