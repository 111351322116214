import React from "react";
import Loader from "../icons/Loader";

interface ConfirmationModalProps {
  description: string;
  title: string;
  isLoading: boolean;
  onConfirm: () => void;
  confirmText: string;
  onCancel: () => void;
  cancelText: string;
}

const ConfirmationModal = (props: ConfirmationModalProps) => (
  <div className="fixed z-10 inset-0 overflow-y-auto">
    <div className="relative flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 bg-gray-500 opacity-75" />
      </div>

      <span
        className="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >
        &#8203;
      </span>
      <div
        className="absolute-center inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl sm:my-8 mx-1 sm:align-middle sm:max-w-lg w-75 sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 mb-3">
            <svg
              className="h-6 w-6 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div className="text-center mt-0 ml-4 text-left">
            <div className="align-top text-lg text-left leading-6 font-medium text-gray-900 mx-1 mb-2">
              {props.title}
            </div>
            <div className="mx-1">
              <p className="text-sm text-left align-top text-gray-500">
                {props.description}
              </p>
            </div>
          </div>
        </div>
        {props.isLoading && (
          <div className="mt-5 sm:mt-4">
            <Loader />
          </div>
        )}
        {!props.isLoading && (
          <div className="mt-5 sm:mt-4 flex flex-row-reverse">
            <button
              onClick={props.onConfirm}
              type="button"
              className="btn btn-danger font-weight-bold"
            >
              {props.confirmText}
            </button>
            <button
              onClick={props.onCancel}
              type="button"
              className="btn btn-secondary mr-2"
            >
              {props.cancelText}
            </button>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default ConfirmationModal;
