import React from "react";
import { getLastPosts, Post } from "../../api/wordpress";
import PostSample from "./PostSample";

const BLOG_ROOT = "https://tenstars.co/blog/";

const EMBED_MEDIA_X = "wp:featuredmedia";
const EMBED_MEDIA_Y = "0";

const BlogSection = () => {
  const [posts, setPosts] = React.useState<Post[]>([{}, {}, {}]);

  React.useEffect(() => {
    getLastPosts(3).then((r) => setPosts(r.data));
  }, []);

  const getImage = (p: Post): string => {
    const media = p._embedded && p._embedded[EMBED_MEDIA_X];
    return media && media[EMBED_MEDIA_Y]?.source_url;
  };

  return (
    <div className="mb-32">
      <h2 className="mb-4 mt-0 font-weight-bolder color-gentle-black text-center">
        Latest Blog Articles
      </h2>
      <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
        {posts.map((p, i) => (
          <PostSample
            title={p.title}
            excerpt={p.excerpt}
            key={`${p.id}${i}row`}
            date={p.date ? new Date(p.date) : undefined}
            link={`${BLOG_ROOT}${p.slug}`}
            imageSrc={getImage(p)}
          />
        ))}
      </div>
    </div>
  );
};

export default BlogSection;
