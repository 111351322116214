import React, {useEffect, useState} from 'react/index';

const TenstarsPricingPage = ({apiKey}: {apiKey: string}) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!loaded && apiKey) {
			const script = document.createElement('script');
			script.id = 'tenstars-pricing';
			script.src = `https://tenstars.web.app/bundled-pricing-script.js?apiKey=${apiKey}`;
			document.head.appendChild(script);
			script.onload = () => setLoaded(true);
		}
	}, [loaded, apiKey]);

	return (
		<>
			<div id="tenstars-pricing-page"/>
		</>
	);
};

export default TenstarsPricingPage;
