import React from "react";
import { ReasonsContent } from "../../pages/HomePage/content";

const SectionReasons = (props: { content: ReasonsContent }) => {
  return (
    <div className="row section px-lg-5 mb-3">
      <div className="col-12 text-center mb-2">
        <h2 className="mt-0 font-weight-bolder color-gentle-black">
          {props.content.sevenReasonsMainClaim}
        </h2>
        <h3 className="mt-3 mb-3 color-orange-brand">
          {props.content.sevenReasonsSecondaryClaim}
        </h3>
      </div>
      <div className="row my-sm-3 w-100">
        <div className="col-12 col-md-6 media pl-md-5 hp-reason mb-3">
          <img
            src={require("../../img/icon/Money.svg")}
            className="mr-3"
            alt="Save cash on a weekly basis"
          />
          <div className="media-body">
            <h3 className="mb-2 font-weight-bold">
              {props.content.reasonOneClaim}
            </h3>
            <p>{props.content.reasonOneText}</p>
          </div>
        </div>
        <div className="col-12 col-md-6 media pr-md-5 hp-reason mb-3">
          <img
            src={require("../../img/icon/Time.svg")}
            width="64px"
            height="64px"
            className="mr-3"
            alt="Save time"
          />
          <div className="media-body">
            <h3 className="mb-2 font-weight-bold">
              {props.content.reasonTwoClaim}
            </h3>
            <p>{props.content.reasonTwoText}</p>
          </div>
        </div>
      </div>
      <div className="row my-sm-3 w-100">
        <div className="col-12 col-md-6 media pl-md-5 hp-reason mb-3">
          <img
            src={require("../../img/icon/Smiley.svg")}
            width="64px"
            height="64px"
            className="mr-3"
            alt={"Invest in customer retention"}
          />
          <div className="media-body">
            <h3 className="mb-2 font-weight-bold">
              {props.content.reasonThreeClaim}
            </h3>
            <p>{props.content.reasonThreeText}</p>
          </div>
        </div>
        <div className="col-12 col-md-6 media pr-md-5 hp-reason mb-3">
          <img
            src={require("../../img/icon/Guarantee.svg")}
            width="64px"
            height={"64px"}
            className="mr-3"
            alt="Guarantee of satisfaction"
          />
          <div className="media-body">
            <h3 className="mb-2 font-weight-bold">
              {props.content.reasonFourClaim}
            </h3>
            <p>{props.content.reasonFourText}</p>
          </div>
        </div>
      </div>
      <div className="row my-sm-3 w-100">
        <div className="col-12 col-md-6 media pl-md-5 hp-reason mb-3">
          <img
            src={require("../../img/icon/Grade.svg")}
            width="64px"
            height="64px"
            className="mr-3"
            alt="Best churn retention solution out there"
          />
          <div className="media-body">
            <h3 className="mb-2 font-weight-bold">
              {props.content.reasonFiveClaim}
            </h3>
            <p>
              {`${props.content.reasonFiveText.part1} `}
              <a href="https://tenstars.co/blog">
                {props.content.reasonFiveText.linkText}
              </a>
              . {props.content.reasonFiveText.part2}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 media pr-md-5 hp-reason mb-3">
          <img
            src={require("../../img/icon/Gear.svg")}
            width="64px"
            height={"64px"}
            className="mr-3"
            alt={"Customizable Churn reduction solution"}
          />
          <div className="media-body">
            <h3 className="mb-2 font-weight-bold">
              {props.content.reasonSixClaim}
            </h3>
            <p>{props.content.reasonSixText}</p>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-8 mx-auto hp-reason mt-3 pt-3 text-center">
        <h3 className="mb-2 font-weight-bold">
          {props.content.reasonSevenClaim}
        </h3>

        <img
          src={require("../../img/stripe_logo.png")}
          height="60px"
          className="img-fluid p-2 max-width-60 mx-auto d-block"
          alt="Retention tool integrated with payment solutions"
        />
      </div>
    </div>
  );
};

export default SectionReasons;
