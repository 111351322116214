import firebaseLib from "firebase";
import { generateApiKeys } from "../api/api";
import Firebase from "../firebase";

const DEFAULT_USERNAME = "Anonymous";

export interface OnboardingResponse {
  siteUrl: string | null;
  siteId: string | null;
  userId: string | null;
  email: string | null;
}

export const registerWithGoogle = async (
  firebase: Firebase,
  code: string | null = null
): Promise<OnboardingResponse> => {
  const result = await firebase.signInWithGooglePopup();
  const email = result.user?.email || "you@email.com";

  return register(firebase, email, "https://your.site.com", result, code);
};

export const registerWithEmail = async (
  firebase: Firebase,
  email: string,
  siteUrl: string,
  code: string | null = null
): Promise<OnboardingResponse> => {
  const creds = await firebase.registerUserAnonymously();
  return register(firebase, email, siteUrl, creds, code);
};

export const register = async (
  firebase: Firebase,
  email: string,
  siteUrl: string,
  creds: firebaseLib.auth.UserCredential,
  code: string | null = null
): Promise<OnboardingResponse> => {
  let createdOn = new Date().getTime();
  let credentials: firebaseLib.auth.UserCredential | null = null;
  let siteId: string | null = null;
  credentials = creds;

  const user = firebase.getDb().ref(`users/${creds.user?.uid}`);
  const exists = await user.get().then((s) => s.exists());

  if (exists) {
    return { userId: null, siteId: null, siteUrl, email };
  }

  return user
    .set({
      username: DEFAULT_USERNAME,
      email,
      siteUrl,
      createdOn,
    })
    .then(async () => {
      if (!credentials?.user?.uid) {
        throw new Error("UserId is required");
      }

      return firebase.saveNewSite(credentials.user.uid, siteUrl, email, code);
    })
    .then(async (siteKey: string | null) => {
      siteId = siteKey;

      await firebase.getDb().ref(`users/${credentials?.user?.uid}`).set({
        siteId,
        username: DEFAULT_USERNAME,
        email,
        siteUrl,
      });
    })
    .then(async () => {
      const token = await firebase.getAFreshToken();
      const userId = credentials?.user?.uid;

      if (code) {
        await firebase.markAsRedeemed(code);
      }

      if (!token || !siteId || !userId) {
        return { userId: userId || null, email, siteUrl, siteId };
      }

      await generateApiKeys(token, userId, siteId);

      return { userId: userId || null, email, siteUrl, siteId };
    });
};
