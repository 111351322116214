import React, { useEffect, useState } from "react";

interface RetentionWidgetTryButtonProps {
  title: string;
  apiKey: string;
  language?: string;
  subscriptionId?: string;
  simulation: boolean;
}

const RetentionWidgetTryButton = (props: RetentionWidgetTryButtonProps) => {
  const [loaded, setLoaded] = useState(false);
  const [prevLanguage, setPrevLanguage] = useState<string | undefined>();
  const [lastScript, setLastScript] = useState<Node | undefined>();
  const { apiKey, language } = props;

  useEffect(() => {
    if (apiKey && (!loaded || language !== prevLanguage)) {
      if (lastScript) {
        lastScript.parentNode?.removeChild(lastScript);
      }

      const script = document.createElement("script");
      script.id = "tenstars";
      script.src = `https://tenstars.web.app/bundled-survey-script.js?apiKey=${apiKey}&simulation=${props.simulation}&language=${language}`;
      document.head.appendChild(script);
      script.onload = () => setLoaded(true);

      setPrevLanguage(language);
      setLastScript(script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, apiKey, language]);

  return (
    <>
      <div id="tenstars-widget" />
      <button
        disabled={!loaded}
        className="btn btn-primary negative"
        id="tenstars_cancel_btn"
        value={props.subscriptionId || "sub_HJYmxWnuDpbb1i"}
      >
        {props.title}
      </button>
    </>
  );
};

export default RetentionWidgetTryButton;
