import React from "react";
import ChartistGraph from "react-chartist";
import Chartist from "chartist";

interface AppPreviewChartExampleProps {
  data?: any;
  options?: any;
}
const AppPreviewChartExample = ({
  data,
  options,
}: AppPreviewChartExampleProps): React.ReactElement => {
  return (
    <div className="mx-3 mt-4">
      <ChartistGraph
        data={data}
        options={{ ...options }}
        type="Line"
        listener={{
          draw: (d: any) => {
            if (d.type === "bar") {
              d.element.animate({
                y2: {
                  begin: 0,
                  dur: 1500,
                  from: d.y1,
                  to: d.y2,
                  easing: Chartist.Svg.Easing.easeOutSine,
                },
              });
            }
          },
        }}
      />
    </div>
  );
};

export default AppPreviewChartExample;
