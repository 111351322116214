import React from 'react'
import Header from "../../components/Header/Header";
import {withRouter} from 'react-router-dom';
import { ArrowRightIcon, LightningBoltIcon, ScaleIcon, ChartBarIcon } from '@heroicons/react/outline'
import CallToAction from "../../components/common/CallToAction";

const features = [
    {
        name: 'Guide your business',
        description:
            'Information you will understand immediately, easy to understand and digest.',
        icon: ArrowRightIcon,
    },
    {
        name: 'Take instant decisions',
        description:
            'We retrieve and calculate most of your metrics live, allowing you to decide what needs to be done right at the moment.',
        icon: LightningBoltIcon,
    },
    {
        name: 'Controlling your data',
        description:
            'We retrieve your data, calculate your metrics and build your charts in your browser. We do not store or collect them.',
        icon: ScaleIcon,
    },
]
const acquisitionFeatures = [
    {
        id: 1,
        name: 'How is my business doing?',
        description:
            'All the metrics you need, your revenue, subscription and core KPIs. In one page. How much money do we make per customer? How long my customers stick around? we got you.',
        icon: ChartBarIcon,
    },
    {
        id: 2,
        name: 'What products are contributing to my revenue?',
        description:
            'All your data, segmented by product. Understand on one sight how your plans and products contribute to your net earnings',
        icon: ChartBarIcon,
    },
    {
        id: 3,
        name: 'WWhere does my revenue come from?',
        description:
            'Your subscriptions and metrics are segmented by the source of the subscription. What is the lifetime value of the subscriptions you acquire by SEO? what about the ones coming from Social media? you can answer that now.',
        icon: ChartBarIcon,
    },
    {
        id: 4,
        name: 'How many users are on trial right now? ',
        description:
            'How many stick after the trial period on average? Find out and take action instantly.',
        icon: ChartBarIcon,
    },
]
const retentionFeatures = [
    {
        id: 1,
        name: 'What is my churn rate? how is my SaaS going?',
        description:
            'Retention curve charts, to see exactly when your users start churning. You can take action and compare how your efforts impact your bottom line.',
        icon: LightningBoltIcon,
    },
    {
        id: 2,
        name: 'What events or characteristics are contributing to my product retention?',
        description:
            'You have cohort charts that let you compare the evolution of your retention, week on week. It allows you to pinpoint what actions are driving your retention.',
        icon: LightningBoltIcon,
    },
    {
        id: 3,
        name: 'Why are our customers leaving?',
        description:
            'Churn reason analysis is enabled by your exit funnel and surveys. We compile the answers as well as the results, letting you know how much money are you losing because of each issue.',
        icon: LightningBoltIcon,
    },
    {
        id: 4,
        name: 'What is my voluntary churn? what is my delinquency churn?',
        description:
            'Find out, as well as how much you are investing in retention incentives, how well are you resuscitating dead accounts, your conversion from incentive to retain and much more.',
        icon: LightningBoltIcon,
    },
]


const ReportingDashboard = () => {
    return(
        <div className="container-main blue-background-bottom">
                <Header/>
                <div className="homepage-content">
                    <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
                        <div className="text-center">
                            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                <span className="block">A simple and effective dashboard</span>
                                <span className="block text-indigo-600">to control your SaaS</span>
                            </h1>
                            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                                All the data you need to understand your acquisition, growth and retention efforts
                            </p>
                        </div>
                    </div>
                </div>

            <div className="py-12 bg-white">
                <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                    <h2 className="sr-only">Metrics and reporting for your business</h2>
                    <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
                        {features.map((feature) => (
                            <div key={feature.name}>
                                <dt>
                                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-orange-brand p-2 shadow-lg">
                                        <feature.icon className="h-12 w-12 text-white"/>
                                    </div>
                                    <p className="mt-5 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    {feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        {/*    What you can do/become thanks to our product*/}

            <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 py-6 ">
                <div className="relative mt-12 sm:mt-16 lg:mt-24">
                    <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center mb-12 sm:mb-16 lg:mb-24">
                        <div className="lg:col-start-1">
                            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                                Improve your Retention Strategy
                            </h3>
                            <p className="mt-3 text-lg text-gray-500">
                                A tricky part about retention is that usually, you are flying blind. We want to end that. By providing you with the metrics and KPIs you need to answer the most important retention questions.
                            </p>

                            <dl className="mt-10 space-y-10">
                                {retentionFeatures.map((item) => (
                                    <div key={item.id} className="relative">
                                        <dt>
                                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-gradient text-white">
                                                <item.icon className="h-6 w-6" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                                        </dt>
                                        <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                        <div className="lg:col-start-2">
                            <img
                                className="relative mx-auto border-1 border-primary shadow-md rounded"
                                width={490}
                                src={require("../../img/retention_charts.png")}
                                alt=""
                            />
                        </div>
                    </div>
                    {/* GROWTH metrics section */}
                    <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                        <div className="lg:col-start-2">
                            {/*here goes second column*/}
                            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Guide your growth</h3>
                            <p className="mt-3 text-lg text-gray-500">
                                Saas owners and managers are busy people, they have a few minutes per day to evaluate how his business is going. We provide a quick snapshot.
                            </p>

                            <dl className="mt-10 space-y-10">
                                {acquisitionFeatures.map((item) => (
                                    <div key={item.id} className="relative">
                                        <dt>
                                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-gradient text-white">
                                                <item.icon className="h-6 w-6" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                                        </dt>
                                        <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                        <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                            {/*Here goes the first column*/}
                            <img
                                className="relative mx-auto border-1 border-primary shadow-md rounded"
                                width={490}
                                src={require("../../img/new_subscriptions_chart.svg")}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        {/*    Call to Action */}
            <CallToAction feature="Get your dashboard for free" featureSupport="Live data you will actually love"/>

        </div>
        )
}

export default withRouter(ReportingDashboard);
