import React from "react";
import Header from "../../components/Header/Header";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Firebase, { withFirebase } from "../../firebase";
import queryString from "query-string";
import { EMAIL_REGEXP, URL_REGEXP } from "../../utils/regex";
import { HOME_URL } from "../protected/MainPage/resources";
import OnboardingForm from "../../components/onboarding/OnboardingForm";
import StatefulButton from "../../components/basic/StatefulButton";
import {
  registerWithEmail,
  registerWithGoogle,
} from "../../service/authService";
import Check from "../../components/basic/Check";
import amplitudeService, {
  ONBOARDING_COMPLETE_EMAIL,
  ONBOARDING_COMPLETE_GOOGLE,
} from "../../service/amplitudeService";

interface OnboardingPageProps {
  firebase: Firebase;
}

interface OnboardingPageState {
  email: string;
  siteUrl: string;
  isUrlValid: boolean;
  isEmailValid: boolean;
  loading: boolean;
  redirect: string;
  error?: string;
  code: string | null;
}

class OnboardingPage extends React.Component<
  OnboardingPageProps & RouteComponentProps,
  OnboardingPageState
> {
  constructor(props: OnboardingPageProps & RouteComponentProps) {
    super(props);
    const values = queryString.parse(props.location.search);
    this.state = {
      siteUrl: "",
      email: "",
      isUrlValid: true,
      isEmailValid: true,
      loading: false,
      redirect: values.redirect
        ? decodeURIComponent(values.redirect as string)
        : HOME_URL,
      code: values.code as string,
    };
  }

  async onSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.setState({ loading: true });
    await registerWithEmail(
      this.props.firebase,
      this.state.email,
      this.state.siteUrl,
      this.state.code
    )
      .then((response) => {
        if (response.userId) {
          amplitudeService.getInstance().setUserId(response.userId);
          amplitudeService
            .getInstance()
            .logEvent(ONBOARDING_COMPLETE_EMAIL, response);
        }
      })
      .catch((error) => {
        this.setState({ error });
        amplitudeService.reportOnboardingError(error);
      });
    this.setState({ loading: false });

    if (!this.state.error) {
      this.props.history.push(this.state.redirect);
    }
  }

  async onGoogleSubmit() {
    this.setState({ loading: true });
    await registerWithGoogle(this.props.firebase, this.state.code)
      .then((response) => {
        if (response.userId) {
          amplitudeService.getInstance().setUserId(response.userId);
          amplitudeService
            .getInstance()
            .logEvent(ONBOARDING_COMPLETE_GOOGLE, response);
        }
      })
      .catch((error) => {
        amplitudeService.reportOnboardingError(error);
        this.setState({ error });
      });
    this.setState({ loading: false });

    if (!this.state.error) {
      this.props.history.push(this.state.redirect);
    }
  }

  onChangeUrl(siteUrl: string) {
    const isUrlValid = siteUrl.match(URL_REGEXP);
    this.setState({ siteUrl, isUrlValid: !!isUrlValid });
  }

  onChangeEmail(email: string) {
    const isEmailValid = email.match(EMAIL_REGEXP);
    this.setState({ email, isEmailValid: !!isEmailValid });
  }

  calculateButtonState = () => {
    if (this.state.loading) {
      return "loading";
    }

    if (
      !this.state.isUrlValid ||
      !this.state.siteUrl ||
      !this.state.isEmailValid ||
      !this.state.email
    ) {
      return "disabled";
    }

    return "active";
  };

  render() {
    const { isUrlValid, siteUrl, email, isEmailValid } = this.state;

    return (
      <div className="container-main blue-background-bottom">
        <Header />
        <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8 ">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:max-w-none">
            <div className="col">
              <div className="px-1 px-md-0 px-lg-5 py-auto my-3 mt-5">
                <div className="text-center mb-3">
                  <h2 className="italic">
                    Retain more customers
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 inline-block ml-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </h2>
                  <img
                    src={require("../../img/increase_revenue_illustration.svg")}
                    alt=""
                  />
                </div>
                <div className="text-center mb-3">
                  <p>
                    A better way of handling cancellations,
                    <br />
                    reduce your churn rate up to 40%. Guaranteed ROI.
                  </p>
                </div>
                <div className="px-3 px-md-5 px-lg-5 mb-3">
                  <ul className="tickList">
                    <li>
                      <Check />
                      <span className="ml-2">No credit card is needed</span>
                    </li>
                    <li>
                      <Check />
                      <span className="ml-2">
                        5 minutes to have it running!
                      </span>
                    </li>
                    <li>
                      <Check />
                      <span className="ml-2">Join awesome SaaS companies</span>
                    </li>
                  </ul>
                </div>
                <div className="px-5">
                  <img
                    src={require("../../img/brands_small.png")}
                    alt=""
                    className="grayscale"
                  />
                </div>
              </div>
            </div>
            <OnboardingForm
              title="Sign In to your new account"
              subtitle="Grow your business faster"
              onGoogleSubmit={this.onGoogleSubmit.bind(this)}
              email={email}
              isEmailValid={isEmailValid}
              onChangeEmail={this.onChangeEmail.bind(this)}
              siteUrl={siteUrl}
              isUrlValid={isUrlValid}
              onChangeUrl={this.onChangeUrl.bind(this)}
            >
              <StatefulButton
                type="submit"
                className="pull-right btn_registration_email well"
                data={{
                  state: this.calculateButtonState(),
                  text: "Create my account",
                }}
                onClick={this.onSubmit.bind(this)}
              />
            </OnboardingForm>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withFirebase(OnboardingPage));
