import React, { useEffect, useState } from "react";
import LineChart from "./LineChart";
import Loader from "../icons/Loader";
import classNames from "classnames";

const formatRevenueChartData = (data: any, currencyValue: string): any => {
  if (!data?.byMonth) {
    return { data: {}, options: {} };
  }

  return {
    data: {
      labels: data.byMonth.map((c: any) => c.month),
      series: [
        {
          name: "Revenue before Tenstars",
          data: data.byMonth.map((c: any) => c.revenue),
        },
        {
          name: "Revenue with Tenstars",
          data: data.byMonth.map((c: any) => c.hackedRevenue),
        },
      ],
    },
    options: {
      stackBars: true,
      fullWidth: true,
      chartPadding: {
        right: 40,
      },
      axisY: {
        labelInterpolationFnc: (value: number) => {
          return `${value.toFixed(0)}${currencyValue}`;
        },
      },
    },
  };
};

interface RevenueChartProps {
  title: string;
  data: any;
  loaded: boolean;
  currency: string;
  center: boolean;
  currencyValue: string;
}

const RevenueChart = (props: RevenueChartProps) => {
  const { title, data, loaded, currency, currencyValue, center } = props;

  const [sourceData, setSourceData] = useState(
    formatRevenueChartData(data, currencyValue)
  );

  useEffect(() => {
    setSourceData(formatRevenueChartData(data, currencyValue));
  }, [data, currency, currencyValue]);

  return (
    <div
      className={classNames({ "justify-content-center text-center": !!center })}
    >
      {title && <h5 className="mb-4">{title}</h5>}
      {!loaded && (
        <div className="mt-5">
          <Loader />
        </div>
      )}
      {loaded && (
        <LineChart data={sourceData.data} options={sourceData.options} />
      )}
    </div>
  );
};

export default RevenueChart;
