import amplitude from "amplitude-js";

// Reference: https://github.com/tenstarsapp/docs/blob/main/Amplitude%20implementation.md

export const REGISTRATION_START_HP = "register start hp";
export const ONBOARDING_COMPLETE_EMAIL = "register first sign-in email";
export const ONBOARDING_COMPLETE_GOOGLE = "register first sign-in google";
export const USER_REGISTERED_EMAIL = "register connected stripe email";
export const CONNECT_STRIPE_PRODUCTION = "connect stripe production";
export const CONNECT_STRIPE_DEVELOPMENT = "connect stripe development";

const AMPLITUDE_KEY = "af6c2e211fe875e00956256267a6257c";

const init = () => {
  getInstance().init(AMPLITUDE_KEY);
};

const getInstance = () => {
  return amplitude.getInstance(process.env.NODE_ENV);
};

const reportOnboardingError = (error: Error): void => {
  console.error(error);
  // amplitude does not like errors so we wrap it as an object field
  getInstance().logEvent("ONBOARDING_ERROR", { error });
};

const reportRegistrationError = (error: Error): void => {
  console.error(error);
  // amplitude does not like errors so we wrap it as an object field
  getInstance().logEvent("REGISTRATION_ERROR", { error });
};

const reportDashboardStatsError = (body: any): void => {
  getInstance().logEvent("DASHBOARD_STATS_LOAD_ERROR", body);
};

const reportDashboardMetricsError = (body: any): void => {
  getInstance().logEvent("DASHBOARD_METRICS_LOAD_ERROR", body);
};

export default {
  init,
  getInstance,
  reportOnboardingError,
  reportRegistrationError,
  reportDashboardStatsError,
  reportDashboardMetricsError,
};
