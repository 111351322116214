import ExpandableContainer from "../../basic/ExpandableContainer";
import ChurnChart from "./ChurnChart";
import React, { useState } from "react";
import { MetricsResponse } from "../../../domain/metricsModel";
import { ChartMode, Rates } from "../../../domain/DomainModel";

interface DashboardChurnChartProps {
  numMonths: number;
  currency: string;
  data?: MetricsResponse;
  loading: boolean;
  rates: Rates;
  convertOthers: boolean;
  menuClosed: boolean;
  minHeight?: number;
}

const DashboardChurnChart = (props: DashboardChurnChartProps) => {
  const [isExpanded, setExpanded] = React.useState(false);
  const [chartMode, setChartMode] = useState<ChartMode>("subscriptions");

  return (
    <ExpandableContainer onUpdate={setExpanded} minHeight={props.minHeight}>
      <ChurnChart
        key={`churn-${isExpanded}/${props.numMonths}/${props.currency}/${props.data?.testMode}/${props.data?.byMonth.length}/${props.convertOthers}/${chartMode}/${props.menuClosed}`}
        loading={props.loading}
        data={props.data}
        currency={props.currency}
        numMonths={props.numMonths}
        rates={props.rates}
        convertOthers={props.convertOthers}
        chartMode={chartMode}
        updateChartMode={setChartMode}
      />
    </ExpandableContainer>
  );
};

export default DashboardChurnChart;
