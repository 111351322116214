import { MetricsResponse } from "../../../domain/metricsModel";
import { ChartMode, Rates } from "../../../domain/DomainModel";
import { getOrConvertCurrency } from "../../../service/currencyConversionService";

export const calculateDelinquentMrr = (
  currency: string,
  data: MetricsResponse | undefined,
  rates: Rates,
  convertOthers: boolean,
  chartMode: ChartMode
): number | undefined => {
  if (!data?.segmentedSubscriptions) {
    return undefined;
  }

  const subs =
    chartMode === "subscriptions"
      ? data.segmentedSubscriptions["incomplete_expired"]?.count
      : data?.segmentedSubscriptions["incomplete_expired"]?.mrr;

  return getOrConvertCurrency(subs, rates, currency, convertOthers);
};
