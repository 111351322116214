import React from "react";
import ConfigPageContainer from "../../components/config/ConfigPageContainer";
import {withFirebase} from "../../firebase";
import PricingPageBuilder from "../../components/pricePreview/PricingPageBuilder";
import {AuthUser} from "../../domain/DomainModel";

interface PublicPricingPageBuilderProps {
	authUser: AuthUser
}

const PublicPricingPageBuilder = (props: PublicPricingPageBuilderProps): React.ReactElement =>  (
			<ConfigPageContainer>
				<div className="container-fluid position-relative">
					<div className="mt-5">
						<PricingPageBuilder authUser={props.authUser}/>
					</div>
				</div>
			</ConfigPageContainer>
		);


export default withFirebase(PublicPricingPageBuilder);
