import _ from "lodash";
import { ChartMode, Dictionary, Rates } from "../../../domain/DomainModel";
import { StatsResponse } from "../../../domain/metricsModel";
import statsCalculator from "../../../service/statsCalculator";
import { DataEntry } from "react-minimal-pie-chart/types/commonTypes";
import { lastN } from "../../../utils/ArrayUtils";

export interface FlatCancellationAttemptsByMonth {
  value: number;
  reason: string;
  language: string;
}

const colors: string[] = [
  "#ff644d",
  "#4de8ff",
  "#ffc600",
  "#4a9Ae2",
  "#9FD356",
  "#B7F0AD",
  "#387780",
  "#F9DC5C",
  "#006BA6",
];

const randomNotTooDarkColor = (i: number) =>
  "hsl(" +
  360 * Math.random() +
  "," +
  (30 + 60 * Math.random()) +
  "%," +
  (60 + i + 10 * Math.random()) +
  "%)";

export const transformDataHorizontalBarChart = (
  rawData: StatsResponse | undefined,
  numMonths: number,
  language: string,
  currency: string,
  chartMode: ChartMode,
  rates: Rates,
  convertOthers: boolean
): DataEntry[] => {
  if (!rawData?.cancellationAttemptsByMonth) {
    return [];
  }

  const filteredByMonth =
    lastN(numMonths, rawData?.cancellationAttemptsByMonth) || [];

  const flatData: Dictionary<FlatCancellationAttemptsByMonth> = {};

  for (const month of filteredByMonth) {
    for (const reason of Object.keys(month)) {
      const value =
        statsCalculator.getValue(
          month[reason].cost,
          currency,
          chartMode,
          convertOthers,
          rates
        ) || 0;

      if (!flatData[reason]) {
        // NOTE: clone is necessary to not modify the original array
        const clone = _.cloneDeep(month[reason]);
        flatData[reason] = { ...clone, value };
      } else {
        flatData[reason].value = flatData[reason]?.value + value;
      }
    }
  }

  const sortedCancellationAttemptsByValue = Object.values(flatData)
    .filter((v) => !!v.value)
    .sort(
      (
        a: FlatCancellationAttemptsByMonth,
        b: FlatCancellationAttemptsByMonth
      ) => b.value - a.value
    );

  return sortedCancellationAttemptsByValue
    .filter((c) => !language || language === "all" || c.language === language)
    .map((d: FlatCancellationAttemptsByMonth, i: number) => {
      return {
        value: d.value,
        unit: chartMode === "subscriptions" ? "users" : currency,
        title: d.reason,
        color: colors[i] || randomNotTooDarkColor(i),
        key: d.reason,
      };
    });
};
