import React from "react";

interface AlertInfoProps {
  to?: string;
  text: string;
  linkText?: string;
}

const AlertInfo = (props: AlertInfoProps) => (
  <div className="rounded-md bg-blue-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <svg
          className="h-5 w-5 text-blue-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="ml-3">
        <div className="text-lg text-blue-700">
          <div>{props.text}</div>
          {props.to && (
            <div className="mt-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={props.to}
                className="font-medium underline text-blue-700 hover:text-yellow-600"
              >
                {props.linkText}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default AlertInfo;
