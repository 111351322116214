import { MetricsResponse } from "../../../domain/metricsModel";
import { ChartMode, Rates } from "../../../domain/DomainModel";
import statsCalculator from "../../../service/statsCalculator";
import { lastN } from "../../../utils/ArrayUtils";

export const transformGrowthRateChartData = (
  data: MetricsResponse | undefined,
  currency: string,
  chartMode: ChartMode,
  numMonths: number,
  convertOthers: boolean,
  rates: Rates
) => {
  const filteredData = lastN(numMonths, data?.byMonth) || [];

  const active: number[] = statsCalculator.getValues(
    filteredData.map((fd) => fd.revenueActiveAtTheEndOfTheMonth),
    currency,
    chartMode,
    convertOthers,
    rates
  );

  const gr: number[] = active.map(
    (v, i) =>
      (statsCalculator.growthRate(v, i === 0 ? 0 : active[i - 1]) || 0) * 100
  );

  return {
    data: {
      labels: filteredData.map((c, i) => c.month || `Month ${i + 1}`),
      series: [
        {
          name: "Active Subscriptions",
          data: gr,
        },
      ],
    },
    options: {
      stackBars: true,
      fullWidth: true,
      chartPadding: {
        right: 40,
      },
      axisY: {
        labelInterpolationFnc: (value: number) => {
          if (value < 1000) {
            return `${value}%`;
          }

          return `${value / 1000}k%`;
        },
      },
    },
  };
};
