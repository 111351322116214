import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @import url('<https://fonts.googleapis.com/css2?family=Montserrat&family=Lato:wght@100;300;400;900&family=Playfair+Display&family=Playfair+Display+SC&display=swap>');
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0; 
    border: 0;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    font-family: 'Lato','Helvetica Neue','Helvetica','Arial','Icons16',sans-serif;
  }
`;
