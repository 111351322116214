import React from "react";

const Drawer = (props) => (
  <div className="fixed inset-0 overflow-hidden" style={{ zIndex: 2000 }}>
    <div className="absolute inset-0 overflow-hidden" style={{ zIndex: 2000 }}>
      <section
        className="absolute inset-y-0 pl-16 max-w-full right-0 flex"
        aria-labelledby="slide-over-heading"
      >
        <div className="w-screen max-w-sm">
          <form className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
            <div className="flex-1 h-0 overflow-y-auto">
              <div className="py-6 px-4 bg-orange-brand sm:px-6">
                <div className="flex items-center justify-between">
                  <div
                    id="slide-over-heading"
                    className="text-lg font-medium text-white"
                  >
                    {props.title}
                  </div>
                  <div className="ml-3 h-7 flex items-center">
                    <button
                      onClick={props.onClose}
                      className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    >
                      <span className="sr-only">Close panel</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mt-1">
                  <p className="text-sm text-gray-200">{props.description}</p>
                </div>
              </div>
              <div className="flex-1 flex flex-col justify-between">
                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                  <div className="space-y-6 pt-6 pb-5">{props.children}</div>
                </div>
              </div>
            </div>
            <div className="flex-shrink-0 px-4 py-4 flex justify-end">
              <button onClick={props.onClose} className="btn btn-secondary">
                Continue Editing
              </button>
              <button
                disabled={props.disabled}
                onClick={props.onContinue}
                className="btn btn-primary ml-2"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
);

export default Drawer;
