import React from "react";
import Header from "../../components/Header/Header";

const NotFound = () => {
  return (
    <div className="container-main error-page">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md">
            <img
              src={require("../../img/dog_walk_interrupted.svg")}
              alt="Juls realized the dow walk should be interrupted"
            />
          </div>
          <div className="col-12 col-md">
            <div className="container">
              <h3>Error 404</h3>
              <blockquote className="blockquote">
                <p>
                  Juls just realized the dog&apos;s walk will be interrupted
                  today.
                </p>
              </blockquote>
              <p>
                <strong>If you just landed here by mistake...</strong>
              </p>
              <p>
                {" "}
                hi, nice to meet you. We are a <i>
                  churn reduction software
                </i>{" "}
                solution. We help SaaS companies{" "}
                <u>save money by retaining their more important customers</u>.
                When their users start cancelling their account we evaluate how
                valuable is the client for the company and trigger different
                actions and incentives for them to remain a client. We generate
                stats and collect feedback from those users. Our clients get
                visibility on what value they need to add to reduce their
                churning and improve their retention rates.
              </p>
              <p>
                <strong>
                  If you have an idea of what is going wrong here...
                </strong>
              </p>
              <p>
                Write me to <strong>julio at tenstars.co</strong>, I&apos;ll do
                my best to help you
              </p>
              <p>
                <strong>There is always a silverlining...</strong>
              </p>
              <p>
                We write <a href="//blog">a blog around customer retention</a>{" "}
                and bi-weekly newsletter with articles like...
              </p>
              <ul>
                <li>
                  <a href="//blog">
                    Customer attrition analysis, quick and dirty way
                  </a>
                </li>
                <li>
                  <a href="//blog">
                    Tearing down average customer retention rates by industry:
                    streaming companies
                  </a>
                </li>
                <li>
                  <a href="//blog">
                    Build a customer retention strategy, not a gimmick
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
