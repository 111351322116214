import React from "react";
import { Link } from "react-router-dom";
import { AuthUserContext } from "../session";
import { CALCULATOR_URL } from "../../App";
import Avatar from "../common/Avatar";
import { HOME_URL } from "../../pages/protected/MainPage/resources";
import { AuthUser } from "../../domain/DomainModel";

const Navigation = () => (
  <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          <NavigationAuth authUser={authUser} />
        ) : (
          <NavigationNonAuth />
        )
      }
    </AuthUserContext.Consumer>
  </div>
);

const NavigationAuth = (props: {
  authUser: AuthUser | null;
}): React.ReactElement => {
  return (
    <div className="navbar-nav ml-auto align-middle flex-nowrap leading-8">
      <Link to="/pricing" className="nav-item nav-link menu-link">
        Pricing
      </Link>
      <Link to={CALCULATOR_URL} className="nav-item nav-link menu-link">
        Churn Rate Calculator
      </Link>
      <a href="/blog" className="nav-item nav-link menu-link">
        Blog
      </a>
      <Link to="/about-us" className="nav-item nav-link menu-link">
        About us
      </Link>
      {props.authUser?.user?.email && (
        <Link to={HOME_URL} className="nav-item nav-link menu-link">
          <Avatar size={32} email={props.authUser?.user.email} />
        </Link>
      )}
    </div>
  );
};

const NavigationNonAuth = () => (
  <div className="navbar-nav ml-auto flex-nowrap align-middle">
    <Link to="/pricing" className="nav-item nav-link text-link menu-link">
      Pricing
    </Link>
    <Link to={CALCULATOR_URL} className="nav-item nav-link text-link menu-link">
      Churn Rate Calculator
    </Link>
    <a
      href="/blog"
      className="nav-item nav-link text-link menu-link"
      tabIndex={-1}
    >
      Blog
    </a>
    <Link to="/about-us" className="nav-item nav-link text-link menu-link">
      About us
    </Link>
    <a
      className="nav-item nav-link md:mb-2"
      href="https://calendly.com/datacompany/onboarding-help"
      aria-disabled="true"
    >
      <button id="btn_schedule_demo" className="btn btn-primary well">
        Book Demo
      </button>
    </a>
    <Link className="nav-item nav-link" to="/signin" aria-disabled="true">
      <button className="btn btn-primary">Sign In</button>
    </Link>
  </div>
);

const Header = (): React.ReactElement => (
  <nav className="navbar navbar-expand-lg">
    <Link className="navbar-brand tenstars-text-logo" to="/">
      <img
        src={require("../../img/logo/tenstars.svg")}
        height="47"
        className="d-inline-block align-middle"
        alt=""
      />
      Tenstars
    </Link>
    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon">
        <img src={require("../../img/btn_ham.svg")} alt="" />
      </span>
    </button>
    <Navigation />
  </nav>
);

export default Header;
