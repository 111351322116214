import React from "react";

interface ListQuestionItemProps {
  onSubmit: (value: string, id: number) => void;
  order: number;
  title: string;
  onDelete: (order: number) => void;
  name: string;
  id: string | number;
  disabled: boolean;
}

interface ListQuestionItemState {
  item: string;
}

class ListQuestionItem extends React.Component<
  ListQuestionItemProps,
  ListQuestionItemState
> {
  constructor(props: ListQuestionItemProps) {
    super(props);
    this.state = {
      item: "",
    };
  }

  onFormSubmit = (e: any) => {
    e.preventDefault();
    this.props.onSubmit(this.state.item, this.props.order);
  };

  componentDidMount() {
    this.setState({ item: this.props.title });
  }

  updateValue(e: any) {
    e.preventDefault();
    this.setState({ item: e.target.value });
    this.props.onSubmit(e.target.value, this.props.order);
  }

  render() {
    return (
      <div className="question_content" key={this.props.order + "littleitem"}>
        <form
          onSubmit={this.onFormSubmit.bind(this)}
          key={this.props.order + "smallform"}
        >
          <div className="row">
            <div className="col-10">
              <div className="form-group">
                <input
                  onChange={this.updateValue.bind(this)}
                  onBlur={this.updateValue.bind(this)}
                  value={this.state.item}
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder={this.state.item}
                  disabled={this.props.disabled}
                  key={this.props.order + "field"}
                />
              </div>
            </div>
            <div className="col-2">
              {!this.props.disabled && (
                <img
                  src={require("../../img/button_delete.svg")}
                  onClick={() => this.props.onDelete(this.props.order)}
                  className="iconDelete"
                  alt="delete question"
                />
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ListQuestionItem;
