import axios, { AxiosResponse } from "axios";
import { STRIPE_LIVE_MODE_REDIRECT_PATH } from "../App";
import { Dictionary, PricingRoot } from "../domain/DomainModel";
import { MetricsResponse, StatsResponse } from "../domain/metricsModel";
import { StripeSubscription } from "../domain/StripeModel";

// Call for data to the different envs

let isDeveloper = false;

if (
  window.location.origin === "http://115af14f6808.ngrok.io" ||
  window.location.origin === "http://localhost:3000"
) {
  isDeveloper = true;
}

let baseURL = "";

isDeveloper
  ? (baseURL = "http://localhost:5001/tenstars/us-central1")
  : (baseURL = "https://us-central1-tenstars.cloudfunctions.net");

export const requestStats = async (
  token: string,
  siteId: string,
  testMode: boolean
): Promise<AxiosResponse<StatsResponse>> => {
  return axios
    .create({
      baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get<StatsResponse>(`stats?siteId=${siteId}&testMode=${testMode}`);
};

export const requestChurn = async (
  token: string,
  siteId: string,
  testMode: boolean
): Promise<AxiosResponse<MetricsResponse>> => {
  return axios
    .create({
      baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get<MetricsResponse>(`stats/churn?siteId=${siteId}&testMode=${testMode}`);
};

export const requestSubscription = async (
  token: string,
  siteId: string,
  subscriptionId: string,
  testMode: boolean
): Promise<AxiosResponse<StripeSubscription>> => {
  return axios
    .create({
      baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get<StripeSubscription>(
      `stats/subscriptions/${subscriptionId}?siteId=${siteId}&testMode=${testMode}`
    );
};

export const requestSubscriptions = async (
  token: string,
  siteId: string,
  testMode: boolean,
  status: string
): Promise<AxiosResponse<StripeSubscription[]>> => {
  return axios
    .create({
      baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get<StripeSubscription[]>(
      `stats/subscriptions?siteId=${siteId}&testMode=${testMode}&status=${status}`
    );
};

export const generateApiKeys = async (
  token: string,
  userId: string,
  siteId: string
): Promise<void> => {
  return axios
    .create({
      baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .post("/site/api-keys", {
      siteId,
      userId,
    })
    .catch((err) => {
      console.error(err);
      return err;
    });
};

export const updateCredentials = async (
  token: string,
  stripeCode: string,
  userId: string,
  siteId: string
): Promise<AxiosResponse<void>> => {
  let isRequestProduction = false;
  if (window.location.pathname === STRIPE_LIVE_MODE_REDIRECT_PATH) {
    isRequestProduction = true;
  }

  return axios
    .create({
      baseURL: baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .put("/account/credentials", {
      payload: {
        production: isRequestProduction,
        code: stripeCode,
        userId,
        siteId,
      },
    });
};

export const updatePricing = async (
  token: string,
  siteId: string,
  pricing: PricingRoot
): Promise<AxiosResponse<PricingRoot>> => {
  return axios
    .create({
      baseURL: baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .post<PricingRoot>("/site/pricing", { pricing, siteId });
};

export interface RedeemCodeResponse {
  error: string;
  code: string;
}

export const redeemCode = async (
  code: string,
  testMode: boolean = false
): Promise<AxiosResponse<RedeemCodeResponse>> => {
  return axios
    .create({
      baseURL: baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .post<RedeemCodeResponse>("/redeem", { code, testMode });
};

export const fetchRates = async (
  token: string
): Promise<AxiosResponse<Dictionary<Dictionary<string>>>> => {
  return axios
    .create({
      baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get<Dictionary<Dictionary<string>>>("rates");
};
