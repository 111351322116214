import React, { useContext } from "react";
import { PricingPageType } from "../../domain/DomainModel";
import { SharedContext } from "../common/SharedProvider";

const typeClassic = "classic";
const typeSingle = "single";
const typeDonation = "donation";

export const defaultType = typeClassic;

export interface PricingPageTypePickProps {
  onClick: (type: PricingPageType) => void;
}

const PricingPageTypePick = (props: PricingPageTypePickProps) => {
  const utils = useContext(SharedContext);

  return (
    <div className="flex flex-col justify-content-center">
      <button
        className="border-0 relative max-w-7xl mx-auto lg:grid lg:grid-cols-12 lg:px-8 grid-cols-12 rounded-xl bg-white pt-12 pb-8 m-lg-4 p-lg-4 clickable"
        onClick={() => props.onClick(typeSingle)}
      >
        <div className="col-span-6 text-left price-editor py-5 px-5">
          <h3 className="pb-2">One star product</h3>
          <h2 className="">You have one product, single tier</h2>
          <p className="mt-2">
            You want to make things as easy as possible, but you also want it to
            be effective.
          </p>
          <div className="grid grid-cols-12 my-4">
            <div className="col-span-1 max-w-xs">
              <div className="bg-orange-brand p-1 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="white"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div className="col-span-11 px-2 ml-2">
              <h4>Display options</h4>
              <p>
                You can open it in a popup, integrate it in your homepage or
                create a separated price page
              </p>
            </div>
          </div>
          <div className="grid grid-cols-12 my-4">
            <div className="col-span-1 max-w-xs">
              <div className="bg-orange-brand p-1 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="white"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div className="col-span-11 px-2 ml-2">
              <h4>Features</h4>
              <p>
                Test your price dynamically, offer discounts, 1-2-1 chat with
                customers
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-6 mx-4 my-lg-5">
          <img
            style={{ width: "99price_single.778b60b2.svg0%" }}
            src={require("../../img/illustrations/browser_single.svg")}
            alt="single product"
          />
        </div>
      </button>

      <button
        className="border-0 relative max-w-7xl mx-auto lg:grid lg:grid-cols-12 lg:px-8 grid-cols-12 rounded-xl bg-white pt-12 pb-8 m-lg-4 p-lg-4 clickable"
        onClick={() => props.onClick(typeClassic)}
      >
        <div className="col-span-6 text-left price-editor py-5 px-5">
          <h3 className="pb-2">Classical Multi Product</h3>
          <h2 className="">You have multiple products</h2>
          <p className="mt-2">
            You want your customers to compare between them, anchoring one.
          </p>
          <div className="grid grid-cols-12 my-4">
            <div className="col-span-1 max-w-xs">
              <div className="bg-orange-brand p-1 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="white"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div className="col-span-11 px-2 ml-2">
              <h4>Options</h4>
              <p>
                Anchor one product, compare features and plans, encourage annual
                subscriptions...
              </p>
            </div>
          </div>
          <div className="grid grid-cols-12 my-4">
            <div className="col-span-1 max-w-xs">
              <div className="bg-orange-brand p-1 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="white"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div className="col-span-11 px-2 ml-2">
              <h4>Features</h4>
              <p>
                Test your price dynamically, offer discounts, 1-2-1 chat with
                customers
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-6 mx-4 my-lg-5">
          <img
            style={{ width: "99price_single.778b60b2.svg0%" }}
            src={require("../../img/illustrations/price_classic.svg")}
            alt="single product"
          />
        </div>
      </button>

      <button
        className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-12 lg:px-8 grid-cols-12 rounded-xl bg-white pt-12 pb-8 m-lg-4 p-lg-4 clickable"
        onClick={() => props.onClick(typeDonation)}
      >
        <div className="col-span-6 text-left price-editor py-5 px-5">
          <h3 className="pb-2">Donation</h3>
          <h2 className="">You accept periodic donations</h2>
          <p className="mt-2">
            You want your customer to choose how much to give
          </p>
          <div className="grid grid-cols-12 my-4">
            <div className="col-span-1 max-w-xs">
              <div className="bg-orange-brand p-1 rounded">
                <img
                  style={{ width: "99price_single.778b60b2.svg0%" }}
                  src={require("../../img/illustrations/browser_single.svg")}
                  alt="single product"
                />
              </div>
            </div>
            <div className="col-span-11 px-2 ml-2">
              <h4>Options</h4>
              <p>
                Pick price and frequency, let customer pick amount, offer gifts
              </p>
            </div>
          </div>
          <div className="grid grid-cols-12 my-4">
            <div className="col-span-1 max-w-xs">
              <div className="bg-orange-brand p-1 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="white"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div className="col-span-11 px-2 ml-2">
              <h4>Features</h4>
              <p>
                Test your price dynamically, offer gifts, 1-2-1 chat with
                customers
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-6 mx-4 my-lg-5">
          <img
            style={{ width: "99price_single.778b60b2.svg0%" }}
            src={require("../../img/illustrations/price_classic.svg")}
            alt="single product"
          />
        </div>
      </button>

      <button
        className="border-0 relative max-w-7xl mx-auto lg:grid lg:grid-cols-12 lg:px-8 grid-cols-12 rounded-xl bg-white pt-12 pb-8 m-lg-4 p-lg-4 clickable"
        onClick={() => {
          utils.setSnackConfig({
            isOpen: true,
            message:
              "😊 Coming soon, lots of features for you in the roadmap! 🚀",
            severity: "info",
          });
        }}
      >
        <div className="col-span-6 text-left price-editor py-5 px-5">
          <h3 className="pb-2">Compare pricing</h3>
          <h2 className="">Your product has multiple complex options</h2>
          <p className="mt-2">
            You want to offer a table that covers all possible combinations and
            options
          </p>
          <div className="grid grid-cols-12 my-4">
            <div className="col-span-1 max-w-xs">
              <div className="bg-orange-brand p-1 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="white"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div className="col-span-11 px-2 ml-2">
              <h4>Options</h4>
              <p>
                Load product data from Google Spreadsheets or upload it,
                generate custom price plans according to features or metered
                consumption,..
              </p>
            </div>
          </div>
          <div className="grid grid-cols-12 my-4">
            <div className="col-span-1 max-w-xs">
              <div className="bg-orange-brand p-1 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="white"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div className="col-span-11 px-2 ml-2">
              <h4>Features</h4>
              <p>
                Test your price dynamically, offer discounts, 1-2-1 chat with
                customers
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-6 mx-4 mx- my-lg-5">
          <img
            style={{ width: "99price_single.778b60b2.svg0%" }}
            src={require("../../img/illustrations/price_compare.svg")}
            alt="single product"
          />
        </div>
      </button>
    </div>
  );
};

export default PricingPageTypePick;
