// custom react Hooks
// - usually they have their own internal state
import { useEffect, useState } from "react";

const UseBasicForm = (callback, validateBasicForm, savedValues, language) => {
  // savedValues is an object containing the saved values requested to the ddbb
  // IF values THEN set values state with values
  // update state with values (if there are, if not, use default)
  // const [values, setValues] = useState({ productName: "", replyAddress: ""});

  const [values, setValues] = useState(
    savedValues || {
      productName: "",
      replyAddress: "",
      senderName: "",
      discount: "",
      emailSubject: "",
    }
  );
  const [errors, setErrors] = useState({
    productName: "",
    replyAddress: "",
    senderName: "",
    discount: "",
    emailSubject: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "discount") {
      setValues({
        ...values,
        [name]: value,
      });
    } else {
      setValues({
        ...values,
        [name]: {
          ...values[name],
          [language]: value,
        },
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle the errors
    setErrors(validateBasicForm(language, values));
    setIsSubmitting(true);
  };

  // use another hook
  // OBSERVE errors if they are changing
  // Takes two args, callback Fn that triggers when something changes
  // and what we want to observe
  useEffect(() => {
    // check to see if there are no errors
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    // call our callback
  }, [errors, callback, isSubmitting]);

  useEffect(() => {
    setValues(savedValues);
    setIsSubmitting(false);
  }, [savedValues, isSubmitting]);

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  };
};

export default UseBasicForm;
