export const INTRO_DONE_KEY = "tenstars_intro_done";

export const INTRO = {
  stepsEnabled: true,
  initialStep: 0,
  steps: [
    {
      element: ".tenstars-text-logo",
      intro: "Welcome to Tenstars",
    },
    {
      element: ".quickstart",
      intro: "Start retaining customers in 3 simple steps",
    },
  ],
  hintsEnabled: false,
  hints: [],
};
