import React, { useContext, useEffect } from "react";
import ToggleWithTip from "../basic/ToggleWithTip";
import {
  TestModeContext,
  TestModeStoreState,
} from "../../reducer/TestModeStore";
import { updateTestModeAction } from "../../reducer/testModeStoragerReducer";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { STRIPE_TESTING_LINK } from "../../utils/stripeUtils";

interface TestModeToggleProps {
  disabled: boolean;
  visible: boolean;
  onToggle?: (value: boolean) => void;
}

const TestModeToggle = (
  props: TestModeToggleProps
): React.ReactElement | null => {
  const history = useHistory();
  const { testMode, dispatch } = useContext<TestModeStoreState>(
    TestModeContext
  );

  useEffect(() => {
    const value = queryString.parse(history.location.search)["test-mode"];
    const paramTestMode = value === "true";
    const hasExplicitValue = value === "true" || value === "false";

    if (hasExplicitValue && paramTestMode !== testMode) {
      updateContext(paramTestMode);
    } else if (!props.visible) {
      updateContext(false);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const value = queryString.parse(history.location.search)["test-mode"];
    const paramTestMode = value === "true";

    if (paramTestMode !== testMode) {
      updatePath(testMode);
    }
    // eslint-disable-next-line
  }, [testMode]);

  const updatePath = (value: boolean) => {
    const queryParams = queryString.parse(history.location.search);
    const newQueries = {
      ...queryParams,
      "test-mode": value ? "true" : undefined,
    };
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify(newQueries),
    });
  };

  const updateContext = (value: boolean) => {
    dispatch &&
      dispatch({ type: updateTestModeAction, payload: { testMode: value } });
    props.onToggle && props.onToggle(value);
  };

  return (
    <div>
      <ToggleWithTip
        onChange={() => updateContext(!testMode)}
        infoTitle="See test or production data"
        infoDescription={`Switch between test and production results.${
          props.disabled ? " Connect your Stripe account to switch mode." : ""
        }`}
        title="View Test Data"
        value={testMode}
        disabled={props.disabled}
        visible={props.visible}
      />
      {props.visible && (
        <div className="mt-1">
          <a
            className="text-xs font-weight-bold"
            href={STRIPE_TESTING_LINK}
            rel="noopener noreferrer"
            target="_blank"
          >
            What&apos;s Stripe test data?
          </a>
        </div>
      )}
    </div>
  );
};

export default TestModeToggle;
