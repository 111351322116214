import {
  MonthlyMetricRow,
  RevenueAndSubscriptions,
} from "../domain/metricsModel";
import { ChartMode, Rates } from "../domain/DomainModel";
import { CohortData } from "../components/dashboard/Retention/CohortChart";

const getValue = (
  data: RevenueAndSubscriptions,
  currency: string,
  mode: ChartMode,
  groupCurrencies: boolean,
  rates: Rates,
  annual?: boolean
): number => {
  const subscriptions =
    (annual ? data.annualSubscriptions : data?.monthlySubscriptions) || {};
  const revenue = (annual ? data.annualRevenue : data?.monthlyRevenue) || {};

  if (mode === "subscriptions" && !groupCurrencies) {
    return subscriptions[currency] || 0;
  }

  if (mode === "revenue" && !groupCurrencies) {
    return revenue[currency] || 0;
  }

  if (mode === "subscriptions" && groupCurrencies) {
    return Object.values(subscriptions).reduce((a, b) => a + b, 0);
  }

  if (mode === "revenue" && groupCurrencies) {
    return Object.keys(revenue)
      .map((k) => revenue[k] * (rates[k] ? rates[k][currency] : 1))
      .reduce((a, b) => a + b, 0);
  }

  return 0;
};

const getValues = (
  data: RevenueAndSubscriptions[],
  currency: string,
  mode: ChartMode,
  groupCurrencies: boolean,
  rates: Rates
): number[] => {
  return data.map((e) => getValue(e, currency, mode, groupCurrencies, rates));
};

const getAccum = (
  data: RevenueAndSubscriptions[] | undefined,
  currency: string,
  mode: ChartMode,
  groupCurrencies: boolean,
  rates: Rates
): number | undefined => {
  if (!data) {
    return undefined;
  }

  return getValues(data, currency, mode, groupCurrencies, rates).reduce(
    (a, b) => a + b,
    0
  );
};

const getAverage = (
  data: RevenueAndSubscriptions[] | undefined,
  currency: string,
  mode: ChartMode,
  groupCurrencies: boolean,
  rates: Rates
): number | undefined => {
  if (!data) {
    return undefined;
  }

  return (
    getValues(data, currency, mode, groupCurrencies, rates).reduce(
      (a: number, b: number) => a + b,
      0
    ) / data.length || 0
  );
};

const getAverageChurn = (
  data: MonthlyMetricRow[] | undefined,
  currency: string,
  mode: ChartMode,
  groupCurrencies: boolean,
  rates: Rates
): number | undefined => {
  if (!data) {
    return undefined;
  }

  return (
    data
      .map((d) => calculateChurn(d, currency, mode, groupCurrencies, rates))
      .reduce((a: number, b: number) => a + b, 0) / data.length || 0
  );
};

export const calculateChurn = (
  row: MonthlyMetricRow,
  currency: string,
  chartMode: ChartMode,
  convertOthers: boolean,
  rates: Rates
): number => {
  let revenueCancelledDuringTheMonth = getValue(
    row.revenueCancelledDuringTheMonth,
    currency,
    chartMode,
    convertOthers,
    rates
  );
  let revenueCreatedBeforeStartOfTheMonth = getValue(
    row.revenueCreatedBeforeStartOfTheMonth,
    currency,
    chartMode,
    convertOthers,
    rates
  );
  let revenueCancelledAfterEndOfTheMonth = getValue(
    row.revenueCancelledAfterEndOfTheMonth,
    currency,
    chartMode,
    convertOthers,
    rates
  );

  if (
    revenueCreatedBeforeStartOfTheMonth + revenueCancelledAfterEndOfTheMonth ===
    0
  ) {
    return 0;
  }

  return (
    revenueCancelledDuringTheMonth /
    (revenueCreatedBeforeStartOfTheMonth + revenueCancelledAfterEndOfTheMonth)
  );
};

const buildRetentionCohort = (
  data: MonthlyMetricRow[] = [],
  currency: string,
  chartMode: ChartMode,
  convertOthers: boolean,
  rates: Rates
): CohortData => {
  const cohortData: CohortData = { months: {} };

  data.forEach((bm, index) => {
    if (cohortData.months) {
      cohortData.months[bm.month || `Month ${index}`] = getValues(
        bm.cohorts,
        currency,
        chartMode,
        convertOthers,
        rates
      ).map((v) =>
        chartMode === "revenue" ? Number((v / 100).toFixed(2)) : v
      );
    }
  });

  return cohortData;
};

const doDelta = (
  current: number,
  previous: number,
  wasPercentage: boolean = false
): number => {
  if (wasPercentage) {
    return current - previous;
  }

  if (current === 0 && previous === 0) {
    return 0;
  }

  if (current === 0 && previous > 0) {
    return -100;
  }

  return (1 - previous / current) * 100;
};

const growthRate = (
  current?: number,
  previous?: number
): number | undefined => {
  if (previous === undefined || current === undefined) {
    return undefined;
  }

  if (previous === 0) {
    return 0;
  }

  return (current - previous) / previous;
};

const calculateLtv = (
  row: MonthlyMetricRow,
  currency: string,
  convertOthers: boolean,
  rates: Rates,
  churn?: number
) => {
  const churnRate = churn
    ? churn
    : calculateChurn(row, currency, "revenue", convertOthers, rates);

  const totalRevenue = getValue(
    row.revenueActiveAtTheEndOfTheMonth,
    currency,
    "revenue",
    convertOthers,
    rates
  );
  const numUsers = getValue(
    row.revenueActiveAtTheEndOfTheMonth,
    currency,
    "subscriptions",
    convertOthers,
    rates
  );
  const arpu = totalRevenue / numUsers;
  return arpu / churnRate;
};

export default {
  getValue,
  getValues,
  getAccum,
  getAverage,
  calculateChurn,
  getAverageChurn,
  buildRetentionCohort,
  growthRate,
  doDelta,
  calculateLtv,
};
