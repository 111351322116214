import _ from "lodash";

type ContentByLanguage = { "en-gb": Content; "es-es": Content };

export type ReasonsContent = {
  sevenReasonsMainClaim: string;
  sevenReasonsSecondaryClaim: string;
  reasonOneClaim: string;
  reasonOneText: string;
  reasonTwoClaim: string;
  reasonTwoText: string;
  reasonThreeClaim: string;
  reasonThreeText: string;
  reasonFourClaim: string;
  reasonFourText: string;
  reasonFiveClaim: string;
  reasonFiveText: {
    part1: string;
    part2: string;
    linkText: string;
  };
  reasonSixClaim: string;
  reasonSixText: string;
  reasonSevenClaim: string;
};

export type TryNowContent = {
  tryNowButtonAnchor: string;
  tryNowClaim: string;
};

export type Content = {
  path: string;
  mainClaim: {
    part1: string;
    part2: string;
  };
  secondaryClaim: string;
  socialProofClaim: {
    part1: string;
    strong1: string;
    and: string;
    strong2: string;
  };
  HowClaim: string;
  HowDescription: string;
  sectionPriceMainClaim: string;
  sectionPriceSecondaryClaim: string;
  sectionPriceSupportText: {
    text: string;
    linkText: string;
  };
  sectionSurveyMainClaim: string;
  sectionSurveySecondaryClaim: string;
  sectionSurveySupportText: string;
  sectionIncentiveMainClaim: string;
  sectionIncentiveSecondaryClaim: string;
  sectionIncentiveSupportText: string;
  sectionIncentiveButtonAnchor: string;
  sectionFollowUpMainClaim: string;
  sectionFollowUpSecondaryClaim: string;
  sectionFollowUpSupportText: string;
  reasons: ReasonsContent;
  tryNow: TryNowContent;
  PressClaim: string;
  PressText: string;
  FooterClaim: string;
  FooterCompany: string[];
  FeaturesFollowUp: string[];
};

const content: ContentByLanguage = {
  "en-gb": {
    path: "/",
    mainClaim: {
      part1: "Grow your business faster,",
      part2: "increase your Customer Retention & Revenue",
    },
    secondaryClaim:
      "We help you Identify, Engage and Retain the Right Customers",
    socialProofClaim: {
      part1: "Helping awesome companies improve their",
      strong1: "Customer Retention",
      and: "and",
      strong2: "MRR metrics",
    },
    HowClaim: "How do we increase your customer retention?",
    HowDescription:
      "By giving you great tools, to prevent customer churn and to increase your MRR",
    sectionPriceMainClaim: "Find your optimal price",
    sectionPriceSecondaryClaim:
      "Deploy a better copy of your price page, test prices automatically",
    sectionPriceSupportText: {
      text:
        "With just a line of code, you can have your price page. Connected with your Stripe account, ready to start selling, capable of automatic testing of different price points. You define the bracket, we find out what is your ideal price point. Stop guessing and find out how much you can charge. Do you want to see an example?",
      linkText: "we use it to deliver our price page",
    },
    sectionSurveyMainClaim:
      "Capture critical feedback to guide your company roadmap",
    sectionSurveySecondaryClaim:
      "Ask your customers why they do want to cancel their subscription",
    sectionSurveySupportText:
      "Survey your customers on their way out with a personalized pop-up. If the user wants to cancel, he has to answer. We compile the answers and connect the data, providing you with the answer to how much revenue are you losing and why.",
    sectionIncentiveMainClaim: "Apply smart incentives to get better results",
    sectionIncentiveSecondaryClaim:
      "Show your user how important they are. Before it is too late",
    sectionIncentiveSupportText:
      "Being it a discount or a plan upgrade, the important thing is to remind the customer how important she is for you. You can decide to apply your offer only for customers that have passed the trial period, not make the discounts stackable or attach the promotions to particular conditions or commitments.",
    sectionIncentiveButtonAnchor: "I want to see an example",
    sectionFollowUpMainClaim: "Follow up with your churned users",
    sectionFollowUpSecondaryClaim: "Don't give up, you'd be surprised",
    sectionFollowUpSupportText:
      "A follow up can work miracles. A perfect message for each user, regardless where they are, customize it for their language. Personalize your messages to be effective with our variables enabled templates. Effortless engaging thanks to our rules and automation. Get your users back with a single click (magic links).",
    reasons: {
      sevenReasonsMainClaim:
        "6 Reasons to start using our Customer Retention solutions today",
      sevenReasonsSecondaryClaim:
        "You will understand your users better, being able to act in the most important moment",
      reasonOneClaim: "You can make more money every week",
      reasonOneText:
        "There is a high chance your SaaS business is draining money every week. Unless you put a system in place to avoid that from happening, it will stay like that. Take action with our retention tools.",
      reasonTwoClaim: "You will save development time",
      reasonTwoText:
        "You can implement it pretty easily, a line of code and passing it a customer ID and you are pretty much sorted out. Our solution comes ready out of the box.",
      reasonThreeClaim: "You don't need to spend a dime",
      reasonThreeText:
        "Tenstars pays its bill by itself. It saves you revenue every day and it improves your numbers over time, you will get better results the more you use it.",
      reasonFourClaim: "Lifetime money-back guarantee",
      reasonFourText:
        "We are certain we will help you save MRR. If you do not see positive results you will get your money back. No cancellation fees or commitments.",
      reasonFiveClaim: "Should I rather do this myself instead?",
      reasonFiveText: {
        part1:
          "We focus on this for you to focus on what you do best. Having said that, if you want to implement your solution from scratch, you might find it useful checking out our",
        part2:
          "We write about some of the challenges we have faced, testing strategies and what we do to increase the conversion rate and recovery ratios.",
        linkText: "blog on customer retention",
      },
      reasonSixClaim: "Customizable and adapted to your brand",
      reasonSixText:
        "Customer retention and recovery experience are completely integrated with your brand. It is fully and easily customizable.",
      reasonSevenClaim: "Easily integrated with your favourite payment system",
    },
    tryNow: {
      tryNowButtonAnchor: "Start Today for FREE",
      tryNowClaim: "Become the revenue hero of your SaaS!",
    },
    PressClaim: "You might have seen us on",
    PressText:
      "Some sites that might or might not have given us amazing PR. Probably. Somehow.",
    FooterClaim: "Retention tools for SaaS business",
    FooterCompany: ["About us", "Terms of Service", "Privacy policy"],
    FeaturesFollowUp: [
      "Automate your follow up process",
      "Personalized messages",
      "Adjust to your user's language",
      "Built-in templates and variables",
      "Magic Links for quick account recovery",
    ],
  },
  "es-es": {
    path: "/es",
    mainClaim: {
      part1: "Haz crecer tu negocio más rápido.",
      part2: "Mejora tu Retención de Clientes, expande tu facturación.",
    },
    secondaryClaim: "Identifica, gestiona y fideliza a tus mejores clientes",
    socialProofClaim: {
      part1: "Ayudando a grandes y pequeños negocios a",
      strong1: "mejorar sus métricas, retención",
      and: "e",
      strong2: "ingresos",
    },
    HowClaim: "¿Cómo conseguimos incrementar tu retención de clientes?",
    HowDescription:
      "Ponemos en tus manos herramientas automatizadas para prevenir las cancelaciones e incrementar tus ingresos.",
    sectionPriceMainClaim: "Encuentra tu mejor precio",
    sectionPriceSecondaryClaim:
      "Agrega una página de precios a tu sitio, con A/B testing automático de precios",
    sectionPriceSupportText: {
      text:
        "Con solo una línea de código puedes empezar a vender tu producto. Conectado con tu cuenta de Stripe, probamos automáticamente diferentes precios. Tu defines el rango, nosotros encontramos tu precio ideal. Deja de discutir acerca de cuánto cobrar. ¿Quieres ver cómo funciona?",
      linkText: "Nuestra página de precios está servida por nuestro servicio.",
    },
    sectionSurveyMainClaim:
      "Captura información crítica y úsala para guiar tu compañía",
    sectionSurveySecondaryClaim:
      "Lanza un cuestionario personalizado a los usuarios que quieren cancelar",
    sectionSurveySupportText:
      "Capturamos el intento de cancelación, presentando un cuestionario personalizado. Compilamos las respuestas y las conectamos con tus datos, proporcionando visibilidad e impacto a las razones causando las cancelaciones.",
    sectionIncentiveMainClaim:
      "Aplica incentivos inteligentes para conseguir mejores resultados",
    sectionIncentiveSecondaryClaim:
      "Demuestra a tus mejores clientes cuánto te importan, antes de que sea demasiado tarde",
    sectionIncentiveSupportText:
      "Ya sean descuentos o mejoras en los planes al mismo precio, lo importante es recordar a nuestro cliente lo mucho que nos importa. Puedes decidir si aplicar los descuentos solo después del periodo de prueba, no hacerlos apilables o conectarlos con condiciones especiales (como permanencia mínima).",
    sectionIncentiveButtonAnchor: "Quiero ver un ejemplo",
    sectionFollowUpMainClaim:
      "Ponte en contacto con los usuarios que se marchan",
    sectionFollowUpSecondaryClaim: "No te rindas, te sorprenderás",
    sectionFollowUpSupportText:
      "Un poco de seguimiento, demostrando interés puede obrar milagros. Un mensaje perfecto para cada cliente. Personaliza tu mensaje para ser más efectivo, con plantillas, idiomas y variables. Vuelve a conectar con tus clientes sin esfuerzo, automáticamente. Trae a tus usuarios de vuelta en un solo click.",
    reasons: {
      sevenReasonsMainClaim:
        "7 razones para empezar hoy a usar nuestra solución de retención de clientes",
      sevenReasonsSecondaryClaim:
        "Entenderás mejor a tus clientes, pudiendo actuar en el momento más crítico",
      reasonOneClaim: "Podrías generar mas ingresos cada semana",
      reasonOneText:
        "Las posibilidades de que tu negocio esté perdiendo ingresos cada semana es más que probable. Si no pones en marcha un plan de retención de clientes, las cosas no van a cambiar mucho. Crea tu cuenta hoy y prueba sin compromiso.",
      reasonTwoClaim: "Ahorrarás costes de desarrollo",
      reasonTwoText:
        "Nuestra solución se implementa muy fácilmente. Una línea de código, pasarle el subscriptionID y listo.",
      reasonThreeClaim: "No tiene un coste elevado",
      reasonThreeText:
        "Es una herramienta que se paga a sí misma. Salva ingresos recurrentes cada día, mejorando las métricas generales de negocio.",
      reasonFourClaim: "Garantía de devolución, siempre",
      reasonFourText:
        "Estamos seguros de mejorar tu Retorno de inversión. Si no ves resultados positivos te devolvemos tu dinero. No hay cancelaciones o compromisos. También tenemos planes a comisión si no te convence pagar una cuota.",
      reasonFiveClaim:
        "¿Deberíamos montar nuestra propia solución internamente?",
      reasonFiveText: {
        part1:
          "Nuestro foco está en la retención de clientes para que tú puedas centrarte en tu negocio tranquilo. Si de todos modos prefieres construir una solución internamente y desde cero, puedes darte una vuelta por nuestro",
        part2:
          "Escribimos acerca de algunos de los desafíos que nos hemos encontrado, estrategias y pruebas que hacemos para incrementar la conversión y la tasa de retención.",
        linkText: "blog acerca de retención de clientes",
      },
      reasonSixClaim: "Personalizado y adaptado a tu marca",
      reasonSixText:
        "Herramientas de retención de cliente y flujos de salida completamente integrados con tu marca y aplicación.",
      reasonSevenClaim: "Nos integramos con tu sistema de pago favorito",
    },
    tryNow: {
      tryNowButtonAnchor: "Crea tu cuenta GRATIS",
      tryNowClaim: "¡Crece más rápido con nosotros!",
    },
    PressClaim: "Puede que nos hayas visto en...",
    PressText:
      "Algunos de los sitios que nos encantan y pueden o no haber escrito acerca de nosotros",
    FooterClaim:
      "Herramientas de retención y fidelización de clientes para SaaS",
    FooterCompany: [
      "Acerca de nosotros",
      "Términos de servicio",
      "Política de privacidad",
    ],
    FeaturesFollowUp: [
      "Automatiza tu proceso de seguimiento",
      "Mensajes personalizados",
      "Multi-lengua",
      "Con plantillas y variables",
      "Magic Links para rápida recuperación de cuentas",
    ],
  },
};

const getContent = (locale?: string): Content => {
  if (!locale || locale.length < 2) {
    return content["en-gb"];
  }

  const contentInLocale = _.get(content, locale.toLowerCase());

  if (contentInLocale) {
    return contentInLocale;
  }

  const language = Object.keys(content).find(
    (k) => k.substr(0, 2) === locale.substr(0, 2)
  );

  if (language) {
    return _.get(content, language);
  }

  return content["en-gb"];
};

export default getContent;
