import { productLink, subscriptionLink } from "../../utils/linkUtils";
import Avatar from "../common/Avatar";
import React from "react";
import { TenstarsAction } from "../../domain/DomainModel";
import ActionDetails from "./ActionDetails";
import { applyDiscount, toFullCurrency } from "../../utils/formatters";

interface FeedElementProps {
  isLast: boolean;
  event: TenstarsAction;
  pageLoadTime: Date;
  testMode: boolean;
}

const FeedElement = (props: FeedElementProps) => {
  const customerName = (event: TenstarsAction, testMode: boolean) =>
    `<a target="_blank" rel="noopener noreferrer" href="${subscriptionLink(
      event.subscriptionId,
      testMode
    )}"><b>${event.name}</b></a>`;

  const productName = (event: TenstarsAction, testMode: boolean) =>
    event.productName
      ? `<a target="_blank" rel="noopener noreferrer" href="${productLink(
          event.productId,
          testMode
        )}"><b>${event.productName}</b></a> `
      : "";

  const prosaicEvent = (event: TenstarsAction, testMode: boolean): string => {
    switch (event.type) {
      case "recovered":
        return `<p class="pb-2">We <b class="text-blue-600">recovered</b> the ${productName(
          event,
          testMode
        )}subscription of ${customerName(
          event,
          testMode
        )} after offering a discount of <b>${
          event.discount
        }%</b> via an email campaign.</p><p class="pb-2">The new price is <b>${toFullCurrency(
          event.priceInCents
        )} ${event.currency.toUpperCase()}</b></p>`;
      case "saved":
        return `<p class="pb-2">We <b class="text-green-600">saved</b> the ${productName(
          event,
          testMode
        )}subscription of ${customerName(
          event,
          testMode
        )} after offering a discount of <b>${
          event.discount
        }%</b> at the time of the cancellation.</p><p class="pb-2">The cancellation reason was going to be <i>"${
          event.vote
        }"</i>.</><p>The new price will be <b>${applyDiscount(
          event.priceInCents,
          event.discount
        )} ${event.currency.toUpperCase()}</b></p>`;
      case "cancelled":
        return `<p class="pb-2">${customerName(
          event,
          testMode
        )} has <b class="text-red-600">cancelled</b> a ${productName(
          event,
          testMode
        )}subscription after declining a discount of <b>${
          event.discount
        }%</b>.</p><p class="pb-2">The cancellation reason was ${
          event.vote ? `<i>'${event.vote}'</i>` : "not specified"
        }.</p>`;
      default:
        return event.name || "Unknown";
    }
  };

  return (
    <div className="relative pb-8">
      {!props.isLast && (
        <span
          className="absolute ml-2 top-5 left-5 h-full w-0.5 bg-gray-200"
          aria-hidden="true"
        />
      )}
      <div className="relative flex items-start space-x-3">
        <div className="relative pl-1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={subscriptionLink(
              props.event.subscriptionId,
              props.event.testMode
            )}
          >
            <Avatar email={props.event.email} size={48} />
          </a>
        </div>
        <div className="min-w-0 flex-1">
          <div>
            <p className="mt-0.5 text-sm text-gray-500">
              {props.event.createdAt > props.pageLoadTime.getTime() && (
                <span className="badge badge-info mr-2">NEW</span>
              )}
              {new Date(props.event.createdAt).toLocaleString()}
            </p>
            <div
              className="text-sm text-gray-900"
              dangerouslySetInnerHTML={{
                __html: prosaicEvent(props.event, props.testMode),
              }}
            />
          </div>
          <div className="mt-2">
            <ActionDetails event={props.event} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedElement;
