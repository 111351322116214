import ConfigSectionContainer from "../config/ConfigSectionContainer";
import SimpleSnippetSection from "../common/SimpleSnippetSection";
import React from "react";
import { AuthUser, Site } from "../../domain/DomainModel";
import QuickStartStep from "./QuickStartStep";
import StripeConnectButton from "../common/StripeConnectButton";
import ConditionWaiter from "./ConditionWaiter";
import Toggle from "react-toggle";

const snippetTextFunction = (apiKey: string) => {
  return [
    '<div id="tenstars-pricing-page"></div>',
    '<link href="https://tenstars.web.app/default-pricing-style.css" rel="stylesheet">',
    `<script id="tenstars-pricing" src="https://tenstars.web.app/bundled-pricing-script.js?apiKey=${apiKey}"></script>`,
  ];
};

const step2Complete = (site: Site, testMode: boolean): boolean =>
  testMode ? !!site?.credentials?.dev : !!site?.credentials?.prod;

const step3Complete = (site: Site, testMode: boolean): boolean =>
  testMode ? !!site.pricingWidgetActive?.dev : !!site.pricingWidgetActive?.prod;

const QuickstartPricingSection = (props: {
  authUser: AuthUser;
  title: string;
  testMode: boolean;
  testScreenVisible?: boolean;
  setTestScreenVisible: (value: boolean) => void;
}) => (
  <div id={props.testMode ? "test-mode" : "live-mode"} className="relative">
    <ConfigSectionContainer title={props.title}>
      {props.testMode && (
        <div className="my-4">
          <Toggle
            checked={props.testScreenVisible}
            title="Display the development settings"
            disabled={false}
            value="yes"
            onChange={() =>
              props.setTestScreenVisible &&
              props.setTestScreenVisible(!props.testScreenVisible)
            }
            className="align-middle"
          />
          <span className="ml-4 text-lg">Display the development settings</span>
        </div>
      )}
      {(!props.testMode || props.testScreenVisible) && (
        <React.Fragment>
          <QuickStartStep
            key={1}
            stepNumber={1}
            title="Create your pricing page"
            isComplete={true}
          >
            <p className="mt-4 mb-8">You can always come back and update it</p>

            <ConditionWaiter
              waitingText=""
              completeText="Pricing Page designed and customized"
              condition={true}
            />
          </QuickStartStep>
          <QuickStartStep
            key={2}
            stepNumber={2}
            title={
              props.testMode
                ? "Connect your Stripe Account in Test Mode"
                : "Connect your Stripe Account"
            }
            isComplete={step2Complete(props.authUser.site, props.testMode)}
          >
            <p className="mt-4 mb-2">
              You will be taken to your stripe account and will be asked to
              grant us permission to connect it.
            </p>
            {!props.testMode && (
              <p className="my-2">
                If you have a development environment and want to test your
                integration first?{" "}
                <a
                  href="#test-mode"
                  onClick={() => props.setTestScreenVisible(true)}
                >
                  you can do it here.
                </a>
              </p>
            )}
            {!step2Complete(props.authUser.site, props.testMode) && (
              <div className="my-8">
                <StripeConnectButton
                  scope="read_write"
                  testMode={props.testMode}
                  text="Connect to Stripe"
                  redirectAfterSuccess={window.location.href}
                />
              </div>
            )}
            <ConditionWaiter
              waitingText="Connect and grant permissions"
              completeText="Stripe account connected"
              condition={step2Complete(props.authUser.site, props.testMode)}
            />
          </QuickStartStep>
          <QuickStartStep
            key={3}
            stepNumber={3}
            title={
              props.testMode
                ? "Install the Tenstars Pricing Page in your test site"
                : "Install the Tenstars Pricing Page"
            }
            isComplete={step3Complete(props.authUser.site, props.testMode)}
            isLast={true}
          >
            <p className="mt-4 mb-2">
              Copy the code below and paste it in your empty pricing page
            </p>
            {props.authUser.site?.urls?.siteUrl[0] && (
              <p className="mt-4 mb-2">
                We recommend{" "}
                <a
                  href={`${props.authUser.site.urls.siteUrl[0]}/pricing`}
                >{`${props.authUser.site.urls.siteUrl[0]}/pricing`}</a>
              </p>
            )}
            <SimpleSnippetSection
              site={props.authUser.site}
              isTest={props.testMode}
              warningMessage="Publish your changes before copying this code"
              snippetTextFunction={snippetTextFunction}
            />
            <ConditionWaiter
              waitingText="Paste the code and load the page in a browser"
              completeText="Pricing Page embedded to your site"
              condition={step3Complete(props.authUser.site, props.testMode)}
            />
          </QuickStartStep>
        </React.Fragment>
      )}
    </ConfigSectionContainer>
  </div>
);

export default QuickstartPricingSection;
