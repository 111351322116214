import { SharedContext } from "../../common/SharedProvider";
import { useContext } from "react";
import React from "react";
import Toggle from "react-toggle";

interface RetentionProps {
  checked: boolean;
  onToggle: (value: boolean) => Promise<void>;
}

const RetentionSwitch = (props: RetentionProps) => {
  const utils = useContext(SharedContext);

  const onUpdate = async (e: React.ChangeEvent) => {
    const newValue = !props.checked;
    e.persist();
    await props
      .onToggle(newValue)
      .then(() => {
        utils.setSnackConfig({
          isOpen: true,
          message: newValue
            ? "in-App retention enabled"
            : "in-App retention disabled successfully!",
          severity: "success",
        });
      })
      .catch(() => {
        utils.setSnackConfig({
          isOpen: true,
          message: `Failed to ${
            newValue ? "enable" : "disable"
          } in-App retention`,
          severity: "error",
        });
      });
  };

  return (
    <div>
      <p>
        By default, the &quot;Retain customers&quot; in app feature is active.
        <br />
        Here you can deactivate it or configure the survey options.
      </p>
      <div className="config-item-box">
        <span className="config-item">Is the in-App retention active?</span>
        <Toggle
          id="isRetentionActive"
          checked={props.checked}
          value="yes"
          onChange={onUpdate}
          className="align-middle ml-2"
        />
      </div>
    </div>
  );
};

export default RetentionSwitch;
