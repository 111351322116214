import React, { useContext, useEffect, useState } from "react";
import ListQuestions from "./ListQuestions";
import { withAuthorization } from "../session";
import ConfigSectionContainer from "./ConfigSectionContainer";
import LanguageDropdown from "../common/LanguageDropdown";
import "./ConfigSectionSurvey.less";
import { AuthUser, Question, Site } from "../../domain/DomainModel";
import _ from "lodash";
import { SharedContext } from "../common/SharedProvider";
import Firebase from "../../firebase";
import { DEFAULT_LANGUAGE } from "../../domain/InternationalDomain";

interface ConfigSectionSurveyProps {
  site: Site;
  firebase: Firebase;
  selectedLanguage: string;
  updateSelectedLanguage: (language: string) => void;
}

const defaultQuestions: Question[] = [
  { id: 0, en: "A question?", es: "¿Una pregunta?" },
];
const other: Question = { id: 0, en: "Other", es: "Otra" };

const ConfigSectionSurvey = (props: ConfigSectionSurveyProps) => {
  const [questions, setQuestions] = useState<Question[]>(defaultQuestions);

  const utils: any = useContext(SharedContext);

  const site = props.site;

  useEffect(() => {
    if (site?.questions) {
      setQuestions(site.questions);
    }
  }, [site, site.questions]);

  const rewriteIds = (questions: Question[]) => {
    return questions.map((question: Question, i: number) => {
      return {
        ...question,
        id: i,
      };
    });
  };

  const addQuestion = (title: string, id: number) => {
    if (!title) {
      return;
    }

    const index = questions.findIndex((q) => q?.id === id);

    if (index >= 0) {
      _.set(questions[index], props.selectedLanguage, title);
      setQuestions(questions);
    } else {
      let newQuestions = [...questions];
      let newId = newQuestions.length;
      let questionToAdd: Question = { id: newId };
      questionToAdd.id = newId;
      _.set(questionToAdd, props.selectedLanguage, title);

      if (props.selectedLanguage !== DEFAULT_LANGUAGE) {
        _.set(questionToAdd, DEFAULT_LANGUAGE, title);
      }

      newQuestions.splice(newQuestions.length - 1, 1, questionToAdd);
      newQuestions.push({ ...other, id: newQuestions.length });
      setQuestions(rewriteIds(newQuestions));
    }
  };

  const onDelete = (id: number) => {
    const index = questions.findIndex((q) => q?.id === id);

    if (index < 0) {
      return;
    }

    questions.splice(index, 1);

    setQuestions(rewriteIds(questions));
  };

  const updateSite = async () => {
    if (site) {
      props.firebase
        .updateSite(site.key, { questions })
        .then(() => {
          utils.setSnackConfig({
            isOpen: true,
            message: `Your questions have been saved for all languages!`,
            severity: "success",
          });
        })
        .catch(() => {
          utils.setSnackConfig({
            isOpen: true,
            message:
              "There was an error updating your questions. Please try again later.",
            severity: "error",
          });
        });
    }
  };

  return (
    <ConfigSectionContainer title="Define the survey" onSave={updateSite}>
      <div className="row mt-3">
        <div className="col-12 col-md-12 text-xs-center">
          <p>
            We ask your users to give a reason and perhaps elaborate on why they
            need to cancel. <b>Our survey works pretty well</b>, but feel free
            to edit, add or remove questions to find out the reasons behind the
            churning.
          </p>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-12 col-md-8 text-center">
          <div className="survey">
            <div className="d-flex flex-row">
              <LanguageDropdown
                modifier="col-4"
                action={props.updateSelectedLanguage}
                selected={props.selectedLanguage}
              />
            </div>

            <ListQuestions
              language={props.selectedLanguage}
              questions={questions}
              onDelete={onDelete}
              onSubmit={addQuestion}
            />
          </div>
        </div>
      </div>
    </ConfigSectionContainer>
  );
};

const condition = (authUser: AuthUser) => !!authUser;

export default withAuthorization(condition)(ConfigSectionSurvey);
