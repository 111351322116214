const productionConfiguration = {
  apiKey: "AIzaSyCnyUSNeHPxqDiSOLcgQ_ZwTUQOcSjWoaY",
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: "https://tenstars.firebaseio.com/",
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  baseURL_ENV: "https://us-central1-tenstars.cloudfunctions.net/",
};

const developmentConfiguration = {
  apiKey: "AIzaSyCnyUSNeHPxqDiSOLcgQ_ZwTUQOcSjWoaY",
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // databaseURL: "http://localhost:9000/?ns=tenstars", // fails on pooling, not sure why // works with FB:emulators
  databaseURL: "https://tenstars.firebaseio.com/",
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  baseURL_ENV: "http://localhost:5001/tenstars/us-central1/",
};

const config =
  process.env.NODE_ENV === "production"
    ? productionConfiguration
    : developmentConfiguration;

export default config;
