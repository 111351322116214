// A component for a simple btn, with different states
// Active
// Disabled
// Loading
// Loading -> success
// Loading -> failure

import React from "react";
// @ts-ignore missing types
import * as SVGLoaders from "svg-loaders-react";
import classNames from "classnames";

export type ButtonState =
  | "active"
  | "disabled"
  | "loading"
  | "success"
  | "failure";

interface StatefulButtonProps {
  className: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  data: {
    state?: ButtonState;
    text?: string;
  };
  type?: "button" | "submit" | "reset";
}

const StatefulButton = ({
  type,
  data: { state = "active", text } = {},
  className,
  onClick,
}: StatefulButtonProps) => {
  switch (state) {
    case "active":
      return (
        <div>
          <button
            className={classNames(className, "btn btn-primary")}
            onClick={(e) => onClick(e)}
          >
            {text}
          </button>
        </div>
      );
    case "disabled":
      return (
        <div>
          <button
            className={classNames(className, "btn btn-primary disabled")}
            disabled
          >
            {text}
          </button>
        </div>
      );
    case "loading":
      return (
        <div>
          <button
            className={classNames(className, "btn btn-primary")}
            type={type}
          >
            <span className="animated">
              <SVGLoaders.Oval height="14px" className="d-inline-flex" />{" "}
              Loading...
            </span>
          </button>
        </div>
      );
    case "success":
      return (
        <div>
          <button className={classNames(className, "btn btn-primary")}>
            OK!
          </button>
        </div>
      );
    case "failure":
      return (
        <div>
          <button
            className={classNames(className, "btn btn-primary")}
            onClick={(e) => onClick(e)}
          >
            Try again
          </button>
        </div>
      );
    default:
      return null;
  }
};

export default StatefulButton;
