import React, { useContext } from "react";
import {
  TestModeContext,
  TestModeStoreState,
} from "../../reducer/TestModeStore";
import FeedElement from "./FeedElement";
import { TenstarsAction } from "../../domain/DomainModel";

interface ActivityFeedProps {
  events: TenstarsAction[];
  testMode: boolean;
  pageLoadTime: Date;
  isSample: boolean;
}
const ActivityFeed = (props: ActivityFeedProps) => {
  const { testMode } = useContext<TestModeStoreState>(TestModeContext);

  return (
    <div className="m-4 flow-root">
      <ul>
        {props.events
          .filter((e) => props.isSample || e.testMode === props.testMode)
          .map((evt, i) => {
            if (evt.fake) {
              return null;
            }

            return (
              <li key={evt.createdAt + i}>
                <FeedElement
                  testMode={testMode}
                  pageLoadTime={props.pageLoadTime}
                  event={evt}
                  isLast={i === props.events.length - 1}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ActivityFeed;
