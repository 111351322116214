import { productLink, subscriptionLink } from "../../../utils/linkUtils";
import Avatar from "../../../components/common/Avatar";
import classNames from "classnames";
import React from "react";
import { StripeSubscription, StripeProduct } from "../../../domain/StripeModel";
import { underscore2title } from "../../../utils/formatters";

interface SubscriptionCardProps {
  subscription: StripeSubscription;
  testMode: boolean;
}

const SubscriptionCard = (props: SubscriptionCardProps) => {
  const { subscription, testMode } = props;
  const { id, customer, plan } = subscription;
  const { product } = plan;

  const productObject: StripeProduct | undefined =
    typeof product === "string" ? undefined : product;

  return (
    <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
      <div className="flex-1 flex flex-col p-8">
        <a
          className="w-32 h-32 flex-shrink-0 mx-auto bg-black"
          target="_blank"
          rel="noopener noreferrer"
          href={subscriptionLink(id, testMode)}
        >
          <Avatar
            email={typeof customer === "string" ? undefined : customer.email}
            size={128}
          />
        </a>
        <h3 className="mt-6 text-gray-900 text-sm font-medium">
          {typeof customer === "string" ? "Unknown" : customer.name}
        </h3>
        <dd className="mt-2">
          <span
            className={classNames(
              "px-2 py-1 text-xs font-medium rounded-full",
              {
                "bg-red-100 text-red-800": !!subscription.ended_at,
                "bg-green-100 text-green-800": subscription.status === "active",
                "bg-yellow-100 text-yellow-800":
                  subscription.status === "unpaid",
                "bg-blue-100 text-blue-800": subscription.status === "trialing",
              }
            )}
          >
            {underscore2title(subscription.status)}
          </span>
        </dd>
        <dl className="mt-4 flex-grow flex flex-col justify-between">
          {productObject && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={productLink(productObject.id, testMode)}
            >
              <dd className="text-gray-500 text-sm">{productObject.name}</dd>
              {productObject.images.length ? (
                <img
                  className="max-width-60 mt-4 mx-auto"
                  src={productObject.images[0]}
                  alt={productObject.name}
                />
              ) : null}
            </a>
          )}
        </dl>
      </div>
      {/*TODO add real content to this card*/}
      {/*<div>*/}
      {/*	<div className="-mt-px flex divide-x divide-gray-200">*/}
      {/*		<div className="w-0 flex-1 flex">*/}
      {/*			<a href="/"*/}
      {/*			   className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">*/}
      {/*				<svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"*/}
      {/*				     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">*/}
      {/*					<path*/}
      {/*						d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"/>*/}
      {/*					<path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"/>*/}
      {/*				</svg>*/}
      {/*				<span className="ml-3">Email</span>*/}
      {/*			</a>*/}
      {/*		</div>*/}
      {/*		<div className="-ml-px w-0 flex-1 flex">*/}
      {/*			<a href="/"*/}
      {/*			   className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">*/}
      {/*				<svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"*/}
      {/*				     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">*/}
      {/*					<path*/}
      {/*						d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"/>*/}
      {/*				</svg>*/}
      {/*				<span className="ml-3">Call</span>*/}
      {/*			</a>*/}
      {/*		</div>*/}
      {/*	</div>*/}
      {/*</div>*/}
    </li>
  );
};

export default SubscriptionCard;
