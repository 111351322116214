import ButtonGroup from "../basic/ButtonGroup";
import React from "react";
import { ChartMode } from "../../domain/DomainModel";
import { currencySymbols } from "../../domain/InternationalDomain";

interface SubscriptionsOrRevenueProps {
  chartMode: ChartMode;
  updateChartMode: (mode: ChartMode) => void;
  convertOthers: boolean;
  currency: string;
  overrideMrr?: string;
}

const SubscriptionsOrRevenue = (props: SubscriptionsOrRevenueProps) => {
  const revenueText = props.convertOthers
    ? props.overrideMrr || "MRR"
    : `${
        currencySymbols[props.currency] || props.currency.toUpperCase() + " "
      }${props.overrideMrr || "MRR"}`;

  return (
    <ButtonGroup
      onClick={props.updateChartMode}
      active={props.chartMode}
      data={[
        { value: "subscriptions", text: "Subscriptions" },
        { value: "revenue", text: revenueText },
      ]}
    />
  );
};

export default SubscriptionsOrRevenue;
