import { currencySymbols } from "../domain/InternationalDomain";
import _ from "lodash";

export const formatFigure = (
  value: number | undefined,
  unit?: string
): string => {
  if (!unit) {
    return `${formatDecimals(value, 0)}`;
  }

  const symbol = currencySymbols[unit] || unit;

  // TODO make this into a map or a formatter into InternationalDomain
  if (symbol === "€") {
    return `${formatDecimals(value, 0)}${symbol}`;
  } else if (symbol === "%") {
    return `${formatDecimals(value, 1)}${symbol}`;
  } else {
    return `${symbol}${formatDecimals(value, 0)}`;
  }
};

export const formatChurn = (churn?: number): number | undefined => {
  if (churn === undefined) {
    return churn;
  }

  if (churn > 1) {
    return 100;
  }

  return Number((churn * 100).toFixed(1));
};

export const formatDecimals = (
  value?: number | undefined,
  decimals: number = 2
): number | undefined | string => {
  if (value === undefined) {
    return 0;
  }

  return parseFloat(value.toFixed(decimals));
};

export const applyDiscount = (p: number, d: number): string =>
  ((p * (100 - d)) / 10000).toFixed(2);

export const toFullCurrency = (p?: number): string | undefined => {
  if (p === undefined) {
    return undefined;
  }

  return String(formatDecimals(p / 100));
};

export const camel2title = (camelCase: string) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

export const underscore2title = (value: string) =>
  _.capitalize(value.replace("_", " "));
