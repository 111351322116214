import React from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { Link } from 'react-router-dom';
import { REGISTER_PATH } from '../../../App';
import Header from "../../../components/Header/Header";


const AuditSubmissionPage = () =>{
    const { width, height } = useWindowSize()
        return (
            <div className="container-main">
                <Confetti
                    width={width}
                    height={height}
                    recycle={false}
                />
                <Header />
                <div className="container">
                    <div className="my-auto text-center py-3">
                        <span className="text-5xl color-gentle-black">SUCCESS</span>
                        <h3 className="text-2xl color-gentle-black">We will email your results soon!</h3>
                        <Link to={REGISTER_PATH} className="text-lg">Play with Tenstars in the meantime</Link>
                        <img
                                src={require("../../../img/success_illustration.svg")}
                                alt="we will contact you"
                                className="mx-auto my-5 w-4/5 md:w-2/5"
                            />
                    </div>
                </div>
            </div>

            )
}

export default AuditSubmissionPage
