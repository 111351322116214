import React from "react";
import Drawer from "../basic/Drawer";
import { Pricing, PricingTheme } from "../../domain/DomainModel";

interface PricingPageBuilderStyleProps {
  onUpdate: (update: Partial<Pricing>) => void;
  onContinue: () => void;
  themes: PricingTheme[];
}

interface PricingPageBuilderStyleState {
  drawerOpen: boolean;
}

class PricingPageBuilderStyle extends React.Component<
  PricingPageBuilderStyleProps,
  PricingPageBuilderStyleState
> {
  constructor(props: PricingPageBuilderStyleProps) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
  }

  selectTheme = (theme: PricingTheme) => {
    this.props.onUpdate({ theme: theme?.name.toLowerCase().replace(" ", "") });
  };

  render() {
    return (
      <div>
        <div className="flex justify-content-end">
          <button
            onClick={() => this.setState({ drawerOpen: true })}
            className="btn btn-primary pull-right"
          >
            Configure Style
          </button>
          {this.state.drawerOpen && (
            <Drawer
              IsValid={true}
              onClose={() => this.setState({ drawerOpen: false })}
              onContinue={this.props.onContinue}
              title="Configure Page"
              description="Before proceeding, configure the style of your pricing page."
            >
              <p className="mb-2">
                Select the base style from these templates.
              </p>

              <div>
                {this.props.themes.map((item: PricingTheme) => (
                  <button
                    className="w-100 py-2 my-2"
                    key={`a-${item.name.toLowerCase()}`}
                    onClick={(e) => {
                      e.preventDefault();
                      this.selectTheme(item);
                    }}
                  >
                    <div className="flex">
                      <img width={48} src={item.logo} alt={item.name} />
                      <div className="ml-4">
                        <div className="font-semibold mb-1 text-left">
                          {item.name}
                        </div>
                        <div className="text-sm text-left">
                          {item.description}
                        </div>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </Drawer>
          )}
        </div>
      </div>
    );
  }
}

export default PricingPageBuilderStyle;
