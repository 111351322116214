import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/browser";
import App from "./App";
import Firebase, { FirebaseContext } from "./firebase/index";
import * as FullStory from "@fullstory/browser";
import { theme, ThemeProvider } from "@chakra-ui/core";
import { SharedProvider } from "./components/common/SharedProvider";
import "./tailwind.output.css";

// @ts-ignore exceptional
global.$ = global.jQuery = require("jquery");

let fullStoryIsDevMode = false;
let isUserDeveloper = "false";

if (process.env.NODE_ENV !== "production") {
  fullStoryIsDevMode = true;
  isUserDeveloper = "true";
}

FullStory.init({
  orgId: "SCPET",
  debug: true,
  devMode: fullStoryIsDevMode,
});

Sentry.init({
  dsn:
    "https://0149153d3887400e9763b8d2059e8125@o914536.ingest.sentry.io/5853576",
  environment: process.env.NODE_ENV,
});

const tagManagerArgs = {
  gtmId: "GTM-TWVHHSJ",
  dataLayer: {
    staticData: "tenstars",
    isUserDeveloper: isUserDeveloper,
  },
};

TagManager.initialize(tagManagerArgs);

// custom theme chakra components

// Let's say you want to add custom colors
const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brand: {
      900: "#1a365d",
      800: "#153e75",
      700: "#2a69ac",
    },
  },
};

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ThemeProvider theme={customTheme}>
      {/*<CSSReset />*/}
      <SharedProvider>
        <App />
      </SharedProvider>
    </ThemeProvider>
  </FirebaseContext.Provider>,
  document.querySelector("#root")
);
