import React from "react";
import styled from "styled-components";

export default function OngoingPill() {
  return (
    <div className="d-inline-block">
      <Blip>Ongoing</Blip>
    </div>
  );
}

const Blip = styled.span`
  //display: inline-flex;
  border-radius: 9999px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  --tw-bg-opacity: 1;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: rgba(255, 247, 237);
  background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
`;
