import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/common/ScrollToTop";
import { withAuthentication } from "./components/session/index";
import HomePage from "./pages/HomePage/HomePage";
import LegalTermsPage from "./pages/public/LegalTermsPage";
import LegalPrivacy from "./pages/public/PrivacyPolicy";
import AboutUsPage from "./pages/public/AboutUsPage";
import SignInPage from "./pages/public/SignInPage";
import NotFound from "./pages/public/NotFoundPage";
import ConnectStripeMiddleman from "./pages/protected/ConnectStripeMiddleman";
import PasswordForgetForm from "./pages/public/ForgotPasswordPage";
import OnboardingPage from "./pages/onboarding/OnboardingPage";
import RegisterPage from "./pages/onboarding/RegisterPage";
import Footer from "./components/common/Footer";
import GlobalStyles from "./theme/global.js";
import ChurnRateCalculator from "./pages/public/ChurnRateCalculatorPage";

import "./theme/styles.less";
import MainPage from "./pages/protected/MainPage/MainPage";
import NewPricingPage from "./pages/public/NewPricingPage";
import TestModeStore from "./reducer/TestModeStore";
import { HOME_URL } from "./pages/protected/MainPage/resources";
import AuditPage from "./pages/public/audit/AuditPage";
import AuditSubmittedPage from "./pages/public/audit/AuditSubmittedPage";
import { DEFAULT_LANGUAGE } from "./domain/InternationalDomain";
import AppSumo from "./pages/public/AppSumo";
import ReportingDashboard from "./pages/public/ReportingDashboard";
import amplitudeService from "./service/amplitudeService";

// Public routes
export const CALCULATOR_URL = "/churn-rate-calculator";
export const STRIPE_TEST_MODE_REDIRECT_PATH = "/connect-account";
export const STRIPE_LIVE_MODE_REDIRECT_PATH = "/connect-account-production";
export const AUDIT_PATH = "/audit";
export const AUDIT_SUBMITTED_PATH = "/submitted";
export const REGISTER_PATH = "/register";

amplitudeService.init();

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <GlobalStyles />
        <ScrollToTop>
          <TestModeStore>
            <div className="main-container">
              <Switch>
                {/*PUBLIC ROUTES*/}
                <Route path="/" exact component={HomePage} />
                <Route
                  path="/es"
                  exact
                  render={() => <HomePage language="es" />}
                />
                <Route
                  path="/en"
                  exact
                  render={() => <HomePage language={DEFAULT_LANGUAGE} />}
                />
                <Route path="/signin" exact component={SignInPage} />
                <Route
                  path="/password-forget"
                  exact
                  component={PasswordForgetForm}
                />
                <Route path="/onboarding" exact component={OnboardingPage} />
                <Route path={REGISTER_PATH} exact component={RegisterPage} />
                <Route path="/pricing" exact component={NewPricingPage} />
                <Route path="/about-us" exact component={AboutUsPage} />
                <Route
                  path="/features/reporting-dashboard"
                  exact
                  component={ReportingDashboard}
                />
                <Route path="/appsumo" exact component={AppSumo} />
                <Route
                  path="/terms-of-service"
                  exact
                  component={LegalTermsPage}
                />
                <Route path="/privacy-policy" exact component={LegalPrivacy} />
                <Route
                  exact
                  path={CALCULATOR_URL}
                  component={ChurnRateCalculator}
                />
                <Route path={AUDIT_PATH} exact component={AuditPage} />
                <Route
                  path={AUDIT_SUBMITTED_PATH}
                  exact
                  component={AuditSubmittedPage}
                />

                {/*PROTECTED ROUTES*/}
                <Route
                  exact
                  path={STRIPE_TEST_MODE_REDIRECT_PATH}
                  component={ConnectStripeMiddleman}
                />
                <Route
                  exact
                  path={STRIPE_LIVE_MODE_REDIRECT_PATH}
                  component={ConnectStripeMiddleman}
                />
                <Route path={HOME_URL} render={() => <MainPage />} />

                {/*FALLBACK*/}
                <Route component={NotFound} />
              </Switch>
            </div>
          </TestModeStore>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default withAuthentication(App);
