import {
  MonthlyMetricRow,
  MetricsResponse,
  RetentionByMonth,
  StatsResponse,
} from "../../../domain/metricsModel";
import { SingleMetricProps } from "./SingleMetric";
import { calculateDelinquentMrr } from "./calculator";
import { ChartMode, Rates } from "../../../domain/DomainModel";
import statsCalculator from "../../../service/statsCalculator";
import { formatChurn } from "../../../utils/formatters";
import { lastN, secondToLastN } from "../../../utils/ArrayUtils";

export const transformRetentionKpiBlocksData = (
  statsData: StatsResponse | undefined,
  churnData: MetricsResponse | undefined,
  currency: string,
  numMonths: number,
  rates: Rates,
  convertOthers: boolean
): SingleMetricProps[] => {
  const currentStatsData = lastN(numMonths, statsData?.retentionByMonth);
  const currentChurnData = lastN(numMonths, churnData?.byMonth);
  const prevStatsData = secondToLastN(numMonths, statsData?.retentionByMonth);
  const prevChurnData = secondToLastN(numMonths, churnData?.byMonth);

  const calculateMetrics = (
    chartMode: ChartMode,
    stats?: RetentionByMonth[],
    churn?: MonthlyMetricRow[]
  ): {
    revenueLost?: number;
    revenueSaved?: number;
    revenueRecovered?: number;
    averageChurn?: number;
  } => {
    const revenueLost = stats
      ? statsCalculator.getAccum(
          stats.map((e) => e.lost),
          currency,
          chartMode,
          convertOthers,
          rates
        ) || 0
      : undefined;
    const revenueSaved = stats
      ? statsCalculator.getAccum(
          stats.map((e) => e.saved),
          currency,
          chartMode,
          convertOthers,
          rates
        ) || 0
      : undefined;
    const revenueRecovered = stats
      ? statsCalculator.getAccum(
          stats.map((e) => e.recovered),
          currency,
          chartMode,
          convertOthers,
          rates
        ) || 0
      : undefined;
    const averageChurn = churn
      ? statsCalculator.getAverageChurn(
          churn,
          currency,
          chartMode,
          convertOthers,
          rates
        ) || 0
      : undefined;

    return { revenueLost, revenueSaved, revenueRecovered, averageChurn };
  };

  const delinquentMrr = calculateDelinquentMrr(
    currency,
    churnData,
    rates,
    convertOthers,
    "revenue"
  );

  const delinquentSubscriptions = calculateDelinquentMrr(
    currency,
    churnData,
    rates,
    convertOthers,
    "subscriptions"
  );

  const currentMetricsSubscriptions = calculateMetrics(
    "subscriptions",
    currentStatsData,
    currentChurnData
  );
  const currentMetricsRevenue = calculateMetrics(
    "revenue",
    currentStatsData,
    currentChurnData
  );
  const prevMetricsSubscriptions = calculateMetrics(
    "subscriptions",
    prevStatsData,
    prevChurnData
  );
  const prevMetricsRevenue = calculateMetrics(
    "revenue",
    prevStatsData,
    prevChurnData
  );

  return [
    {
      subscriptionMetric: {
        name: "Avg. Churn Rate",
        unit: "%",
        descriptor: `Last ${numMonths} months`,
        figure: formatChurn(currentMetricsSubscriptions.averageChurn),
        prevFigure: formatChurn(prevMetricsSubscriptions.averageChurn),
      },
      revenueMetric: {
        name: "Avg. Churn Rate",
        unit: "%",
        descriptor: `Last ${numMonths} months`,
        figure: formatChurn(currentMetricsRevenue.averageChurn),
        prevFigure: formatChurn(prevMetricsRevenue.averageChurn),
      },
      reverseDelta: true,
      description: `Average Churn Rate of the last ${numMonths} months.`,
      convertOthers,
      currency,
      defaultMode: "revenue",
    },
    {
      subscriptionMetric: {
        name: "Delinquent Subscriptions",
        unit: "",
        descriptor: convertOthers
          ? "today"
          : `for ${currency.toUpperCase()} accounts today`,
        figure: delinquentSubscriptions,
      },
      revenueMetric: {
        name: "Delinquent MRR",
        unit: currency,
        descriptor: convertOthers
          ? "today"
          : `for ${currency.toUpperCase()} accounts today`,
        figure: delinquentMrr === undefined ? undefined : delinquentMrr / 100,
      },

      description: `MRR that has been lost due to failed payments that were not repaid and expired.`,
      convertOthers,
      currency,
      defaultMode: "revenue",
    },
    {
      subscriptionMetric: {
        name: "Subscriptions Saved",
        descriptor: `Last ${numMonths} months`,
        unit: "",
        figure: currentMetricsSubscriptions.revenueSaved,
        prevFigure: prevMetricsSubscriptions.revenueSaved,
      },
      revenueMetric: {
        name: "MRR Saved",
        descriptor: `Last ${numMonths} months`,
        unit: currency,
        figure: currentMetricsRevenue.revenueSaved
          ? currentMetricsRevenue.revenueSaved / 100
          : undefined,
        prevFigure: prevMetricsRevenue.revenueSaved
          ? prevMetricsRevenue.revenueSaved / 100
          : 0,
      },
      description: `Total amount of Monthly Recurring Revenue and subscriptions saved with Tenstars before cancellation during the last ${numMonths} months.`,
      convertOthers,
      currency,
      defaultMode: "revenue",
    },
    {
      subscriptionMetric: {
        name: "Subscriptions Recovered",
        descriptor: `Last ${numMonths} months`,
        unit: "",
        figure: currentMetricsSubscriptions.revenueRecovered,
        prevFigure: prevMetricsSubscriptions.revenueRecovered,
      },
      revenueMetric: {
        name: "MRR Recovered",
        descriptor: `Last ${numMonths} months`,
        unit: currency,
        figure: currentMetricsRevenue.revenueRecovered
          ? currentMetricsRevenue.revenueRecovered / 100
          : undefined,
        prevFigure: prevMetricsRevenue.revenueRecovered
          ? prevMetricsRevenue.revenueRecovered / 100
          : 0,
      },
      description: `Total amount of Monthly Recurring Revenue and subscriptions saved with Tenstars after cancellation during the last ${numMonths} months.`,
      convertOthers,
      currency,
      defaultMode: "revenue",
    },
  ];
};
