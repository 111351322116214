import LanguageDropdown from "../common/LanguageDropdown";
import EmailEditor, { EmailFrame } from "../trumbowyg/EmailEditor";
import ConfigSectionContainer from "./ConfigSectionContainer";
import React, { useContext } from "react";
// @ts-ignore no types :(
import ReactMustache from "react-mustache";
import { BasicRecovery, Site } from "../../domain/DomainModel";
import _ from "lodash";
import { SharedContext } from "../common/SharedProvider";
import {
  AvailableLanguage,
  InternationalizedContent,
} from "../../domain/InternationalDomain";

interface ConfigSectionTemplateProps {
  onSave: () => Promise<void>;
  onLanguageUpdate: (language: AvailableLanguage) => void;
  selectedLanguage: AvailableLanguage;
  emailBody: InternationalizedContent;
  onEmailBodyChange: (html: string) => void;
  site?: Site;
  placeholderValues: BasicRecovery;
}

const ConfigSectionTemplate = (props: ConfigSectionTemplateProps) => {
  const utils: any = useContext(SharedContext);

  const onSave = async () => {
    props
      .onSave()
      .then(() => {
        utils.setSnackConfig({
          isOpen: true,
          message: `Your template has been saved for all languages!`,
          severity: "success",
        });
      })
      .catch(() => {
        utils.setSnackConfig({
          isOpen: true,
          message:
            "There was an error saving your template. Please try again later.",
          severity: "error",
        });
      });
  };

  const flattenBasicRecovery = (
    basicRecovery: BasicRecovery,
    placeholderValues: BasicRecovery,
    language: string
  ) => {
    return {
      productName: basicRecovery.productName
        ? basicRecovery.productName[language]
        : placeholderValues?.productName[language],
      replyAddress: basicRecovery.replyAddress
        ? basicRecovery.replyAddress[language]
        : placeholderValues?.replyAddress[language],
      senderName: basicRecovery.senderName
        ? basicRecovery.senderName[language]
        : placeholderValues?.senderName[language],
      emailSubject: basicRecovery.emailSubject
        ? basicRecovery.emailSubject[language]
        : placeholderValues?.emailSubject[language],
      customerName: basicRecovery.customerName || "John Dow",
      discount: basicRecovery.discount,
      magicLink: "https://tenstars.co/veryfakemagiclinksample",
    };
  };

  return (
    <ConfigSectionContainer title="Recovery email template" onSave={onSave}>
      <p className="mt-3 mb-2">
        Edit the email you want your users to see in this language:
      </p>
      <LanguageDropdown
        modifier="col-4"
        action={props.onLanguageUpdate}
        selected={props.selectedLanguage}
      />
      <p className="mb-2">
        <strong>*Remember to save before changing languages</strong>
      </p>
      <div className="row">
        <div className="mx-2 col-12 col-md-12 col-lg-5">
          <EmailEditor
            emailBody={props.emailBody}
            language={props.selectedLanguage}
            onChange={(e: any) => {
              e.preventDefault();
              props.onEmailBodyChange(e.target.innerHTML);
            }}
          />
        </div>
        <div className="mx-2 col-12 col-md-12 col-lg-6 mb-8">
          <EmailFrame>
            {props.site?.basicRecovery ? (
              <ReactMustache
                template={_.get(
                  props.emailBody,
                  props.selectedLanguage,
                  _.get(props.emailBody, props.selectedLanguage)
                )}
                data={flattenBasicRecovery(
                  props.site.basicRecovery,
                  props.placeholderValues,
                  props.selectedLanguage
                )}
              />
            ) : null}
          </EmailFrame>
        </div>
      </div>
    </ConfigSectionContainer>
  );
};

export default ConfigSectionTemplate;
