import { updateCredentials } from "../api/api";

interface StripeCodeOptions {
  stripeCode?: string;
  token?: string;
  userId?: string;
  siteId?: string;
  onSuccess: () => void;
  onFailure: (data: any) => void;
}

export const saveStripeCode = async ({
  stripeCode,
  token,
  userId,
  siteId,
  onSuccess,
  onFailure,
}: StripeCodeOptions): Promise<void> => {
  if (!stripeCode) {
    console.error("Stripe code is missing");
    return;
  }

  if (!userId || !token || !userId || !siteId) {
    return;
  }

  await updateCredentials(token, stripeCode, userId, siteId)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        onSuccess();
      } else {
        onFailure(response.data);
      }
    })
    .catch((err) => onFailure(err));
};
