import { SharedContext } from "../../common/SharedProvider";
import { useContext } from "react";
import React from "react";
import Toggle from "react-toggle";
import MoreInfoHelper from "../../common/MoreInfoHelper";

interface TrialSwitchProps {
  checked: boolean;
  onToggle: (value: boolean) => Promise<void>;
}

const TrialSwitch = (props: TrialSwitchProps) => {
  const utils = useContext(SharedContext);

  const onUpdate = async (e: React.ChangeEvent) => {
    const newValue = !props.checked;
    e.persist();
    await props
      .onToggle(newValue)
      .then(() => {
        utils.setSnackConfig({
          isOpen: true,
          message: newValue
            ? "Discount offer during trial period enabled successfully!"
            : "Discount offer during trial period disabled successfully!",
          severity: "success",
        });
      })
      .catch(() => {
        utils.setSnackConfig({
          isOpen: true,
          message: `Failed to ${
            newValue ? "enable" : "disable"
          } discount offer during trial period`,
          severity: "error",
        });
      });
  };

  return (
    <div className="config-item-box">
      <span className="config-item">
        Should it apply to users still in trial period?
      </span>
      <MoreInfoHelper
        info={{
          title: "Trial Period consideration",
          description:
            "If the user is still in trial mode, IT WILL NOT offer any incentive unless activated",
        }}
      />
      <Toggle
        id="makeOfferDuringTrialPeriod"
        checked={props.checked}
        value="yes"
        onChange={onUpdate}
        className="align-middle ml-2"
      />
    </div>
  );
};

export default TrialSwitch;
