import React from "react";
import Firebase, { withFirebase } from "../../firebase";
import withAuthorization from "../session/withAuthorization";
import AuthUserContext from "../session/context";
import defaultEmailBodyTemplate from "./DefaultEmailBodyTemplate";
import ConfigSectionContainer from "./ConfigSectionContainer";
import Toggle from "react-toggle";
import ConfigSectionEmailDetails from "./ConfigSectionEmailDetails";
import CollapsibleContainer from "../basic/CollapsibleContainer";
import * as FullStory from "@fullstory/browser";
import "react-toggle/style.css";
import "../forms/ConfigEmail.css";
import ConfigSectionTemplate from "./ConfigSectionTemplate";
import { AuthUser, BasicRecovery, Site } from "../../domain/DomainModel";
import FaqItem from "../basic/FaqItem";
import {
  AvailableLanguage,
  DEFAULT_LANGUAGE,
  InternationalizedContent,
} from "../../domain/InternationalDomain";

const INITIAL_STATE = {
  isLoading: true,
  isComplete: false,
  emailBody: defaultEmailBodyTemplate,
  site: {
    connectedAccount: {},
    basicRecovery: {
      emailBodyTemplate: defaultEmailBodyTemplate,
      productName: {
        en: "",
        es: "",
      },
      senderName: {
        en: "",
        es: "",
      },
      replyAddress: {
        en: "",
        es: "",
      },
      emailSubject: {
        en: "",
        es: "",
      },
    },
  },
};

const placeholderValues: BasicRecovery = {
  retentionDiscount: 20,
  productName: {
    en: "Your product name",
    es: "Tu producto",
  },
  replyAddress: {
    en: "eli_the_ceo@yourcompany.com",
    es: "eli_la_ceo@tucompania.com",
  },
  senderName: {
    en: "Elizabeth Dbrw.",
    es: "Elisa Dbrw.",
  },
  discount: 20,
  emailSubject: {
    en: "Hey, this is Elizabeth, about your cancellation",
    es: "Hola, soy Elisa. Tengo una pregunta acerca de tu cancelación",
  },
  customerName: "John Dow",
};

interface ConfigSectionsResurrectionPageProps {
  site: Site;
  firebase: Firebase;
  authUser: AuthUser;
}

interface ConfigSectionsResurrectionPageState {
  emailBody: InternationalizedContent;
  selectedLanguage: AvailableLanguage;
  site: {
    basicRecovery?: Partial<BasicRecovery>;
    offSite?: boolean;
    key?: string;
  };
  isComplete: boolean;
}

class ConfigSectionsResurrectionPage extends React.Component<
  ConfigSectionsResurrectionPageProps,
  ConfigSectionsResurrectionPageState
> {
  static contextType = AuthUserContext;

  constructor(props: ConfigSectionsResurrectionPageProps) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      selectedLanguage:
        props.authUser?.user.defaultLanguage ||
        (DEFAULT_LANGUAGE as AvailableLanguage),
      site: props.site,
    };
  }

  onEmailBodyChange = (html: string) => {
    const { emailBody, selectedLanguage } = this.state;

    this.setState({
      emailBody: {
        ...emailBody,
        [selectedLanguage]: html,
      },
    });
  };

  onEmailBodySave = async () => {
    const { site, emailBody } = this.state;
    this.setState(
      {
        site: {
          ...site,
          basicRecovery: {
            ...site.basicRecovery,
            emailBodyTemplate: emailBody,
          },
        },
      },
      () => {
        this.props.firebase.updateSite(this.props.site.key, this.state.site);
      }
    );
  };

  onEmailVariablesFormSubmit = async (siteToSave: Site) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        site: {
          ...prevState.site,
          basicRecovery: siteToSave.basicRecovery,
          offSite: prevState.site.offSite || false,
        },
      }),
      () => {
        let siteToSave = Object.assign({}, this.state.site);
        let siteIdString = this.state.site?.key;
        delete siteToSave.key;

        if (!siteIdString) {
          return;
        }

        this.props.firebase.updateSite(siteIdString, siteToSave).then(() => {
          console.log("Site details saved (on submit)!");
          this.setState({ isComplete: true });
        });
      }
    );
  };

  onFeatureToggleChange = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        site: {
          ...prevState.site,
          offSite: !prevState.site.offSite,
        },
      }),
      () => {
        let siteToSave = Object.assign({}, this.state.site);
        let siteIdString = this.state.site?.key;
        delete siteToSave.key;

        if (!siteIdString) {
          return;
        }

        this.props.firebase
          .updateSite(siteIdString, siteToSave)
          .then(() => {
            console.log("Site details saved (on switch)!");
          })
          .catch(() => {
            console.log("Site was not saved, try again");
            this.setState((prevState) => ({
              ...prevState,
              site: {
                ...prevState.site,
                offSite: !prevState.site.offSite,
              },
            }));
          });
      }
    );
  };

  onLanguageUpdate = (selectedLanguage: AvailableLanguage) => {
    this.setState({ selectedLanguage });
  };

  componentDidMount = () => {
    let { email, isAnonymous, metadata, uid } = this.context;

    let userVars = {
      email: email,
      isAnonymous: isAnonymous,
      created: metadata.creationTime,
    };
    FullStory.identify(uid, userVars);

    this.setState({
      site: this.props.site,
      emailBody:
        this.props.site?.basicRecovery?.emailBodyTemplate ||
        defaultEmailBodyTemplate,
    });
  };

  componentDidUpdate = (
    prevProps: ConfigSectionsResurrectionPageProps,
    prevState: ConfigSectionsResurrectionPageState
  ) => {
    if (prevState.site?.key !== this.props.site?.key) {
      this.setState({
        site: this.props.site,
        emailBody:
          this.props.site?.basicRecovery?.emailBodyTemplate ||
          defaultEmailBodyTemplate,
      });
    }
  };

  render() {
    const emailBody =
      this.state.site?.basicRecovery?.emailBodyTemplate ||
      defaultEmailBodyTemplate;

    const { site, selectedLanguage } = this.state;

    return (
      <AuthUserContext.Consumer>
        {() => (
          <>
            <ConfigSectionContainer title="Enable Email Resurrection">
              <p className="mt-3">
                When your application cancels or pauses the Stripe subscription,
                we send automatically an email to your customer. You can add a
                magic link to renew the subscription with a discount. All
                automatic.{" "}
              </p>
              <div className="m-3">
                <Toggle
                  disabled={!site?.hasOwnProperty("offSite")}
                  checked={site?.offSite || false}
                  value="yes"
                  onChange={this.onFeatureToggleChange}
                />
                <br />
                <small>
                  {!site?.hasOwnProperty("offSite")
                    ? "Please save your Email Details below before activating this"
                    : ""}
                </small>
              </div>
            </ConfigSectionContainer>

            <ConfigSectionEmailDetails
              savedValues={this.props.site.basicRecovery}
              placeholderValues={placeholderValues}
              selectedLanguage={selectedLanguage}
              onSubmitForm={this.onEmailVariablesFormSubmit.bind(this)}
              onLanguageChanged={this.onLanguageUpdate.bind(this)}
            />

            <ConfigSectionTemplate
              selectedLanguage={selectedLanguage}
              onLanguageUpdate={this.onLanguageUpdate.bind(this)}
              onEmailBodyChange={this.onEmailBodyChange.bind(this)}
              emailBody={emailBody}
              site={this.props.site}
              placeholderValues={placeholderValues}
              onSave={this.onEmailBodySave.bind(this)}
            />
            <ConfigSectionContainer title="FAQ">
              <div className="col mt-3">
                <p>Some of the most common questions.</p>
                <FaqItem title="How to offer the discount to the customer?">
                  <ul>
                    <li>You add a magic link to your email template</li>
                    <li>Your customer clicks on the link</li>
                    <li>
                      We create the subscription (with the discount applied){" "}
                      <strong>in your Stripe</strong>
                    </li>
                  </ul>
                </FaqItem>
                <FaqItem title="How do I create a magic link?">
                  <ul>
                    <li>
                      Add {"{{magicLink}}"} in the href value, we take care of
                      the rest{" "}
                    </li>
                    <li>
                      <strong>Example:</strong>
                      <br />
                      {"<a href={"}
                      {"{{magicLink}}"}
                      {">Get your discount</a>"}
                    </li>
                  </ul>
                </FaqItem>
                <FaqItem title="How do I use variables in my template?">
                  <ul>
                    <li>
                      To add a variable, just insert it between{" "}
                      <strong>{"{{yourVariable}}"}</strong>
                    </li>
                  </ul>
                </FaqItem>
                <CollapsibleContainer title="How do I know if the email was sent in test mode?">
                  <div className="text-monospace bg-light rounded-sm p-4">
                    <ul>
                      <li>
                        The variable <strong>{"{{testMode}}"}</strong> will be
                        true for test mode
                      </li>
                    </ul>
                  </div>
                </CollapsibleContainer>
              </div>
            </ConfigSectionContainer>
          </>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser: AuthUser) => !!authUser;
export default withFirebase(
  withAuthorization(condition)(ConfigSectionsResurrectionPage)
);
