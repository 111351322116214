import React from "react";
import {
  AuthUserContext,
  withAuthorization,
} from "../../../components/session";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Firebase, { withFirebase } from "../../../firebase";
import AccountHistoryPage from "../AccountHistoryPage";
import TenstarsLogoWithTitle from "../../../components/common/TenstarsLogoWithTitle";
import Loader from "../../../components/icons/Loader";
import ResurrectionPage from "../ResurrectionPage";
import RetentionPage from "../RetentionPage";
import ConfigurationPage from "../ConfigurationPage";
import SubscribersPage from "../Subscriptions/SubscribersPage";
import PublicPricingPageBuilder from "../../public/PublicPricingPageBuilder";
import QuickStartPage from "../QuickStartPage";
import FloatingSideBar from "./FloatingSideBar";
import FixSideBar from "./FixedSideBar";
import HomeHeader from "../../../components/Header/HomeHeader";
import {
  ACCOUNT_HISTORY_URL,
  CONFIGURATION_PAGE_URL,
  menuContent,
  PRICING_PAGE_URL,
  QUICK_START_PAGE_URL,
  RESULTS_PAGE_URL,
  RESURRECTION_PAGE_URL,
  RETENTION_PAGE_URL,
  SUBSCRIBERS_URL,
  toolsMenuContent,
  HOME_URL,
  ACCOUNT_PAGE_URL,
} from "./resources";
import { AuthUser } from "../../../domain/DomainModel";
import AccountPage from "../AccountPage";
import { hasCredentials } from "../../../utils/siteUtils";
import { REGISTER_PATH } from "../../../App";
import { Steps, Hints } from "intro.js-react";
import { INTRO, INTRO_DONE_KEY } from "./Intro";
import "intro.js/introjs.css";
import Dashboard from "../Dashboard/Dashboard";
import { RatesWrapper } from "../../../firebase/RatesContext";

interface MainPageProps {
  firebase: Firebase;
}

const MainPage = (props: MainPageProps & RouteComponentProps) => {
  const [menuClosed, setMenuClosed] = React.useState(true);
  const [showIntro, setShowIntro] = React.useState(
    !localStorage.getItem(INTRO_DONE_KEY)
  );

  const signOut = () => {
    localStorage.clear();
    props.firebase.signOut();
  };

  const onExitIntro = () => {
    localStorage.setItem(INTRO_DONE_KEY, "true");
    setShowIntro(false);
  };

  const renderContent = (authUser: AuthUser | null) => {
    if (!authUser?.site || !authUser?.token) {
      return <Loader />;
    }

    switch (props.location.pathname) {
      case QUICK_START_PAGE_URL:
        return <QuickStartPage site={authUser?.site} />;
      case RESURRECTION_PAGE_URL:
        return <ResurrectionPage authUser={authUser} site={authUser?.site} />;
      case RETENTION_PAGE_URL:
        return <RetentionPage authUser={authUser} site={authUser?.site} />;
      case CONFIGURATION_PAGE_URL:
        return <ConfigurationPage site={authUser?.site} />;
      case ACCOUNT_HISTORY_URL:
        return (
          <AccountHistoryPage site={authUser?.site} token={authUser?.token} />
        );
      case SUBSCRIBERS_URL:
        return (
          <SubscribersPage
            site={authUser?.site}
            token={authUser?.token}
            authUser={authUser?.user}
          />
        );
      case PRICING_PAGE_URL:
        return (
          <PublicPricingPageBuilder
            authUser={authUser}
            site={authUser?.site}
            token={authUser?.token}
          />
        );
      case ACCOUNT_PAGE_URL:
        return <AccountPage authUser={authUser} />;
      case RESULTS_PAGE_URL:
      case HOME_URL:
      default:
        return (
          <Dashboard
            authUser={authUser}
            site={authUser?.site}
            token={authUser?.token}
            menuClosed={menuClosed}
          />
        );
    }
  };

  return (
    <AuthUserContext.Consumer>
      {(authUser: AuthUser | null) => {
        if (
          hasCredentials(authUser?.site) &&
          props.firebase.isUserAnonymous()
        ) {
          props.history.push(REGISTER_PATH);
        }

        return (
          <RatesWrapper token={authUser?.token}>
            {showIntro && (
              <React.Fragment>
                <Steps
                  options={{ hideNext: false }}
                  enabled={INTRO.stepsEnabled}
                  steps={INTRO.steps}
                  initialStep={INTRO.initialStep}
                  onExit={onExitIntro}
                />
                <Hints enabled={INTRO.hintsEnabled} hints={INTRO.hints} />
              </React.Fragment>
            )}
            <div className="h-screen flex overflow-hidden bg-gray-100 main-page">
              <FloatingSideBar
                closed={menuClosed}
                onClose={() => setMenuClosed(true)}
                onSignOut={signOut}
                currentUrl={props.location.pathname}
                tools={toolsMenuContent}
                menu={menuContent}
                site={authUser?.site}
              />
              <FixSideBar
                onSignOut={signOut}
                currentUrl={props.location.pathname}
                tools={toolsMenuContent}
                menu={menuContent}
                site={authUser?.site}
              />
              <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <div className="relative z-10 h-16 bg-white shadow">
                  <button
                    className="p-4 border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                    onClick={() => setMenuClosed(false)}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h7"
                      />
                    </svg>
                  </button>
                  <TenstarsLogoWithTitle
                    white={false}
                    className="py-4 px-2 md:hidden absolute-center"
                  />
                  <HomeHeader email={authUser?.user.email} />
                </div>
                <main
                  className="flex-1 relative overflow-y-auto focus:outline-none"
                  tabIndex={0}
                >
                  <div className="container-main">
                    {renderContent(authUser)}
                  </div>
                </main>
              </div>
            </div>
          </RatesWrapper>
        );
      }}
    </AuthUserContext.Consumer>
  );
};

const condition = (authUser: AuthUser) => !!authUser;
export default withRouter(withFirebase(withAuthorization(condition)(MainPage)));
