import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Color } from "@material-ui/lab/Alert/Alert";
import { SnackbarCloseReason } from "@material-ui/core/Snackbar/Snackbar";

interface SnackConfig {
  message: string;
  autoHideDuration?: number;
  isOpen?: boolean;
  severity?: Color;
}

interface SnackBarMessageProps {
  message: string;
  autoHideDuration?: number;
  isOpen?: boolean;
  severity?: Color;
  setSnackConfig: (config: (previous: SnackConfig) => SnackConfig) => void;
}

interface AlertProps {
  onClose: (event: React.SyntheticEvent) => void;
  children: React.ReactNode | React.ReactNode[];
}

const Alert = (props: AlertProps & SnackBarMessageProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const SnackBarMessage = (props: SnackBarMessageProps) => {
  const handleClose = (
    event: React.SyntheticEvent,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    props.setSnackConfig(
      (prevSnackConfig: SnackConfig): SnackConfig => {
        return {
          ...prevSnackConfig,
          isOpen: false,
        };
      }
    );
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={props.isOpen || false}
        autoHideDuration={props.autoHideDuration || 4000}
        onClose={handleClose}
      >
        <Alert
          {...props}
          onClose={handleClose}
          severity={props.severity || "warning"}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackBarMessage;
