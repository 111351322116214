import { useContext } from "react";
import { SharedContext } from "../../common/SharedProvider";
import React from "react";
import MoreInfoHelper from "../../common/MoreInfoHelper";
import Toggle from "react-toggle";

interface PriceDiffSwitchProps {
  checked: boolean;
  onToggle: (value: boolean) => Promise<void>;
}

const PriceDiffSwitch = (props: PriceDiffSwitchProps): React.ReactElement => {
  const utils = useContext(SharedContext);

  const onUpdate = async (e: React.ChangeEvent) => {
    const newValue = !props.checked;
    e.persist();
    await props
      .onToggle(newValue)
      .then(() => {
        utils.setSnackConfig({
          isOpen: true,
          message: newValue
            ? "Price diff display enabled successfully!"
            : "Price diff display disabled successfully!",
          severity: "success",
        });
      })
      .catch(() => {
        utils.setSnackConfig({
          isOpen: true,
          message: `Failed to ${
            newValue ? "enable" : "disable"
          } price diff display`,
          severity: "error",
        });
      });
  };

  return (
    <div className="config-item-box">
      <span className="config-item">
        Display original vs new discounted price?
      </span>
      <MoreInfoHelper
        info={{
          title: "Price Difference Display",
          description:
            "If enabled, the user will be shown both the original price and the new discounted price in addition to the discounted percentage.",
        }}
      />
      <Toggle
        id="showPriceDifference"
        checked={props.checked}
        value="yes"
        onChange={onUpdate}
        className="align-middle ml-2"
      />
    </div>
  );
};

export default PriceDiffSwitch;
