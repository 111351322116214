import React from "react";
import classNames from "classnames";

export type RowValue = string | null | undefined | number | React.ReactElement;

interface StatsTableProps {
  headers: string[];
  data: RowValue[][];
  selected?: number;
  setSelected?: (value?: number) => void;
  hovered?: number;
  setHovered?: (value?: number) => void;
}

const DataTable = (props: StatsTableProps) => {
  return (
    <div className="w-100 flex flex-col m-auto lg:pl-4 pr-8">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="pt-2 pb-4 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 border">
              <thead className="bg-gray-50">
                <tr>
                  {props.headers.map((h, index) => (
                    <th
                      key={props.headers[index]}
                      scope="col"
                      className="px-6 py-3 text-left text-md font-weight-bold text-gray-500 uppercase tracking-wider"
                    >
                      {props.headers[index]}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {props.data.map((row: RowValue[], index: number) => {
                  const isEven = index % 2 === 0;
                  const iSelected = props.selected === index;
                  const isHovered = props.hovered === index;
                  return (
                    <tr
                      key={`${JSON.stringify(row)}`}
                      className={classNames(
                        { "cursor-pointer": props.setSelected },
                        { "bg-white": !iSelected && !isHovered && isEven },
                        { "bg-gray-50": !iSelected && !isHovered && !isEven },
                        { "bg-blue-200 font-bold": iSelected },
                        { "bg-blue-50": isHovered }
                      )}
                      onClick={() =>
                        props.setSelected &&
                        props.setSelected(
                          props.selected === index ? undefined : index
                        )
                      }
                      onMouseOver={() => {
                        props.setHovered && props.setHovered(index);
                      }}
                      onMouseOut={() => {
                        props.setHovered && props.setHovered(undefined);
                      }}
                    >
                      {row.map((value, index) => (
                        <td
                          key={`${JSON.stringify(row)}_${JSON.stringify(
                            value
                          )}_${new Date().getTime()}_${index}`}
                          className="px-6 py-4 lg:whitespace-nowrap text-md font-medium text-gray-900"
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
