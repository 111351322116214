import React from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import MoreInfoIcon from "../icons/MoreInfoIcon";

export default function MoreInfoHelper({ info, placement = "top" }) {
  const popover = (
    <Popover id="popover-basic">
      {info.title && <Popover.Title as="h3">{info.title}</Popover.Title>}
      <Popover.Content>{info.description}</Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger placement={placement} overlay={popover}>
      <span>
        <MoreInfoIcon _height="16px" _width="16px" />
      </span>
    </OverlayTrigger>
  );
}

MoreInfoHelper.propTypes = {
  info: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string.isRequired,
  }),
};

// export default ExpandInformation;
