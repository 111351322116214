import React from "react";
import ConfigSectionContainer from "./ConfigSectionContainer";
import RetentionWidgetTryButton from "../common/RetentionWidgetTryButton";

interface ConfigSectionTryProps {
  language: string;
  apiKey?: string;
  title?: string;
}

const ConfigSectionTry = (props: ConfigSectionTryProps) => {
  if (!props.apiKey) {
    return null;
  }

  return (
    <ConfigSectionContainer title="Try the retention widget">
      <div className="mt-3">
        <div id="tenstars-widget" />
        <RetentionWidgetTryButton
          apiKey={props.apiKey}
          title={props.title || "Cancel my subscription"}
          language={props.language}
          simulation={true}
        />
      </div>
      <p />
    </ConfigSectionContainer>
  );
};

export default ConfigSectionTry;
