import React from "react";

interface CreateQuestionItemProps {
  onSubmit: (value: string, order: number) => void;
  title: string;
  id: number | string;
  order: number;
}

interface CreateQuestionItemState {
  title: string;
}

const placeholderValue = "Add one more";

class CreateQuestionItem extends React.Component<
  CreateQuestionItemProps,
  CreateQuestionItemState
> {
  constructor(props: CreateQuestionItemProps) {
    super(props);
    this.state = { title: "" };
  }

  onFormSubmit = (e: any) => {
    e.preventDefault();
    this.props.onSubmit(this.state.title, this.props.order);
    this.setState({ title: "" });
  };

  render() {
    return (
      <div className={"question_content"}>
        <form onSubmit={this.onFormSubmit}>
          <div className="row">
            <div className="col-10">
              <div className="form-group">
                <input
                  onChange={(e) => {
                    this.setState({ title: e.target.value });
                  }}
                  onBlur={this.onFormSubmit}
                  value={this.state.title}
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder={placeholderValue}
                />

                <small id="emailHelp" className="form-text text-muted">
                  Press enter to add your questions.
                </small>
              </div>
            </div>
            <div className="col-2" />
          </div>
        </form>
      </div>
    );
  }
}

export default CreateQuestionItem;
