import { Link } from "react-router-dom";
import { CALCULATOR_URL } from "../../App";
import Avatar from "../common/Avatar";
import React from "react";
import { HOME_URL } from "../../pages/protected/MainPage/resources";

interface HeaderSectionProps {
  email?: string;
}

const HomeHeader = (props: HeaderSectionProps) => (
  <div className="p-3 hidden lg:block">
    <div className="flex justify-end mx-4 leading-8">
      <Link to={CALCULATOR_URL} className="px-4 menu-link">
        Churn Rate Calculator
      </Link>
      <a href="/blog" className="px-4 menu-link">
        Customer Retention hacks
      </a>
      <Link to={HOME_URL} className="pl-4">
        <Avatar size={32} email={props.email} />
      </Link>
    </div>
  </div>
);

export default HomeHeader;
