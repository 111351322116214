import TenstarsLogoWithTitle from "../../../components/common/TenstarsLogoWithTitle";
import { Link } from "react-router-dom";
import classNames from "classnames";
import React, { useEffect } from "react";
import { MenuItem, QUICK_START_PAGE_URL } from "./resources";
import CompletionDonut from "../../../components/quickstart/CompletionDonut";
import { countCompleteSteps } from "../../../components/quickstart/QuickstartSection";
import { Site } from "../../../domain/DomainModel";

interface FloatingSideBar {
  closed: boolean;
  onClose: () => void;
  menu: MenuItem[];
  tools: MenuItem[];
  currentUrl: string;
  onSignOut: () => void;
  site?: Site;
}

const FloatingSideBar = (props: FloatingSideBar) => {
  const [completedSteps, setCompletedSteps] = React.useState<number>(1);

  useEffect(() => {
    if (props.site) {
      const newCompletedStepsCount = countCompleteSteps(props.site, false);
      setCompletedSteps(newCompletedStepsCount);
    }
  }, [props.site]);

  if (props.closed) {
    return null;
  }

  return (
    <div className="md:hidden">
      <div className="fixed inset-0 flex z-40">
        <div className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-600 opacity-75" />
        </div>
        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
          <div className="absolute top-0 right-0 mr-2 pt-2">
            <button
              onClick={props.onClose}
              className="ml-1 flex items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <svg
                className="h-8 w-8 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex-shrink-0 flex items-center px-4">
            <TenstarsLogoWithTitle white={true} />
          </div>
          <div className="mt-5 flex-1 h-0 overflow-y-auto">
            <nav className="px-2 space-y-1">
              {props.menu.map((t) => {
                if (t.disabled) {
                  return null;
                }

                return (
                  <Link
                    key={`${t.name}_floating`}
                    to={t.to}
                    onClick={props.onClose}
                    className={classNames(
                      "fill-current text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md sidebar-link",
                      {
                        "fill-current bg-gray-900 text-white":
                          props.currentUrl === t.to,
                      }
                    )}
                  >
                    <div className="mr-4">{t.icon}</div>
                    {t.name}
                  </Link>
                );
              })}

              <div className="space-y-1 mt-5">
                <h3
                  className="px-3 py-2 text-white-50 px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  id="projects-headline"
                >
                  Config
                </h3>
                <div
                  className="space-y-1"
                  role="group"
                  aria-labelledby="projects-headline"
                >
                  {props.tools.map((t) => {
                    if (t.disabled) {
                      return null;
                    }

                    return (
                      <Link
                        key={`${t.name}_floating`}
                        to={t.to}
                        onClick={props.onClose}
                        className={classNames(
                          "px-3 py-2 text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md sidebar-link",
                          {
                            "bg-gray-900 text-white": props.currentUrl === t.to,
                          }
                        )}
                      >
                        {<div className="mr-4">{t.icon}</div>}
                        {t.name}
                        {t.to === QUICK_START_PAGE_URL && props.site && (
                          <CompletionDonut
                            maxSteps={3}
                            completeSteps={completedSteps}
                          />
                        )}
                        {t.beta && (
                          <span className="badge badge-primary ml-2">Beta</span>
                        )}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </nav>
          </div>
          <div className="flex-shrink-0 flex p-3" onClick={props.onSignOut}>
            <a
              href="/"
              className="fill-current text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md sidebar-link"
            >
              <svg
                className="mr-4 h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clipRule="evenodd"
                />
              </svg>
              Sign Out
            </a>
          </div>
        </div>
        <div className="flex-shrink-0 w-14" aria-hidden="true" />
      </div>
    </div>
  );
};

export default FloatingSideBar;
