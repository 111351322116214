import React from "react";
import styled from "styled-components";
import { ParallaxProvider } from "react-scroll-parallax";

import AppPreviewLeftMenu from "./AppPreviewLeftMenu";
import AppPreviewTopMenu from "./AppPreviewTopMenu";
import AppPreviewStatsBasic from "./AppPreviewStatsBasic";
import AppPreviewRetentionEvolution from "./AppPreviewRetentionEvolution";
import AppPreviewCustomer from "./AppPreviewCustomer";

const SUBSCRIPTION_PRICE = 99;

const dataRetention = {
  unit: "$",
  prefix: true,
  labels: ["W1", "W2", "W3", "W4", "W5", "W6", "W7", "W8", "W9", "W10"],
  series: [
    [
      SUBSCRIPTION_PRICE * 2,
      SUBSCRIPTION_PRICE * 3,
      SUBSCRIPTION_PRICE * 3,
      SUBSCRIPTION_PRICE * 4,
      SUBSCRIPTION_PRICE * 4,
      SUBSCRIPTION_PRICE * 4,
      SUBSCRIPTION_PRICE * 5,
      SUBSCRIPTION_PRICE * 6,
      SUBSCRIPTION_PRICE * 6,
      SUBSCRIPTION_PRICE * 7,
    ],
  ],
};

const dataNetRevenue = {
  unit: "$",
  prefix: true,
  labels: ["W1", "W2", "W3", "W4", "W5", "W6", "W7", "W8", "W9", "W10"],
  series: [
    [
      SUBSCRIPTION_PRICE * 50,
      SUBSCRIPTION_PRICE * 52,
      SUBSCRIPTION_PRICE * 54,
      SUBSCRIPTION_PRICE * 59,
      SUBSCRIPTION_PRICE * 58,
      SUBSCRIPTION_PRICE * 64,
      SUBSCRIPTION_PRICE * 67,
      SUBSCRIPTION_PRICE * 70,
      SUBSCRIPTION_PRICE * 75,
      SUBSCRIPTION_PRICE * 76,
    ],
  ],
};

const dataGrowthRate = {
  unit: "%",
  labels: ["W1", "W2", "W3", "W4", "W5", "W6", "W7", "W8", "W9", "W10"],
  series: [[5, 3, 4, 5, 6, 6, 7, 7, 6, 8]],
};

const dataChurnedSubscriptions = {
  unit: "$",
  prefix: true,
  labels: ["W1", "W2", "W3", "W4", "W5", "W6", "W7", "W8", "W9", "W10"],
  series: [
    [
      SUBSCRIPTION_PRICE * 12,
      SUBSCRIPTION_PRICE * 9,
      SUBSCRIPTION_PRICE * 11,
      SUBSCRIPTION_PRICE * 12,
      SUBSCRIPTION_PRICE * 9,
      SUBSCRIPTION_PRICE * 9,
      SUBSCRIPTION_PRICE * 10,
      SUBSCRIPTION_PRICE * 8,
      SUBSCRIPTION_PRICE * 9,
      SUBSCRIPTION_PRICE * 7,
    ],
  ],
};

const AppPreview = () => {
  return (
    <ParallaxProvider>
      <Wrapper className="grid grid-cols-1 mx-auto text-center">
        <BrowserOutline className="shadow-lg">
          <BrowserContent>
            <AppPreviewCustomer />
            <AppContainer>
              <Header>
                <div className="h-100 place-self-center pl-3 pt-3">
                  <img
                    className="h-8 inline-block"
                    src={require("../../img/logo/tenstars.svg")}
                    alt="Tenstars"
                  />
                </div>

                <AppPreviewTopMenu />
              </Header>
              <Application>
                <ApplicationMenu className="w-16">
                  <AppPreviewLeftMenu />
                </ApplicationMenu>
                <div className="mx-auto p-4">
                  <AppPreviewStatsBasic />
                  <div className="grid grid-cols-3 md:grid-cols-2 gap-3 grid-rows-1">
                    <div className="col-span-3 md:col-span-1">
                      <AppPreviewRetentionEvolution
                        title="Retention evolution"
                        data={dataRetention}
                      />
                    </div>
                    <div>
                      <AppPreviewRetentionEvolution
                        className="col-span-1"
                        title="NET revenue"
                        data={dataNetRevenue}
                      />
                    </div>
                  </div>
                  {/* Second row of stats */}
                  <div className="grid grid-cols-3 md:grid-cols-2 gap-3 grid-rows-1">
                    <div className="col-span-3 md:col-span-1">
                      <AppPreviewRetentionEvolution
                        title="Growth Rate"
                        data={dataGrowthRate}
                      />
                    </div>
                    <div>
                      <AppPreviewRetentionEvolution
                        className="col-span-1"
                        title="Price Testing"
                        data={dataChurnedSubscriptions}
                      />
                    </div>
                  </div>
                </div>
              </Application>
            </AppContainer>
          </BrowserContent>
        </BrowserOutline>
      </Wrapper>
    </ParallaxProvider>
  );
};

const Wrapper = styled.div``;
const BrowserOutline = styled.div`
  border: 1px #bdbdbd solid;
  border-radius: 40px;
  max-width: 100%;
  min-height: 768px;
  padding: 56px;
  margin: auto;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
const BrowserContent = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: #f8f8f8;
  min-height: 100%;
  padding: 40px 40px 0 40px;
  box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.1);
`;
const AppContainer = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px 8px 0 0;
  background: white;
  height: 650px;
  overflow: hidden;
`;
const Application = styled.div`
  display: grid;
  grid-template-columns: 64px 1fr;
  height: 650px;
`;
const Header = styled.div`
  border-bottom: 1px #f2f2f2 solid;
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 50px 1fr;
`;
const ApplicationMenu = styled.div`
  //background-color: #fff3cd;
  height: 100%;
  border-right: 1px solid #f2f2f2;
`;

export default AppPreview;
