import { Link } from "react-router-dom";
import classNames from "classnames";
import React from "react";

interface TenstarsLogoWithTitleProps {
  className?: string;
  linkTo?: string;
  white?: boolean;
}

const TenstarsLogoWithTitle = (props: TenstarsLogoWithTitleProps) => (
  <span className={props.className}>
    <Link to={props.linkTo || "/"}>
      <img
        className="h-8 inline-block"
        src={require("../../img/logo/tenstars.svg")}
        alt="Tenstars"
      />
      <span
        className={classNames(
          "ml-2 tenstars-text-logo text-xl inline-block",
          { "text-white": props.white },
          { "color-gentle-black": !props.white }
        )}
      >
        Tenstars
      </span>
    </Link>
  </span>
);

export default TenstarsLogoWithTitle;
