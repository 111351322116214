import { StatsResponse } from "../../../domain/metricsModel";
import { ChartMode, Rates } from "../../../domain/DomainModel";
import { currencySymbols } from "../../../domain/InternationalDomain";
import statsCalculator from "../../../service/statsCalculator";
import { lastN } from "../../../utils/ArrayUtils";
import { LineChartProps } from "../../charts/LineChart";

export const transformRetentionChartData = (
  data: StatsResponse | undefined,
  currency: string,
  chartMode: ChartMode,
  numMonths: number,
  convertOthers: boolean,
  rates: Rates
): LineChartProps => {
  const filteredData = lastN(numMonths, data?.retentionByMonth) || [];

  const saved: number[] = statsCalculator
    .getValues(
      filteredData.map((fd) => fd.saved),
      currency,
      chartMode,
      convertOthers,
      rates
    )
    .map((v) => (chartMode === "revenue" ? v / 100 : v));

  const recovered: number[] = statsCalculator
    .getValues(
      filteredData.map((fd) => fd.recovered),
      currency,
      chartMode,
      convertOthers,
      rates
    )
    .map((v) => (chartMode === "revenue" ? v / 100 : v));

  return {
    data: {
      labels: filteredData.map((c, i) => c.month || `Month ${i + 1}`),
      series: [
        {
          name: "Accounts Saved",
          data: saved,
        },
        {
          name: "Accounts Recovered",
          data: recovered,
        },
        {
          name: "Total Revenue Recovered",
          data: saved.map((s, i) => s + recovered[i]),
        },
      ],
    },
    options: {
      stackBars: true,
      fullWidth: true,
      chartPadding: {
        right: 40,
      },
      axisY: {
        labelInterpolationFnc: (value: number): string | number => {
          if (value < 1000) {
            return chartMode === "subscriptions"
              ? value
              : `${currencySymbols[currency] || "$"}${value}`;
          }

          return chartMode === "subscriptions"
            ? `${value / 1000}k`
            : `${currencySymbols[currency] || "$"}${value / 1000}k`;
        },
      },
    },
  };
};
