import React from "react";
import {
  AvailableLanguage,
  LanguageNames,
} from "../../domain/InternationalDomain";
import classNames from "classnames";

interface LanguageDropdownProps {
  selected: string;
  action: (language: AvailableLanguage) => void;
  showAllOption?: boolean;
  modifier?: string;
}

function LanguageDropdown(props: LanguageDropdownProps) {
  const [value, setValue] = React.useState<string>(props.selected);

  return (
    <select
      value={value}
      onChange={(e) => {
        const language = e.currentTarget.value as AvailableLanguage;
        setValue(e.currentTarget.value);
        props.action(language);
      }}
      className={classNames(
        "browser-default custom-select mb-3",
        props.modifier
      )}
    >
      {Object.keys(LanguageNames).map((k: string) => (
        <option key={k} value={k}>
          {LanguageNames[k]}
        </option>
      ))}
      {props.showAllOption && (
        <option key="all" value="all">
          All
        </option>
      )}
    </select>
  );
}

export default LanguageDropdown;
