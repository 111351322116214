import React from "react";
import styled from "styled-components";

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 inline  mr-2"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

export default function ListFeaturesFollowUp(props) {
  const content = props.content;

  return (
    <Wrapper>
      {content.FeaturesFollowUp.map((feature, index) => (
        <ListItem key={index}>
          <Icon />
          <b>{feature}</b>
        </ListItem>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: block;
`;
const ListItem = styled.span`
  display: flex;
  align-items: center;
  margin-top: 0.6rem;
  padding-top: 8px;
  border-top: 1px solid #f3f3f3;
`;
