import React from "react";
import Header from "../../components/Header/Header";
import AuthUserContext from "../../components/session/context";
import { withFirebase } from "../../firebase";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import SectionReasons from "../../components/homePage/SectionReasons";
import ButtonTry from "../../components/common/RetentionWidgetTryButton";
import TryNow from "../../components/common/TryNow";
import getContent, { Content } from "./content";
// add animation component
import PriceHeroAnimation from "../../components/animations/PriceHeroAnimation";
import InsightsHeroAnimation from "../../components/animations/InsightsHeroAnimation";
import SmartIncentivesHeroAnimation from "../../components/animations/SmartIncentivesHeroAnimation";
import FollowUpAnimation from "../../components/animations/FollowUpAnimation";
import ListFeaturesFollowUp from "../../components/homePage/ListFeaturesFollowUp";
import AppPreview from "../../components/AppPreview/AppPreview";
import BlogSection from "./BlogSection";

interface HomePageProps {
  language?: string;
}

class HomePage extends React.Component<HomePageProps & RouteComponentProps> {
  static contextType = AuthUserContext;
  content: Content;

  constructor(props: HomePageProps & RouteComponentProps) {
    super(props);
    this.content = getContent(this.props.language || navigator?.language);
    this.props.history.push(this.content.path);
  }

  render() {
    return (
      <>
        <div className="container-main homepage">
          <Header />
          <div className="homepage-content">
            <div className="container home-start">
              <div className="row ">
                <div className="col section text-center align-middle fancy-background-light">
                  <h1 className="mt-3 mb-4 font-bold">
                    <span className="relative highlighted_effect">
                      {this.content.mainClaim.part1}
                    </span>
                    <br /> {this.content.mainClaim.part2}
                  </h1>
                  <p className="mb-10">
                    <span className="text-xl">
                      <strong>{this.content.secondaryClaim}</strong>
                    </span>
                  </p>
                  <TryNow buttonText={this.content.tryNow.tryNowButtonAnchor} />
                  <AppPreview />
                  <div className="mt-5 mb-3">
                    <h3 className="mt-20">
                      {this.content.socialProofClaim.part1}{" "}
                      <strong>{this.content.socialProofClaim.strong1}</strong>{" "}
                      {this.content.socialProofClaim.and}{" "}
                      <strong>{this.content.socialProofClaim.strong2}</strong>
                    </h3>
                    <div className="d-flex justify-content-center">
                      <img
                        src={require("../../img/brands_block.png")}
                        alt="Brands working to increase their MRR!"
                        className="d-none d-sm-block img-fluid my-3 px-5 p-2 grayscale"
                      />
                      <img
                        src={require("../../img/brands_mobile.png")}
                        alt="Brands working to increase their MRR!"
                        className="d-block d-sm-none img-fluid my-3 px-1 p-2 grayscale"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col section text-center">
                <h2 className="mb-2 mt-3 font-semibold">
                  {this.content.HowClaim}
                </h2>
                <h3>{this.content.HowDescription}</h3>
              </div>
            </div>

            {/* SECTION - Value: insights */}
            <div className="row section mb-4">
              <div className="col-12 col-md-5 order-md-2 order-lg-2 order-xl-2 pl-md-5">
                <h2 className="mt-0 font-weight-bolder color-gentle-black">
                  {this.content.sectionSurveyMainClaim}
                </h2>
                <h3 className="mt-3 mb-3 color-orange-brand">
                  {this.content.sectionSurveySecondaryClaim}
                </h3>
                <p className="mb-3 text-justify">
                  {this.content.sectionSurveySupportText}
                </p>
              </div>
              <div className="col-12 col-md-7 order-2 order-md-1 order-lg-1 order-xl-1 fancy-background-light">
                <InsightsHeroAnimation />
              </div>
            </div>

            {/* SECTION - Value: Smart incentives */}
            <div className="row section mb-5">
              <div className="col-12 col-md-5 order-md-2 order-lg-2 order-xl-2 pl-md-5">
                <h2 className="mt-0 font-weight-bolder color-gentle-black">
                  {this.content.sectionIncentiveMainClaim}
                </h2>
                <h3 className="mt-3 mb-3 color-orange-brand">
                  {this.content.sectionIncentiveSecondaryClaim}
                </h3>
                <p className="mb-3">
                  {this.content.sectionIncentiveSupportText}
                </p>
                <div className="mt-3">
                  <ButtonTry
                    simulation={true}
                    apiKey="a29fb84852d8f136243663722ce642870d07dbd60695b20c17f1c214520f5a7a6840c329e9c9ab4a9710fdd39ea3609c"
                    title={this.content.sectionIncentiveButtonAnchor}
                  />
                </div>
              </div>
              <div className="col-12 col-md-7 order-2 order-md-1 order-lg-1 order-xl-1">
                <SmartIncentivesHeroAnimation />
              </div>
            </div>

            {/* SECTION - Value: Customer resuscitation */}
            <div className="row section mt-5">
              <div className="col-12 col-md-5 order-md-2 order-lg-2 order-xl-2 pl-md-5 mt-5">
                <h2 className="mt-0 font-weight-bolder color-gentle-black">
                  {this.content.sectionFollowUpMainClaim}
                </h2>
                <h3 className="mt-3 mb-3 color-orange-brand">
                  {this.content.sectionFollowUpSecondaryClaim}
                </h3>
                <p className="mb-3">
                  {this.content.sectionFollowUpSupportText}
                </p>
                <div>
                  <ListFeaturesFollowUp content={this.content} />
                </div>
              </div>
              <div className="col-12 col-md-7 order-2 order-md-1 order-lg-1 order-xl-1 mt-5 flex justify-center md:justify-center -mt-16">
                <FollowUpAnimation />
              </div>
            </div>

            {/* SECTION - Value: Find your ideal price point */}
            <div className="row section mb-5 md:mb-3">
              <div className="col-12 col-md-5 order-md-2 order-lg-2 order-xl-2 pl-md-5">
                <h2 className="mt-0 font-weight-bolder color-gentle-black">
                  {this.content.sectionPriceMainClaim}
                </h2>
                <h3 className="mt-3 mb-3 color-orange-brand">
                  {this.content.sectionPriceSecondaryClaim}{" "}
                  <span className="badge badge-primary ml-2">Beta</span>
                </h3>
                <p className="mb-3">
                  {`${this.content.sectionPriceSupportText.text} `} <br />
                  <Link className="underline" to="/pricing">
                    {this.content.sectionPriceSupportText.linkText}
                  </Link>
                </p>
              </div>
              <div className="col-12 col-md-7 order-2 order-md-1 order-lg-1 order-xl-1 fancy-background-light">
                <PriceHeroAnimation />
              </div>
            </div>

            {/* SECTION - 6 REASONS TO BUY */}
            <SectionReasons content={this.content.reasons} />
            <div className="row mb-md-5 " id="first-step">
              <div className="col-12 col-md-9 mx-auto center-block section text-center">
                <div className="demo-section mx-auto center-block ">
                  <TryNow buttonText={this.content.tryNow.tryNowButtonAnchor} />
                  <h3 className="my-2 d-none d-sm-block">
                    {this.content.tryNow.tryNowClaim}
                  </h3>
                </div>
              </div>
            </div>

            <BlogSection />

            {/* SECTION - Publications that mention us */}

            <div className="row section">
              <div className="col section text-center">
                <h2 className="my-3">{this.content.PressClaim}</h2>
                <h3>{this.content.PressText}</h3>
                <div className="d-flex justify-content-center">
                  <img
                    src={require("../../img/publications.png")}
                    alt="Publications that mention us"
                    className="img-fluid my-3 my-md-5 my-lg-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withFirebase(HomePage));
