import React from "react";
import { RouteComponentProps } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { withRouter } from "react-router-dom";
import { AuthUserContext, withAuthorization } from "../../components/session";
import queryString from "query-string";
import Header from "../../components/Header/Header";
import { AuthUser } from "../../domain/DomainModel";
import { HOME_URL } from "./MainPage/resources";
import {
  REDIRECT_FAILURE_STORAGE_KEY,
  REDIRECT_STORAGE_KEY,
  SKIP_REGISTRATION_STEP,
} from "../../components/common/StripeConnectButton";
import Firebase from "../../firebase";
import { saveStripeCode } from "../../service/stripeService";
import { sendEventToGA } from "../../service/gaService";
import amplitudeService, {
  CONNECT_STRIPE_DEVELOPMENT,
  CONNECT_STRIPE_PRODUCTION,
} from "../../service/amplitudeService";
import { STRIPE_LIVE_MODE_REDIRECT_PATH } from "../../App";

interface ConnectStripeMiddlemanProps {
  firebase: Firebase;
  authUser: AuthUser | null;
}

interface ConnectStripeMiddlemanState {
  stripeConnected?: boolean;
}

// example of received data
// http://f68c734cce00.ngrok.io/connect-account?scope=read_write&code=ac_HOW0sXjAscIp1JczmSxvVEDobcKj99mE
// PARAMS:
// code
// An authorization code you can use in the next call to get an access token for your user. This can only be used once and expires in 5 minutes.
// scope
// read_write or read_only, depending what you passed on the initial GET request.
// state
// The value of the state parameter you provided on the initial GET request.

// we use secret API key as a client_secret POST parameter
// See your keys here: https://dashboard.stripe.com/account/apikeys
class ConnectStripeMiddleman extends React.Component<
  ConnectStripeMiddlemanProps & RouteComponentProps,
  ConnectStripeMiddlemanState
> {
  static contextType = AuthUserContext;

  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);

    if (values.error || values.error_description) {
      const url = sessionStorage.getItem(REDIRECT_FAILURE_STORAGE_KEY);

      if (url) {
        window.location.href = url;
      } else {
        this.props.history.push(HOME_URL);
      }
    }

    if (this.props.authUser?.site) {
      await this.updateStripeCode();
    }
  }

  async componentDidUpdate(
    prevProps: ConnectStripeMiddlemanProps & RouteComponentProps
  ) {
    if (!prevProps.authUser?.site && this.props.authUser?.site) {
      await this.updateStripeCode();
    }
  }

  async updateStripeCode() {
    // url params parsed
    const values = queryString.parse(this.props.location.search);
    const token = await this.props.firebase.getToken();
    const userId = this.props.firebase.getAuth().currentUser?.uid;
    const siteId = this.props.authUser?.site.key;

    await saveStripeCode({
      stripeCode: values.code as string,
      token,
      userId,
      siteId,
      onSuccess: this.onSaveSuccess.bind(this),
      onFailure: this.onSaveFailure.bind(this),
    });
  }

  redirectOnSuccess = () => {
    const successRedirect = sessionStorage.getItem(REDIRECT_STORAGE_KEY);
    if (successRedirect && sessionStorage.getItem(SKIP_REGISTRATION_STEP)) {
      sessionStorage.removeItem(SKIP_REGISTRATION_STEP);
      window.location.href = successRedirect;
    } else {
      this.props.history.push(`/register${this.props.location.search}`);
    }
  };

  onSaveSuccess = () => {
    this.setState({ stripeConnected: true });
    sendEventToGA("onboarding_stripe_end_ok");
    const isProd = window.location.pathname.startsWith(
      STRIPE_LIVE_MODE_REDIRECT_PATH
    );
    amplitudeService
      .getInstance()
      .logEvent(
        isProd ? CONNECT_STRIPE_PRODUCTION : CONNECT_STRIPE_DEVELOPMENT,
        {},
        this.redirectOnSuccess.bind(this)
      );
  };

  onSaveFailure = (error: any) => {
    console.error(error);
    this.setState({ stripeConnected: false });
    alert(
      "something went wrong connecting your account, try later or please, contact support"
    );
    this.props.history.push("/home");
  };

  render() {
    return (
      <div className="container-main">
        <Header />
        <div className="container-loading">
          <div className="row">
            <div className="col-4 p-3">
              <ContentLoader
                speed={2}
                width={476}
                height={224}
                viewBox="0 0 476 224"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="24" y="0" rx="0" ry="0" width="218" height="22" />
                <rect x="24" y="54" rx="0" ry="0" width="218" height="24" />
                <rect x="24" y="97" rx="0" ry="0" width="220" height="25" />
                <rect x="24" y="137" rx="0" ry="0" width="221" height="21" />
                <rect x="24" y="177" rx="0" ry="0" width="216" height="21" />
              </ContentLoader>
            </div>
            <div className="col-8">
              <ContentLoader viewBox="0 0 600 260">
                <rect x="0" y="10" rx="2" ry="2" width="600" height="200" />
              </ContentLoader>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedConnectStripeMiddleman = React.forwardRef<
  ConnectStripeMiddlemanProps & RouteComponentProps
>((props, ref) => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <ConnectStripeMiddleman
        {...(props as ConnectStripeMiddlemanProps & RouteComponentProps)}
        authUser={authUser}
        ref={ref as any}
      />
    )}
  </AuthUserContext.Consumer>
));

export default withRouter(
  // @ts-ignore
  withAuthorization(() => true)(WrappedConnectStripeMiddleman)
);
