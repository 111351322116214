import React, { useState } from "react";
import PropTypes from "prop-types";

export const SharedContext = React.createContext();

export const SharedProvider = ({ children }) => {
  const [SnackConfig, setSnackConfig] = useState({
    isOpen: false,
    snackConfigExample: "something",
  });

  const utilities = {
    SnackConfig,
    setSnackConfig,
  };

  return (
    <SharedContext.Provider value={utilities}>
      {children}
    </SharedContext.Provider>
  );
};

SharedProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
