import React from "react";
import Header from "../../components/Header/Header";
import TenstarsPricingPage from "./TenstarsPricingPage";

const NewPricingPage = () => {

	return (
		<div className="container-main">
			<Header/>
			<div className="mt-12">
				<TenstarsPricingPage
					apiKey="2bc88e00593157b4723597914fcc7908feef82d6f3e7ccd1b707443bc9f89752f7678964ff63f5d1c8d7efc3875f4888"/>
			</div>
			<div className="text-center mb-4">
				* Prices don&apos;t include VAT, which may be added depending on the country where the service recipient has its billing location.
			</div>
			{/* FAQ */}
			<div className="bg-white">
				<div className="max-w-7xl mx-auto py-2 px-4 sm:py-24 sm:px-6 lg:px-8">
					<h2 className="text-3xl font-extrabold text-gray-900 text-center">
						Frequently asked questions
					</h2>
					<div className="mt-12">
						<dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
							<div>
								<dt className="text-lg leading-6 font-medium text-gray-900">
									“What happens after my trial period expires?”
								</dt>
								<dd className="mt-2 text-base text-gray-500">
									Your evaluation account comes with 15 days of the standard account features. <b>Once it passes, your account will be in evaluation mode</b>. It does not break your cancellation flow. When the users try to cancel, we will still launch the survey and collect the answers but you will not be able to offer them an incentive to stay or following up with them.
								</dd>
							</div>
							<div>
								<dt className="text-lg leading-6 font-medium text-gray-900">
									“When will I be charged?”
								</dt>
								<dd className="mt-2 text-base text-gray-500">
									After your trial period passes, we will send you instructions to convert your account to a professional one. <b>You will not lose your data and your survey will remain in place</b>. Pro functionalities will be on hold.
								</dd>
							</div>
							<div>
								<dt className="text-lg leading-6 font-medium text-gray-900">
									“What happens when I reach the limit?”
								</dt>
								<dd className="mt-2 text-base text-gray-500">
									Standard accounts have a monthly threshold of MRR revenue saved. Once you reach the limit your account will work the same. We will contact you and propose a new price plan that we are sure you will be comfortable with.
								</dd>
							</div>
							<div>
								<dt className="text-lg leading-6 font-medium text-gray-900">
									“How does the price page works?”
								</dt>
								<dd className="mt-2 text-base text-gray-500">
									Your dynamic price page is directly connected with your Stripe account. Every charge and interaction goes through your account. <b>You have full control</b>. We help you start your business straight away, find your ideal price and increase your revenue. Easy as that.
								</dd>
							</div>
							<div>
								<dt className="text-lg leading-6 font-medium text-gray-900">
									“Would you integrate with my API?”
								</dt>
								<dd className="mt-2 text-base text-gray-500">
									Do you have specific needs? Do you handle your payments with your proprietary system or have your customer information in a CRM? let&lsquo;s talk. We will evaluate the complexity of the integration and we will discuss together if we are fit for your company.
								</dd>
							</div>
							<div>
								<dt className="text-lg leading-6 font-medium text-gray-900">
									“Could we have a revenue share deal?”
								</dt>
								<dd className="mt-2 text-base text-gray-500">
									If that's what you need, we will work together with you to provide our premium service. Based on a % of revenue saved, instead of a monthly fee. We will run a customer retention audit. We will assess the cost and potential savings when working together.
								</dd>
							</div>
						</dl>
					</div>
				</div>
			</div>
			{/* Contact us for help */}
			<div className="grid grid-cols-1 justify-items-center my-16">
				<div className="price-other-plans w-9/12 row">
					<div className="col-12 col-md-2">
						<div className="my-4">
							<img
								src={require("../../img/support-from-the-boss.png")}
								alt=""
								width="112px"
								height="112px"
								className="float-left rounded-circle"
							/>
						</div>
					</div>
					<div className="col-12 col-md-10">
						<h2 className="text-3xl font-extrabold text-gray-900 mb-3">Let&apos;s have a chat</h2>
						<p>
							Not sure why your users are churning? We will see if our tool is
							right for you.
							<br/>
							Still haven&apos;t figured out your cancellation flow? We&apos;ll
							give you our honest opinion.
							<br/>
							Not super technical? we will give you a hand with the onboarding.
							<br/>
							<br/>
							Write to julio @ tenstars.co and let&apos;s chat!
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NewPricingPage;
