import classNames from "classnames";
import React from "react";
import { withFirebase } from "../../firebase";

interface QuickStartStepProps {
  title: string;
  stepNumber: number;
  children: React.ReactElement;
  isComplete: boolean;
  isLast?: boolean;
}

const QuickStartStep = (props: QuickStartStepProps) => (
  <div className="mt-16 md:mt-8 lg:mt-8">
    <h3>
      <span
        className={classNames(
          "rounded-full h-7 w-7 inline-flex items-center justify-center mr-3 bg-gray-400 text-base text-white",
          { "bg-green-400": props.isComplete }
        )}
      >
        {props.stepNumber}
      </span>
      {props.title}
    </h3>
    <div className="mx-4 md:mx-12 md:px-6">{props.children}</div>
    {!props.isLast && (
      <div className="mt-4 mb-8 border-b-2 border-gray-200 border-dashed" />
    )}
  </div>
);

export default withFirebase(QuickStartStep);
