import React from "react";

import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";

const stats = [
  {
    name: "MRR saved",
    stat: "$7,189",
    previousStat: "7094$",
    change: "12%",
    changeType: "increase",
  },
  {
    name: "Churn",
    stat: "11.6%",
    previousStat: "15.2%",
    change: "-8.4%",
    changeType: "increase",
  },
  {
    name: "Suscriptions",
    stat: "1,078",
    previousStat: "1083",
    change: "4.05%",
    changeType: "decrease",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AppPreviewStatsBasic() {
  return (
    <div className="mt-2">
      <dl className="mt-4 grid grid-cols-1 gap-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.name}
            className="px-4 py-auto sm:p-6 shadow-md border rounded-md col-span-3 lg:col-span-1"
          >
            <dt className="text-base font-normal text-gray-500 text-left">
              {item.name}
              {/* arrow */}
              <div
                className={classNames(
                  item.changeType === "increase"
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800",
                  "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 ml-3 place-self-end"
                )}
              >
                {item.changeType === "increase" ? (
                  <ArrowSmUpIcon
                    className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowSmDownIcon
                    className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                )}

                <span className="sr-only">
                  {item.changeType === "increase" ? "Increased" : "Decreased"}{" "}
                  by
                </span>
                {item.change}
              </div>
              {/*  arrow */}
            </dt>
            <dd className="mt-1 flex-wrap justify-between items-baseline md:block lg:flex">
              {/* Contenido */}
              <div className="flex items-baseline text-2xl md:text-2xl font-semibold text-indigo-600">
                {item.stat}
                <span className="ml-2 text-sm font-medium text-gray-500">
                  from {item.previousStat}
                </span>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
