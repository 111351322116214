import React, { ChangeEvent, FormEvent, useContext, useState } from "react";
import { SharedContext } from "../common/SharedProvider";

interface SetDiscountProps {
  savedValues: { discount?: number };
  onSubmit: (discount: number, callback: () => void) => Promise<void>;
}

const SetDiscount = (props: SetDiscountProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [discount, setDiscount] = useState<number | undefined>(
    props.savedValues.discount
  );
  const utils = useContext(SharedContext);

  const regexConst = new RegExp("^(100|[1-9]?[0-9]|0)$");

  const validateDiscount = (valor: number) => {
    setDiscount(valor);

    if (!valor) {
      setErrorMessage("You need to define a discount");
      return;
    }

    if (!regexConst.test(String(valor))) {
      setErrorMessage("This is not a valid discount");
    } else {
      setErrorMessage("");
    }
  };
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    validateDiscount(parseFloat(e.target.value));
  };

  const submit = (
    e: FormEvent<HTMLFormElement> | ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    if (!errorMessage) {
      props.onSubmit(discount || 0, () =>
        utils.setSnackConfig({
          isOpen: true,
          message: `${discount}% discount saved successfully!`,
          severity: "success",
        })
      );
    }
  };

  return (
    <>
      <form noValidate className="m-3" onSubmit={submit}>
        <div className="col-lg-6 col-12">
          <div className="form-group">
            <label htmlFor="Discount">Discount</label>
            <div className="input-group">
              <input
                id="discount"
                name="discount"
                value={discount || ""}
                onChange={onChange}
                onBlur={submit}
                type="number"
                className={
                  (errorMessage && "form-control is-invalid") || "form-control"
                }
                aria-describedby="discount"
              />
              <div className="input-group-append">
                <span className="input-group-text">%</span>
              </div>
              {errorMessage && (
                <div className="invalid-feedback">{errorMessage}</div>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SetDiscount;
