import React from "react";
import TryNow from "./TryNow";

interface CallToActionProps {
  feature: string;
  featureSupport: string;
}

const CallToAction = (props: CallToActionProps) => {
  return (
    <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 py-6 ">
      <div className="relative mt-12 sm:mt-16">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-1 lg:items-center mb-12 sm:mb-16 lg:mb-24 text-center py-3">
          <span className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            {props.feature || "Get your dashboard for free"}
          </span>
          <br />
          <span className="font-weight-bold text-gray-600 text-2xl">
            {props.featureSupport || "Live Data you will actually love"}
          </span>
          <div className="mt-6">
            <TryNow buttonText="Try this feature" bulletPoints={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
