import { Link } from "react-router-dom";
import React from "react";
import GoogleLogin from "../basic/GoogleLogin";

interface OnboardFromProps {
  onGoogleSubmit?: () => void;
  isUrlValid: boolean;
  isEmailValid: boolean;
  siteUrl: string;
  email: string;
  onChangeUrl: (value: string) => void;
  onChangeEmail: (value: string) => void;
  children: React.ReactElement;
  title: string;
  subtitle: string;
}

const OnboardingForm = (props: OnboardFromProps) => {
  return (
    <div className="col">
      <div className="px-3 px-md-1 px-lg-5 py-auto my-3 mt-5">
        <div className="mb-3 px-3 text-md-left text-sm-center">
          <h2 className="mb-3 px-2 text-lg-plus">
            <strong>{props.title}</strong>
          </h2>
          <h3 className="px-2">{props.subtitle}</h3>
        </div>

        {props.onGoogleSubmit && (
          <div className="flex justify-center my-8">
            <GoogleLogin onClick={props.onGoogleSubmit} />
          </div>
        )}

        <div className="flex justify-center my-8 text-lg font-weight-bold">
          - OR -
        </div>
        <form className="ui form px-md-4">
          <div className="form-group">
            <input
              name="site"
              value={props.siteUrl}
              onChange={(e) => props.onChangeUrl(e.target.value)}
              type="text"
              placeholder={"Your company's url. Ex. https://yahoo.com"}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <input
              name="email"
              value={props.email}
              onChange={(e) => props.onChangeEmail(e.target.value)}
              type="email"
              placeholder="Enter your email"
              className="form-control"
            />
          </div>
          <div>
            {!props.isUrlValid && props.siteUrl && (
              <div className="row p-2">
                <div className="col">
                  <p className="alert alert-warning">
                    Site URL is invalid (must start with https:// )
                  </p>
                </div>
              </div>
            )}
            {!props.isEmailValid && props.email && (
              <div className="row p-2">
                <div className="col">
                  <p className="alert alert-warning">
                    Email address is invalid
                  </p>
                </div>
              </div>
            )}

            <div className="row ml-auto p-2 d-flex">
              <div className="ml-auto p-2">{props.children}</div>
            </div>
          </div>
          <p className="float-right">
            Already have an account? <Link to="/signin">Sign in</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default OnboardingForm;
