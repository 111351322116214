import React, { useContext } from "react";
import UseBasicForm from "../forms/UseBasicForm";
import validate from "../../utils/validateEmailDetailsForm";
import LanguageDropdown from "../common/LanguageDropdown";
import ConfigSectionContainer from "./ConfigSectionContainer";
import { BasicRecovery, Site } from "../../domain/DomainModel";
import { SharedContext } from "../common/SharedProvider";
import { AvailableLanguage } from "../../domain/InternationalDomain";

interface ConfigSectionEmailDetailsProps {
  placeholderValues: BasicRecovery;
  savedValues?: BasicRecovery;
  onSubmitForm: (values: Site) => Promise<void>;
  onLanguageChanged: (language: AvailableLanguage) => void;
  selectedLanguage: string;
}

const ConfigSectionEmailDetails = (props: ConfigSectionEmailDetailsProps) => {
  let savedValues = props.savedValues;
  let placeholderValues = props.placeholderValues;
  const utils: any = useContext(SharedContext);

  const { selectedLanguage } = props;

  const submit = async () => {
    await props
      .onSubmitForm(values)
      .then(() => {
        utils.setSnackConfig({
          isOpen: true,
          message: `Your details have been saved for all languages!`,
          severity: "success",
        });
      })
      .catch(() => {
        utils.setSnackConfig({
          isOpen: true,
          message:
            "There was an error saving your details. Please try again later.",
          severity: "error",
        });
      });
  };

  const { handleChange, handleSubmit, values, errors } = UseBasicForm(
    submit,
    validate,
    savedValues,
    selectedLanguage
  );

  const formComplete = !!(
    values.productName &&
    values.productName[selectedLanguage] &&
    values.senderName &&
    values.senderName[selectedLanguage] &&
    values.discount &&
    values.replyAddress &&
    values.replyAddress[selectedLanguage] &&
    values.emailSubject &&
    values.emailSubject[selectedLanguage]
  );

  return (
    <ConfigSectionContainer
      title="Email Details"
      onSave={submit}
      disabled={!formComplete}
    >
      <p className="mt-3 mb-2">
        Configure your recovery email for this language:
      </p>
      <LanguageDropdown
        modifier="col-4"
        action={(l: AvailableLanguage) => {
          props.onLanguageChanged(l);
        }}
        selected={props.selectedLanguage}
      />
      <p className="mb-2">
        <strong>*Remember to save before changing languages</strong>
      </p>
      <form noValidate className="m-3" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="form-group">
              <label htmlFor="productName">Your product Name</label>
              <input
                id="productName"
                name="productName"
                value={
                  (values.productName &&
                    values.productName[selectedLanguage]) ||
                  ""
                }
                onChange={handleChange}
                placeholder={placeholderValues.productName[selectedLanguage]}
                type="text"
                className={
                  (errors.productName && "form-control is-invalid") ||
                  "form-control"
                }
                aria-describedby="productName"
              />
              {errors.productName && (
                <div className="invalid-feedback">{errors.productName}</div>
              )}
              <small id="emailHelp" className="form-text text-muted">
                &nbsp;
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="senderName">Sender Name</label>
              <input
                id="senderName"
                name="senderName"
                value={
                  (values.senderName && values.senderName[selectedLanguage]) ||
                  ""
                }
                onChange={handleChange}
                placeholder={placeholderValues.senderName[selectedLanguage]}
                type="text"
                className={
                  (errors.senderName && "form-control is-invalid") ||
                  "form-control"
                }
                aria-describedby="senderName"
              />
              {errors.senderName && (
                <div className="invalid-feedback">{errors.senderName}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="Discount">Discount to incentive retention</label>
              <div className="input-group">
                <input
                  id="discount"
                  name="discount"
                  value={values.discount || ""}
                  onChange={handleChange}
                  placeholder={String(placeholderValues.discount || 0)}
                  type="text"
                  className={
                    (errors.discount && "form-control is-invalid") ||
                    "form-control"
                  }
                  aria-describedby="discount"
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
                {errors.discount && (
                  <div className="invalid-feedback">{errors.discount}</div>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-12">
            <div className="form-group">
              <label htmlFor="productName">From (email address)</label>
              <input
                id="replyAddress"
                name="replyAddress"
                value={
                  (values.replyAddress &&
                    values.replyAddress[selectedLanguage]) ||
                  ""
                }
                onChange={handleChange}
                placeholder={placeholderValues.replyAddress[selectedLanguage]}
                type="email"
                className={
                  (errors.replyAddress && "form-control is-invalid") ||
                  "form-control"
                }
                aria-describedby="replyAddress"
              />
              {errors.replyAddress && (
                <div className="invalid-feedback">{errors.replyAddress}</div>
              )}
              <small id="emailHelp" className="form-text text-muted">
                The address you want to use to receive replies
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="emailSubject">Email Subject</label>
              <input
                id="emailSubject"
                name="emailSubject"
                value={
                  (values.emailSubject &&
                    values.emailSubject[selectedLanguage]) ||
                  ""
                }
                onChange={handleChange}
                placeholder={placeholderValues.emailSubject[selectedLanguage]}
                type="text"
                className={
                  (errors.emailSubject && "form-control is-invalid") ||
                  "form-control"
                }
                aria-describedby="emailSubject"
              />
              {errors.emailSubject && (
                <div className="invalid-feedback">{errors.emailSubject}</div>
              )}
            </div>
          </div>
        </div>
      </form>
    </ConfigSectionContainer>
  );
};

export default ConfigSectionEmailDetails;
