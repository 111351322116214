import React, {useContext} from 'react'
import Header from "../../components/Header/Header";
import Firebase, {withFirebase} from '../../firebase';
import {HOME_URL} from "../protected/MainPage/resources";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {redeemCode} from "../../api/api";
import {SharedContext} from "../../components/common/SharedProvider";
import StatefulButton, {ButtonState} from "../../components/basic/StatefulButton";

const AppSumo = ({firebase, history}: { firebase: Firebase } & RouteComponentProps) => {

    const [value, setValue] = React.useState('');
    const [buttonState, setButtonState] = React.useState<ButtonState>('disabled')
    const utils = useContext(SharedContext);

    if (firebase.getAuth().currentUser && !firebase.getAuth().currentUser?.isAnonymous) {
        history.push(HOME_URL);
    }

    const onInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newValue = event.target.value.toUpperCase()
        setValue(newValue)

        if (newValue) {
            setButtonState('active')
        }
    }

    const sendCode = async (): Promise<void> => {
        setButtonState('loading')
        await redeemCode(value, false)
            .then(async (response) => {
                if(response.data.error){
                    utils.setSnackConfig({
                        isOpen: true,
                        message: response?.data?.error || 'An unexpected error occurred. Please try again later.',
                        severity: "error",
                    });
                    setButtonState('active')
                } else {
                    history.push(`/onboarding?code=${response.data.code}`)
                }
            })
            .catch((error) => {
                utils.setSnackConfig({
                    isOpen: true,
                    message: error.response?.data?.error || 'An unexpected error occurred. Please try again later.',
                    severity: "error",
                });
                setButtonState('active')
            })
    }

    return (
        <div className="container-main blue-background-bottom">
            <Header/>
            <div className="m-auto w-50 h-50 pt-20 section">
                <h1>AppSumo Black Friday!</h1>
                <div className="my-8">
                    <strong className="text-lg">Get your yearly accounts with a 66% discount at <a
                        href="https://appsumo.com/products/tenstars-increase-your-customer-retention-revenue/" target="_blank" rel="noopener noreferrer">
                        AppSumo</a>
                    </strong>
                </div>
                <h2 className="mb-3 px-2 text-lg-plus mb-4">
                    <strong>Redeem Code</strong>
                </h2>
                <input
                    className="form-control"
                    onChange={onInput}
                    value={value}
                />
                <StatefulButton onClick={sendCode} data={{text: 'Send', state: buttonState}} className="mt-4"/>
            </div>
        </div>)
}

export default withRouter(withFirebase(AppSumo))
