import React, { ComponentProps } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { toFullCurrency } from "../../../utils/formatters";

type Props = {
  data: ComponentProps<typeof PieChart>["data"];
  selected?: number;
  setSelected: (value?: number) => void;
  hovered?: number;
  setHovered: (value?: number) => void;
};

const isSmall = (percentage: number): boolean => percentage < 15;

function FullOptionPieChart(props: Props) {
  const data = props.data.map((entry, i) => {
    if (props.hovered === i) {
      return {
        ...entry,
        color: "lightgray",
      };
    }
    return entry;
  });

  return (
    <PieChart
      data={data}
      radius={30}
      lineWidth={60}
      segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
      segmentsShift={(index) => (index === props.selected ? 6 : 1)}
      animate
      label={({ x, y, dx, dy, dataEntry }) => {
        const index = data.findIndex(
          (d) => d.title === dataEntry.title && d.value === dataEntry.value
        );

        if (
          (index !== props.selected ||
            (index === props.selected && props.hovered !== undefined)) &&
          index !== props.hovered &&
          isSmall(dataEntry.percentage)
        ) {
          return;
        }

        return (
          <React.Fragment key={dataEntry.title + "_text"}>
            <text
              x={x}
              y={y}
              dx={dx}
              dy={dy}
              dominantBaseline="central"
              textAnchor="middle"
              style={{
                fill: "#000000",
                opacity: 0.75,
                pointerEvents: "none",
                fontSize: "2.2px",
                fontWeight: 700,
              }}
            >
              {`${dataEntry.title}`}
            </text>
            <text
              x={x}
              y={y + 4}
              dx={dx}
              dy={dy}
              dominantBaseline="central"
              textAnchor="middle"
              style={{
                fill: "#000000",
                opacity: 0.5,
                pointerEvents: "none",
                fontSize: "2.0px",
                fontWeight: 600,
              }}
            >
              {dataEntry.percentage.toFixed(2)}% (
              {dataEntry.unit === "users"
                ? `${dataEntry.value} ${
                    dataEntry.value === 1 ? "user" : "users"
                  }`
                : `${toFullCurrency(dataEntry.value)} ${dataEntry.unit}`}
              )
            </text>
          </React.Fragment>
        );
      }}
      labelStyle={() => ({
        fontSize: "1.5px",
      })}
      labelPosition={80}
      onClick={(_, index) => {
        props.setSelected(index === props.selected ? undefined : index);
      }}
      onMouseOver={(_, index) => {
        props.setHovered(index);
      }}
      onMouseOut={() => {
        props.setHovered(undefined);
      }}
    />
  );
}

export default FullOptionPieChart;
