export default function validate(language, values) {
  let errors = {};
  // -> productName
  //  More than X characters
  if (!values.productName[language]) {
    errors.productName = "Product name is required";
  }
  // -> replyAddress
  //  has email
  //  email is well formed
  if (!values.replyAddress[language]) {
    errors.replyAddress = "You need a valid email address for handling replies";
  } else if (
    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.replyAddress[language])
  ) {
    errors.replyAddress = "Email address is invalid";
  }

  // -> senderName
  if (!values.senderName[language]) {
    errors.senderName = "A sender name is required";
  } else if (values.senderName[language].length < 2) {
    errors.senderName = "The name needs to have more than 2 characters";
  }
  // -> discount
  // validate the discount
  // is a numberbetween 1 and 99

  if (!/\b([1-9]|[1-9][0-9])\b/.test(values.discount)) {
    errors.discount = "The discount figure needs to be between 1 and 99";
  }
  // round, coherent with Stripe rules
  if (!/^\+?(0|[1-9]\d*)$/.test(values.discount)) {
    errors.discount = "The discount needs to be a full integer";
  }

  if (!values.discount) {
    errors.discount = "You need a discount value";
  }
  // -> emailSubject
  if (!values.emailSubject[language]) {
    errors.emailSubject = "Your email needs a subject";
  } else if (values.emailSubject[language].length < 2) {
    errors.emailSubject = "Your subject needs to be a bit longer!";
  }
  return errors;
}
