import ConfigPageContainer from "../../components/config/ConfigPageContainer";
import React from "react";
import ConfigSectionSiteUrls from "../../components/config/ConfigSectionSiteUrls";
import { Site } from "../../domain/DomainModel";

const ConfigurationPage = (props: { site: Site }): React.ReactElement => (
  <ConfigPageContainer>
    <ConfigSectionSiteUrls site={props.site} />
  </ConfigPageContainer>
);

export default ConfigurationPage;
