import React from "react";
import { ArrowSmUpIcon } from "@heroicons/react/solid";
import AppPreviewChartExample from "./AppPreviewChartExample";

interface CardProps {
  title: string;
  data: { series: number[][]; labels: string[]; unit: "$"; prefix: boolean };
}

const AppPreviewRetentionEvolution = ({ title, data }: CardProps) => {
  const firstValue = data.series[0][0];
  const lastValue = data.series[0][data.series[0].length - 1];

  return (
    <div className="my-3 border rounded-sm shadow-md py-4 px-2 text-left">
      <span className="place-items-start px-3 text-base font-normal text-gray-500 text-left">
        {title}
      </span>
      {/* Metric and key changes*/}
      <div className="grid grid-cols-4 gap-4 px-3">
        <div className="col-span-3 place-self-start">
          <span className="text-2xl md:text-2xl font-semibold text-gray-500">
            {data.prefix && data.unit}
            {lastValue.toLocaleString("en-US")}
            {!data.prefix && data.unit}
          </span>
          <span className="ml-2 text-sm font-medium text-indigo-600">
            from {data.prefix && data.unit}
            {firstValue.toLocaleString("en-US")}
            {!data.prefix && data.unit}
          </span>
        </div>

        <div className="col-span-1 place-self-end">
          {/* RATIO */}
          <div className="bg-green-100 text-green-800 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
            <ArrowSmUpIcon
              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
            <span className="sr-only">increased by</span>
            {((lastValue / firstValue) * 100).toFixed(2)}%
          </div>
        </div>
      </div>
      {/* Chart */}
      <AppPreviewChartExample data={data} />
    </div>
  );
};

export default AppPreviewRetentionEvolution;
