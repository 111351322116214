import React from "react";
import Firebase, { withFirebase } from "../../../firebase";
import ConfigSectionContainer from "../ConfigSectionContainer";
import SetDiscount from "../../forms/SetDiscount";
import { BasicRecovery, Site } from "../../../domain/DomainModel";
import RetentionSwitch from "./RetentionSwitch";
import PriceDiffSwitch from "./PriceDiffSwitch";
import TrialSwitch from "./TrialSwitch";

const INITIAL_STATE = {
  site: {
    basicRecovery: {
      makeOfferDuringTrialPeriod: false,
    },
    isRetentionActive: false,
  },
};

interface ConfigSectionRetentionProps {
  site?: Site;
  firebase: Firebase;
}

interface ConfigSectionRetentionState {
  site?: { basicRecovery: Partial<BasicRecovery>; isRetentionActive?: boolean };
}

class ConfigSectionRetention extends React.Component<
  ConfigSectionRetentionProps,
  ConfigSectionRetentionState
> {
  constructor(props: ConfigSectionRetentionProps) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const copyOfSite = Object.assign({ basicRecovery: {} }, this.props.site);
    if (this.props.site?.isRetentionActive === undefined) {
      copyOfSite.isRetentionActive = false;
    }
    if (this.props.site?.basicRecovery?.isOpenCommentActive === undefined) {
      copyOfSite.basicRecovery.isOpenCommentActive = false;
    }
    if (
      this.props.site?.basicRecovery?.makeOfferDuringTrialPeriod === undefined
    ) {
      copyOfSite.basicRecovery.makeOfferDuringTrialPeriod = false;
    }
    this.setState({ site: copyOfSite });
  }

  onUpdateRetentionFlag = async (value: boolean): Promise<void> => {
    this.setState(
      (prevState) => ({
        ...prevState,
        site: {
          basicRecovery: {},
          ...prevState.site,
          isRetentionActive: value,
        },
      }),
      () => {
        this.state.site &&
          this.props.site?.key &&
          this.props.firebase.updateSite(this.props.site.key, this.state.site);
      }
    );
  };

  onUpdateTrialToggle = async (value: boolean) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        site: {
          ...prevState.site,
          basicRecovery: {
            ...prevState.site?.basicRecovery,
            makeOfferDuringTrialPeriod: value,
          },
        },
      }),
      () => {
        this.props.site?.key &&
          this.state.site &&
          this.props.firebase.updateSite(this.props.site.key, this.state.site);
      }
    );
  };

  onUpdatePriceSwitchToggle = async (value: boolean) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        site: {
          ...prevState.site,
          basicRecovery: {
            ...prevState.site?.basicRecovery,
            showPriceDifference: value,
          },
        },
      }),
      () => {
        this.props.site?.key &&
          this.state.site &&
          this.props.firebase.updateSite(this.props.site.key, this.state.site);
      }
    );
  };

  onSaveDiscount = async (value: number, callback: () => void) => {
    if (!this.props.site) {
      return;
    }

    this.props.firebase
      .updateSite(this.props.site?.key, {
        "basicRecovery/retentionDiscount": Number(value || "20"),
      })
      .then(() => callback && callback());
  };

  render() {
    return (
      <ConfigSectionContainer title="Configure your retention rules">
        <div className="row mt-3">
          <div className="col">
            <RetentionSwitch
              checked={!!this.state.site?.isRetentionActive}
              onToggle={this.onUpdateRetentionFlag.bind(this)}
            />
            {this.state.site?.isRetentionActive && (
              <div className="config-item-box">
                <SetDiscount
                  savedValues={{
                    discount: this.state.site.basicRecovery?.retentionDiscount,
                  }}
                  onSubmit={this.onSaveDiscount.bind(this)}
                />
              </div>
            )}

            {this.state.site?.isRetentionActive && (
              <PriceDiffSwitch
                checked={!!this.state.site?.basicRecovery?.showPriceDifference}
                onToggle={this.onUpdatePriceSwitchToggle.bind(this)}
              />
            )}

            {this.state.site?.isRetentionActive && (
              <TrialSwitch
                checked={
                  !!this.state.site?.basicRecovery?.makeOfferDuringTrialPeriod
                }
                onToggle={this.onUpdateTrialToggle.bind(this)}
              />
            )}
          </div>
          `
        </div>
      </ConfigSectionContainer>
    );
  }
}

export default withFirebase(ConfigSectionRetention);
