import React, {Component, FormEvent} from "react";
import {Link} from "react-router-dom";
import Firebase, {withFirebase} from "../../firebase";
import Header from "../../components/Header/Header";

const PasswordForgetPage = () => (
	<div className="container-main blue-background-bottom">
		<Header/>
		<div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8 ">
			<div className="grid sm:grid-cols-1 md:grid-cols-2 xl:max-w-none">
				<div className="col"/>
				<div className="col">
					<div className="px-3 px-md-5 py-auto my-3 mt-5">
						<PasswordForgetForm/>
					</div>
				</div>
			</div>
		</div>
	</div>
);

type PasswordForgetFormBaseProps = {
	firebase: Firebase;
}

type PasswordForgetFormBaseState = {
	email?: string;
	error?: Error;
	success?: string;
}

class PasswordForgetFormBase extends Component<PasswordForgetFormBaseProps, PasswordForgetFormBaseState> {
	constructor(props: PasswordForgetFormBaseProps) {
		super(props);
		this.state = {};
	}

	onSubmit = (event: FormEvent<HTMLFormElement>) => {
		const {email} = this.state;

		if (email) {
			event.preventDefault();

			this.props.firebase.passwordReset(email)
				.then(() => {
					this.setState({
						error: undefined,
						email: undefined,
						success: 'An e-mail was sent to your address to reset the password.'
					});
				})
				.catch((error: Error) => {
					this.setState({error});
				});
		}
	};

	render() {
		const {email, error, success} = this.state;

		return (
			<div>
				<div className="mb-3 text-md-left text-sm-center">
					<h2 className="mb-3 text-lg-plus">
						<strong>Forgot your password?</strong>
					</h2>
					<h3 className="">We will send you a link to reset your password</h3>
				</div>
				<form onSubmit={this.onSubmit} className="ui form">
					<div className="form-group">
						<input
							name="email"
							value={this.state.email}
							onChange={(event) => this.setState({email: event.target.value})}
							type="text"
							placeholder="Email Address"
							className="form-control"
						/>
					</div>
					<div className="text-right">
						<button
							disabled={!email}
							type="submit"
							className="btn btn-primary"
						>
							Reset Password
						</button>
					</div>

					{error && <div style={{display: 'block'}} className="invalid-feedback">{error.message}</div>}
					{success && <div style={{display: 'block'}} className="valid-feedback">{success}</div>}
				</form>
			</div>
		);
	}
}

const PasswordForgetLink = () => (

		<p className="mt-2">
			Forgot your password? <Link to="/password-forget">Recover it here</Link>
		</p>
);

export default PasswordForgetPage;
const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
export {PasswordForgetForm, PasswordForgetLink};
