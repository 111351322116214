import React from "react";

interface ConfigSectionContainerProps {
  title?: string;
  children: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  onSave?: () => void;
}

const ConfigSectionContainer = (props: ConfigSectionContainerProps) => (
  <div className="shadow sm:rounded-md sm:overflow-hidden mb-4 mx-2 md:mx-6">
    <div className="bg-white py-6 px-6">
      <div className="py-4 md:pl-4">
        {props.title && <h5 className="mb-8">{props.title}</h5>}
        {props.children}
      </div>
    </div>
    {props.onSave && (
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          type="submit"
          disabled={props.disabled}
          onClick={props.onSave}
          className="btn btn-primary"
        >
          Save
        </button>
      </div>
    )}
  </div>
);

export default ConfigSectionContainer;
