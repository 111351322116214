import React from "react";
import Loader from "../../../components/icons/Loader";
import TestModeToggle from "../../../components/common/TestModeToggle";
import ConfigPageContainer from "../../../components/config/ConfigPageContainer";
import ConfigSectionContainer from "../../../components/config/ConfigSectionContainer";
import { withFirebase } from "../../../firebase";
import { requestSubscriptions } from "../../../api/api";
import ToggleWithTip from "../../../components/basic/ToggleWithTip";
import _ from "lodash";
import { hasCredentials, hasCredentialsFor } from "../../../utils/siteUtils";
import { Site } from "../../../domain/DomainModel";
import { StripeSubscription } from "../../../domain/StripeModel";
import SubscriptionCard from "./SubscriptionCard";
import SimulationTeaser from "../../../components/common/SimulationTeaser";
import { TestModeContext } from "../../../reducer/TestModeStore";
import queryString from "query-string";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { underscore2title } from "../../../utils/formatters";
import AlertInfo from "../../../components/basic/AlertInfo";

interface SubscribersPageProps {
  site?: Site;
  token?: string;
}

interface SubscribersPageState {
  testMode: boolean;
  prodSubscriptions: StripeSubscription[];
  testSubscriptions: StripeSubscription[];
  loadedTest: boolean;
  loadedProd: boolean;
  showEnded: boolean;
  mounted: boolean;
  status: string;
}

class SubscribersPage extends React.Component<
  SubscribersPageProps & RouteComponentProps,
  SubscribersPageState
> {
  constructor(props: SubscribersPageProps & RouteComponentProps) {
    super(props);
    const values = queryString.parse(props.location.search);
    this.state = {
      prodSubscriptions: [],
      testSubscriptions: [],
      testMode: false,
      loadedTest: false,
      loadedProd: false,
      showEnded: values.status !== "active" && values.status !== "trialing",
      mounted: true,
      status: (values.status || "all") as string,
    };
  }

  static contextType = TestModeContext;

  componentDidMount = async () => {
    const testMode = this.context.testMode;

    if (this.props.site && this.props.token) {
      await this.fetchSubscriptions(testMode);
    }

    this.setState({ testMode });
  };

  componentWillUnmount() {
    this.setState({ mounted: false });
    this.context = null;
  }

  componentDidUpdate = async (
    prevProps: SubscribersPageProps,
    prevState: SubscribersPageState
  ) => {
    if (
      this.props.token &&
      ((!prevProps.site && this.props.site) ||
        prevState.testMode !== this.state.testMode)
    ) {
      await this.fetchSubscriptions(this.state.testMode);
    }
  };

  async fetchSubscriptions(testMode: boolean): Promise<void> {
    if (!this.props.site || !this.props.token) {
      return;
    }

    this.state.testMode &&
      this.setState({ loadedTest: false, testSubscriptions: [] });
    !this.state.testMode &&
      this.setState({ loadedProd: false, prodSubscriptions: [] });

    return requestSubscriptions(
      this.props.token,
      this.props.site.key,
      testMode,
      this.state.status
    )
      .then((subscriptions) => {
        if (!this.state.mounted) {
          return;
        }

        const sortedSubscriptions = _.orderBy(
          subscriptions.data,
          ["created"],
          ["desc"]
        );
        if (testMode) {
          this.setState({
            testSubscriptions: sortedSubscriptions,
            loadedTest: true,
          });
        } else {
          this.setState({
            prodSubscriptions: sortedSubscriptions,
            loadedProd: true,
          });
        }
      })
      .catch((e) => {
        if (!this.state.mounted) {
          return;
        }

        console.error(e);

        if (testMode) {
          this.setState({ testSubscriptions: [], loadedTest: true });
        } else {
          this.setState({ prodSubscriptions: [], loadedProd: true });
        }
      });
  }

  renderContent(subscriptionsForType: StripeSubscription[]) {
    const { loadedTest, loadedProd, testMode, showEnded } = this.state;

    if ((!loadedTest && testMode) || (!loadedProd && !testMode)) {
      return <Loader />;
    }

    return (
      <div>
        <SimulationTeaser
          text="Connect your Stripe account to see your site's subscriptions."
          testMode={testMode}
          disabled={hasCredentialsFor(testMode, this.props.site)}
        />
        <>
          {!subscriptionsForType.length &&
            hasCredentialsFor(testMode, this.props.site) && (
              <AlertInfo text="The data in this view is a sample. Your customers' data will appear here once your site has at least one subscription." />
            )}
        </>
        <div className="my-8">
          <ToggleWithTip
            onChange={() => this.setState({ showEnded: !this.state.showEnded })}
            disabled={false}
            infoTitle="Include cancelled subscriptions"
            infoDescription="Enable to see also subscriptions that have already ended either by expiration or cancellation."
            title="Include Ended Subscriptions"
            value={this.state.showEnded}
            visible={!!subscriptionsForType.length}
          />
        </div>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-4">
          {subscriptionsForType
            .filter(
              (ts: StripeSubscription) =>
                !ts.ended_at || (ts.ended_at && showEnded)
            )
            .map((ts: StripeSubscription) => (
              <SubscriptionCard
                key={ts.id}
                subscription={ts}
                testMode={testMode}
              />
            ))}
        </ul>
        {!subscriptionsForType.length && (
          <h3 className="mb-4 mt-6">
            You do not have {underscore2title(this.state.status).toLowerCase()}{" "}
            {testMode ? "test " : ""}subscriptions yet.
          </h3>
        )}
      </div>
    );
  }

  onToggleTestMode = async (value: boolean): Promise<void> => {
    this.setState({ testMode: value }, async () => {
      const { testMode } = this.state;
      if (
        (testMode && !this.state.testSubscriptions?.length) ||
        (!testMode && !this.state.prodSubscriptions?.length)
      ) {
        await this.fetchSubscriptions(testMode);
      }
    });
  };

  render() {
    const { testSubscriptions, prodSubscriptions } = this.state;
    const subscriptionsForType = this.state.testMode
      ? testSubscriptions
      : prodSubscriptions;

    return (
      <ConfigPageContainer>
        <ConfigSectionContainer
          title={`${underscore2title(this.state.status)} Subscriptions`}
        >
          <div className="container-fluid position-relative">
            <div>
              <div className="mb-2">
                <TestModeToggle
                  disabled={false}
                  onToggle={this.onToggleTestMode.bind(this)}
                  visible={hasCredentials(this.props.site)}
                />
              </div>
            </div>
            {this.renderContent(subscriptionsForType)}
          </div>
        </ConfigSectionContainer>
      </ConfigPageContainer>
    );
  }
}

export default withRouter(withFirebase(SubscribersPage));
