import React from "react";
import "./PricePreview.less";

const SimplePricePreview = () => (
  <div className="w-100 z-0 relative mb-4">
    <div>
      <div className="flex justify-content-center mb-3">
        <strong className="text-xl">Click on elements to edit them</strong>
      </div>
      <a
        className="mb-8 flex justify-content-center"
        href={`${window.location.origin}/html/pricing_simulation_snippet.html`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="btn btn-primary negative mx-2">
          Preview Fullscreen
        </button>
      </a>
    </div>
    <iframe
      id="price-preview-iframe"
      title="Your Pricing Page"
      className="w-100 z-0"
      src={`${window.location.origin}/html/pricing_simulation_snippet.html`}
      style={{
        position: "relative",
        zIndex: 0,
        width: "100%",
        height: "75vh",
      }}
    />
  </div>
);

export default SimplePricePreview;
