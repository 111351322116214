import React from "react";
import styled, { keyframes } from "styled-components";

// todo: unify shadows styles

export default function PriceHeroResults() {
  return (
    <Wrapper className="px-4 py-3 shadow-xl">
      <span>Dynamic Price Testing</span>
      <Container>
        <div>
          <strong>A</strong>
        </div>
        <BarA />
        <div>
          <strong>B</strong>
        </div>
        <BarB />
      </Container>
    </Wrapper>
  );
}

const animation = keyframes`
  0% { opacity: 0; transform: translateY(-100px);}
  10% { opacity: 1; transform: translateY(0);}
  95% { opacity: 1; transform: translateY(0);}
  100% { opacity: 0; transform: translateY(-100px);}
`;

const anim_bar_a = keyframes`
  0% { opacity: 0; transform: scaleX(0); transform-origin: left; transition-delay: 5s;}
  25% { opacity: 1; transform: scaleX(1); }
  75% { opacity: 1; transform: scaleX(1); }
  100% { opacity: 0; transform: scaleX(0); transform-origin: left;}
`;
const anim_bar_b = keyframes`
  0% { opacity: 0; transform: scaleX(0); transform-origin: left; transition-timing-function: ease-out; transition-delay: 5s;}
  25% { opacity: 1; transform: scaleX(1); transition-timing-function: ease-in;}
  75% { opacity: 1; transform: scaleX(1); transition-timing-function: ease-in;}
  100% { opacity: 0; transform: scaleX(0); transform-origin: left; transition-timing-function: ease-out;}
`;

const Wrapper = styled.span`
  display: inline-block;
  min-width: 230px;
  position: relative;
  top: 20px;
  left: 35px;

  padding: 24px 16px;
  border-radius: 8px;
  border: 1px solid #f3f3f3;

  animation-name: ${animation};
  animation-duration: 25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;

  @media only screen and (min-width: 768px) {
    top: -310px;
    left: 340px;
  }
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "header header"
    "bar" "bar"
    "bar" "bar";
`;
const BarA = styled.div`
  animation-name: ${anim_bar_a};
  animation-duration: 25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;

  display: inline-block;
  background-color: #ff644d;
  height: 9px;
  width: 100%;
  border-radius: 2px;
  margin-top: auto;
  margin-bottom: auto;
`;
const BarB = styled.div`
  animation-name: ${anim_bar_b};
  animation-duration: 25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  display: inline-block;
  background-color: #c4c4c4;
  height: 9px;
  width: 70%;
  border-radius: 2px;
  margin-top: auto;
  margin-bottom: auto;
`;
