import Gravatar from "react-gravatar";
import React from "react";

interface AvatarProps {
  email?: string;
  size: number;
}

const Avatar = (props: AvatarProps) => (
  <button
    className="max-w-xs bg-white items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    id="user-menu"
    aria-haspopup="true"
  >
    <Gravatar
      email={props.email || "fakeemail@fakedomain.com"}
      default="mp"
      size={props.size}
      className="rounded-full"
    />
  </button>
);

export default Avatar;
