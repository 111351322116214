import React, { useState } from "react";
import { MetricsResponse } from "../../../domain/metricsModel";
import CohortChart, { CohortData } from "./CohortChart";
import statsCalculator from "../../../service/statsCalculator";
import { ChartMode, Rates } from "../../../domain/DomainModel";
import { lastN } from "../../../utils/ArrayUtils";

const RetentionCohortChart = ({
  data,
  numMonths,
  defaultExpanded,
  loading,
  rates,
  convertOthers,
  currency,
  hideSwitch,
}: {
  data?: MetricsResponse;
  numMonths: number;
  defaultExpanded: boolean;
  loading: boolean;
  rates: Rates;
  convertOthers: boolean;
  currency: string;
  hideSwitch?: boolean;
}) => {
  const [cohortData, setCohortData] = useState<CohortData>();
  const [chartMode, setChartMode] = useState<ChartMode>("subscriptions");

  const generateCohorts = (): void => {
    if (data?.byMonth) {
      const filtered = lastN(numMonths, data.byMonth) || [];
      const newCohortData = statsCalculator.buildRetentionCohort(
        filtered,
        currency,
        chartMode,
        convertOthers,
        rates
      );
      setCohortData(newCohortData);
    }
  };

  React.useEffect(() => {
    generateCohorts();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    generateCohorts();
    // eslint-disable-next-line
  }, [numMonths, data, currency, chartMode, convertOthers, rates]);

  return (
    <CohortChart
      data={cohortData}
      defaultExpanded={defaultExpanded}
      loading={loading}
      chartMode={chartMode}
      hideSwitch={hideSwitch}
      updateChartMode={setChartMode}
      convertOthers={convertOthers}
      currency={currency}
      numMonths={numMonths}
    />
  );
};

export default RetentionCohortChart;
