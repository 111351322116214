export const lastN = <T>(n: number, elements?: T[]): T[] | undefined => {
  if (elements === undefined || elements.length < n) {
    return undefined;
  }

  return elements.slice(elements.length - n, elements.length);
};

export const secondToLastN = <T>(
  n: number,
  elements?: T[]
): T[] | undefined => {
  if (elements === undefined || elements.length < n * 2) {
    return undefined;
  }

  return elements.slice(elements.length - n * 2, elements.length - n);
};
