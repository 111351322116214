import React from "react";

interface ConfigPageContainerProps {
  children: React.ReactElement | React.ReactElement[];
}
const ConfigPageContainer = (props: ConfigPageContainerProps) => {
  return <div>{props.children}</div>;
};

export default ConfigPageContainer;
