import React, { useEffect, useState } from "react";
import LineChart from "../../charts/LineChart";
import { transformGrowthRateChartData } from "./mapper";
import { MetricsResponse } from "../../../domain/metricsModel";
import { ChartMode, Rates } from "../../../domain/DomainModel";
import Loader from "../../icons/Loader";
import SubscriptionsOrRevenue from "../SubscriptionsOrRevenue";
import FigureHeader from "../FigureHeader";

interface GrowthRateChartProps {
  title?: string;
  data?: MetricsResponse;
  currency: string;
  numMonths: number;
  rates: Rates;
  convertOthers: boolean;
  hideSwitch?: boolean;
  chartMode: ChartMode;
  updateChartMode: (mode: ChartMode) => void;
  loading: boolean;
}

const GrowthRateChart = (props: GrowthRateChartProps) => {
  const {
    title,
    data,
    loading,
    currency,
    numMonths,
    rates,
    convertOthers,
    chartMode,
    updateChartMode,
    hideSwitch,
  } = props;

  const [retentionData, setRetentionData] = useState(
    transformGrowthRateChartData(
      data,
      currency,
      chartMode,
      numMonths,
      convertOthers,
      rates
    )
  );

  useEffect(() => {
    updatedData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updatedData();
    // eslint-disable-next-line
  }, [data, currency, chartMode, numMonths, rates, convertOthers]);

  const updatedData = () => {
    const newData = transformGrowthRateChartData(
      data,
      currency,
      chartMode,
      numMonths,
      convertOthers,
      rates
    );

    setRetentionData(newData);
  };

  if (!loading && !data) {
    return null;
  }

  let lastValue;
  let prevValue;

  if (retentionData?.data.series) {
    const data = retentionData.data.series[0].data;
    lastValue = data[data.length - 1];
    prevValue = data[data.length - 2];
  }

  return (
    <div key={`growth-chart-${chartMode}`}>
      {title && <h5 className="mb-4">{title}</h5>}
      {loading && (
        <div className="m-8">
          <Loader />
        </div>
      )}
      {!loading && data && (
        <div>
          <div className="w-100 ml-2">
            <FigureHeader
              name="Growth Rate"
              unit="%"
              figure={lastValue}
              prevFigure={prevValue}
              descriptor={`In the last month`}
            />
          </div>
          {!hideSwitch && (
            <div className="py-4">
              <SubscriptionsOrRevenue
                updateChartMode={updateChartMode}
                chartMode={chartMode}
                currency={currency}
                convertOthers={convertOthers}
              />
            </div>
          )}
          <LineChart
            data={retentionData.data}
            options={retentionData.options}
          />
        </div>
      )}
    </div>
  );
};

export default GrowthRateChart;
