import { MetricsResponse } from "../../../domain/metricsModel";
import { ChartMode, Rates } from "../../../domain/DomainModel";
import statsCalculator from "../../../service/statsCalculator";
import { lastN } from "../../../utils/ArrayUtils";
import { currencySymbols } from "../../../domain/InternationalDomain";
import { LineChartProps } from "../../charts/LineChart";

export const transformLtvChartData = (
  churn: MetricsResponse | undefined,
  currency: string,
  chartMode: ChartMode,
  numMonths: number,
  convertOthers: boolean,
  rates: Rates
): LineChartProps => {
  if (!churn?.byMonth) {
    return { data: { series: [], labels: [] }, options: { fullWidth: true } };
  }

  const filteredData = lastN(numMonths, churn.byMonth) || [];

  const data: (number | undefined)[] = filteredData.map((f) =>
    statsCalculator.calculateLtv(f, currency, convertOthers, rates)
  );

  const cleanData: number[] = [];

  data.forEach((v, i) => {
    let value = v;
    if (value !== undefined && i !== 0 && !isFinite(value)) {
      value = data[i - 1];
    }

    cleanData.push(value !== undefined && isFinite(value) ? value / 100 : 0);
  });

  return {
    data: {
      labels: filteredData.map((c, i) => c.month || `Month ${i + 1}`),
      series: [
        {
          name: "Lifetime Value",
          data: cleanData,
        },
      ],
    },
    options: {
      stackBars: true,
      fullWidth: true,
      chartPadding: {
        right: 40,
      },
      axisY: {
        labelInterpolationFnc: (value: number) => {
          if (value < 1000) {
            return `${currencySymbols[currency] || "$"}${value}`;
          }

          return `${currencySymbols[currency] || "$"}${value / 1000}k`;
        },
      },
    },
  };
};
