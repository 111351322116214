import React, { Component } from "react";
import PropTypes from "prop-types";
import svgIcons from "./icons.svg";
import "./trumbowyg.css";

/* eslint-disable no-unused-vars */
import trumbowyg from "trumbowyg";

const trumbowygIconsId = "trumbowyg-icons";
// eslint-disable-next-line no-undef
const $ = global.$;

class Trumbowyg extends Component {
  componentDidMount() {
    const {
      id,
      lang,
      buttons,
      semantic,
      resetCss,
      removeformatPasted,
      autogrow,
      data,
      disabled,
      onFocus,
      onBlur,
      onInit,
      onChange,
      onResize,
      onPaste,
      onOpenFullScreen,
      onCloseFullScreen,
      onClose,
      btnsDef,
      plugins,
    } = this.props;

    if ($(`#${trumbowygIconsId}`).length === 0) {
      $("body").prepend(`<div id="${trumbowygIconsId}">${svgIcons}</div>`);
    }

    const trumbowygInstance = $(`#${id}`).trumbowyg({
      lang: lang,
      btns: buttons,
      btnsDef: btnsDef,
      semantic: semantic,
      resetCss: resetCss,
      removeformatPasted: removeformatPasted,
      autogrow: autogrow,
      plugins: plugins,
    });

    if (onFocus) {
      trumbowygInstance.on("tbwfocus", onFocus);
    }

    if (onBlur) {
      trumbowygInstance.on("tbwblur", onBlur);
    }

    if (onInit) {
      trumbowygInstance.on("tbwinit", onInit);
    }

    if (onChange) {
      trumbowygInstance.on("tbwchange", onChange);
    }

    if (onResize) {
      trumbowygInstance.on("tbwresize", onResize);
    }

    if (onPaste) {
      trumbowygInstance.on("tbwpaste", onPaste);
    }

    if (onOpenFullScreen) {
      trumbowygInstance.on("tbwopenfullscreen", onOpenFullScreen);
    }

    if (onCloseFullScreen) {
      trumbowygInstance.on("tbwclosefullscreen", onCloseFullScreen);
    }

    if (onClose) {
      trumbowygInstance.on("tbwclose", onClose);
    }

    $(`#${id}`).trumbowyg("html", data);
    $(`#${id}`).trumbowyg(disabled === true ? "disable" : "enable");
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.data !== this.props.data ||
      nextProps.disabled !== this.props.disabled
    );
  }

  componentDidUpdate(prevProps, prevState) {
    $(`#${this.props.id}`).trumbowyg("html", this.props.data);
    $(`#${this.props.id}`).trumbowyg(
      this.props.disabled === true ? "disable" : "enable"
    );
  }

  componentWillUnmount() {
    if ($(`#${this.props.id}`)) {
      $(`#${this.props.id}`).trumbowyg("destroy");
    }
  }

  render() {
    return (
      <>
        <svg
          style={{ visibility: "hidden", height: 0, width: 0, display: "none" }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <symbol id="trumbowyg-blockquote" viewBox="0 0 72 72">
            <path d="M21.3 31.9h-.6c.8-1.2 1.9-2.2 3.4-3.2 2.1-1.4 5-2.7 9.2-3.3l-1.4-8.9c-4.7.7-8.5 2.1-11.7 4-2.4 1.4-4.3 3.1-5.8 4.9-2.3 2.7-3.7 5.7-4.5 8.5-.8 2.8-1 5.4-1 7.5 0 2.3.3 4 .4 4.8 0 .1.1.3.1.4 1.2 5.4 6.1 9.5 11.9 9.5 6.7 0 12.2-5.4 12.2-12.2s-5.5-12-12.2-12zM49.5 31.9h-.6c.8-1.2 1.9-2.2 3.4-3.2 2.1-1.4 5-2.7 9.2-3.3l-1.4-8.9c-4.7.7-8.5 2.1-11.7 4-2.4 1.4-4.3 3.1-5.8 4.9-2.3 2.7-3.7 5.7-4.5 8.5-.8 2.8-1 5.4-1 7.5 0 2.3.3 4 .4 4.8 0 .1.1.3.1.4 1.2 5.4 6.1 9.5 11.9 9.5 6.7 0 12.2-5.4 12.2-12.2s-5.5-12-12.2-12z" />
          </symbol>
          <symbol id="trumbowyg-bold" viewBox="0 0 72 72">
            <path d="M51.1 37.8c-1.1-1.4-2.5-2.5-4.2-3.3 1.2-.8 2.1-1.8 2.8-3 1-1.6 1.5-3.5 1.5-5.3 0-2-.6-4-1.7-5.8-1.1-1.8-2.8-3.2-4.8-4.1-2-.9-4.6-1.3-7.8-1.3h-16v42h16.3c2.6 0 4.8-.2 6.7-.7 1.9-.5 3.4-1.2 4.7-2.1 1.3-1 2.4-2.4 3.2-4.1.9-1.7 1.3-3.6 1.3-5.7.2-2.5-.5-4.7-2-6.6zM40.8 50.2c-.6.1-1.8.2-3.4.2h-9V38.5h8.3c2.5 0 4.4.2 5.6.6 1.2.4 2 1 2.7 2 .6.9 1 2 1 3.3 0 1.1-.2 2.1-.7 2.9-.5.9-1 1.5-1.7 1.9-.8.4-1.7.8-2.8 1zm2.6-20.4c-.5.7-1.3 1.3-2.5 1.6-.8.3-2.5.4-4.8.4h-7.7V21.6h7.1c1.4 0 2.6 0 3.6.1s1.7.2 2.2.4c1 .3 1.7.8 2.2 1.7.5.9.8 1.8.8 3-.1 1.3-.4 2.2-.9 3z" />
          </symbol>
          <symbol id="trumbowyg-close" viewBox="0 0 72 72">
            <path d="M57 20.5l-5.4-5.4-15.5 15.5-15.6-15.5-5.4 5.4L30.7 36 15.1 51.5l5.4 5.4 15.6-15.5 15.5 15.5 5.4-5.4L41.5 36z" />
          </symbol>
          <symbol id="trumbowyg-create-link" viewBox="0 0 72 72">
            <path d="M31.1 48.9l-6.7 6.7c-.8.8-1.6.9-2.1.9s-1.4-.1-2.1-.9L15 50.4c-1.1-1.1-1.1-3.1 0-4.2l6.1-6.1.2-.2 6.5-6.5c-1.2-.6-2.5-.9-3.8-.9-2.3 0-4.6.9-6.3 2.6L11 41.8c-3.5 3.5-3.5 9.2 0 12.7l5.2 5.2c1.7 1.7 4 2.6 6.3 2.6s4.6-.9 6.3-2.6l6.7-6.7c2.5-2.6 3.1-6.7 1.5-10l-5.9 5.9zM38.7 22.5l6.7-6.7c.8-.8 1.6-.9 2.1-.9s1.4.1 2.1.9l5.2 5.2c1.1 1.1 1.1 3.1 0 4.2l-6.1 6.1-.2.2L42 38c1.2.6 2.5.9 3.8.9 2.3 0 4.6-.9 6.3-2.6l6.7-6.7c3.5-3.5 3.5-9.2 0-12.7l-5.2-5.2c-1.7-1.7-4-2.6-6.3-2.6s-4.6.9-6.3 2.6l-6.7 6.7c-2.7 2.7-3.3 6.9-1.7 10.2l6.1-6.1c0 .1 0 .1 0 0z" />
            <path d="M44.2 30.5c.2-.2.4-.6.4-.9 0-.3-.1-.6-.4-.9l-2.3-2.3c-.3-.2-.6-.4-.9-.4-.3 0-.6.1-.9.4L25.9 40.6c-.2.2-.4.6-.4.9 0 .3.1.6.4.9l2.3 2.3c.2.2.6.4.9.4.3 0 .6-.1.9-.4l14.2-14.2zM49.9 55.4h-8.5v-5h8.5v-8.9h5.2v8.9h8.5v5h-8.5v8.9h-5.2v-8.9z" />
          </symbol>
          <symbol id="trumbowyg-del" viewBox="0 0 72 72">
            <path d="M45.8 45c0 1-.3 1.9-.9 2.8-.6.9-1.6 1.6-3 2.1s-3.1.8-5 .8c-2.1 0-4-.4-5.7-1.1-1.7-.7-2.9-1.7-3.6-2.7-.8-1.1-1.3-2.6-1.5-4.5l-.1-.8-6.7.6v.9c.1 2.8.9 5.4 2.3 7.6 1.5 2.3 3.5 4 6.1 5.1 2.6 1.1 5.7 1.6 9.4 1.6 2.9 0 5.6-.5 8-1.6 2.4-1.1 4.3-2.7 5.6-4.7 1.3-2 2-4.2 2-6.5 0-1.6-.3-3.1-.9-4.5l-.2-.6H44c0 .1 1.8 2.3 1.8 5.5zM29 28.9c-.8-.8-1.2-1.7-1.2-2.9 0-.7.1-1.3.4-1.9.3-.6.7-1.1 1.4-1.6.6-.5 1.4-.9 2.5-1.1 1.1-.3 2.4-.4 3.9-.4 2.9 0 5 .6 6.3 1.7 1.3 1.1 2.1 2.7 2.4 5.1l.1.9 6.8-.5v-.9c-.1-2.5-.8-4.7-2.1-6.7s-3.2-3.5-5.6-4.5c-2.4-1-5.1-1.5-8.1-1.5-2.8 0-5.3.5-7.6 1.4-2.3 1-4.2 2.4-5.4 4.3-1.2 1.9-1.9 3.9-1.9 6.1 0 1.7.4 3.4 1.2 4.9l.3.5h11.8c-2.3-.9-3.9-1.7-5.2-2.9zm13.3-6.2zM22.7 20.3zM13 34.1h46.1v3.4H13z" />
          </symbol>
          <symbol id="trumbowyg-em" viewBox="0 0 72 72">
            <path d="M26 57l10.1-42h7.2L33.2 57H26z" />
          </symbol>
          <symbol id="trumbowyg-fullscreen" viewBox="0 0 72 72">
            <path d="M25.2 7.1H7.1v17.7l6.7-6.5 10.5 10.5 4.5-4.5-10.4-10.5zm22 0l6.5 6.7-10.5 10.5 4.5 4.5 10.5-10.4 6.7 6.8V7.1zm.5 36.1l-4.5 4.5 10.4 10.5-6.8 6.7h18.1V47.2l-6.7 6.5zm-23.4 0L13.8 53.6l-6.7-6.8v18.1h17.7l-6.5-6.7 10.5-10.5z" />
            <path
              fill="currentColor"
              d="M10.7 28.8h18.1V11.2l-6.6 6.4L11.6 7.1l-4.5 4.5 10.5 10.5zm50.1 0l-6.4-6.6 10.5-10.6-4.5-4.5-10.5 10.5-6.7-6.9v18.1zm-.4 36.1l4.5-4.5-10.5-10.5 6.9-6.7H43.2v17.6l6.6-6.4zm-48.8 0l10.5-10.5 6.7 6.9V43.2H11.1l6.5 6.6L7.1 60.4z"
            />
          </symbol>
          <symbol id="trumbowyg-h1" viewBox="0 0 72 72">
            <path d="M6.4 14.9h7.4v16.7h19.1V14.9h7.4V57h-7.4V38H13.8v19H6.4V14.9zM47.8 22.5c1.4 0 2.8-.1 4.1-.4 1.3-.2 2.5-.6 3.6-1.2 1.1-.5 2-1.3 2.8-2.1.8-.9 1.3-1.9 1.5-3.2h5.5v41.2h-7.4v-29H47.8v-5.3z" />
          </symbol>
          <symbol id="trumbowyg-h2" viewBox="0 0 72 72">
            <path d="M1.5 14.9h7.4v16.7H28V14.9h7.4V57H28V38H8.8v19H1.5V14.9zM70.2 56.9H42c0-3.4.9-6.4 2.5-9s3.8-4.8 6.6-6.7c1.3-1 2.7-1.9 4.2-2.9 1.5-.9 2.8-1.9 4-3 1.2-1.1 2.2-2.2 3-3.4.8-1.2 1.2-2.7 1.2-4.3 0-.7-.1-1.5-.3-2.4s-.5-1.6-1-2.4c-.5-.7-1.2-1.3-2.1-1.8-.9-.5-2.1-.7-3.5-.7-1.3 0-2.4.3-3.3.8s-1.6 1.3-2.1 2.2-.9 2-1.2 3.3c-.3 1.3-.4 2.6-.4 4.1h-6.7c0-2.3.3-4.4.9-6.3.6-1.9 1.5-3.6 2.7-5 1.2-1.4 2.7-2.5 4.4-3.3 1.7-.8 3.8-1.2 6.1-1.2 2.5 0 4.6.4 6.3 1.2 1.7.8 3.1 1.9 4.1 3.1 1 1.3 1.8 2.6 2.2 4.1.4 1.5.6 2.9.6 4.2 0 1.6-.3 3.1-.8 4.5-.5 1.3-1.2 2.6-2.1 3.7-.9 1.1-1.8 2.2-2.9 3.1-1.1.9-2.2 1.8-3.4 2.7-1.2.8-2.4 1.6-3.5 2.4-1.2.7-2.3 1.5-3.3 2.2-1 .7-1.9 1.5-2.6 2.3-.7.8-1.3 1.7-1.5 2.6h20.1v5.9z" />
          </symbol>
          <symbol id="trumbowyg-h3" viewBox="0 0 72 72">
            <path d="M1.4 14.5h7.4v16.7h19.1V14.5h7.4v42.1h-7.4v-19H8.8v19H1.4V14.5zM53.1 32.4c1.1 0 2.2 0 3.3-.2 1.1-.2 2.1-.5 2.9-1 .9-.5 1.6-1.2 2.1-2 .5-.9.8-1.9.8-3.2 0-1.8-.6-3.2-1.8-4.2-1.2-1.1-2.7-1.6-4.6-1.6-1.2 0-2.2.2-3.1.7-.9.5-1.6 1.1-2.2 1.9-.6.8-1 1.7-1.3 2.7-.3 1-.4 2-.4 3.1h-6.7c.1-2 .5-3.9 1.1-5.6.7-1.7 1.6-3.2 2.7-4.4s2.6-2.2 4.2-2.9c1.6-.7 3.5-1.1 5.6-1.1 1.6 0 3.2.2 4.7.7 1.6.5 2.9 1.2 4.2 2.1 1.2.9 2.2 2.1 3 3.4.7 1.4 1.1 3 1.1 4.8 0 2.1-.5 3.9-1.4 5.4-.9 1.6-2.4 2.7-4.4 3.4v.1c2.4.5 4.2 1.6 5.5 3.5 1.3 1.9 2 4.1 2 6.8 0 2-.4 3.7-1.2 5.3-.8 1.6-1.8 2.9-3.2 3.9-1.3 1.1-2.9 1.9-4.7 2.5-1.8.6-3.6.9-5.6.9-2.4 0-4.5-.3-6.3-1s-3.3-1.7-4.5-2.9c-1.2-1.3-2.1-2.8-2.7-4.5-.6-1.8-1-3.7-1-5.9h6.7c-.1 2.5.5 4.6 1.9 6.3 1.3 1.7 3.3 2.5 5.9 2.5 2.2 0 4.1-.6 5.6-1.9 1.5-1.3 2.3-3.1 2.3-5.4 0-1.6-.3-2.9-.9-3.8-.6-.9-1.5-1.7-2.5-2.2-1-.5-2.2-.8-3.4-.9-1.3-.1-2.6-.2-3.9-.1v-5.2z" />
          </symbol>
          <symbol id="trumbowyg-h4" viewBox="0 0 72 72">
            <path d="M1.5 14.9h7.4v16.7H28V14.9h7.4V57H28V38H8.9v19H1.5V14.9zM70.5 47.2h-5.3V57h-6.4v-9.8H41.2v-6.7l17.7-24.8h6.4v26.2h5.3v5.3zm-24.2-5.3h12.5V23.7h-.1L46.3 41.9z" />
          </symbol>
          <symbol id="trumbowyg-h5" viewBox="0 0 72 72">
            <path d="M1.5 14.9h7.4v16.7H28V14.9h7.4V57H28V38H8.9v19H1.5V14.9zM69.043 42.368q0 5.655-3.92 10.154-3.856 4.434-9.383 4.434-6.105 0-9.768-2.892-3.599-2.891-3.728-8.033h6.62q0 2.892 1.735 4.37 1.735 1.478 4.691 1.478 3.085 0 5.142-2.57 2.056-2.57 2.056-6.748 0-8.869-6.426-8.869-4.692 0-7.327 5.463h-5.848l.9-24.292h22.75v5.398H50.02l-.643 12.21q3.02-3.599 8.355-3.599 5.205 0 8.226 3.985 3.084 3.92 3.084 9.511z" />
          </symbol>
          <symbol id="trumbowyg-h6" viewBox="0 0 72 72">
            <path d="M1.5 14.9h7.4v16.7H28V14.9h7.4V57H28V38H8.9v19H1.5zM69.188 43.56q0 2.269-.63 4.475-.63 2.206-2.017 4.286t-4.097 3.403q-2.71 1.261-6.366 1.261-4.349 0-7.185-1.324-2.773-1.386-4.16-4.16-1.386-2.836-1.89-6.113t-.505-8.194q0-22.312 14.181-22.312 10.4 0 11.66 10.526h-6.177q-.378-5.61-5.483-5.61-2.521 0-4.223 1.766-1.702 1.701-2.458 4.538-.693 2.836-1.008 5.168-.252 2.269-.252 4.664 1.575-2.458 3.53-3.719 2.016-1.323 5.42-1.323 3.34 0 5.735 1.26 2.458 1.198 3.656 3.215 1.197 2.017 1.701 4.033.568 2.017.568 4.16zm-6.492.252q0-8.193-6.555-8.193-6.68 0-6.68 8.193 0 8.257 6.68 8.257 6.555 0 6.555-8.257z" />
          </symbol>
          <symbol id="trumbowyg-horizontal-rule" viewBox="0 0 72 72">
            <path d="M9.1 32h54v8h-54z" />
          </symbol>
          <symbol id="trumbowyg-insert-image" viewBox="0 0 72 72">
            <path d="M64 17v38H8V17h56m8-8H0v54h72V9z" />
            <path d="M17.5 22C15 22 13 24 13 26.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5-2-4.5-4.5-4.5zM16 50h27L29.5 32zM36 36.2l8.9-8.5L60.2 50H45.9S35.6 35.9 36 36.2z" />
          </symbol>
          <symbol id="trumbowyg-italic" viewBox="0 0 72 72">
            <path d="M26 57l10.1-42h7.2L33.2 57H26z" />
          </symbol>
          <symbol id="trumbowyg-justify-center" viewBox="0 0 72 72">
            <path d="M9 14h54v8H9zM9 50h54v8H9zM18 32h36v8H18z" />
          </symbol>
          <symbol id="trumbowyg-justify-full" viewBox="0 0 72 72">
            <path d="M9 14h54v8H9zM9 50h54v8H9zM9 32h54v8H9z" />
          </symbol>
          <symbol id="trumbowyg-justify-left" viewBox="0 0 72 72">
            <path d="M9 14h54v8H9zM9 50h54v8H9zM9 32h36v8H9z" />
          </symbol>
          <symbol id="trumbowyg-justify-right" viewBox="0 0 72 72">
            <path d="M9 14h54v8H9zM9 50h54v8H9zM27 32h36v8H27z" />
          </symbol>
          <symbol id="trumbowyg-link" viewBox="0 0 72 72">
            <path d="M30.9 49.1l-6.7 6.7c-.8.8-1.6.9-2.1.9s-1.4-.1-2.1-.9l-5.2-5.2c-1.1-1.1-1.1-3.1 0-4.2l6.1-6.1.2-.2 6.5-6.5c-1.2-.6-2.5-.9-3.8-.9-2.3 0-4.6.9-6.3 2.6L10.8 42c-3.5 3.5-3.5 9.2 0 12.7l5.2 5.2c1.7 1.7 4 2.6 6.3 2.6s4.6-.9 6.3-2.6l6.7-6.7C38 50.5 38.6 46.3 37 43l-6.1 6.1zM38.5 22.7l6.7-6.7c.8-.8 1.6-.9 2.1-.9s1.4.1 2.1.9l5.2 5.2c1.1 1.1 1.1 3.1 0 4.2l-6.1 6.1-.2.2-6.5 6.5c1.2.6 2.5.9 3.8.9 2.3 0 4.6-.9 6.3-2.6l6.7-6.7c3.5-3.5 3.5-9.2 0-12.7l-5.2-5.2c-1.7-1.7-4-2.6-6.3-2.6s-4.6.9-6.3 2.6l-6.7 6.7c-2.7 2.7-3.3 6.9-1.7 10.2l6.1-6.1z" />
            <path d="M44.1 30.7c.2-.2.4-.6.4-.9 0-.3-.1-.6-.4-.9l-2.3-2.3c-.2-.2-.6-.4-.9-.4-.3 0-.6.1-.9.4L25.8 40.8c-.2.2-.4.6-.4.9 0 .3.1.6.4.9l2.3 2.3c.2.2.6.4.9.4.3 0 .6-.1.9-.4l14.2-14.2z" />
          </symbol>
          <symbol id="trumbowyg-ordered-list" viewBox="0 0 72 72">
            <path d="M27 14h36v8H27zM27 50h36v8H27zM27 32h36v8H27zM11.8 15.8V22h1.8v-7.8h-1.5l-2.1 1 .3 1.3zM12.1 38.5l.7-.6c1.1-1 2.1-2.1 2.1-3.4 0-1.4-1-2.4-2.7-2.4-1.1 0-2 .4-2.6.8l.5 1.3c.4-.3 1-.6 1.7-.6.9 0 1.3.5 1.3 1.1 0 .9-.9 1.8-2.6 3.3l-1 .9V40H15v-1.5h-2.9zM13.3 53.9c1-.4 1.4-1 1.4-1.8 0-1.1-.9-1.9-2.6-1.9-1 0-1.9.3-2.4.6l.4 1.3c.3-.2 1-.5 1.6-.5.8 0 1.2.3 1.2.8 0 .7-.8.9-1.4.9h-.7v1.3h.7c.8 0 1.6.3 1.6 1.1 0 .6-.5 1-1.4 1-.7 0-1.5-.3-1.8-.5l-.4 1.4c.5.3 1.3.6 2.3.6 2 0 3.2-1 3.2-2.4 0-1.1-.8-1.8-1.7-1.9z" />
          </symbol>
          <symbol id="trumbowyg-p" viewBox="0 0 72 72">
            <path d="M47.8 15.1H30.1c-4.7 0-8.5 3.7-8.5 8.4s3.7 8.4 8.4 8.4v25h7V19.8h3v37.1h4.1V19.8h3.7v-4.7z" />
          </symbol>
          <symbol id="trumbowyg-redo" viewBox="0 0 72 72">
            <path d="M10.8 51.2c0-5.1 2.1-9.7 5.4-13.1 3.3-3.3 8-5.4 13.1-5.4H46v-12L61.3 36 45.9 51.3V39.1H29.3c-3.3 0-6.4 1.3-8.5 3.5-2.2 2.2-3.5 5.2-3.5 8.5h-6.5z" />
          </symbol>
          <symbol id="trumbowyg-removeformat" viewBox="0 0 72 72">
            <path d="M58.2 54.6L52 48.5l3.6-3.6 6.1 6.1 6.4-6.4 3.8 3.8-6.4 6.4 6.1 6.1-3.6 3.6-6.1-6.1-6.4 6.4-3.7-3.8 6.4-6.4zM21.7 52.1H50V57H21.7zM18.8 15.2h34.1v6.4H39.5v24.2h-7.4V21.5H18.8v-6.3z" />
          </symbol>
          <symbol id="trumbowyg-strikethrough" viewBox="0 0 72 72">
            <path d="M45.8 45c0 1-.3 1.9-.9 2.8-.6.9-1.6 1.6-3 2.1s-3.1.8-5 .8c-2.1 0-4-.4-5.7-1.1-1.7-.7-2.9-1.7-3.6-2.7-.8-1.1-1.3-2.6-1.5-4.5l-.1-.8-6.7.6v.9c.1 2.8.9 5.4 2.3 7.6 1.5 2.3 3.5 4 6.1 5.1 2.6 1.1 5.7 1.6 9.4 1.6 2.9 0 5.6-.5 8-1.6 2.4-1.1 4.3-2.7 5.6-4.7 1.3-2 2-4.2 2-6.5 0-1.6-.3-3.1-.9-4.5l-.2-.6H44c0 .1 1.8 2.3 1.8 5.5zM29 28.9c-.8-.8-1.2-1.7-1.2-2.9 0-.7.1-1.3.4-1.9.3-.6.7-1.1 1.4-1.6.6-.5 1.4-.9 2.5-1.1 1.1-.3 2.4-.4 3.9-.4 2.9 0 5 .6 6.3 1.7 1.3 1.1 2.1 2.7 2.4 5.1l.1.9 6.8-.5v-.9c-.1-2.5-.8-4.7-2.1-6.7s-3.2-3.5-5.6-4.5c-2.4-1-5.1-1.5-8.1-1.5-2.8 0-5.3.5-7.6 1.4-2.3 1-4.2 2.4-5.4 4.3-1.2 1.9-1.9 3.9-1.9 6.1 0 1.7.4 3.4 1.2 4.9l.3.5h11.8c-2.3-.9-3.9-1.7-5.2-2.9zm13.3-6.2zM22.7 20.3zM13 34.1h46.1v3.4H13z" />
          </symbol>
          <symbol id="trumbowyg-strong" viewBox="0 0 72 72">
            <path d="M51.1 37.8c-1.1-1.4-2.5-2.5-4.2-3.3 1.2-.8 2.1-1.8 2.8-3 1-1.6 1.5-3.5 1.5-5.3 0-2-.6-4-1.7-5.8-1.1-1.8-2.8-3.2-4.8-4.1-2-.9-4.6-1.3-7.8-1.3h-16v42h16.3c2.6 0 4.8-.2 6.7-.7 1.9-.5 3.4-1.2 4.7-2.1 1.3-1 2.4-2.4 3.2-4.1.9-1.7 1.3-3.6 1.3-5.7.2-2.5-.5-4.7-2-6.6zM40.8 50.2c-.6.1-1.8.2-3.4.2h-9V38.5h8.3c2.5 0 4.4.2 5.6.6 1.2.4 2 1 2.7 2 .6.9 1 2 1 3.3 0 1.1-.2 2.1-.7 2.9-.5.9-1 1.5-1.7 1.9-.8.4-1.7.8-2.8 1zm2.6-20.4c-.5.7-1.3 1.3-2.5 1.6-.8.3-2.5.4-4.8.4h-7.7V21.6h7.1c1.4 0 2.6 0 3.6.1s1.7.2 2.2.4c1 .3 1.7.8 2.2 1.7.5.9.8 1.8.8 3-.1 1.3-.4 2.2-.9 3z" />
          </symbol>
          <symbol id="trumbowyg-subscript" viewBox="0 0 72 72">
            <path d="M32 15h7.8L56 57.1h-7.9L44.3 46H27.4l-4 11.1h-7.6L32 15zm-2.5 25.4h12.9L36 22.3h-.2l-6.3 18.1zM58.7 59.9c.6-1.4 2-2.8 4.1-4.4 1.9-1.3 3.1-2.3 3.7-2.9.8-.9 1.3-1.9 1.3-3 0-.9-.2-1.6-.7-2.2-.5-.6-1.2-.9-2.1-.9-1.2 0-2.1.5-2.5 1.4-.3.5-.4 1.4-.5 2.5h-4c.1-1.8.4-3.2 1-4.3 1.1-2.1 3-3.1 5.8-3.1 2.2 0 3.9.6 5.2 1.8 1.3 1.2 1.9 2.8 1.9 4.8 0 1.5-.5 2.9-1.4 4.1-.6.8-1.6 1.7-3 2.6L66 57.7c-1 .7-1.7 1.2-2.1 1.6-.4.3-.7.7-1 1.1H72V64H57.8c0-1.5.3-2.8.9-4.1z" />
          </symbol>
          <symbol id="trumbowyg-superscript" viewBox="0 0 72 72">
            <path d="M32 15h7.8L56 57.1h-7.9l-4-11.1H27.4l-4 11.1h-7.6L32 15zm-2.5 25.4h12.9L36 22.3h-.2l-6.3 18.1zM49.6 28.8c.5-1.1 1.6-2.3 3.4-3.6 1.5-1.1 2.5-1.9 3-2.4.7-.7 1-1.6 1-2.4 0-.7-.2-1.3-.6-1.8-.4-.5-1-.7-1.7-.7-1 0-1.7.4-2.1 1.1-.2.4-.3 1.1-.4 2.1H49c.1-1.5.3-2.6.8-3.5.9-1.7 2.5-2.6 4.8-2.6 1.8 0 3.2.5 4.3 1.5 1.1 1 1.6 2.3 1.6 4 0 1.3-.4 2.4-1.1 3.4-.5.7-1.3 1.4-2.4 2.2l-1.3 1c-.8.6-1.4 1-1.7 1.3-.3.3-.6.6-.8.9h7.4v3H48.8c0-1.3.3-2.4.8-3.5z" />
          </symbol>
          <symbol id="trumbowyg-underline" viewBox="0 0 72 72">
            <path d="M36 35zM15.2 55.9h41.6V59H15.2zM21.1 13.9h6.4v21.2c0 1.2.1 2.5.2 3.7.1 1.3.5 2.4 1 3.4.6 1 1.4 1.8 2.6 2.5 1.1.6 2.7 1 4.8 1 2.1 0 3.7-.3 4.8-1 1.1-.6 2-1.5 2.6-2.5.6-1 .9-2.1 1-3.4.1-1.3.2-2.5.2-3.7V13.9H51v23.3c0 2.3-.4 4.4-1.1 6.1-.7 1.7-1.7 3.2-3 4.4-1.3 1.2-2.9 2-4.7 2.6-1.8.6-3.9.9-6.1.9-2.2 0-4.3-.3-6.1-.9-1.8-.6-3.4-1.5-4.7-2.6-1.3-1.2-2.3-2.6-3-4.4-.7-1.7-1.1-3.8-1.1-6.1V13.9z" />
          </symbol>
          <symbol id="trumbowyg-undo" viewBox="0 0 72 72">
            <path d="M61.2 51.2c0-5.1-2.1-9.7-5.4-13.1-3.3-3.3-8-5.4-13.1-5.4H26.1v-12L10.8 36l15.3 15.3V39.1h16.7c3.3 0 6.4 1.3 8.5 3.5 2.2 2.2 3.5 5.2 3.5 8.5h6.4z" />
          </symbol>
          <symbol id="trumbowyg-unlink" viewBox="0 0 72 72">
            <path d="M30.9 49.1l-6.7 6.7c-.8.8-1.6.9-2.1.9s-1.4-.1-2.1-.9l-5.2-5.2c-1.1-1.1-1.1-3.1 0-4.2l6.1-6.1.2-.2 6.5-6.5c-1.2-.6-2.5-.9-3.8-.9-2.3 0-4.6.9-6.3 2.6L10.8 42c-3.5 3.5-3.5 9.2 0 12.7l5.2 5.2c1.7 1.7 4 2.6 6.3 2.6s4.6-.9 6.3-2.6l6.7-6.7C38 50.5 38.6 46.3 37 43l-6.1 6.1zM38.5 22.7l6.7-6.7c.8-.8 1.6-.9 2.1-.9s1.4.1 2.1.9l5.2 5.2c1.1 1.1 1.1 3.1 0 4.2l-6.1 6.1-.2.2-6.5 6.5c1.2.6 2.5.9 3.8.9 2.3 0 4.6-.9 6.3-2.6l6.7-6.7c3.5-3.5 3.5-9.2 0-12.7l-5.2-5.2c-1.7-1.7-4-2.6-6.3-2.6s-4.6.9-6.3 2.6l-6.7 6.7c-2.7 2.7-3.3 6.9-1.7 10.2l6.1-6.1z" />
            <path d="M44.1 30.7c.2-.2.4-.6.4-.9 0-.3-.1-.6-.4-.9l-2.3-2.3c-.2-.2-.6-.4-.9-.4-.3 0-.6.1-.9.4L25.8 40.8c-.2.2-.4.6-.4.9 0 .3.1.6.4.9l2.3 2.3c.2.2.6.4.9.4.3 0 .6-.1.9-.4l14.2-14.2zM41.3 55.8v-5h22.2v5H41.3z" />
          </symbol>
          <symbol id="trumbowyg-unordered-list" viewBox="0 0 72 72">
            <path d="M27 14h36v8H27zM27 50h36v8H27zM9 50h9v8H9zM9 32h9v8H9zM9 14h9v8H9zM27 32h36v8H27z" />
          </symbol>
          <symbol id="trumbowyg-view-html" viewBox="0 0 72 72">
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="8"
              strokeMiterlimit="10"
              d="M26.9 17.9L9 36.2 26.9 54M45 54l17.9-18.3L45 17.9"
            />
          </symbol>
          <symbol id="trumbowyg-back-color" viewBox="0 0 72 72">
            <path d="M36.5 22.3l-6.3 18.1H43l-6.3-18.1z" />
            <path d="M9 8.9v54.2h54.1V8.9H9zm39.9 48.2L45 46H28.2l-3.9 11.1h-7.6L32.8 15h7.8l16.2 42.1h-7.9z" />
          </symbol>
          <symbol id="trumbowyg-fore-color" viewBox="0 0 72 72">
            <path d="M32 15h7.8L56 57.1h-7.9l-4-11.1H27.4l-4 11.1h-7.6L32 15zm-2.5 25.4h12.9L36 22.3h-.2l-6.3 18.1z" />
          </symbol>
          <symbol id="trumbowyg-base64" viewBox="0 0 72 72">
            <path d="M64 17v38H8V17h56m8-8H0v54h72V9z" />
            <path d="M29.9 28.9c-.5-.5-1.1-.8-1.8-.8s-1.4.2-1.9.7c-.5.4-.9 1-1.2 1.6-.3.6-.5 1.3-.6 2.1-.1.7-.2 1.4-.2 1.9l.1.1c.6-.8 1.2-1.4 2-1.8.8-.4 1.7-.5 2.7-.5.9 0 1.8.2 2.6.6.8.4 1.6.9 2.2 1.5.6.6 1 1.3 1.2 2.2.3.8.4 1.6.4 2.5 0 1.1-.2 2.1-.5 3-.3.9-.8 1.7-1.5 2.4-.6.7-1.4 1.2-2.3 1.6-.9.4-1.9.6-3 .6-1.6 0-2.8-.3-3.9-.9-1-.6-1.8-1.4-2.5-2.4-.6-1-1-2.1-1.3-3.4-.2-1.3-.4-2.6-.4-3.9 0-1.3.1-2.6.4-3.8.3-1.3.8-2.4 1.4-3.5.7-1 1.5-1.9 2.5-2.5 1-.6 2.3-1 3.8-1 .9 0 1.7.1 2.5.4.8.3 1.4.6 2 1.1.6.5 1.1 1.1 1.4 1.8.4.7.6 1.5.7 2.5h-4c0-1-.3-1.6-.8-2.1zm-3.5 6.8c-.4.2-.8.5-1 .8-.3.4-.5.8-.6 1.2-.1.5-.2 1-.2 1.5s.1.9.2 1.4c.1.5.4.9.6 1.2.3.4.6.7 1 .9.4.2.9.3 1.4.3.5 0 1-.1 1.3-.3.4-.2.7-.5 1-.9.3-.4.5-.8.6-1.2.1-.5.2-.9.2-1.4 0-.5-.1-1-.2-1.4-.1-.5-.3-.9-.6-1.2-.3-.4-.6-.7-1-.9-.4-.2-.9-.3-1.4-.3-.4 0-.9.1-1.3.3zM36.3 41.3v-3.8l9-12.1H49v12.4h2.7v3.5H49v4.8h-4v-4.8h-8.7zM45 30.7l-5.3 7.2h5.4l-.1-7.2z" />
          </symbol>
          <symbol id="trumbowyg-emoji" viewBox="0 0 72 72">
            <path d="M36.05 9C21.09 9 8.949 21.141 8.949 36.101c0 14.96 12.141 27.101 27.101 27.101 14.96 0 27.101-12.141 27.101-27.101S51.01 9 36.05 9zm9.757 15.095c2.651 0 4.418 1.767 4.418 4.418s-1.767 4.418-4.418 4.418-4.418-1.767-4.418-4.418 1.767-4.418 4.418-4.418zm-19.479 0c2.651 0 4.418 1.767 4.418 4.418s-1.767 4.418-4.418 4.418-4.418-1.767-4.418-4.418 1.767-4.418 4.418-4.418zm9.722 30.436c-14.093 0-16.261-13.009-16.261-13.009h32.522S50.143 54.531 36.05 54.531z" />
          </symbol>
          <symbol id="trumbowyg-fontsize" viewBox="0 0 24 24">
            <path d="M9 4v3h5v12h3V7h5V4H9zm-6 8h3v7h3v-7h3V9H3v3z" />
          </symbol>
          <symbol id="trumbowyg-giphy" viewBox="0 0 72 72">
            <g fillRule="nonzero">
              <path d="M15.676 40.149v-5.282h13.645v12.489c-1.326 1.283-3.248 2.413-5.764 3.39-2.516.977-5.065 1.465-7.646 1.465-3.279 0-6.137-.688-8.575-2.064-2.439-1.376-4.271-3.343-5.497-5.903C.613 41.685 0 38.901 0 35.893c0-3.265.684-6.166 2.053-8.704 1.369-2.538 3.372-4.484 6.01-5.839 2.01-1.04 4.512-1.561 7.506-1.561 3.893 0 6.933.816 9.122 2.449 2.188 1.632 3.596 3.889 4.223 6.769l-6.287 1.176c-.442-1.54-1.273-2.755-2.492-3.646-1.219-.892-2.741-1.337-4.566-1.337-2.766 0-4.965.877-6.597 2.63-1.633 1.754-2.449 4.356-2.449 7.806 0 3.722.827 6.513 2.481 8.373 1.654 1.861 3.821 2.791 6.501 2.791 1.326 0 2.656-.26 3.989-.781 1.333-.52 2.477-1.151 3.432-1.892v-3.978h-7.25zM36.568 20.324h6.33v31.352h-6.33zM50.507 51.676V20.324H72v5.304H56.837v7.421h13.089v5.303H56.837v13.324h-6.33z" />
            </g>
          </symbol>
          <symbol id="trumbowyg-highlight" viewBox="0 0 60 60">
            <path d="M11 7c0-2.206-1.794-4-4-4S3 4.794 3 7s1.794 4 4 4 4-1.794 4-4zM7 9c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM16 11c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zM25 11c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z" />
            <path d="M0 0v60h60V0H0zm2 2h56v10H2V2zm0 56V14h5v44H2zm56 0H9V14h49v44z" />
            <path d="M5 17H4a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zM5 21H4a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zM5 25H4a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zM5 29H4a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zM5 33H4a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zM5 37H4a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zM5 41H4a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zM5 45H4a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zM5 49H4a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zM5 53H4a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zM13 19h13a1 1 0 1 0 0-2H13a1 1 0 1 0 0 2zM30 19h10a1 1 0 1 0 0-2H30a1 1 0 1 0 0 2zM44 19h2a1 1 0 1 0 0-2h-2a1 1 0 1 0 0 2zM35 22a1 1 0 0 0 1 1h15a1 1 0 1 0 0-2H36a1 1 0 0 0-1 1zM32 21H18a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2zM13 23h1a1 1 0 1 0 0-2h-1a1 1 0 1 0 0 2zM13 27h9a1 1 0 1 0 0-2h-9a1 1 0 1 0 0 2zM26 27h2a1 1 0 1 0 0-2h-2a1 1 0 1 0 0 2zM46 25H32a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2zM13 31h13a1 1 0 1 0 0-2H13a1 1 0 1 0 0 2zM41 30a1 1 0 0 0-1-1H30a1 1 0 1 0 0 2h10a1 1 0 0 0 1-1zM36 35h8a1 1 0 1 0 0-2h-8a1 1 0 1 0 0 2zM17 34a1 1 0 0 0 1 1h14a1 1 0 1 0 0-2H18a1 1 0 0 0-1 1zM13 35h1a1 1 0 1 0 0-2h-1a1 1 0 1 0 0 2zM13 39h9a1 1 0 1 0 0-2h-9a1 1 0 1 0 0 2zM26 39h2a1 1 0 1 0 0-2h-2a1 1 0 1 0 0 2zM31 38a1 1 0 0 0 1 1h15a1 1 0 1 0 0-2H32a1 1 0 0 0-1 1zM13 43h13a1 1 0 1 0 0-2H13a1 1 0 1 0 0 2zM40 41H30a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2zM51 41h-7a1 1 0 1 0 0 2h7a1 1 0 1 0 0-2zM40 45h-4a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2zM17 46a1 1 0 0 0 1 1h14a1 1 0 1 0 0-2H18a1 1 0 0 0-1 1zM13 47h1a1 1 0 1 0 0-2h-1a1 1 0 1 0 0 2zM22 49h-9a1 1 0 1 0 0 2h9a1 1 0 1 0 0-2zM28 49h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM39 49h-7a1 1 0 1 0 0 2h7a1 1 0 1 0 0-2zM44 49h-1a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2z" />
          </symbol>
          <symbol id="trumbowyg-insert-audio" viewBox="-2 -2 12 12">
            <path d="M3.344 0L2 2H0v4h2l1.344 2H4V0h-.656zM5 1v1c.152 0 .313.026.469.063H5.5c.86.215 1.5.995 1.5 1.938a1.99 1.99 0 0 1-2 2.001v1a2.988 2.988 0 0 0 3-3 2.988 2.988 0 0 0-3-3zm0 2v2l.25-.031C5.683 4.851 6 4.462 6 4c0-.446-.325-.819-.75-.938v-.031h-.031L5 3z" />
          </symbol>
          <symbol id="trumbowyg-lineheight" viewBox="0 0 72 72">
            <path
              fill="currentColor"
              d="M31 14h36v8H31zm0 36h36v8H31zm0-18h30v8H31z"
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="3.643"
              d="M7.82 47.207l8.332 8.15 8.106-8.15m.018-22.572l-8.333-8.15-8.105 8.15"
            />
            <path fill="currentColor" d="M14.348 53.761v-36h3.384v36z" />
          </symbol>
          <symbol id="trumbowyg-mention" viewBox="0 0 128 128">
            <path
              fillRule="evenodd"
              d="M73.309 62.5c-1.53 9.86-8.16 17.85-14.45 17.85-5.61 0-8.5-4.08-8.5-10.54 0-13.089 8.84-24.139 20.06-24.139 2.21 0 3.91.34 5.27.68L73.309 62.5zm14.45 44.2c-6.8 3.57-15.98 5.44-24.65 5.44-25.329 0-43.519-16.15-43.519-44.54 0-32.469 22.78-52.869 48.789-52.869 26.35 0 40.63 17.17 40.63 39.27 0 19.549-9.18 28.899-16.49 28.729-4.76-.17-5.95-4.76-4.25-14.79l5.1-31.449c-4.25-2.38-12.75-4.25-20.23-4.25-24.48 0-39.779 18.87-39.779 39.609 0 13.94 7.99 22.1 19.039 22.1 9.01 0 16.66-4.42 21.93-13.09h.34c.85 9.01 6.63 13.09 14.62 13.09 18.36 0 32.3-15.64 32.3-40.459 0-28.56-21.42-49.13-51.34-49.13C31.83 4.361 6.67 34.451 6.67 69.13c0 33.32 24.82 53.55 53.039 53.55 12.07 0 20.91-1.53 31.11-6.12l-3.06-9.86z"
            />
          </symbol>
          <symbol id="trumbowyg-mathml" viewBox="0 0 445.878 445.878">
            <path d="M426.024 86.447H209.705l-84.911 298.911c-2.568 7.967-9.854 13.482-18.22 13.771-.236 0-.464.006-.688.006a19.868 19.868 0 0 1-18.436-12.478l-34.714-86.782H19.851C8.884 299.876 0 290.986 0 280.022c0-10.965 8.893-19.854 19.851-19.854H66.18a19.862 19.862 0 0 1 18.436 12.483l19.237 48.09 72.472-260.218a19.855 19.855 0 0 1 18.903-13.781h230.798c10.97 0 19.854 8.89 19.854 19.851s-8.892 19.854-19.856 19.854zm10.699 266.78l-78.259-87.904 74.576-82.783c1.318-1.454 1.638-3.547.857-5.341a4.977 4.977 0 0 0-4.54-2.946h-47.18a4.995 4.995 0 0 0-3.759 1.72l-50.059 58.047-49.674-58.029a4.95 4.95 0 0 0-3.771-1.738H225.58a4.947 4.947 0 0 0-4.521 2.929 4.939 4.939 0 0 0 .824 5.332l73.743 82.81-77.641 87.923a4.977 4.977 0 0 0-.813 5.325 4.978 4.978 0 0 0 4.528 2.92h48.9c1.472 0 2.867-.65 3.807-1.785l51.819-62.181 53.05 62.229a4.972 4.972 0 0 0 3.782 1.743h49.97a4.938 4.938 0 0 0 4.527-2.926 4.966 4.966 0 0 0-.832-5.345z" />
          </symbol>
          <symbol id="trumbowyg-noembed" viewBox="0 0 72 72">
            <path d="M31.5 33.6V25l11 11-11 11v-8.8z" />
            <path d="M64 17v38H8V17h56m8-8H0v54h72V9z" />
          </symbol>
          <symbol id="trumbowyg-preformatted" viewBox="0 0 72 72">
            <path d="M10.3 33.5c.4 0 .9-.1 1.5-.2s1.2-.3 1.8-.7c.6-.3 1.1-.8 1.5-1.3.4-.5.6-1.3.6-2.1V17.1c0-1.4.3-2.6.8-3.6s1.2-1.9 2-2.5c.8-.7 1.6-1.2 2.5-1.5.9-.3 1.6-.5 2.2-.5h5.3v5.3h-3.2c-.7 0-1.3.1-1.8.4-.4.3-.8.6-1 1-.2.4-.4.9-.4 1.3-.1.5-.1.9-.1 1.4v11.4c0 1.2-.2 2.1-.7 2.9-.5.8-1 1.4-1.7 1.8-.6.4-1.3.8-2 1-.7.2-1.3.3-1.7.4v.1c.5 0 1 .1 1.7.3.7.2 1.3.5 2 .9.6.5 1.2 1.1 1.7 1.9.5.8.7 2 .7 3.4v11.1c0 .4 0 .9.1 1.4.1.5.2.9.4 1.3s.6.7 1 1c.4.3 1 .4 1.8.4h3.2V63h-5.3c-.6 0-1.4-.2-2.2-.5-.9-.3-1.7-.8-2.5-1.5s-1.4-1.5-2-2.5c-.5-1-.8-2.2-.8-3.6V43.5c0-.9-.2-1.7-.6-2.3-.4-.6-.9-1.1-1.5-1.5-.6-.4-1.2-.6-1.8-.7-.6-.1-1.1-.2-1.5-.2v-5.3zM61.8 38.7c-.4 0-1 .1-1.6.2-.6.1-1.2.4-1.8.7-.6.3-1.1.7-1.5 1.3-.4.5-.6 1.3-.6 2.1v12.1c0 1.4-.3 2.6-.8 3.6s-1.2 1.9-2 2.5c-.8.7-1.6 1.2-2.5 1.5-.9.3-1.6.5-2.2.5h-5.3v-5.3h3.2c.7 0 1.3-.1 1.8-.4.4-.3.8-.6 1-1 .2-.4.4-.9.4-1.3.1-.5.1-.9.1-1.4V42.3c0-1.2.2-2.1.7-2.9.5-.8 1-1.4 1.7-1.8.6-.4 1.3-.8 2-1 .7-.2 1.3-.3 1.7-.4v-.1c-.5 0-1-.1-1.7-.3-.7-.2-1.3-.5-2-.9-.6-.4-1.2-1.1-1.7-1.9-.5-.8-.7-2-.7-3.4V18.5c0-.4 0-.9-.1-1.4-.1-.5-.2-.9-.4-1.3s-.6-.7-1-1c-.4-.3-1-.4-1.8-.4h-3.2V9.1h5.3c.6 0 1.4.2 2.2.5.9.3 1.7.8 2.5 1.5s1.4 1.5 2 2.5c.5 1 .8 2.2.8 3.6v11.6c0 .9.2 1.7.6 2.3.4.6.9 1.1 1.5 1.5.6.4 1.2.6 1.8.7.6.1 1.2.2 1.6.2v5.2z" />
          </symbol>
          <symbol id="trumbowyg-ruby" viewBox="0 0 72 72">
            <path d="M16.499 24.477h8.018L41.08 67.5H33l-4.04-11.361H11.804L7.764 67.5H0l16.499-43.023zm-2.65 25.907h13.127l-6.438-18.497h-.177l-6.512 18.497zM65.053 16.685c-6.316 1.178-12.025 1.98-17.126 2.408a362.385 362.385 0 0 0-.965 5.833c-.25 1.57-.679 3.907-1.286 7.013 3.033-1.963 5.852-3.266 8.458-3.907 2.639-.642 4.905-.891 6.797-.75 1.891.108 3.746.661 5.566 1.661 1.82.964 3.264 2.408 4.334 4.334 1.104 1.893 1.427 4.088.965 6.584-.466 2.461-1.554 4.494-3.265 6.101-1.679 1.605-3.658 2.783-5.941 3.532-2.283.785-4.853 1.251-7.707 1.391-2.819.144-5.906.161-9.259.056 0-1.642-.287-3.212-.857-4.71l.108-.59c2.711.5 5.246.768 7.601.802 2.39 0 4.529-.195 6.421-.589 1.927-.393 3.605-1.069 5.031-2.031 1.427-.965 2.319-2.319 2.676-4.067.394-1.75.269-3.229-.373-4.443-.644-1.249-1.446-2.213-2.408-2.891-.929-.68-2.161-1.034-3.693-1.071-1.536-.072-3.265.089-5.192.482-1.927.391-3.82 1.14-5.672 2.248a24.308 24.308 0 0 0-4.978 3.907l-4.872-1.981c1.463-5.031 2.355-8.597 2.677-10.703.321-2.105.642-4.067.963-5.887-3.961.25-7.154.411-9.58.481-.215-1.927-.52-3.534-.91-4.817l.32-.32c3.604.32 7.225.446 10.865.375.214-1.355.481-3.103.804-5.245.354-2.175.407-3.621.16-4.336.034-.784.374-1.017 1.017-.695l5.085.749c.428.251.444.573.055.964l-.857.91c-.537 2.89-.981 5.352-1.338 7.385 4.279-.427 9.312-1.393 15.092-2.89l1.284 4.707" />
          </symbol>
          <symbol id="trumbowyg-col-delete" viewBox="0 0 24 24">
            <g transform="translate(-326 -532.36)">
              <rect
                width="6"
                height="15"
                x="335"
                y="537.36"
                fill="#ed5565"
                stroke="#000"
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeWidth=".837"
                rx=".646"
              />
              <path d="M347.58 536.498c-.051-.618-.55-1.138-1.178-1.138H329.6c-.628 0-1.127.52-1.18 1.138h-.02v15.663a1.2 1.2 0 0 0 1.2 1.2h16.801a1.2 1.2 0 0 0 1.2-1.2v-15.663h-.021M334.4 552.16h-4.8v-3.6h4.8v3.6m0-4.7h-4.8v-3.7h4.8v3.7m0-4.9h-4.8v-3.601h4.8v3.6m6 9.601h-4.8v-3.6h4.8v3.6m0-4.7h-4.8v-3.7h4.8v3.7m0-4.9h-4.8v-3.601h4.8v3.6m6.001 9.601h-4.8v-3.6h4.8v3.6m0-4.7h-4.8v-3.7h4.8v3.7m0-4.9h-4.8v-3.601h4.8v3.6" />
            </g>
          </symbol>
          <symbol id="trumbowyg-col-left" viewBox="0 0 24 24">
            <g transform="translate(-326 -532.36)">
              <rect
                width="6"
                height="15"
                x="329"
                y="537.36"
                fill="#1ab394"
                stroke="#000"
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeWidth=".837"
                rx=".646"
              />
              <path d="M347.58 536.498c-.051-.618-.55-1.138-1.178-1.138H329.6c-.628 0-1.127.52-1.18 1.138h-.02v15.663a1.2 1.2 0 0 0 1.2 1.2h16.801a1.2 1.2 0 0 0 1.2-1.2v-15.663h-.021M334.4 552.16h-4.8v-3.6h4.8v3.6m0-4.7h-4.8v-3.7h4.8v3.7m0-4.9h-4.8v-3.601h4.8v3.6m6 9.601h-4.8v-3.6h4.8v3.6m0-4.7h-4.8v-3.7h4.8v3.7m0-4.9h-4.8v-3.601h4.8v3.6m6.001 9.601h-4.8v-3.6h4.8v3.6m0-4.7h-4.8v-3.7h4.8v3.7m0-4.9h-4.8v-3.601h4.8v3.6" />
            </g>
          </symbol>
          <symbol id="trumbowyg-col-right" viewBox="0 0 24 24">
            <g transform="matrix(-1 0 0 1 350 -532.36)">
              <rect
                y="537.36"
                x="329"
                height="15"
                width="6"
                fill="#1ab394"
                stroke="#000"
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeWidth=".837"
                rx=".646"
              />
              <path d="M347.58 536.498c-.051-.618-.55-1.138-1.178-1.138H329.6c-.628 0-1.127.52-1.18 1.138h-.02v15.663a1.2 1.2 0 0 0 1.2 1.2h16.801a1.2 1.2 0 0 0 1.2-1.2v-15.663h-.021M334.4 552.16h-4.8v-3.6h4.8v3.6m0-4.7h-4.8v-3.7h4.8v3.7m0-4.9h-4.8v-3.601h4.8v3.6m6 9.601h-4.8v-3.6h4.8v3.6m0-4.7h-4.8v-3.7h4.8v3.7m0-4.9h-4.8v-3.601h4.8v3.6m6.001 9.601h-4.8v-3.6h4.8v3.6m0-4.7h-4.8v-3.7h4.8v3.7m0-4.9h-4.8v-3.601h4.8v3.6" />
            </g>
          </symbol>
          <symbol id="trumbowyg-row-above" viewBox="0 0 24 24">
            <rect
              y="-347"
              x="537.06"
              height="18"
              width="6"
              transform="matrix(0 1 1 0 350 -532.36)"
              fill="#1ab394"
              stroke="#000"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".917"
              rx=".776"
            />
            <path d="M2.42 4.138C2.47 3.52 2.97 3 3.598 3H20.4c.628 0 1.127.52 1.18 1.138h.02V19.8a1.2 1.2 0 0 1-1.2 1.2H3.599a1.2 1.2 0 0 1-1.2-1.2V4.138h.021M15.6 19.8h4.8v-3.6h-4.8v3.6m0-4.7h4.8v-3.7h-4.8v3.7m0-4.9h4.8V6.6h-4.8v3.6m-6 9.601h4.8v-3.6H9.6v3.6m0-4.7h4.8v-3.7H9.6v3.7m0-4.9h4.8V6.6H9.6v3.6m-6.001 9.601h4.8v-3.6H3.6v3.6m0-4.7h4.8v-3.7H3.6v3.7m0-4.9h4.8V6.6H3.6v3.6" />
          </symbol>
          <symbol id="trumbowyg-row-below" viewBox="0 0 24 24">
            <rect
              y="-347.06"
              x="548.4"
              height="18.13"
              width="4.427"
              transform="matrix(0 1 1 0 350 -532.36)"
              fill="#1ab394"
              stroke="#000"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".79"
              rx=".781"
            />
            <path d="M2.42 4.138C2.47 3.52 2.97 3 3.598 3H20.4c.628 0 1.127.52 1.18 1.138h.02V19.8a1.2 1.2 0 0 1-1.2 1.2H3.599a1.2 1.2 0 0 1-1.2-1.2V4.138h.021M15.6 19.8h4.8v-3.6h-4.8v3.6m0-4.7h4.8v-3.7h-4.8v3.7m0-4.9h4.8V6.6h-4.8v3.6m-6 9.601h4.8v-3.6H9.6v3.6m0-4.7h4.8v-3.7H9.6v3.7m0-4.9h4.8V6.6H9.6v3.6m-6.001 9.601h4.8v-3.6H3.6v3.6m0-4.7h4.8v-3.7H3.6v3.7m0-4.9h4.8V6.6H3.6v3.6" />
          </symbol>
          <symbol id="trumbowyg-row-delete" viewBox="0 0 24 24">
            <rect
              width="5"
              height="18"
              x="543.36"
              y="-347"
              transform="rotate(90 103.18 -429.18)"
              fill="#ed5565"
              rx=".776"
            />
            <path d="M21.58 4.136c-.051-.618-.55-1.138-1.178-1.138H3.6c-.628 0-1.127.52-1.18 1.138H2.4v15.663A1.2 1.2 0 0 0 3.6 21h16.801a1.2 1.2 0 0 0 1.2-1.2V4.136h-.021M8.4 19.799H3.6v-3.6h4.8v3.6m0-4.7H3.6v-3.7h4.8v3.7m0-4.9H3.6V6.597h4.8v3.6m6 9.601H9.6v-3.6h4.8v3.6m0-4.7H9.6v-3.7h4.8v3.7m0-4.9H9.6V6.597h4.8v3.6m6.001 9.601h-4.8v-3.6h4.8v3.6m0-4.7h-4.8v-3.7h4.8v3.7m0-4.9h-4.8V6.597h4.8v3.6" />
          </symbol>
          <symbol id="trumbowyg-table-delete" viewBox="0 0 24 24">
            <rect
              y="-347"
              x="537.06"
              height="18"
              width="6"
              transform="matrix(0 1 1 0 350 -532.36)"
              fill="#ed5565"
              stroke="#000"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".917"
              rx=".776"
            />
            <rect
              width="5"
              height="18"
              x="543.36"
              y="-347"
              transform="rotate(90 103.18 -429.18)"
              fill="#ed5565"
              rx=".776"
            />
            <rect
              y="-347.06"
              x="548.4"
              height="18.13"
              width="4.427"
              transform="matrix(0 1 1 0 350 -532.36)"
              fill="#ed5565"
              stroke="#000"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".79"
              rx=".781"
            />
            <path d="M21.58 4.136c-.051-.618-.55-1.138-1.178-1.138H3.6c-.628 0-1.127.52-1.18 1.138H2.4v15.663A1.2 1.2 0 0 0 3.6 21h16.801a1.2 1.2 0 0 0 1.2-1.2V4.136h-.021M8.4 19.799H3.6v-3.6h4.8v3.6m0-4.7H3.6v-3.7h4.8v3.7m0-4.9H3.6V6.597h4.8v3.6m6 9.601H9.6v-3.6h4.8v3.6m0-4.7H9.6v-3.7h4.8v3.7m0-4.9H9.6V6.597h4.8v3.6m6.001 9.601h-4.8v-3.6h4.8v3.6m0-4.7h-4.8v-3.7h4.8v3.7m0-4.9h-4.8V6.597h4.8v3.6" />
          </symbol>
          <symbol id="trumbowyg-table" viewBox="0 0 72 72">
            <path d="M25.686 51.38v-6.347q0-.462-.297-.76-.298-.297-.761-.297H14.04q-.463 0-.761.297-.298.298-.298.76v6.346q0 .463.298.76.298.298.76.298h10.589q.463 0 .76-.298.298-.297.298-.76zm0-12.692v-6.346q0-.463-.297-.76-.298-.298-.761-.298H14.04q-.463 0-.761.298-.298.297-.298.76v6.346q0 .462.298.76.298.297.76.297h10.589q.463 0 .76-.297.298-.298.298-.76zm16.94 12.691v-6.346q0-.462-.297-.76-.298-.297-.761-.297H30.98q-.463 0-.76.297-.299.298-.299.76v6.346q0 .463.298.76.298.298.761.298h10.588q.463 0 .76-.298.299-.297.299-.76zm-16.94-25.383v-6.345q0-.463-.297-.76-.298-.298-.761-.298H14.04q-.463 0-.761.297-.298.298-.298.76v6.346q0 .463.298.76.298.298.76.298h10.589q.463 0 .76-.298.298-.297.298-.76zm16.94 12.692v-6.346q0-.463-.297-.76-.298-.298-.761-.298H30.98q-.463 0-.76.298-.299.297-.299.76v6.346q0 .462.298.76.298.297.761.297h10.588q.463 0 .76-.297.299-.298.299-.76zm16.94 12.691v-6.346q0-.462-.297-.76-.298-.297-.76-.297H47.92q-.463 0-.76.297-.298.298-.298.76v6.346q0 .463.297.76.298.298.761.298h10.588q.463 0 .761-.298.298-.297.298-.76zm-16.94-25.383v-6.345q0-.463-.297-.76-.298-.298-.761-.298H30.98q-.463 0-.76.297-.299.298-.299.76v6.346q0 .463.298.76.298.298.761.298h10.588q.463 0 .76-.298.299-.297.299-.76zm16.94 12.692v-6.346q0-.463-.297-.76-.298-.298-.76-.298H47.92q-.463 0-.76.298-.298.297-.298.76v6.346q0 .462.297.76.298.297.761.297h10.588q.463 0 .761-.297.298-.298.298-.76zm0-12.692v-6.345q0-.463-.297-.76-.298-.298-.76-.298H47.92q-.463 0-.76.297-.298.298-.298.76v6.346q0 .463.297.76.298.298.761.298h10.588q.463 0 .761-.298.298-.297.298-.76zm4.236-10.576v35.96q0 2.18-1.555 3.734-1.555 1.553-3.739 1.553H14.04q-2.184 0-3.739-1.553-1.555-1.553-1.555-3.735V15.42q0-2.181 1.555-3.735 1.555-1.553 3.739-1.553h44.468q2.184 0 3.739 1.553 1.555 1.554 1.555 3.735z" />
          </symbol>
          <symbol id="trumbowyg-special-chars" viewBox="0 0 72 72">
            <text
              style={{ textAlign: "start", lineHeight: "125%" }}
              x="6.03"
              y="48.528"
              transform="scale(1.0117 .98844)"
              fontSize="60.056"
              fontWeight="400"
              fontFamily="Times New Roman"
            >
              <tspan x="6.03" y="48.528">
                &#x2122;
              </tspan>
            </text>
          </symbol>
          <symbol id="trumbowyg-upload" viewBox="0 0 72 72">
            <path d="M64 27v28H8V27H0v36h72V27h-8z" />
            <path d="M32.1 6.7h8v33.6h-8z" />
            <path d="M48 35.9L36 49.6 24 36h24z" />
          </symbol>
        </svg>
        <div id={`${this.props.id}`} placeholder={this.props.placeholder} />
      </>
    );
  }
}

Trumbowyg.defaultProps = {
  buttons: [
    ["viewHTML"],
    ["formatting"],
    "btnGrp-semantic",
    ["superscript", "subscript"],
    ["link"],
    ["insertImage"],
    "btnGrp-justify",
    "btnGrp-lists",
    ["horizontalRule"],
    ["removeformat"],
    ["fullscreen"],
  ],
  semantic: true,
  resetCss: false,
  removeformatPasted: false,
  autogrow: false,
  disabled: false,
  btnsDef: {},
  plugins: {},
};

Trumbowyg.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  buttons: PropTypes.array,
  semantic: PropTypes.bool,
  resetCss: PropTypes.bool,
  removeformatPasted: PropTypes.bool,
  autogrow: PropTypes.bool,
  disabled: PropTypes.bool,
  btnsDef: PropTypes.object,
  //event handlers
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onInit: PropTypes.func,
  onChange: PropTypes.func,
  onResize: PropTypes.func,
  onPaste: PropTypes.func,
  onOpenFullScreen: PropTypes.func,
  onCloseFullScreen: PropTypes.func,
  onClose: PropTypes.func,

  plugins: PropTypes.object,
  lang: PropTypes.string,
};

export default Trumbowyg;
