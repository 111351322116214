import React, { createContext, Dispatch, useReducer } from "react";
import TestModeStorageKey, { storageKey } from "./testModeStoragerReducer";

export interface TestModeStoreState {
  testMode: boolean;
  dispatch?: Dispatch<any>;
}

const initialState: TestModeStoreState = {
  testMode: Boolean(localStorage.getItem(storageKey) || false),
};

type Reducer = (
  prevState: TestModeStoreState,
  action: any
) => TestModeStoreState;

const TestModeStore = ({ children }: { children: React.ReactElement }) => {
  const [testMode, dispatch] = useReducer<Reducer>(
    TestModeStorageKey,
    initialState
  );
  return (
    <TestModeContext.Provider value={{ testMode: testMode.testMode, dispatch }}>
      {children}
    </TestModeContext.Provider>
  );
};

export const TestModeContext = createContext(initialState);
export default TestModeStore;
