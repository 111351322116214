import React from "react";
import ConfigPageContainer from "../../components/config/ConfigPageContainer";
import { Site } from "../../domain/DomainModel";
import { withFirebase } from "../../firebase";
import QuickStartSection from "../../components/quickstart/QuickstartSection";
import { STRIPE_TESTING_LINK } from "../../utils/stripeUtils";

interface QuickStartPageProps {
  site: Site;
}

const QuickStartPage = (props: QuickStartPageProps) => {
  const [testScreenVisible, setTestScreenVisible] = React.useState(false);

  return (
    <ConfigPageContainer>
      <QuickStartSection
        key="quickstartprod"
        title="Start Using Tenstars"
        site={props.site}
        testMode={false}
        setTestScreenVisible={setTestScreenVisible}
      >
        <div>
          <p className="mb-2">
            Connecting and implementing can be done in just 3 simple steps.
          </p>
          <p className="mb-2">
            Do you think someone else should do this instead?
          </p>
          <a href="mailto:francesc@tenstars.co">Ask a Tenstars team member</a>
        </div>
      </QuickStartSection>
      <QuickStartSection
        key="quickstarttest"
        title="Test your integration before going live"
        site={props.site}
        testMode={true}
        testScreenVisible={testScreenVisible}
        setTestScreenVisible={setTestScreenVisible}
      >
        <p className="mb-2">
          Do you have a test environment or want to run tests first in
          development mode?{" "}
          <a
            href={STRIPE_TESTING_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn what is the difference between test and live modes
          </a>
        </p>
      </QuickStartSection>
    </ConfigPageContainer>
  );
};

export default withFirebase(QuickStartPage);
