import React from "react";
import ChartistGraph from "react-chartist";

interface CompletionDonutProps {
  maxSteps: number;
  completeSteps: number;
}

const CompletionDonut = (props: CompletionDonutProps) => {
  const createSeries = () => {
    let series = [];

    for (let i = 0; i < props.completeSteps; i++) {
      series.push({ value: 1, className: "full" });
    }

    while (series.length < props.maxSteps) {
      series.push({ value: 1, className: "empty" });
    }

    return series;
  };

  const data = {
    series: createSeries(),
  };

  const options = {
    donut: true,
    donutWidth: 8,
    width: 48,
    height: 48,
    showLabel: false,
    total: props.maxSteps,
  };

  return (
    <div
      className="progress-donut relative"
      style={{ width: "48px", height: "48px" }}
    >
      <ChartistGraph data={data} options={options as any} type="Pie" />
      <div className="centered-number">{props.completeSteps}</div>
    </div>
  );
};
export default CompletionDonut;
