import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import config from "./config";
import firebase from "firebase";
import { Product, Site, User, RecursivePartial } from "../domain/DomainModel";

class Firebase {
  private auth: firebase.auth.Auth;
  private db: firebase.database.Database;

  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
  }

  getDb = (): firebase.database.Database => {
    return this.db;
  };

  getAuth = (): firebase.auth.Auth => {
    return this.auth;
  };

  registerUserAnonymously = async (): Promise<firebase.auth.UserCredential> => {
    return this.auth.signInAnonymously();
  };

  signInWithGooglePopup = async (): Promise<firebase.auth.UserCredential> => {
    return this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  };

  isUserAnonymous = () => !!this.auth.currentUser?.isAnonymous;

  upgradeAccount = async (
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential | undefined> => {
    let credential = app.auth.EmailAuthProvider.credential(email, password);
    return this.auth.currentUser?.linkWithCredential(credential);
  };

  getToken = async (): Promise<string | undefined> => {
    return this.auth.currentUser?.getIdToken();
  };

  getAFreshToken = async (): Promise<string | undefined> => {
    return this.auth.currentUser?.getIdToken(/* forceRefresh */ true);
  };

  createUserWithEmailAndPassword = (
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> =>
    this.auth.createUserWithEmailAndPassword(email, password);

  signInWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> =>
    this.auth.signInWithEmailAndPassword(email, password);

  registerUser = async (uid: string, data: object): Promise<any> =>
    this.db.ref(`users/${uid}`).set(data);

  signOut = async (): Promise<void> => this.auth.signOut();

  passwordReset = async (email: string): Promise<void> =>
    this.auth.sendPasswordResetEmail(email);

  saveNewSite = async (
    userId: string,
    url: string,
    email: string,
    code: string | null = null
  ): Promise<string | null> => {
    let createdOn = new Date().getTime();
    let newSiteRef = await this.db.ref("sites").push({
      usedCode: code,
      owner: userId,
      urls: {
        siteUrl: [url],
        siteTestUrl: [url],
      },
      isRetentionActive: true,
      createdOn,
      // Do not set to false by default. Null means it was never set
      offSite: null,
      questions: [
        {
          id: 0,
          title: "It has too many bugs",
          en: "It has too many bugs",
          es: "Tiene demasiados errores",
        },
        {
          id: 1,
          title: "I found a better solution",
          en: "I found a better solution",
          es: "He encontrado una solución mejor",
        },
        {
          id: 2,
          title: "I don't use it anymore",
          en: "I don't use it anymore",
          es: "No lo uso lo suficiente",
        },
        {
          id: 3,
          title: "It lacks features I need",
          en: "It lacks features I need",
          es: "No tiene todo lo que necesito",
        },
      ],
      basicRecovery: {
        isOpenCommentActive: false,
        makeOfferDuringTrialPeriod: false,
        discount: 20,
        retentionDiscount: 20,
        replyAddress: {
          en: email,
          es: email,
        },
      },
    });

    return newSiteRef.key;
  };

  updateSite = async (
    siteId: string,
    dataToUpdate: RecursivePartial<Site> & {
      "basicRecovery/retentionDiscount"?: number;
    }
  ): Promise<any> => {
    await this.db.ref("sites").child(siteId).update(dataToUpdate);
  };

  updateUser = async (
    userId: string,
    dataToUpdate: Partial<User>
  ): Promise<any> => {
    await this.db.ref("users").child(userId).update(dataToUpdate);
  };

  updateProduct = async (
    productId: string,
    updatedData: Partial<Product>
  ): Promise<any> => {
    return this.db.ref("products").child(productId).update(updatedData);
  };

  requestAllProducts = async (siteId: string): Promise<Product[]> => {
    return (this.db
      .ref("products")
      .orderByChild("siteID")
      .equalTo(siteId)
      .once("value")
      .valueOf() as any)
      .then((snap: any) => snap.val())
      .catch((err: Error) =>
        console.error("error retrieving all products for the site", err)
      );
  };

  markAsRedeemed = async (code: string): Promise<void> => {
    await this.db.ref("codes").child(code).update({ redeemed: true });
  };
}

export default Firebase;
