import React from "react";
import classNames from "classnames";
import ToggleWithTip from "../basic/ToggleWithTip";
import { currencySymbols } from "../../domain/InternationalDomain";

interface CurrencyDropdownProps {
  action: (value: string) => void;
  disabled?: boolean;
  selected: string;
  modifier?: string;
  convertOthers: boolean;
  updateConvertOthers?: (value: boolean) => void;
  canCovert?: boolean;
}

function CurrencyDropdown(props: CurrencyDropdownProps) {
  return (
    <div className="inline-flex">
      <select
        disabled={props.disabled}
        value={props.selected}
        onChange={(e) => {
          props.action(e.currentTarget.value);
        }}
        className={classNames("browser-default custom-select", props.modifier)}
        style={{ minWidth: "100px" }}
      >
        {Object.keys(currencySymbols).map((s) => (
          <option key={s} value={s} label={s.toUpperCase()} />
        ))}
      </select>
      <div className="my-auto ml-2 pb-2">
        <ToggleWithTip
          onChange={() =>
            props.updateConvertOthers &&
            props.updateConvertOthers(!props.convertOthers)
          }
          infoTitle="Include other currencies"
          infoDescription={`If enabled, data in all currencies will be shown as ${props.selected.toUpperCase()} using an estimated conversion rate.`}
          title="Group currencies"
          value={props.convertOthers}
          disabled={false}
          visible={Boolean(props.canCovert && props.updateConvertOthers)}
        />
      </div>
    </div>
  );
}

export default CurrencyDropdown;
