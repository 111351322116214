import React, { useContext, useState } from "react";
import { FirebaseContext } from "../../firebase/index";
import PropTypes from "prop-types";
import { Button, Form, Input, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { SharedContext } from "../common/SharedProvider";
import ConfigSectionContainer from "./ConfigSectionContainer";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const ConfigSectionSiteUrls = (props) => {
  const [siteUrl] = useState(props.site.urls?.siteUrl);
  const [siteTestUrl] = useState(props.site.urls?.siteTestUrl);

  const context = useContext(FirebaseContext);
  const utils = useContext(SharedContext);

  const onFinish = (values) => {
    const updatedSite = {
      ...props.site,
      urls: values,
    };

    // update @firebase site
    context
      .updateSite(props.site.key, updatedSite)
      .then(() => {
        utils.setSnackConfig({
          isOpen: true,
          message: "Configuration updated successfully!",
          severity: "success",
        });
      })
      .catch((err) => {
        console.error(
          "Error when updating the site with new credentials:",
          err
        );
        utils.setSnackConfig({
          isOpen: true,
          message: "It seems there was an error",
          severity: "error",
        });
      });
  };

  return (
    <ConfigSectionContainer title={props.title || "Your site Urls"}>
      <p className="mt-3">
        In order to protect your site, the cancellations and incentives only
        work from the pre-defined urls. You have one for production and one for
        your dev environment.
      </p>

      <Form
        name="dynamic_form_item"
        className="mt-3 p-3"
        layout="vertical"
        {...formItemLayoutWithOutLabel}
        onFinish={onFinish}
        initialValues={{ siteUrl: siteUrl, siteTestUrl: siteTestUrl }}
      >
        <Form.List name="siteUrl">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Production" : ""}
                    required={false}
                    key={field.key}
                    style={{ fontWeight: "bold" }}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            "Please input your production url or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder="yourdomain.com"
                        style={{ width: "100%" }}
                      />
                      {/*<Input placeholder="yourdomain.com" style={{ width: '60%' }} />*/}
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ margin: "0 8px" }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "60%" }}
                  >
                    <PlusOutlined /> Add production Url
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <Space />
        <Form.List name="siteTestUrl">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Development" : ""}
                    required={false}
                    key={field.key}
                    style={{ fontWeight: "bold" }}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            "Please input your development url or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder="http://dev.yourdomain.com"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ margin: "0 8px" }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "60%" }}
                  >
                    <PlusOutlined /> Add development Url
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="btn btn-primary"
              style={{ height: "auto", marginLeft: "auto" }}
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </ConfigSectionContainer>
  );
};

ConfigSectionSiteUrls.propTypes = {
  title: PropTypes.string,
  site: PropTypes.shape({
    key: PropTypes.string,
    site: PropTypes.object,
  }),
};

export default ConfigSectionSiteUrls;
