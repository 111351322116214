import ConfigPageContainer from "../../components/config/ConfigPageContainer";
import React, { useState } from "react";
import ConfigSectionTry from "../../components/config/ConfigSectionTry";
import ConfigSectionRetention from "../../components/config/ConfigSectionRetention/ConfigSectionRetention";
import ConfigSectionSurvey from "../../components/config/ConfigSectionSurvey";
import { AuthUser, Site } from "../../domain/DomainModel";
import { DEFAULT_LANGUAGE } from "../../domain/InternationalDomain";

interface RetentionPageProps {
  site: Site;
  authUser?: AuthUser;
}

const RetentionPage = (props: RetentionPageProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    props.authUser?.user.defaultLanguage || DEFAULT_LANGUAGE
  );

  return (
    <ConfigPageContainer>
      <ConfigSectionTry
        language={selectedLanguage}
        apiKey={props.site.testApiKey || props.site.liveApiKey}
      />
      <ConfigSectionRetention site={props.site} />
      <ConfigSectionSurvey
        selectedLanguage={selectedLanguage}
        updateSelectedLanguage={setSelectedLanguage}
        site={props.site}
      />
    </ConfigPageContainer>
  );
};

export default RetentionPage;
