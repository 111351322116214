import React, { useContext, useState } from "react";
import "highlight.js/styles/github.css";
import { Site } from "../../domain/DomainModel";

import hljs from "highlight.js";
import xml from "highlight.js/lib/languages/xml";
import { SharedContext } from "./SharedProvider";
import AlertWarning from "../basic/AlertWarning";

hljs.registerLanguage("xml", xml);
hljs.configure({ useBR: true });

interface SimpleSnippetSectionProps {
  site: Site;
  isTest: boolean;
  snippetTextFunction: (apiKey: string) => string[];
  warningMessage?: string;
}

const SimpleSnippetSection = (props: SimpleSnippetSectionProps) => {
  const utils = useContext(SharedContext);
  const [hidden, setHidden] = useState<boolean>(true);

  const updateClipboard = (newClip: any) => {
    navigator.clipboard.writeText(newClip).then(
      () => {
        utils.setSnackConfig({
          isOpen: true,
          message: `😊 Copied!`,
          severity: "info",
        });
      },
      () => {
        utils.setSnackConfig({
          isOpen: true,
          message: `Oops, something went wrong!`,
          severity: "error",
        });
      }
    );
  };

  const apiKey = props.isTest ? props.site?.testApiKey : props.site?.liveApiKey;

  if (!apiKey) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="mt-8 break-all">
        {props.warningMessage && (
          <div className="mb-4">
            <AlertWarning text={props.warningMessage} />
          </div>
        )}
        {!hidden && (
          <div className="code-block bg-grey-pale my-4 br-rad-1">
            <div className="code px-5 py-4">
              {props
                .snippetTextFunction(apiKey)
                .map((st: string, i: number) => (
                  <div key={`${st}-${apiKey}-${i}-${props.isTest}`}>
                    <code
                      dangerouslySetInnerHTML={{
                        __html: hljs.highlight("xml", st).value,
                      }}
                    />
                    <br />
                  </div>
                ))}
            </div>
          </div>
        )}
        <div className="mb-4">
          <button
            className="btn btn-secondary"
            onClick={() =>
              updateClipboard(
                props.snippetTextFunction(apiKey).reduce((a, b) => a + b, "")
              )
            }
          >
            <svg
              width="1.4em"
              height="1.4em"
              viewBox="0 0 16 16"
              className="bi bi-file-earmark-code mr-2 d-inline"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 1h5v1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6h1v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z" />
              <path d="M9 4.5V1l5 5h-3.5A1.5 1.5 0 0 1 9 4.5z" />
              <path
                fillRule="evenodd"
                d="M8.646 6.646a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 9 8.646 7.354a.5.5 0 0 1 0-.708zm-1.292 0a.5.5 0 0 0-.708 0l-2 2a.5.5 0 0 0 0 .708l2 2a.5.5 0 0 0 .708-.708L5.707 9l1.647-1.646a.5.5 0 0 0 0-.708z"
              />
            </svg>
            Copy
          </button>
          <span
            className="ml-4 underline cursor-pointer"
            onClick={() => setHidden(!hidden)}
          >
            {hidden ? "Show me the code" : "Hide the code"}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SimpleSnippetSection;
