import { subscriptionLink, subscriptionEditLink } from "../../utils/linkUtils";
import AlertWarning from "../basic/AlertWarning";
import React from "react";
import { TenstarsAction } from "../../domain/DomainModel";
import AlertInfo from "../basic/AlertInfo";
import AlertSuccess from "../basic/AlertSuccess";
import { applyDiscount } from "../../utils/formatters";

interface ActionDetailsProps {
  event: TenstarsAction;
}

interface ActionDetailsBody {
  text?: string;
  to?: string;
  linkText?: string;
  type: "info" | "warning" | "success";
}

const ActionDetails = (
  props: ActionDetailsProps
): React.ReactElement | null => {
  const cancelledEventActions = (event: TenstarsAction): ActionDetailsBody => {
    if (!event.confirmed)
      return {
        type: "warning",
        text: `Action Required:`,
        linkText: `Cancel the subscription of ${event.name} according to your policy.`,
        to: subscriptionEditLink(event.subscriptionId, event.testMode),
      };
    else {
      return {
        type: "success",
        text: `You have already cancelled the subscription of ${event.name}.${
          event.emailSent
            ? ` We have sent ${event.name} an automatic email as a last chance to recover the subscription with the new incentives.`
            : ""
        }`,
        linkText: `See subscription`,
        to: subscriptionLink(event.subscriptionId, event.testMode),
      };
    }
  };

  const savedEventActions = (event: TenstarsAction): ActionDetailsBody => {
    if (!event.appliedPercentageDiscount) {
      return {
        type: "warning",
        text: `Action Required:`,
        linkText: `Give ${event.name} the ${
          event.discount
        }% discount you promised. Change the subscription price to ${applyDiscount(
          event.priceInCents,
          event.discount
        )} ${event.currency.toUpperCase()} using a -${
          event.discount
        }% off coupon.`,
        to: subscriptionEditLink(event.subscriptionId, event.testMode),
      };
    } else {
      if (event.appliedPercentageDiscount === event.discount) {
        return {
          type: "success",
          text: `You have given ${event.name} a ${event.appliedPercentageDiscount}% discount.`,
          linkText: `See subscription`,
          to: subscriptionLink(event.subscriptionId, event.testMode),
        };
      } else {
        return {
          type: "warning",
          text: `Warning: ${event.name} has a ${event.appliedPercentageDiscount}% but was promised a ${event.discount}%.`,
          linkText: `Update the discount. Change the subscription price to ${applyDiscount(
            event.priceInCents,
            event.discount
          )} ${event.currency.toUpperCase()} using a -${
            event.discount
          }% off coupon.`,
          to: subscriptionEditLink(event.subscriptionId, event.testMode),
        };
      }
    }
  };

  const actionDetails = (event: TenstarsAction): ActionDetailsBody => {
    switch (event.type) {
      case "saved":
        return savedEventActions(event);
      case "cancelled":
        return cancelledEventActions(event);
      default:
        return { type: "info" };
    }
  };

  const { text, to, linkText, type } = actionDetails(props.event);

  if (!text || !to || !linkText) {
    return null;
  }

  return (
    <div className="mt-2">
      {type === "warning" && (
        <AlertWarning text={text} to={to} linkText={linkText} />
      )}
      {type === "info" && <AlertInfo text={text} to={to} linkText={linkText} />}
      {type === "success" && (
        <AlertSuccess text={text} to={to} linkText={linkText} />
      )}
    </div>
  );
};

export default ActionDetails;
