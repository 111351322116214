import axios, { AxiosResponse } from "axios";

export interface Post {
  id?: number;
  date?: string;
  date_gmt?: string;
  guid?: { rendered: string };
  modified?: string;
  modified_gmt?: string;
  slug?: string;
  status?: string;
  type?: string;
  link?: string;
  title?: { rendered: string };
  content?: { rendered: string };
  excerpt?: { rendered: string; protected: false };
  author?: number;
  featured_media?: number;
  comment_status?: string;
  ping_status?: string;
  sticky?: boolean;
  template?: string;
  format?: string;
  meta?: string[];
  categories?: number[];
  tags?: string[];
  _embedded?: any;
}

interface Embedded {}

const baseURL = "https://backend.tenstars.co/wp-json";

export const getLastPosts = (count: number): Promise<AxiosResponse<Post[]>> => {
  return axios
    .create({ baseURL })
    .get<Post[]>(`wp/v2/posts?per_page=${count}&_embed`);
};
