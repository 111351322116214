import React from "react";
import Delta from "./KpiBlocks/Delta";
import { formatFigure } from "../../utils/formatters";
import { SimplerLoader } from "../icons/Loader";
import classNames from "classnames";

interface FigureHeaderProps {
  name: string;
  unit?: string;
  figure?: number;
  stringFigure?: string;
  prevFigure?: number;
  descriptor: string;
  reverseDelta?: boolean;
  hideFigure?: boolean;
  loading?: boolean;
}

const FigureHeader = (props: FigureHeaderProps) => (
  <dl>
    <dt className="text-xl font-weight-bold text-gray-500 text-left mb-1">
      {props.name}
    </dt>
    {props.descriptor && !props.loading && (
      <dt className="text-sm font-medium text-gray-500 mt-2 mb-4">
        {props.descriptor}
      </dt>
    )}

    {!props.hideFigure && (
      <div className="flex flex-col justify-between">
        <dd>
          {!props.loading && (
            <div
              className={classNames(
                "text-4xl font-semibold whitespace-nowrap mb-2",
                {
                  "text-4xl": (props.figure || 0) < 1000,
                  "text-3xl":
                    (props.figure || 0) < 10000 && (props.figure || 0) >= 1000,
                  "text-2xl":
                    (props.figure || 0) < 100000 &&
                    (props.figure || 0) >= 10000,
                }
              )}
            >
              {props.stringFigure
                ? props.stringFigure
                : formatFigure(props.figure, props.unit)}
              {props.prevFigure !== undefined && (
                <span
                  className={classNames(
                    "text-gray-500 ml-2",
                    { "text-xl": (props.prevFigure || 0) < 100 },
                    {
                      "text-lg":
                        (props.prevFigure || 0) >= 100 &&
                        (props.prevFigure || 0) < 1000,
                    },
                    { "text-base": (props.prevFigure || 0) >= 1000 }
                  )}
                >
                  from {formatFigure(props.prevFigure, props.unit)}
                </span>
              )}
            </div>
          )}

          {props.loading && (
            <div className="my-2">
              <SimplerLoader />
            </div>
          )}
        </dd>
        <Delta
          reverse={props.reverseDelta}
          current={props.figure}
          previous={props.prevFigure}
          unit={props.unit}
        />
      </div>
    )}
  </dl>
);

export default FigureHeader;
