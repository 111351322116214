import React, { useContext, useState } from "react";
import { AuthUser, Site } from "../../../domain/DomainModel";
import Firebase, { withFirebase } from "../../../firebase";
import { requestChurn, requestStats } from "../../../api/api";
import {
  TestModeContext,
  TestModeStoreState,
} from "../../../reducer/TestModeStore";
import { MetricsResponse, StatsResponse } from "../../../domain/metricsModel";
import TestModeToggle from "../../../components/common/TestModeToggle";
import { hasCredentials, hasCredentialsFor } from "../../../utils/siteUtils";
import MonthsDropdown from "../../../components/common/MonthsDropdown";
import CurrencyDropdown from "../../../components/common/CurrencyDropdown";
import { DEFAULT_CURRENCY } from "../AccountPage";
import RetentionCohortChart from "../../../components/dashboard/Retention/RetentionCohortChart";
import DashboardChurnChart from "../../../components/dashboard/ChurnRate/DashboardChurnChart";
import SimulationTeaser from "../../../components/common/SimulationTeaser";
import DashboardRetentionChart from "../../../components/dashboard/Retention/DashboardRetentionChart";
import { DEFAULT_LANGUAGE } from "../../../domain/InternationalDomain";
import DashboardCancellationsChart from "../../../components/dashboard/Cancellations/DashboardCancellationsChart";
import { RatesContext } from "../../../firebase/RatesContext";
import CollapsibleSection from "../../../components/basic/CollapsibleSection";
import KpiBlocks from "../../../components/dashboard/KpiBlocks/KpiBlocks";
import { transformAcquisitionKpiBlocksData } from "../../../components/dashboard/KpiBlocks/acquisitionMapper";
import { transformRetentionKpiBlocksData } from "../../../components/dashboard/KpiBlocks/retentionMapper";
import DashboardGrowthRateChart from "../../../components/dashboard/GrowthRate/DashboardGrowthRateChart";
import DashboardLtvChart from "../../../components/dashboard/Ltv/DashboardLtvChart";
import DashboardSegregationTables from "../../../components/dashboard/SegregationTables/DashboardSegregationTables";
import AlertInfo from "../../../components/basic/AlertInfo";
import amplitudeService from "../../../service/amplitudeService";
import AlertWarning from "../../../components/basic/AlertWarning";

const CHART_MIN_HEIGHT = 600;

interface DashboardProps {
  authUser?: AuthUser;
  firebase: Firebase;
  site?: Site;
  token: string;
  menuClosed: boolean;
}

const Dashboard = ({
  firebase,
  authUser,
  site,
  token,
  menuClosed,
}: DashboardProps) => {
  const rates = useContext(RatesContext);

  const [convertOthers, setConvertOthers] = useState<boolean>(false);

  const [mounted, setMounted] = useState(false);
  const [churnLoading, setChurnLoading] = useState<boolean>(false);
  const [statsLoading, setStatsLoading] = useState<boolean>(false);

  const { testMode } = useContext<TestModeStoreState>(TestModeContext);

  const [metrics, setMetrics] = useState<MetricsResponse | undefined>();
  const [statsData, setStatsData] = useState<StatsResponse | undefined>();

  const [numMonths, setNumMonths] = useState<number>(12);

  const [currency, setCurrency] = useState<string>(
    authUser?.user?.preferredCurrency || DEFAULT_CURRENCY
  );

  const [isTestStatsSimulation, setIsTestStatsSimulation] = useState(false);
  const [isTestMetricsSimulation, setIsTestMetricsSimulation] = useState(false);
  const [isProdStatsSimulation, setIsProdStatsSimulation] = useState(false);
  const [isProdMetricsSimulation, setIsProdMetricsSimulation] = useState(false);
  const [fetchError, setIsFetchError] = useState(false);

  const isStatsSimulation = () =>
    (isTestStatsSimulation && testMode) || (isProdStatsSimulation && !testMode);

  const isMetricsSimulation = () =>
    (isTestMetricsSimulation && testMode) ||
    (isProdMetricsSimulation && !testMode);

  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    authUser?.user.defaultLanguage || DEFAULT_LANGUAGE
  );

  React.useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  React.useEffect(() => {
    if (Object.keys(rates).length) setConvertOthers(true);
  }, [rates]);

  React.useEffect(() => {
    if (testMode === undefined) {
      return;
    }

    if (mounted) {
      loadChurn();
      loadStats();
    }
    // eslint-disable-next-line
  }, [mounted, testMode]);

  const onMetricsError = (error: Error | any): void => {
    amplitudeService.reportDashboardMetricsError(error);
    setIsFetchError(true);
  };

  const onStatsError = (error: Error | any): void => {
    amplitudeService.reportDashboardStatsError(error);
    setIsFetchError(true);
  };

  const loadChurn = async (): Promise<void> => {
    if (!churnLoading && site?.key) {
      setChurnLoading(true);
      setMetrics(undefined);
      requestChurn(token, site.key, testMode)
        .then(async (response) => {
          setChurnLoading(false);

          if (!mounted) {
            return;
          }

          if (!response.data.testMode) {
            setIsProdMetricsSimulation(!!response.data.simulation);
          }

          if (response.data.testMode) {
            setIsTestMetricsSimulation(!!response.data.simulation);
          }

          if (response.status === 200 || response.status === 201) {
            if (response.data) {
              setMetrics(response.data);
            }
          } else {
            console.error(JSON.stringify(response));
            onMetricsError(response.data);
          }
        })
        .catch((e) => {
          onMetricsError(e);
          setChurnLoading(false);

          if (!mounted) {
            return;
          }
        });
    }
  };

  const loadStats = async (): Promise<void> => {
    if (!statsLoading && site?.key) {
      setStatsLoading(true);
      setStatsData(undefined);

      requestStats(token, site.key, testMode)
        .then(async (response) => {
          setStatsLoading(false);
          if (!mounted) {
            return;
          }

          if (response.status === 200 || response.status === 201) {
            if (response.data) {
              if (!response.data.testMode) {
                setIsProdStatsSimulation(!!response.data.simulation);
              }

              if (response.data.testMode) {
                setIsTestStatsSimulation(!!response.data.simulation);
              }

              setStatsData(response.data);
            }
          } else {
            console.error(JSON.stringify(response));
            onStatsError(response.data);
          }
        })
        .catch((e) => {
          onStatsError(e);
          setStatsLoading(false);

          if (!mounted) {
            return;
          }
        });
    }
  };

  return (
    <div className="relative w-100">
      {fetchError && (
        <AlertWarning text="You account has a large volume of data. It is being processed in batch and you will receive an email when it's complete." />
      )}
      <div className="mb-8 mx-1">
        <SimulationTeaser
          text="Connect your Stripe account to collect your metrics and start generating insights about your business subscriptions."
          testMode={testMode}
          disabled={hasCredentialsFor(testMode, site)}
        />
      </div>
      <div className="config-item-box flex flex-col sm:flex-row justify-content-between justify-content-between">
        <div className="pb-4">
          <TestModeToggle
            disabled={churnLoading || statsLoading}
            visible={!!site && hasCredentials(site)}
          />
        </div>
        <div className="flex pb-4 justify-end">
          <div className="mr-2">
            <MonthsDropdown
              action={(c) => setNumMonths(parseInt(c))}
              selected={String(numMonths)}
            />
          </div>
          <CurrencyDropdown
            action={(c: string) => setCurrency(c)}
            selected={currency}
            modifier="w-auto"
            convertOthers={convertOthers}
            updateConvertOthers={setConvertOthers}
            canCovert={!!Object.keys(rates).length}
          />
        </div>
      </div>
      <div>
        <CollapsibleSection title="Acquisition">
          <>
            {isMetricsSimulation() && hasCredentialsFor(testMode, site) && (
              <AlertInfo text="Looks like you don't have subscriptions yet. This a preview of how your dashboard will look once you do." />
            )}
          </>
          <KpiBlocks
            statsData={statsData}
            churnData={metrics}
            currency={currency}
            numMonths={numMonths}
            rates={rates}
            convertOthers={convertOthers}
            mapper={transformAcquisitionKpiBlocksData}
            loading={churnLoading || statsLoading}
          />
          <div className="flex flex-wrap mb-8">
            <DashboardGrowthRateChart
              data={metrics}
              numMonths={numMonths}
              currency={currency}
              rates={rates}
              convertOthers={convertOthers}
              menuClosed={menuClosed}
              loading={churnLoading}
              minHeight={CHART_MIN_HEIGHT}
            />
            <DashboardLtvChart
              data={metrics}
              numMonths={numMonths}
              currency={currency}
              rates={rates}
              convertOthers={convertOthers}
              menuClosed={menuClosed}
              loading={churnLoading}
              minHeight={CHART_MIN_HEIGHT}
            />
            <DashboardSegregationTables
              defaultExpanded={true}
              data={metrics}
              currency={currency}
              rates={rates}
              convertOthers={convertOthers}
              loading={churnLoading}
              testMode={testMode}
            />
          </div>
        </CollapsibleSection>
        <CollapsibleSection title="Retention">
          <>
            {isStatsSimulation() && hasCredentialsFor(testMode, site) && (
              <AlertInfo text="The data in this view is a sample. Your customers' data will appear here once the first event arrives." />
            )}
          </>
          <KpiBlocks
            statsData={statsData}
            churnData={metrics}
            currency={currency}
            numMonths={numMonths}
            rates={rates}
            convertOthers={convertOthers}
            mapper={transformRetentionKpiBlocksData}
            loading={churnLoading || statsLoading}
          />
          <div className="flex flex-wrap mb-8">
            <DashboardChurnChart
              loading={churnLoading}
              data={metrics}
              currency={currency}
              numMonths={Number(numMonths)}
              rates={rates}
              convertOthers={convertOthers}
              menuClosed={menuClosed}
              minHeight={CHART_MIN_HEIGHT}
            />
            <DashboardRetentionChart
              numMonths={numMonths}
              currency={currency}
              data={statsData}
              rates={rates}
              convertOthers={convertOthers}
              menuClosed={menuClosed}
              loading={statsLoading}
              minHeight={CHART_MIN_HEIGHT}
            />
            <RetentionCohortChart
              data={metrics}
              numMonths={numMonths}
              defaultExpanded={true}
              loading={churnLoading}
              rates={rates}
              convertOthers={convertOthers}
              currency={currency}
            />
          </div>
        </CollapsibleSection>
        <CollapsibleSection title="Exit Survey">
          <div className="my-4">
            <DashboardCancellationsChart
              selectedLanguage={selectedLanguage}
              updateSelectedLanguage={setSelectedLanguage}
              loading={statsLoading}
              data={statsData}
              setNumMonths={setNumMonths}
              numMonths={Number(numMonths)}
              setCurrency={setCurrency}
              currency={currency}
              defaultExpanded={true}
              rates={rates}
              convertOthers={convertOthers}
              updateConvertOthers={setConvertOthers}
            />
          </div>
        </CollapsibleSection>
      </div>
    </div>
  );
};

export default withFirebase(Dashboard);
