import React from "react";
import Header from "../../components/Header/Header";

const LegalPrivacy = () => {
  return (
    <div className="container-main">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col"/>
          <div className="col-12 col-md-12 my-md-5 my-4 px-4 section p-5">
            <h2 className="py-4">Privacy Policy</h2>

            <p>Effective date: Jan 25, 2021</p>

            <p>
              Tenstars ("us", "we", or "our") operates the https://tenstars.co
              website (hereinafter referred to as the "Service").
            </p>

            <p>
              This page informs you of our policies regarding the collection,
              use, and disclosure of personal data when you use our Service and
              the choices you have associated with that data.
            </p>

            <p>
              We use your data to provide and improve the Service. By using the
              Service, you agree to the collection and use of information in
              accordance with this policy. Unless otherwise defined in this
              Privacy Policy, the terms used in this Privacy Policy have the
              same meanings as in our Terms and Conditions, accessible from
              https://tenstars.co
            </p>

            <h3 className="py-4">Definitions</h3>
            <ul>
              <li>
                <p>
                  <strong>Service</strong>
                </p>
                <p>
                  Service is the https://tenstars.co website operated by
                  Tenstars
                </p>
              </li>
              <li>
                <p>
                  <strong>Personal Data</strong>
                </p>
                <p>
                  Personal Data means data about a living individual who can be
                  identified from those data (or from those and other
                  information either in our possession or likely to come into
                  our possession).
                </p>
              </li>
              <li>
                <p>
                  <strong>Usage Data</strong>
                </p>
                <p>
                  Usage Data is data collected automatically either generated by
                  the use of the Service or from the Service infrastructure
                  itself (for example, the duration of a page visit).
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies</strong>
                </p>
                <p>
                  Like many websites, we and our marketing partners, affiliates,
                  analytics, and service providers use “cookies” to collect
                  information. A cookie is a small data file that we transfer to
                  your computer’s hard disk for record-keeping purposes. We use
                  both persistent cookies that remain on your computer or
                  similar device (such as to save your registration ID and login
                  password for future logins to the Service and to track your
                  compliance with the Tenstars Terms of Service) and session ID
                  cookies, which expire at the end of your browser session (for
                  example, to enable certain features of the Service, to better
                  understand how Tenstars Users interact with the Service and to
                  monitor aggregate usage by Tenstars Users and web traffic
                  routing on the Service). You can control the use of cookies at
                  the individual browser level, but if you choose to disable
                  cookies, it may limit your use of certain features or
                  functionality of the Service.
                </p>
              </li>
            </ul>

            <h3 className="py-4">Information Collection and Use</h3>
            <p>
              Like other online companies, we receive technical information when
              you use our Services. We use these technologies to analyze how
              people use our Services, to improve how our Site functions, to
              save your log-in information for future sessions, and to serve you
              with advertisements that may interest you.
            </p>
            <p>
              We and our third party service providers, including analytics and
              third party content providers, may automatically collect certain
              information from you whenever you access or interact with the
              Service. This information may include, among other information,
              the browser and operating system you are using, the URL or
              advertisement that referred you to the Service, the search terms
              you entered into a search engine that led you to the Service,
              areas within the Service that you visited, which links you clicked
              on, which pages or content you viewed and for how long, other
              similar information and statistics about your interactions, such
              as content response times, download errors and length of visits to
              certain pages and other information commonly shared when browsers
              communicate with websites. We may combine this automatically
              collected log information with other information we collect about
              you. We do this to improve services we offer you, and to improve
              marketing, analytics, and site functionality.
            </p>

            <h3 className="py-4">Types of Data Collected</h3>

            <h4>Personal Data</h4>
            <p>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you ("Personal Data"). Personally identifiable
              information may include, but is not limited to:
            </p>

            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Cookies and Usage Data</li>
            </ul>

            <h4>Usage Data</h4>

            <p>
              We may also collect information how the Service is accessed and
              used ("Usage Data"). This Usage Data may include information such
              as your computer's Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that you
              visit, the time and date of your visit, the time spent on those
              pages, unique device identifiers and other diagnostic data.
            </p>

            <h4>Tracking & Cookies Data</h4>
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.
            </p>
            <p>
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyse our Service.
            </p>
            <p>
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
            <p>Examples of Cookies we use:</p>
            <ul>
              <li>
                <strong>Session Cookies.</strong> We use Session Cookies to
                operate our Service.
              </li>
              <li>
                <strong>Preference Cookies.</strong> We use Preference Cookies
                to remember your preferences and various settings.
              </li>
              <li>
                <strong>Security Cookies.</strong> We use Security Cookies for
                security purposes.
              </li>
            </ul>

            <h3 className="py-4">Use of Data</h3>

            <p>Tenstars uses the collected data for various purposes:</p>
            <ul>
              <li>To provide and maintain the Service</li>
              <li>To notify you about changes to our Service</li>
              <li>
                To allow you to participate in interactive features of our
                Service when you choose to do so
              </li>
              <li>To provide customer care and support</li>
              <li>
                To provide analysis or valuable information so that we can
                improve the Service
              </li>
              <li>To monitor the usage of the Service</li>
              <li>To detect, prevent and address technical issues</li>
            </ul>

            <h3 className="py-4">Transfer Of Data</h3>
            <p>
              Your information, including Personal Data, may be transferred to -
              and maintained on - computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ than those from your jurisdiction.
            </p>
            <p>
              If you are located outside United Kingdom and choose to provide
              information to us, please note that we transfer the data,
              including Personal Data, to United Kingdom and process it there.
            </p>
            <p>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>
            <p>
              Tenstars will take all steps reasonably necessary to ensure that
              your data is treated securely and in accordance with this Privacy
              Policy and no transfer of your Personal Data will take place to an
              organization or a country unless there are adequate controls in
              place including the security of your data and other personal
              information.
            </p>


            <h3 className="py-4">Legal Requirements</h3>
            <p>
              Tenstars may disclose your Personal Data in the good faith belief
              that such action is necessary to:
            </p>
            <ul>
              <li>To comply with a legal obligation</li>
              <li>To protect and defend the rights or property of Tenstars</li>
              <li>
                To prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li>
                To protect the personal safety of users of the Service or the
                public
              </li>
              <li>To protect against legal liability</li>
            </ul>

            <p>
              As an European citizen, under GDPR, you have certain individual
              rights. You can learn more about these guides in the{" "}
              <a href="https://termsfeed.com/blog/gdpr/#Individual_Rights_Under_the_GDPR">
                GDPR Guide
              </a>
              .
            </p>

            <h3 className="py-4">Security of Data</h3>
            <p>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>

            <h3 className="py-4">Service Providers</h3>
            <p>
              We may employ third party companies and individuals to facilitate
              our Service ("Service Providers"), to provide the Service on our
              behalf, to perform Service-related services or to assist us in
              analyzing how our Service is used. These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </p>

            <h3 className="py-4">Analytics</h3>
            <p>Information Collected Automatically</p>
            <p>
              Like other online companies, we receive technical information when
              you use our Services. We use these technologies to analyze how
              people use our Services, to improve how our Site functions, to
              save your log-in information for future sessions, and to serve you
              with advertisements that may interest you.
            </p>
            <p>
              We and our third party service providers, including analytics and
              third party content providers, may automatically collect certain
              information from you whenever you access or interact with the
              Service. This information may include, among other information,
              the browser and operating system you are using, the URL or
              advertisement that referred you to the Service, the search terms
              you entered into a search engine that led you to the Service,
              areas within the Service that you visited, which links you clicked
              on, which pages or content you viewed and for how long, other
              similar information and statistics about your interactions, such
              as content response times, download errors and length of visits to
              certain pages and other information commonly shared when browsers
              communicate with websites. We may combine this automatically
              collected log information with other information we collect about
              you. We do this to improve services we offer you, and to improve
              marketing, analytics, and site functionality.
            </p>
            <p>
              The information we collect also includes the Internet Protocol
              (“IP”) address or other unique device identifier (“Device
              Identifier”) for any device (computer, mobile phone, tablet, etc.)
              used to access the Service. A Device Identifier is a number that
              is automatically assigned or connected to the device you use to
              access the Service, and our servers identify your device by its
              Device Identifier. Some mobile service providers may also provide
              us or our third party service providers with information regarding
              the physical location of the device used to access the Service.
            </p>

            <h3 className="py-4">Links to Other Sites</h3>
            <p>
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>

            <h3 className="py-4">Children's Privacy</h3>
            <p>
              Our Service does not address anyone under the age of 18
              ("Children").
            </p>
            <p>
              We do not knowingly collect personally identifiable information
              from anyone under the age of 18. If you are a parent or guardian
              and you are aware that your Child has provided us with Personal
              Data, please contact us. If we become aware that we have collected
              Personal Data from children without verification of parental
              consent, we take steps to remove that information from our
              servers.
            </p>

            <h3 className="py-4">Changes to This Privacy Policy</h3>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update the
              "effective date" at the top of this Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>

            <h3 className="py-4">Contact Us</h3>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us:
            </p>
            <ul>
              <li>By email: team@tenstars.co</li>
            </ul>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </div>
  );
};

export default LegalPrivacy;
