import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import FigureHeader from "../FigureHeader";
import SubscriptionsOrRevenue from "../SubscriptionsOrRevenue";
import { ChartMode } from "../../../domain/DomainModel";

export interface MetricModel {
  name: string;
  unit: string;
  figure?: number;
  stringFigure?: string;
  prevFigure?: number;
  descriptor: string;
}

export interface SingleMetricProps {
  subscriptionMetric?: MetricModel;
  revenueMetric?: MetricModel;
  description: string;
  reverseDelta?: boolean;
  hideSwitch?: boolean;
  convertOthers: boolean;
  currency: string;
  defaultMode: ChartMode;
  loading?: boolean;
  overrideMrr?: string;
}

export default function SingleMetric(props: SingleMetricProps) {
  const [chartMode, setChartMode] = useState<ChartMode>(props.defaultMode);

  const metric: MetricModel | undefined =
    chartMode === "subscriptions"
      ? props.subscriptionMetric
      : props.revenueMetric;

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{metric?.name}</Popover.Title>
      <Popover.Content>{props.description}</Popover.Content>
    </Popover>
  );

  return (
    <div className="m-2.5">
      <OverlayTrigger placement="top" overlay={popover}>
        <div
          className="overflow-hidden shadow-md border rounded-lg relative bg-white"
          style={{ minHeight: "300px", minWidth: "250px" }}
        >
          <div className="p-4 h-100 mx-auto">
            <div className="flex flex-col justify-between h-100">
              <div className="ml-2 flex-1">
                <FigureHeader
                  figure={metric?.figure}
                  prevFigure={metric?.prevFigure}
                  unit={metric?.unit}
                  name={metric?.name || ""}
                  reverseDelta={props.reverseDelta}
                  stringFigure={metric?.stringFigure}
                  descriptor={metric?.descriptor || ""}
                  loading={props.loading}
                />
              </div>
              {!props.hideSwitch && !props.loading && (
                <div className="pt-4">
                  <SubscriptionsOrRevenue
                    updateChartMode={setChartMode}
                    chartMode={chartMode}
                    currency={props.currency}
                    convertOthers={props.convertOthers}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
}
